import React from 'react';

import '../sass/main.scss';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import Image from '../assets/korean3.jpeg';
import Image1 from '../assets/korean-alphabet.jpg';
import Image2 from '../assets/Korean-numbers.jpg';
import Image3 from '../assets/Korean-sentence-structure.jpg';

const korean = () => {
  const head = () => (
    <MetaTags>
      <title>Learn korean with LinguaPsych | LinguaPsych</title>
      <meta
        name="description"
        content="Learn korean with easy blogs, learn about korean grammar, korean vocubalary and patterns with easy examples."
      />{' '}
      <link rel="canonical" href="https://linguapsych.com/korean/" />
      <meta
        property="og:title"
        content="Learn korean with easy blogs, learn about korean grammar, korean vocubalary and patterns with easy examples."
      />{' '}
      <meta
        property="og:description"
        content="Learn korean with easy blogs, learn about korean grammar, korean vocubalary and patterns with easy examples."
      />{' '}
      <meta property="og:type" content="website" />
      <meta property="og:url" href="https://linguapsych.com/korean/" />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/korean.jpeg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/korean.jpeg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}

      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_DOMAIN_PRODUCTION}`}>
          <div className="article__general-banner">
            <div className="article__general-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img src={Image} alt="Korean" className="article__featured-image" />
          <div className="article__header--text">
            <h1 className="article__title ">
              {' '}
              {/* <span> Learn korean</span>{' '} */}
            </h1>
          </div>
        </header>
        <div className="row">
          <div className="col-1-of-3">
            <Link to="/korean-alphabet-letters/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img src={Image1} style={{ width: '100%' }} height="170px" />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>Korean Alphabet and Accents</h2>
                  </div>
                  <div>
                    <p>
                      Intensive Guide to Korean Alphabet, Letters, Characters
                      with Pronunciation
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-1-of-3">
            <Link to="/korean-numbers/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img src={Image2} style={{ width: '100%' }} height="170px" />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>Learn Counting Korean Numbers</h2>
                  </div>
                  <div>
                    <p>
                      Beginner&#039;s Guide to Korean Numbers | Counting in
                      Korean
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-1-of-3">
            <Link to="/korean-sentence-structure/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img src={Image3} style={{ width: '100%' }} height="170px" />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>Korean Sentence Structure</h2>
                  </div>
                  <div>
                    <p>
                      Complete Guide to Korean Sentence Structure, Korean
                      Particles
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default korean;
