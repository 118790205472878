import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

import '../sass/main.scss';
import Image from '../assets/Spanish-def-indef-articles.png';
import Spanish from '../assets/spanish_course_bottom.png';

const spanishDefinite = () => {
  const head = () => (
    <MetaTags>
      <title>
        Complete Guide To Spanish Definite And Indefinite Articles | LinguaPsych
      </title>
      <meta
        name="description"
        content="This is the complete guide on definite &amp; indefinite articles in Spanish. It has every piece of information available on Spanish Articles basic to advanced."
      />{' '}
      <link
        rel="canonical"
        href="https://linguapsych.com/spanish-definite-indefinte-articles/"
      />
      <meta
        property="og:title"
        content="This is the complete guide on definite &amp; indefinite articles in Spanish. It has every piece of information available on Spanish Articles basic to advanced."
      />{' '}
      <meta
        property="og:description"
        content="This is the complete guide on definite &amp; indefinite articles in Spanish. It has every piece of information available on Spanish Articles basic to advanced."
      />{' '}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        href="https://linguapsych.com/spanish-definite-indefinte-articles/"
      />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/Spanish-def-indef-articles.png`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/Spanish-def-indef-articles.png`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}

      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_SPANISH_COURSE}`}>
          <div className="article__spanish-banner">
            <div className="article__spanish-banner__image"> </div>
          </div>
        </a>

        <header className="article__header">
          <img
            src={Image}
            alt="Spanish-definite-indefinite-articles"
            className="article__featured-image"
          />
          <div className="article__header-elements">
            <h1 className="article__title ">
              {' '}
              <span
                style={{ fontSize: '5rem' }}
                className="article__highlight--black "
              >
                {' '}
                Complete guide to Spanish definite and Indefinite articles
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <h2 className="u-margin-bottom-medium">
              What are Definite and Indefinite articles?
            </h2>
            <p>
              Before getting to the main topic, let’s understand what an article
              is. An article is a unique adjective which modifies a noun.
            </p>
            <p>
              As we know in English, we have three articles a, an & the, out of
              which only “The” is a Definite article. On the other hand,
              Indefinite articles are a & an.
            </p>
            <p>
              Here’s good news for all of us English speakers that use of
              Articles in Spanish is more or less the same as in English. There
              are some exceptions which we are going to discuss in this post.
            </p>
            <p>
              Definite Article is a special type of adjective which is used to
              modify a noun. It takes a position when a speaker believes the
              listener is already aware of the subject. On the other hand,
              Indefinite article takes place whenever we want to use a noun in a
              general way and not focusing on a particular term.
            </p>
            <p>
              In English, we have only one definite article, “THE.” Whenever
              we’re using this definite article, we already know which noun
              we’re referring.
            </p>
            <p>
              In comparison to English Spanish has four Definite articles: el,
              la, Los & las. Out of those
            </p>
            <div style={{ textAlign: 'center' }}>
              <p>EL & LA ⇒ Singular</p>
              <p>Las & Los ⇒ Plural</p>
            </div>
            <p>
              In English, we have 2 Indefinite Articles A & An. Whenever we’re
              using them, we are not focusing on one particular noun but in a
              general way.
            </p>
            <p>
              In comparison to English Spanish has 4 Indefinite articles: Un Una
              Unos & Unas
            </p>
            <p>
              You can check my previous post on Spanish Articles & Genders for
              clarity. If you still have some confusion, let’s look at some
              examples here:
            </p>
            <p>
              English: I’m in the office ( Definite article) – I’m in the office
              where I work
            </p>
            <p>Spanish: Estoy en la oficina ( Definite article)</p>
            <p>VS</p>
            <p>
              English: I’m in an office ( Indefinite article) – I can be in any
              office
            </p>
            <p>Spanish: Estoy en una oficina ( Indefinite article)</p>
            <p>English: I want the car( I know which car I want to buy)</p>
            <p>Spanish: Quiero el coche</p>
            <p>VS</p>
            <p>English: I want a car( I only want a car, can be any of them)</p>
            <p>Spanish: Quiero un coche</p>
            <p>
              As you can see in the above examples, Definite articles are more
              specific & Indefinite articles are more general.
            </p>
            <p>
              We all know the use of Definite & Indefinite articles in English.
              Below we’re going to understand the situations where we can use
              Definite Articles & Indefinite Articles like English
            </p>
            <h2 className="u-margin-bottom-medium">
              The beginner’s guide to Definite Articles in Spanish
            </h2>
            <h3>Using Definite Articles with titles or general objects</h3>
            <p>
              In Spanish, whenever we are addressing any individual or an
              object, using the definite article before them is very common.
              Also, if you are talking about any general topic, may it be the
              food, book, or a place, we will use Definite Articles. Not using
              them will look very unnatural. Let’s look at some examples:
            </p>
            <p>Los tigres son rapidos (Tigers are fast)</p>
            <p>El doctor es muy inteligente ( Doctor is smart)</p>
            <p>
              La gente in japón es muy educada ( People in Japan are very
              polite)
            </p>
            <p>La Cerveza está fría ( Beer is cold)</p>
            <h4>Things to keep in mind:</h4>
            <p>
              In the above-stated examples, we can refer to a specific Beer, or
              we can talk about beer in general. Definite articles will be used
              either way, unlike English, where we use Definitive article when
              we want to stress on a specific noun.
            </p>
            <h3>Using Definite articles with Languages</h3>
            <p>
              Unlike English, we use Definite articles before the names of
              languages when they are the subject. Please keep in mind that if a
              language is not the subject of the sentence but an object, we will
              omit the Definitive article. Let’s look at some examples:
            </p>
            <h3>When language is the main Subject</h3>
            <ul>
              <li>Spanish: El chino es muy dificil</li>
              <li>English: Chinese is very difficult</li>
              <p></p>
              <li>Spanish: El Japonés tiene más de 2000 caracteres</li>
              <li className="u-margin-bottom-medium">
                English: Japanese has more than 2000 characters
              </li>

              <h3>
                Using Definite article when the name of the language is an
                object
              </h3>
              <li>Spanish: Yo hablo Italiano</li>
              <li>English: I speak Italian</li>
              <p></p>
              <li>Spanish: No entiendo ingles</li>
              <li className="u-margin-bottom-medium">
                English: I don’t understand English
              </li>
              <h2 className="u-margin-bottom-medium">
                Using Definite Articles with Days of the week
              </h2>
              <p>
                Days of the week in Spanish are all masculine and take the
                article el before them. Unless we’re using verb Ser before them,
                we’ll always use Definite articles before the days of the week.
              </p>
              <p>Let’s look at some examples:</p>
              <li>Spanish: Vamos a encontrar el lunes</li>
              <li className="u-margin-bottom-medium">
                English: Let’s meet on Monday
              </li>
              <li>Spanish: Tengo una cita el Domingo</li>
              <li className="u-margin-bottom-medium">
                English: I have an appointment on Sunday
              </li>
              <h2 className="u-margin-bottom-medium">
                When days of the week appear with the verb Ser
              </h2>
              <li>Spanish: Hoy es sábado</li>
              <li className="u-margin-bottom-medium">
                English: Today is Saturday.
              </li>
              <li>Spanish: Mañana es Martes</li>
              <li className="u-margin-bottom-medium">
                English: Tomorrow is Tuesday
              </li>
              <h2 className="u-margin-bottom-medium">
                Using Definite articles when telling time
              </h2>
              <p>
                Whenever telling the time in Spanish Definite article is used.
                Time telling will always take the feminine article with it, and
                except 1 0 clock all the articles will be plural in nature.
                Let’s have a look at some examples:
              </p>
              <li>Spanish: Es la una y media</li>
              <li className="u-margin-bottom-medium">
                English: It’s half past one
              </li>
              <li>Spanish: Vamos a encontrar a las cinco</li>
              <li className="u-margin-bottom-medium">
                English: Let’s meet at 5
              </li>
              <h2 className="u-margin-bottom-medium">
                Using Definite Articles with Body parts
              </h2>
              <p>
                Whenever talking about the body parts in Spanish, for example,
                talking about the pain or describing the features of a body
                part, use definite articles. Let’s look at some cases:
              </p>
              <li>Spanish: Me duele la cabeza</li>
              <li className="u-margin-bottom-medium">
                English: My head is hurting
              </li>
              <li>Spanish: Ella tiene los ojos castaños</li>
              <li className="u-margin-bottom-medium">
                English: She has brown eyes.
              </li>
              <h2 className="u-margin-bottom-medium">
                The beginner’s guide to Indefinite Articles in Spanish
              </h2>
              <h3>Use of Indefinite Articles in Spanish vs. English</h3>
              <p>
                Un & Una replace A & An in English, but the question is can you
                do word to word translation when using Indefinite articles in
                English or Spanish? I’m afraid the answer is no.
              </p>
              <p>
                There are cases where the same rule applies to both English &
                Spanish, but also there a lot of instances where rules are
                entirely different. Let’s take a look at where not to use
                Indefinite Articles in Spanish
              </p>
              <h3>
                Don’t use Indefinite articles with the verb Ser unless you want
                a modification
              </h3>
              <p>
                Whenever you want to express your profession, religion, social
                status, or feature(physical or non-physical) don’t use the
                indefinite article with it. Use it only when you want to modify
                it with the adjective. If you’re going to use it with the
                modifiers, you’ll have to use the articles. Let’s have a look
                here:
              </p>
              <li>Spanish: Yo Soy doctor (Without modification)</li>
              <li className="u-margin-bottom-medium">
                English: I’m a doctor (Without modification)
              </li>
              <p>VS</p>
              <li>Spanish: Soy un doctor bueno (With modification)</li>
              <li className="u-margin-bottom-medium">
                English: I’m a good doctor (With modification)
              </li>
              <p>
                As you can see above that English uses Indefinite articles in
                both the cases but in Spanish unless we used modifiers we didn’t
                use the indefinite articles.
              </p>
              <p>Let’s look at one more example:</p>
              <li>Spanish: El es inteligente ( With adjective)</li>
              <li className="u-margin-bottom-medium">
                English: He is intelligent
              </li>
              <p>VS</p>
              <li>
                Spanish: El es un chico inteligente ( Modification of noun here)
              </li>
              <li className="u-margin-bottom-medium">
                English: He is an intelligent boy( Modification of noun here)
              </li>
              <h3 className="u-margin-bottom-medium">
                Don’t use Indefinite articles with large numbers.
              </h3>
              <p>
                English tends to use Indefinite article “A” with large numbers
                like thousand and ten thousand depending on the context,
                although it’s an unstated rule but very common in English. On
                the other hand, Spanish follows the same rule of not using
                Indefinite articles with numbers. Let’s look at some examples:
              </p>
              <li>English: I’m going to earn a thousand dollars.</li>
              <li className="u-margin-bottom-medium">
                Spanish: Yo voy a ganar mil dolares (Without Indefinite article)
              </li>

              <li>English: The USA is a thousand miles far.</li>
              <li className="u-margin-bottom-medium">
                Spanish: Estados Unidos es mil millas lejos ( Without Indefinite
                Article)
              </li>
              <h3 className="u-margin-bottom-medium">
                Don’t use Indefinite Articles with prepositions Con & Sin.
              </h3>
              <p>
                English tends to use Indefinite articles with prepositions like
                With & Without, on the other hand, Spanish only uses Indefinite
                articles with them when something important needs to be focused.
              </p>
              <li>English: I can’t work without a Computer</li>
              <li className="u-margin-bottom-medium">
                Spanish: No puedo trabajar sin ordenador ( Omitting Article
                here)
              </li>
              <p>On the above is a simple phrase and the article can omit.</p>
              <p>VS</p>

              <li>
                English: He lived for two days without a single drop of water
              </li>
              <li className="u-margin-bottom-medium">
                Spanish: Vivió 2 días sin una gota de agua (Not omitting article
                here)
              </li>
              <p>
                The article was not omitted here because it was essential to
                emphasize that he survived without a drop of water for two days.
              </p>
              <p>
                Let’s take a look at the use of Indefinite article with
                preposition CON.
              </p>
              <li>English: I eat with a chopstick.</li>
              <li className="u-margin-bottom-medium">
                Spanish: Yo como con palillo (Omitting the article here)
              </li>
              <p>
                It is widespread to use chopstick hence no need to put the
                article here.
              </p>
              <p>VS</p>
              <li>English: I opened the lock with a hairpin.</li>
              <li className="u-margin-bottom-medium">
                Spanish: Abrí la cerradura con una horquilla (Not omitting the
                article here)
              </li>
              <p>
                It is essential to specify that the lock was opened using a
                hairpin; hence, there is no need to omit the article.
              </p>
              <h3 className="u-margin-bottom-medium">
                Don’t use Indefinite articles with large numbers.
              </h3>
              <p>
                English uses Indefinite articles when used with exclamations
                with the word What, on the other hand, Spanish replaces the word
                what with que & omits the indefinite article between them. Let’s
                look at the example here.
              </p>
              <li>English: What a shame!</li>
              <li className="u-margin-bottom-medium">
                Spanish:!Qué lastima! (Omitting the article)
              </li>

              <li>English: What a pity!</li>
              <li className="u-margin-bottom-medium">
                Spanish:!Que pena! (Omitting article here) s es mil millas lejos
                ( Without Indefinite Article)
              </li>
              <h3 className="u-margin-bottom-medium">
                Don’t use Indefinite Articles with some special verbs.
              </h3>
              <p>
                Most used verbs in Spanish like tener (to have), llevar (to
                wear), comprar (to buy) don’t take indefinite articles with
                them. On the other hand, their English counterpart use
                Indefinite articles with them. Let’s look at some examples:
              </p>
              <li>English: I don’t have a house</li>
              <li className="u-margin-bottom-medium">
                Spanish: No tengo casa ( Omitting article here)
              </li>

              <li>English: I’m wearing a jacket.</li>
              <li className="u-margin-bottom-medium">
                Spanish: llevo chaqueta ( Omitting article here)
              </li>
            </ul>
            <h3 className="u-margin-bottom-medium">Points to notice</h3>
            <p>
              Spanish indefinite articles can mean a & an in some situations. It
              can also mean “one” in most of the cases. It is essential to
              understand the context.
            </p>
            <p>
              In English when you’re referring to one cat and one mouse, you can
              use the indefinite article once and still get away with it, for
              example, A cat and mouse.
            </p>
            <p>
              In Spanish, you’ll have to use the article 2 times for the same
              text A cat and mouse, for example, Un gato y un raton.
            </p>
            <h3 className="u-margin-bottom-medium">Conclusion:</h3>
            <h4>In this article, we learned:</h4>
            <p>What are definite and indefinite articles?</p>
            <p>Using Definite articles with titles and general topics</p>
            <p>Using definite article with languages</p>
            <p>Using definite article with days of the week</p>
            <p>Using definite article when telling time</p>
            <p>Using definite article when mentioning body parts</p>
            <p>Omitting Indefinite article with verb ser</p>
            <p>Omitting Indefinite article when using large numbers</p>
            <p>Omitting Indefinite article with some prepositions</p>
            <p>Omitting Indefinite article with exclamations</p>
            <p>Omitting Indefinite article with some verbs</p>
            <h4>Also check our exclusive guide on:</h4>
            <ul>
              <li>
                <a href="/direct-indirect-object-pronouns/">
                  Basics of Spanish Nouns and Articles
                </a>
              </li>
              <li>
                <a href="/spanish-plural-nouns/">
                  How to make Nouns Plural in Spanish
                </a>
              </li>
              <li>
                <a href="/subject-personal-pronouns-in-spanish/">
                  Complete guide on Subject Pronouns in Spanish
                </a>
              </li>
              <li>
                <a href="/spanish-nouns-articles-genders/">
                  Spanish Direct and Indirect Object Pronouns
                </a>
              </li>
            </ul>
          </div>
          <div className="article__blog-container__bottom">
            <div
              className="article__blog-container__bottom__left"
              to="/learn-spanish"
            >
              <Link to="/learn-spanish">
                <img
                  className="article__blog-container__bottom__image"
                  src={Spanish}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/spanish-days-of-the-week/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Days of the Week in Spanish
                </li>
              </Link>
              <Link to="/subject-personal-pronouns-in-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Subject Personal Pronouns
                </li>
              </Link>

              <Link to="/spanish-nouns-articles-genders/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Nouns, articles and Genders
                </li>
              </Link>
              <Link to="/preterite-vs-imperfect/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Preterite vs Imperfect
                </li>
              </Link>
              <Link to="/months-in-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Months in Spanish
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default spanishDefinite;
