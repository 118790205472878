import React from 'react';
import { Link } from 'react-router-dom';

import '../sass/main.scss';
import MetaTags from 'react-meta-tags';
import Table from '../assets/Spanish-Alphabets.png';
import Image from '../assets/spanish-alphabet-list.jpg';
import Spanish from '../assets/spanish_course_bottom.png';

const spanishAlphabets = () => {
  const head = () => (
    <MetaTags>
      <title>
        Kick start Spanish Alphabets, Pronunciation &amp; Accents. | LinguaPsych
      </title>
      <meta
        name="description"
        content="This is a beginner friendly guide to Spanish Alphabets, Pronunciation &amp; Accents. This will help to understand every problem a beginner faces with Spanish letters."
      />{' '}
      <link
        rel="canonical"
        href="https://linguapsych.com/spanish-alphabets-accents-pronunciation/"
      />
      <meta
        property="og:title"
        content="This is a beginner friendly guide to Spanish Alphabets, Pronunciation &amp; Accents. This will help to understand every problem a beginner faces with Spanish letters."
      />{' '}
      <meta
        property="og:description"
        content="This is a beginner friendly guide to Spanish Alphabets, Pronunciation &amp; Accents. This will help to understand every problem a beginner faces with Spanish letters."
      />{' '}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        href="https://linguapsych.com/spanish-alphabets-accents-pronunciation/"
      />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/Spanish-Alphabets.png`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/Spanish-Alphabets.png`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}

      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_SPANISH_COURSE}`}>
          <div className="article__spanish-banner">
            <div className="article__spanish-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="spanish-articles"
            className="article__featured-image"
          />
          <div
            style={{ marginTop: '-45px' }}
            className="article__header-elements"
          >
            <h1 className="article__title ">
              {' '}
              <span
                style={{ fontSize: '5rem' }}
                className="article__highlight--black "
              >
                {' '}
                Spanish Alphabets, Pronunciation and Accents
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <h2>SPANISH ALPHABETS</h2>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table}
                alt="spanish-articles-table"
                className="article__big-images"
                style={{ width: '100%' }}
              />
            </div>
            <h2 className="u-margin-bottom-medium">Remember</h2>
            <ul className="u-margin-bottom-medium">
              <li>
                All the alphabets are feminine in nature: la a, la be, la ce……..
                la z.
              </li>
              <li>
                Unlike other foreign languages, Spanish has few double
                consonants. With respect to the pronunciation there are two
                cases:
              </li>
              <p></p>
              <p>(ll and rr) have a single sound and not two.</p>
              <p>(cc and nn) have two different sounds.</p>
              <li>
                In some Latin American countries, the letters be and uve are
                called be larga and ve corta.
              </li>
            </ul>
            <h2 className="u-margin-bottom-medium">
              Spanish Letters and Pronunciation
            </h2>
            <p>
              <strong>
                In general, every letter corresponds to one single sound and one
                single sound corresponds to one letter, but there are some
                special cases.
              </strong>
            </p>
            <div className="row">
              <div>
                <ul className="u-margin-bottom-medium">
                  <h3>The letter C has two different sounds:</h3>
                  <li>
                    (K), before a, o, u and at the end of a syllable: casa,
                    cuento, copa, acto.
                  </li>
                  <li>(S), before c and i : cero, cien.</li>
                </ul>
              </div>
              <h3 className="u-margin-bottom-medium">
                The letter CH is pronounced as ch in English word chat .
              </h3>
              <div>
                <ul className="u-margin-bottom-medium">
                  <h3>The letter G has two sounds:</h3>
                  <li>(kh), before e or i: genius, ginebra.</li>
                  <li>
                    (g), before a, o and u: gato, gorro, gusta. When letter u is
                    used after g, before letters e and i, it is always muted:
                    guitarra, guerra. For letter u to sound, dieresis (¨) is
                    used: vergüenza, lingüística.
                  </li>
                </ul>
              </div>
              <h3 className="u-margin-bottom-medium">
                The letter H is silent and has no sound.
              </h3>
              <h3>The letter J always sounds as (kh).</h3>
              <p className="u-margin-bottom-medium">
                Most of the times the letter is followed by a, o & u: jardín,
                joven, jugar. and sometimes followed by e & i: jefe, jirafa.
              </p>
              <h3>The letter K produces the same sound (k). </h3>
              <p>
                Spanish has originated close to zero words with this letter. It
                is used with words originated from a different language: Kilo,
                Irak.
              </p>
              <h4 className="u-margin-bottom-medium">
                The letter LL has two different pronunciations depending on the
                region you are in, but every Spanish speaking native pronounces
                it very close to y in you in English.
              </h4>
              <p className="u-margin-bottom-medium">
                The letter QU is similar to the sound (k). It’s used only when
                the sound is followed by e or i.
              </p>
              <h4 className="u-margin-bottom-medium">
                R/RR produce a strong single sound when a word begins with it
                (reloj), when it is used twice (arroz), at the end of a syllable
                (corta) or before letters l or n (alredador).
              </h4>
              <h3 className="u-margin-bottom-medium">
                The letter V sounds like letter b.
              </h3>
              <h3 className="u-margin-bottom-medium">
                The letter W is used only with the words originated from
                different languages. It is pronounced as u or gu (web) and
                sometimes as b too.
              </h3>
              <h3 className="u-margin-bottom-medium">
                The letter Z sounds like (S). It always sound as S when followed
                by letters a, o, u, or at the end of a syllable (zapato, zona,
                paz). Just in the above cases.
              </h3>
              Spanish Accent Marks
              <ul>
                <li>
                  In Latin America, in the south of Spain & in Canarias, the
                  letter C is always pronounced as S.
                </li>
                <li>
                  In Latin America, in the south of Spain & in Canarias, the
                  letter Zis always pronounced as S.
                </li>
              </ul>
            </div>
            <h2 className="u-margin-bottom-medium">Spanish Accent Marks</h2>
            <ul>
              <h3 className="u-margin-bottom-medium">
                In Spanish, all the words have a strong syllable.
              </h3>
              <li>
                When the strong syllable is at the end, they are called palabras
                agudas (Sharp words)
              </li>
              <p>canción,vivir, mamá</p>
              <li>
                When the strong syllable is at the second last position, they
                are called palabras graves or llanas. They are out there in
                majority: casa, árbol, lunes.
              </li>
              <li>
                When the strong syllable is at left to the second last position,
                they are called palabras esdrújulas: matemáticas, práctica.
              </li>
              <li className="u-margin-bottom-medium">
                When the strong syllable is at the fourth position from the end,
                they are called Sobreesdrújulas: diciéndomelo
              </li>
              <h3 className="u-margin-bottom-medium">
                Not all Spanish words have visual accent marks. Below are the
                rules on where to put the accent marks:
              </h3>
              <li>
                Palabras agudas (Sharp words) carry an accent mark when they end
                with -n, -s or a vowel: canción, jamás, mamá.
              </li>
              <li>
                Palabras graves or llanas carry an accent mark when they don’t
                end with -n, -s or a vowel: trébol, mártir, álbum.
              </li>
              <li className="u-margin-bottom-medium">
                Palabras esdrújulas and Sobreesdrújulas always carry an accent
                mark, irrespective of the position.
              </li>
            </ul>
            <h2 className="u-margin-bottom-medium">Remember?</h2>
            <p>
              In Spanish, exclamation marks and interrogation marks are always
              used before the start of the sentence and after the sentence.
            </p>
          </div>
          <div className="article__blog-container__bottom">
            <div
              className="article__blog-container__bottom__left"
              to="/learn-spanish"
            >
              <Link to="/learn-spanish">
                <img
                  className="article__blog-container__bottom__image"
                  src={Spanish}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/spanish-definite-indefinte-articles/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Definite & Indefinite Articles
                </li>
              </Link>
              <Link to="/subject-personal-pronouns-in-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Subject Personal Pronouns
                </li>
              </Link>

              <Link to="/spanish-nouns-articles-genders/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Nouns, articles and Genders
                </li>
              </Link>
              <Link to="/preterite-vs-imperfect/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Preterite vs Imperfect
                </li>
              </Link>
              <Link to="/months-in-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Months in Spanish
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default spanishAlphabets;
