import React, { useState } from 'react';

import Monthly from '../assets/monthly.png';
import GetStarted from './GetStarted';

const GermanMainHeader = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return (
    <>
      <GetStarted
        show={showModal}
        onCancel={() => closeModal()}
        contentClass="place-item__modal-content"
        footerClass="place-item__modal-actions"
        course="german"
      />
      <div className="new-main">
        <div className="new-main__top">
          <div className="new-main__top__left">
            <div className="new-main__top__left__box">
              <div className="new-main__top__left__title">
                GERMAN BEGINNER COURSE
              </div>
              <h1 className="new-main__top__left__main">
                Start Speaking German in 6 Weeks.
              </h1>
              <div className="new-main__top__left__desc">
                Start speaking German in just 6 weeks with a curriculum that
                guides you day-by-day & challenges you to test in between. Get
                conversational by learning the most practical things you’ll use.
              </div>
            </div>
            <div onClick={openModal} className="new-main__top__left__button">
              Join Waitlist
            </div>
            <div className="new-main__top__left__info">
              Enrollment for the course is closed. Join the waitlist and be the
              first one to know when the enrollment opens for the course.
            </div>
          </div>
          <div className="new-main__top__right">
            <img
              className="new-main__top__right__image"
              src={Monthly}
              width="100%"
            />
          </div>
        </div>
        <div className="new-main__bottom">
          <div className="new-main__bottom__point">
            <div className="new-main__bottom__point__top">NEXT BATCH</div>
            <div className="new-main__bottom__point__middle">COMING SOON</div>
            <div className="new-main__bottom__point__bottom">
              Join the waitlist to know when the course starts.
            </div>
          </div>
          <div className="new-main__bottom__point">
            <div className="new-main__bottom__point__top">TIME</div>
            <div className="new-main__bottom__point__middle">
              6 Weeks Commitment
            </div>
            <div className="new-main__bottom__point__bottom">
              7-10 hrs/week for 6 weeks. Get immersed in the process.
            </div>
          </div>
          <div className="new-main__bottom__point">
            <div className="new-main__bottom__point__top">END GOAL</div>
            <div className="new-main__bottom__point__middle">Level A1-A2</div>
            <div className="new-main__bottom__point__bottom">
              Equivalent to German level A1-A2
            </div>
          </div>
          <div className="new-main__bottom__point">
            <div className="new-main__bottom__point__top">
              WHO IS THIS CLASS FOR
            </div>
            <div className="new-main__bottom__point__middle">ANYBODY</div>
            <div className="new-main__bottom__point__bottom">
              Anyone who wants to get started with German.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GermanMainHeader;
