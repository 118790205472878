import React, { useState } from 'react';

const FAQ = () => {
  const [card1, setCard1] = useState(false);
  const [card2, setCard2] = useState(false);
  const [card3, setCard3] = useState(false);
  const [card4, setCard4] = useState(false);
  const [card5, setCard5] = useState(false);
  const [card6, setCard6] = useState(false);
  return (
    <div className="faq">
      <div className="faq__top">STILL HAVE QUESTIONS? HERE ARE ANSWERS</div>
      <div className="faq__second">Frequently Asked Questions</div>
      <div className="faq__third">
        {' '}
        If your questions aren’t covered below, you can chat with us.
      </div>
      <div className="faq__cards">
        <div onClick={() => setCard1(!card1)} className="faq__cards__card">
          <div className="faq__cards__card__top">
            <div className="faq__cards__card__title">
              Who is this course for?
            </div>
            <div className="faq__cards__card__plus">&#43; </div>
          </div>
          {card1 && (
            <div className="faq__cards__card__desc">
              This course is for anyone who is starting out on learning a
              language and wants a clear path to move on. If you follow our
              schedule for learning and be on point, you will be speaking a
              language in just 6 weeks. You do not need any prior knowledge,
              we’ll start everything from scratch. This course is also for
              someone who has little bit knowledge about a language but wants to
              enhance the skills and get grasp over other aspects of the
              language, including grammar, vocabulary, listening and reading
              skills.
            </div>
          )}
        </div>
        <div onClick={() => setCard2(!card2)} className="faq__cards__card">
          <div className="faq__cards__card__top">
            <div className="faq__cards__card__title">
              How will I learn a language in just 6 weeks?
            </div>
            <div className="faq__cards__card__plus">&#43; </div>
          </div>
          {card2 && (
            <div className="faq__cards__card__desc">
              We have our guided curriculum established in a way to make you
              learn with small steps everyday towards enhancing each aspect of a
              language. You will have to take out about 60 minutes a day and
              dedicate that time to some serious learning. You will start by
              watching video lesson, then reading its notes for future
              reference; practice exercises/quizzes along with each video (to
              ensure you understand what you’re learning). You will be learning
              new words every day in the form of flashcards (with examples to
              make you remember them) and be able to save your favorite words
              you want to revise the next day. For enhancing listening and
              reading skills, we have lessons wherein you’ll be listening to
              native speakers. There will also be a section for listening and
              reading comprehensions, so read/listen the text and answer the
              questions based on that. You won’t need anything else to enhance
              any part of your language.
            </div>
          )}
        </div>
        <div onClick={() => setCard3(!card3)} className="faq__cards__card">
          <div className="faq__cards__card__top">
            <div className="faq__cards__card__title">
              Is the course live and includes live classes with a teacher?
            </div>
            <div className="faq__cards__card__plus">&#43; </div>
          </div>
          {card3 && (
            <div className="faq__cards__card__desc">
              No, the course is not live. All lessons are pre-recorded by a
              professional language expert. You do not need a tutor anymore. You
              can watch the video lessons at your ease, anytime and anywhere. No
              fix time or place required. You can fit the course into your
              schedule easily if you really want to learn a language. Take the
              course at any time, from your home/office/couch/while travelling.
              Inside the course, you’ll find day-wise plan for next 6 weeks,
              telling you what all you need to finish on that day. And if you
              move as per the schedule, you will start speaking a language in
              just 6 weeks.
            </div>
          )}
        </div>
        <div onClick={() => setCard4(!card4)} className="faq__cards__card">
          <div className="faq__cards__card__top">
            <div className="faq__cards__card__title">
              What are weekly assignments?
            </div>
            <div className="faq__cards__card__plus">&#43; </div>
          </div>
          {card4 && (
            <div className="faq__cards__card__desc">
              As you go along learning, after a week of learning, we’ll give you
              an assignment/small writing project which you can finish and
              submit us at info@linguapsych.com and get direct feedback from us
              on your assignment. This is just to test you are on track with us
              and learning smoothly. We’ll guide you in case we feel you need
              some additional support.
            </div>
          )}
        </div>
        <div onClick={() => setCard5(!card5)} className="faq__cards__card">
          <div className="faq__cards__card__top">
            <div className="faq__cards__card__title">
              What if I miss a day’s lessons and a little off track?
            </div>
            <div className="faq__cards__card__plus">&#43; </div>
          </div>
          {card5 && (
            <div className="faq__cards__card__desc">
              We recommend that you follow the curriculum. But, in case you miss
              out on track and have to stop in between, you can pick up from the
              where you left anytime. The course curriculum is with you to pick
              up at any time and start learning seriously. You can also re-watch
              a lesson and back to what you have studied.
            </div>
          )}
        </div>
        <div onClick={() => setCard6(!card6)} className="faq__cards__card">
          <div className="faq__cards__card__top">
            <div className="faq__cards__card__title">
              When will the course and I can start learning?
            </div>
            <div className="faq__cards__card__plus">&#43; </div>
          </div>
          {card6 && (
            <div className="faq__cards__card__desc">
              The course will be starting again soon. You can join the waitlist
              to know when the course is live. We are working on some technical
              aspect to smoothen the entire experience for you. Join the
              waitlist now to avail early bird offers.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
