import React from 'react';
import { Link } from 'react-router-dom';

import '../sass/main.scss';
import MetaTags from 'react-meta-tags';
import Image1 from '../assets/Language courses in Delhi University.jpg';
import Image3 from '../assets/foreign-language-courses-in-delhi-university.jpeg';
import Image2 from '../assets/du-admission-language-courses.jpeg';
import Language from '../assets/language_course_bottom.png';

const delhiUniversityCourse = () => {
  const head = () => (
    <MetaTags>
      <title>
        Top Foreign Language Courses in Delhi University | Admission, Fee
        Details | LinguaPsych
      </title>
      <meta
        name="description"
        content="Want to pursue a foreign language course in Delhi University? Check out the complete details relating to DU language course admissions and choose the best college available."
      />{' '}
      <link
        rel="canonical"
        href="https://linguapsych.com/foreign-language-courses-in-delhi-university"
      />
      <meta
        property="og:title"
        content="Want to pursue a foreign language course in Delhi University? Check out the complete details relating to DU language course admissions and choose the best college available."
      />{' '}
      <meta
        property="og:description"
        content="Want to pursue a foreign language course in Delhi University? Check out the complete details relating to DU language course admissions and choose the best college available."
      />{' '}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        href="https://linguapsych.com/foreign-language-courses-in-delhi-university"
      />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/Language courses in Delhi University.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/Language courses in Delhi University.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}
      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_DOMAIN_PRODUCTION}`}>
          <div className="article__general-banner">
            <div className="article__general-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image1}
            alt="Language courses in Delhi University"
            className="article__featured-image"
          />
          <div className="article__header-elements">
            <h1 className="article__title ">
              {' '}
              <span className="article__highlight--black ">
                {' '}
                Top Foreign Language Courses in Delhi University
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <p>
              Choosing your career is one of the most important decision you’ll
              ever make in your life. Well, these days, learning a foreign
              language is trending, you can learn it as a hobby or even
              professionally. Delhi can be considered as a hub for learning
              foreign languages, lots of private Institutes are coming up
              looking at the increasing demand of students wanting to learn
              Foreign Languages.
            </p>
            <p>
              When it comes to choosing a place to learn a foreign language,
              Delhi University emerges as a clear winner. With hundreds of
              students taking admission every year and Delhi University offering
              plenty of options to choose from. DU is anyone’s first
              recommendation.
            </p>
            <p>
              Talking about the courses, Delhi University provides Certificate,
              Diploma and Advanced Diploma short term courses. It even provides
              full time graduation in the foreign languages.
            </p>
            <h2 className="u-margin-bottom-medium">
              Types of Language Courses in DU
            </h2>
            <img
              src={Image2}
              alt="du-admission-language-courses"
              className="u-margin-bottom-medium"
              style={{ width: '100%' }}
            />
            <p>
              There are two types of language courses in university of Delhi.
              One is the part-time courses and other is full time degree
              courses. Let us look into them in detail:
            </p>
            <ul>
              <li>Part-time Language Courses</li>
              <p>
                Don’t have time to join a full time course? If you really want
                to learn a foreign language, but do not have time for a full
                time course, you can opt for part time courses available in
                Delhi University (DU). These part-time courses in DU are
                available in a variety of languages, including Indian and
                foreign languages.
              </p>
              <p>
                Delhi University offers part time courses through its various
                departments meant for languages. Most popular ones being
                Department of Germanic and Romance Studies (GRS), Department of
                Slavonic and Finno-Ugrian Studies and Department of East Asian
                Studies. There are separate departments for different languages
                in respective colleges as well.
              </p>
              <li>Full-time Language Courses</li>
              <p>
                If you want to pursue a full-time language course in Delhi. Then
                University of Delhi offers degree courses in a variety of
                languages. The Arts Faculty of University of Delhi offers
                Graduate, Post-Graduate, M.Phil. and Ph.D courses in a variety
                of languages.
              </p>
              <p>
                These courses provide full exposure to students about the
                language. Not only will you learn language, but also get deep
                understanding of the literature and culture of the concerned
                language.
              </p>
            </ul>
            <p>
              Also read:
              <a href="/foreign-language-courses-delhi">
                Top Foreign Language Courses in Delhi
              </a>
            </p>
            <h2 className="u-margin-bottom-medium">
              Department of Slavonic and Finno-Ugrian Studies
            </h2>
            <p>
              The Department of Slavonic and Finno-Ugrian Studies (SFUS) is the
              only place where you can learn Slovanic languages. The languages
              offered are Bulgarian, Croatian, Czech, Polish, Russian and
              Hungarian. This department in DU takes care of the needs of those
              who want to learn these languages but cannot find it anywhere. It
              emphasizes language, literature and culture.
            </p>
            <p>
              These languages are offered in part-time courses through
              Certificate, Diploma and Advanced Diploma courses. The duration of
              each of these part-time courses is one year. Your purpose to learn
              these unique languages could be any, be it for travelling to that
              country or just learning it out of curiosity. You can always learn
              these languages in part-time if you do not have time for full time
              degree course.
            </p>
            <p>
              This department takes care of the fluency of students in these
              languages and also teaches them about culture and diversity of the
              language. The department provides a platform to enable learning
              language along with the literature. Classes are held thrice a week
              for 1.5 hours each.
            </p>
            <h2 className="u-margin-bottom-medium">
              Department of Germanic and Romance Studies
            </h2>
            <p>
              The Department of Germanic and Romance Studies (GRS) offers part
              time and full-time foreign language courses.
            </p>
            <p>
              Following are the full-time degree courses offered in GRS
              department of Delhi University:
            </p>
            <ul className="u-margin-bottom-medium">
              <li>
                B.A. (Hons.) in French, German, Spanish and Italan. It is a
                three year course and recognized worldwide as a graduation
                degree.
              </li>
              <li>
                M.A. in French, German, Spanish and Italian. It is a two year
                course and admission to this post-graduate course is subject to
                availability of seats. Admission is done through a entrance
                exam.
              </li>
              <li>M.Phil in French, German, Spanish and Italian.</li>
              <li>Ph.D in French, German, Spanish and Italian.</li>
            </ul>
            <p>
              Part-time courses are offered in the form of Certificate, Diploma
              and Advanced Diploma in various languages. These language courses
              are offered through different colleges of University of Delhi.
            </p>
            <p>
              GRS department offers following languages – French, German,
              Italian, Spanish and Portuguese. For admission to any of these
              languages for part-time course, you can directly contact the
              concerned college and check for the admission details.
            </p>
            <p>
              There are different eligibility criteria for taking admission in
              different colleges of DU for these part time foreign language
              courses. Certificate, Diploma and Advanced Diploma are all one
              year courses. There are different time slots available, depending
              on different colleges and subject to the batch strength. You can
              refer to the respective colleges for these details.
            </p>
            <p style={{ fontWeight: 'bold' }}>Eligibility Criteria:</p>
            <p>
              <span style={{ fontWeight: 'bold' }}> Certificate course: </span>
              For admission to certificate courses, candidate should have passed
              the Senior School Certificate Exam (10+2) with minimum 45% marks.
              Those with higher educational qualifications such as Bachelors,
              Masters and above can also apply.
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}> Diploma: </span>
              For admission to Diploma courses, candidate should have passed the
              Certificate exam of the University of Delhi or equivalent in the
              respective language. The admissions are all merit based. Those
              with a gap year or who have done similar level from some other
              institution will have to appear in the entrance exam.
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}> Advanced Diploma: </span>
              For admission to Advanced Diploma, candidates should have passed
              Diploma exam of the University of Delhi or equivalent in the
              concerned language. The admissions are merit based. Those with a
              gap year or who have done similar level from some other
              institution will have to appear in the entrance exam.
            </p>
            <h2 className="u-margin-bottom-medium">
              List of Colleges offering Part-Time Language Courses
            </h2>
            <p>
              This is the list of all colleges affiliated to University of Delhi
              which offer part-time foreign language courses if you want to
              pursue foreign language in Delhi.
            </p>
            <h3 style={{ fontWeight: 'bold' }}>North Campus Colleges:</h3>
            <ul className="u-margin-bottom-medium">
              <li>
                Daulat Ram College, Maurice Nagar – Certificate, Diploma and
                Advanced Diploma in French, German, Spanish and Italian.
              </li>
              <li>
                Hans Raj College, MalkaGanj – Certificate, Diploma and Advanced
                Diploma in French and German
              </li>
              <li>
                Keshav Mahavidyalay, Pitampura – Certificate in French and
                German
              </li>
              <li>
                Mata Sundari College, Mata Sundari Lane – Certificate in French,
                German and Spanish
              </li>
              <li>
                Miranda House, Patel Chest Marg – Certificate in French, German
                and Spanish
              </li>
              <li>
                Ramjas College, Maurice Nagar – Certificate in French, German
                and Spanish
              </li>
              <li>
                Satyawati College, Ashok Vihar – Certificate and Diploma in
                French and German
              </li>
              <li>
                SGTB Khalsa College, Mall Road – Certificate in German and
                Spanish
              </li>
              <li>
                Sri Guru Gobind Singh College of Commerce, Pitampura –
                Certificate in French and German
              </li>
              <li>
                St. Stephen’s College, University Enclave – Certificate, Diploma
                and Advanced Diploma in French and German
              </li>
              <h3 style={{ fontWeight: 'bold' }}>South Campus Colleges:</h3>
              <li>
                Acharya Narendra Dev College, Govindpuri – Certificate and
                Diploma in French, German and Spanish; Advanced Diploma in
                Spanish
              </li>
              <li>
                Bharti College, Janakpuri – Certificate, Diploma and Advanced
                Diploma in French and German
              </li>
              <li>
                College of Vocational Studies, Sheikh Sarai – Certificate and
                Diploma in French and German
              </li>
              <li>
                Delhi College of Arts and Commerce (DCAC), Netaji Nagar –
                Certificate in French, German and Spanish; Diploma in German and
                Spanish
              </li>
              <li>Gargi College, Siri Fort Road – Certificate in German</li>
              <li>
                Jesus and Mary College, Chanakyapuri – Certificate in French
              </li>
              <li>
                Kamla Nehru College, August-Kranti Marg – Certificate, Diploma
                and Advanced Diploma in French
              </li>
              <li>
                Sri Venkateswara College, Dhaula Kuan – Certificate in German
              </li>
            </ul>
            <h2 className="u-margin-bottom-medium">
              Department of East Asian Studies
            </h2>
            <p>
              The Department of East Asian Studies offers languages such as
              Chinese, Japanese and Korean. The demand for these languages is at
              peak in the moment. More and more people all over the globe want
              to learn these languages for wider career scope and other personal
              reasons.
            </p>
            <p>The department offers the following courses:</p>
            <ul>
              <li>
                Post-Graduate Intensive Advance Diploma in Chinese, Japanese and
                Korean
              </li>
              <li>
                Post-Graduate Intensive Diploma in Chinese, Japanese and Korean
              </li>
              <li>M.A. in East Asian Studies and Japanese</li>
              <li>Ph.D in East Asian Studies</li>
            </ul>
            <p>
              {' '}
              For complete details relating to admission, you can visit their
              website for information relating to admission procedure and dates.
            </p>
            <div className="row">
              <div className="col-1-of-2">
                <p>
                  Learning a foreign language can broaden the scope of one’s
                  career and give your career a new boost. Many new directions
                  open up after studying a language. So go ahead and learn a
                  foreign language from Delhi University. Happy learning!
                </p>
              </div>
              <div className="col-1-of-2">
                <img
                  src={Image3}
                  alt="foreign-language-courses-in-delhi-university"
                  style={{ width: '100%' }}
                />
              </div>
            </div>
            <p>
              Also read:
              <a href="/german-language-course-in-delhi/">
                Learn German Language Course in Delhi |
              </a>
              <a href="/learn-japanese-in-delhi/">
                Top 9 Places to learn Japanese Language in Delhi
              </a>
            </p>
          </div>
          <div className="article__blog-container__bottom">
            <div className="article__blog-container__bottom__left">
              <Link to="/">
                <img
                  className="article__blog-container__bottom__image"
                  src={Language}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/foreign-language-courses-delhi/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Foreign Language courses in Delhi
                </li>
              </Link>
              <Link to="/learn-japanese-in-delhi/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Learn Japanese in Delhi
                </li>
              </Link>

              <Link to="/french-language-course-in-delhi/">
                <li className="article__blog-container__bottom__posts__title__item">
                  French Language course in delhi
                </li>
              </Link>
              <Link to="/german-language-course-in-delhi/">
                <li className="article__blog-container__bottom__posts__title__item">
                  German Language course in delhi
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default delhiUniversityCourse;
