import React, { useRef, useState, useEffect } from 'react';

const VideoPlayer = (props) => {
  const [play, setPlay] = useState(false);
  const [width, setWidth] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hover, setHover] = useState(false);
  const [rate, setRate] = useState('1');
  const [isEnded, setIsEnded] = useState(false);
  const video = useRef(null);
  const progressRange = useRef(null);
  const playBtn = useRef(null);
  const progressBar = useRef(null);
  const player = useRef(null);

  // Play & Pause ----------------------------------- //

  function togglePlay() {
    setIsEnded(false);
    console.log(video.current.paused);
    console.log(video.current);
    if (video.current.paused) {
      video.current.play();
      setPlay(true);
    } else {
      setPlay(false);
      video.current.pause();
    }
  }

  // On video end, show play button icon
  //   video.addEventListener('ended', showPlayIcon);

  // Progress Bar ---------------------------------- //

  // Format current time, duration
  function displayTime(time) {
    const minutes = Math.floor(time / 60);
    let seconds = Math.floor(time % 60);
    seconds = seconds > 9 ? seconds : `0${seconds}`;
    return `${minutes}:${seconds}`;
  }

  // Update progress bar as video plays
  function updateProgress() {
    setWidth((video.current.currentTime / video.current.duration) * 100);
  }

  // Click to seek within the video
  function setProgress(e) {
    console.log('Set Progress was clicked');
    const newTime = e.nativeEvent.offsetX / progressRange.current.offsetWidth;
    setWidth(newTime * 100);
    video.current.currentTime = newTime * video.current.duration;
  }

  function mouseEnter() {
    setHover(true);
    setTimeout(() => {
      setHover(false);
    }, 2000);
  }

  return (
    <div
      onContextMenu={(e) => e.preventDefault()}
      ref={player}
      className={hover ? 'week-player week-mouse-move' : 'week-player'}
      onMouseMove={mouseEnter}
    >
      <div className="player-hover">
        {loading && (
          <div className="video-loader">
            <i style={{ fontSize: '35px' }} className="fas fa-spinner"></i>
          </div>
        )}
        <video
          onWaiting={() => setLoading(true)}
          onPlaying={() => {
            setLoading(false);
            setPlay(true);
            setIsEnded(false);
          }}
          onCanPlay={updateProgress}
          onTimeUpdate={updateProgress}
          onEnded={() => {
            setIsEnded(true);
          }}
          onClick={togglePlay}
          ref={video}
          type="video/mp4"
          className="video"
          src={props.src}
          playsInline
          poster={props.poster}
        ></video>
        <div className="week-show-controls">
          <div className="week-controls-container">
            {/* <!-- Progress Bar --> */}
            <div className="play-button">
              {isEnded ? (
                <i
                  onClick={() => {
                    togglePlay();
                    setWidth(0);
                  }}
                  className="fas fa-redo-alt"
                ></i>
              ) : (
                <i
                  onClick={togglePlay}
                  ref={playBtn}
                  className={play ? 'fas fa-pause' : 'fas fa-play'}
                  title={play ? 'pause' : 'play'}
                  id="play-btn"
                ></i>
              )}
            </div>
            <div
              onClick={setProgress}
              ref={progressRange}
              className="week-progress-range"
              title="Seek"
            >
              <div
                onClick={setProgress}
                onTimeUpdate={updateProgress}
                style={{ width: width + '%' }}
                ref={progressBar}
                className="week-progress-bar"
              >
                {' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
