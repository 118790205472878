import React from 'react';
import { Link } from 'react-router-dom';
import '../sass/main.scss';
import MetaTags from 'react-meta-tags';
import Image from '../assets/german-months-seasons.jpg';
import German from '../assets/german_course_bottom.png';

const germanMonths = () => {
  const head = () => (
    <MetaTags>
      <title>
        The Ultimate Guide to German Months, Seasons with Pronunciation &amp;
        Abbreviation | LinguaPsych
      </title>
      <meta
        name="description"
        content="The ultimate guide to learn German Months and Seasons along with their pronunciation and abbreviation for months. What is January, February, March, April in German?"
      />
      <link
        rel="canonical"
        href="https://linguapsych.com/german-months-seasons/"
      />
      <meta
        property="og:title"
        content="The ultimate guide to learn German Months and Seasons along with their pronunciation and abbreviation for months. What is January, February, March, April in German?"
      />
      <meta
        property="og:description"
        content="The ultimate guide to learn German Months and Seasons along with their pronunciation and abbreviation for months. What is January, February, March, April in German?"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://linguapsych.com/german-months-seasons/"
      />
      <meta property="og:site_name" content="https://linguapsych.com/" />

      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/german-months-seasons.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/german-months-seasons.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}
      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_GERMAN_COURSE}`}>
          <div className="article__german-banner">
            <div className="article__german-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="german-months-seasons"
            className="article__featured-image"
          />
          <div
            style={{ marginTop: '-24px' }}
            className="article__header-elements"
          >
            <h1 className="article__title">
              {' '}
              <span className="article__highlight--skyblue">
                {' '}
                How do you say German Months and Seasons?
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <p>
              {' '}
              Do you want to learn what are months and seasons in German called?
              You are at the right place. In this lesson, we will learn all the
              German months and seasons along with their pronunciation and
              sounds. You should not only be able to speak about months and
              seasons, but also spell seasons and months in German.
            </p>
            <p>
              We all know, there are twelve months in a year; January, February,
              March, April, May, June, July, August, September, October,
              November, December. These are the month names in English. Months
              in almost all languages have some sort of similarity. After this
              lesson, you yourself will say that Learning German months was not
              that tough at all; since months in English and German share a lot
              of similarities. In some cases; like April, the only difference is
              of pronunciation, rest everything is same. You will discover many
              such cases where spelling of months is exactly the same, just
              pronunciation differs. Now let us discover all the names of months
              in a year in German.
            </p>
            <h2 className="u-margin-bottom-medium">
              What are the Months of the Year in German?
            </h2>
            <p>
              The twelve months of the year in German are written in the
              following order from January to December:
            </p>
            <div
              style={{
                backgroundColor: 'pink',
                color: 'white',
                fontSize: '3rem',
              }}
            >
              <p>January - Januar</p>
              <p>February - Februar</p>
              <p>March - März</p>
              <p>April - April</p>
              <p>May - Mai</p>
              <p>June - Juni</p>
              <p>July - Juli</p>
              <p>August - August</p>
              <p>September - September</p>
              <p>October - Oktober</p>
              <p>November - November</p>
              <p>December - Dezember</p>
            </div>
            <h2 className="u-margin-bottom-medium">
              How to pronounce Months in German?
            </h2>
            <p>
              Months in German language are quite similar to the months name in
              English. So you are lucky enough that it would not be tough for
              you to figure out German pronunciations and you will be able to
              easily grasp German months.
            </p>
            <ul
              className="u-margin-bottom-medium"
              style={{ textAlign: 'left' }}
            >
              <li>January is Januar in German, pronounced as “Yaanooaar“</li>
              <li>February is Februar in German, pronounced as “Febrooaar“</li>
              <li>March is März in German, pronounced as “May-rz“</li>
              <li>
                April is April in German (same as in English, but differs in
                pronunciation), pronounced as “Aapril“
              </li>
              <li>May is Mai in German, pronounced as “Maaee“</li>
              <li>June is Juni in German, pronounced as “Yooni“</li>
              <li>July is Juli in German, pronounced as “Yooli“</li>
              <li>
                August is August in German (just as in English, but again
                differs in pronunciation), pronounced as “Aaoogoost“
              </li>
              <li>
                September is September in German (again similar to that in
                English, but different pronunciation), pronounced as “Zeptember“
              </li>
              <li>
                October is Oktober in German (this time spelling of October is
                different, but pronunciation same), pronounced as “Oktobehr“
              </li>
              <li>
                November is November in German (ughh again same spelling
                November, but this time even the pronunciation is same),
                pronounced as “November“
              </li>
              <li>
                December is Dezember in German (just a little different),
                pronounced as “Deesember“
              </li>
            </ul>
            <div style={{ color: 'white', backgroundColor: 'skyblue' }}>
              <p>
                <span style={{ display: 'block' }}>
                  {' '}
                  Just for Fun: What is the longest month name?
                </span>
                <span style={{ display: 'block' }}>
                  {' '}
                  September is the longest of all months with 9 characters!
                </span>
              </p>
            </div>
            <h2 className="u-margin-bottom-medium">
              Month Abbreviation in English
            </h2>
            <p>
              Most of the time we use abbreviations for months. Even if the name
              of the month is not that large, we are used to using
              abbreviations. Yes we are that lazy!
            </p>
            <p>First let us see what are abbreviation for months in English.</p>
            <ul
              className="u-margin-bottom-medium"
              style={{
                fontSize: '3rem',
                listStyle: 'none',
                backgroundColor: 'pink',
                color: 'white',
              }}
            >
              <li>January – Jan.</li>
              <li>February – Feb.</li>
              <li>March – Mar.</li>
              <li>April – Apr.</li>
              <li>May – May</li>
              <li>June – June</li>
              <li>July – July</li>
              <li>August – Aug.</li>
              <li>September – Sept</li>
              <li>October – Oct.</li>
              <li>November – Nov.</li>
              <li>December – Dec.</li>
            </ul>
            <p>
              Usually, in English months abbreviation are written as first 3
              letters of the month. Exceptions being May, June and July; since
              they are already three or four letter words. So no point
              abbreviating them.
            </p>
            <h2 className="u-margin-bottom-medium">
              Month Abbreviation in German
            </h2>
            <p>
              Moving on to the month abbreviations in German. They are almost
              similar to those in English. you just need to know month names in
              German. Let us look into the abbreviation for January, February,
              March, April, May, June, July, August, September, October,
              November, December.
            </p>
            <ul
              className="u-margin-bottom-medium"
              style={{
                fontSize: '3rem',
                listStyle: 'none',
                backgroundColor: 'pink',
                color: 'white',
              }}
            >
              <li>Januar – Jan.</li>
              <li>Februar – Feb.</li>
              <li>März – März.</li>
              <li>April – Apr</li>
              <li>Mai – Mai</li>
              <li>Juni – Juni</li>
              <li>Juli – Juli</li>
              <li>August – Aug</li>
              <li>September – Sept</li>
              <li>October – Okt</li>
              <li>November – Nov</li>
              <li>December – Dez</li>
            </ul>
            <p>
              In German, abbreviation for March, May, June and July is the
              entire word itself. There wasn’t much difference in abbreviation
              for month in English and German.
            </p>
            <h2 className="u-margin-bottom-medium">
              Related Vocabulary you must know
            </h2>
            <p>
              At this stage, we know how to say the months of year in German,
              but knowing that is not enough if you want to make sentences
              related to this topic. Below are some very useful words which are
              going to be used very often with the months in German. I have
              added some examples too, so you won’t find it difficult to
              understand the context.
            </p>
            <p
              style={{
                color: 'black',
                fontWeight: 'bold',
                fontSize: '2.5rem',
              }}
            >
              Year
            </p>
            <p>
              Year is das Jahr in German. It’s article is das, since it is
              neutral. The plural of Jahr will be die Jahre. We talk about years
              very frequently; while remembering old memories, or planning about
              future, or in your birthdays. Let us look at it’s examples Ein
              Jahr hat 365 Tagen. (One year has 365 days.)
            </p>
            <p
              style={{
                color: 'black',
                fontWeight: 'bold',
                fontSize: '2.5rem',
              }}
            >
              Leap Year
            </p>
            <p>
              Leap year is called das Schaltjahr in German. It’s article is das,
              since it is neutral. The plural of Schaltjahr will be die
              Schaltjahre.
            </p>
            <p>
              Manchmal hat das Jahr auch 365 Tage. Es heißt dann Schaltjahr.
            </p>
            <p>
              (Sometimes the year also has 365 days. It’s called leap year.)
            </p>
            <p
              style={{
                color: 'black',
                fontWeight: 'bold',
                fontSize: '2.5rem',
              }}
            >
              Month
            </p>
            <p>
              Month is called der Monat in German. It’s article is der, since it
              is masculine. The plural of Monat will be die Monate.
            </p>
            <p>Vier Wochen sind ein Monat. (Four weeks is one month.)</p>
            <p
              style={{
                color: 'black',
                fontWeight: 'bold',
                fontSize: '2.5rem',
              }}
            >
              Week
            </p>
            <p>
              Week is die Woche in German. It’s article is die, since it is
              feminine. The plural of Woche will be die Wochen.
            </p>
            <p>Sieben Tage sind eine Woche. (Seven days is one week.)</p>
            <p
              style={{
                color: 'black',
                fontWeight: 'bold',
                fontSize: '2.5rem',
              }}
            >
              Day
            </p>
            <p>
              Day is der Tag in German. It’s article is der, since it is
              masculine. The plural of Tag will be die Tage.
            </p>
            <p>Ein Tag hat 24 Stunden. (A day has 24 hours.)</p>
            <p
              style={{
                color: 'black',
                fontWeight: 'bold',
                fontSize: '2.5rem',
              }}
            >
              Season
            </p>
            <p>
              Season is die Jahreszeit in German. It’s article is die, since it
              is feminine. The plural of Jahreszeit will be die Jahreszeiten.
            </p>
            <p className="u-margin-bottom-medium">
              Ein Jahr hat vier Jahreszeiten. (One year has four seasons.)
            </p>
            <h2 className="u-margin-bottom-medium">
              What are the Seasons in Germany?
            </h2>
            <p>
              When talking about months in German, we must know the seasons in
              German as well. These are some of the basic words you must know to
              master learning German language. Very basic and frequent
              conversations like talking about your birthday or to meet a friend
              or to plan you next trip, you will need to know what are days,
              months and season in German. Seasons are formulated by months.
              Certain months have different seasons. We will know which month
              has what season of the year. Let us begin with it.
            </p>
            <p>
              There are four season in a year. Usually there are fixed months in
              which we experience these season, but depending on the weather
              conditions, they can change as well.
            </p>
            <p>
              You know that all the nouns in German have an article. Seasons in
              German are masculine and thus their article is always der. So
              before every season “der” article will be used. In Germany, the
              year begins from 1st January. The start of the year is always
              chilly. If you too like cold places, spend your next new year in
              Germany.
            </p>
            <ul
              className="u-margin-bottom-medium"
              style={{
                listStyle: 'none',
              }}
            >
              <li style={{ fontWeight: 'bold', color: 'black' }}>
                Spring Season
              </li>
              <p>
                Spring is called der Frühling in German. Officially spring
                season is during the months of March, April and May. March is a
                month which is a little cold. In the month of April begins main
                spring when flowers start blossoming. May is the favorite month
                for roaming around in Germany. This season is the time of
                festivities when everyone is celebrating Easter and enjoying
                pleasant weather. Labor day is also celebrated in the month of
                May.
              </p>
              <p>
                Im Frühling blühen die Bäume. (In spring, the trees are
                blooming)
              </p>
              <li style={{ fontWeight: 'bold', color: 'black' }}>
                Summer Season
              </li>
              <p>
                Summer is called der Sommer in German. Summer season remains
                from June till August. Thus, the months of June, July and August
                are the months of summer. Summer is the favorite season for
                Germans. People wait for this season and huge volume of tourists
                visit Germany during this season. In this season, people are on
                streets, enjoying summer and participating in certain
                activities.
              </p>
              <p>Im Sommer baden die Leute. (In summer, people bathe.)</p>
              <li style={{ fontWeight: 'bold', color: 'black' }}>
                Autumn Season
              </li>
              <p>
                Autumn is called der Herbst in German. Fall/Autumn season runs
                from September till November. Thus, the months of September,
                October and November are the months of autumn. Germany is known
                for it’s world famous festival called Oktoberfest in Munich.
                People from all over the world visit Germany to attend
                Oktoberfest. It starts from the end of September and ends in the
                beginning of November. Halloween is also celebrated in this
                season itself.
              </p>
              <p>Im Herbst fallen die Blätter. (In autumn, leaves fall.)</p>
              <li style={{ fontWeight: 'bold', color: 'black' }}>
                Winter Season
              </li>
              <p>
                Winter is called der Winter in German. Germany experiences
                winters from December till February. So the months of December,
                January and February are the months of winter season. During
                these months of the season, it is freezing cold in Germany.
                People enjoy winter as much as they enjoy summer. During these
                months of the season, there are festivals which are celebrated
                in the country. On December 25, Christmas is celebrated with
                great spirit in the country. Then on 1st January, new year is
                welcomed with high spirits, full of of hopes.
              </p>
              <p>
                Im Winter spielen die Leute mit Schnee. (In winter, people play
                with snow.)
              </p>
            </ul>
            <h2 className="u-margin-bottom-medium">Festivals in Germany</h2>
            <p>
              We know all the months in German and also all the season in
              German. Now let us look at what festivals are celebrated in which
              month. We will look into this with the help of examples, so that
              we are able to brush up our skill side by side. In the last
              sentence you will also learn how say your birthday in German.
            </p>
            <ul
              className="u-margin-bottom-medium"
              style={{ textAlign: 'left' }}
            >
              <li>
                Am ersten Januar ist Neujahr. (On the first of January is New
                Year.)
              </li>
              <li>
                Am vierzehnten Februar ist Valentinstag. (On the fourteenth of
                February is Valentine’s Day.)
              </li>
              <li>
                Am achten März ist Internationaler Frauentag. (On the eighth of
                March is International Women’s Day.)
              </li>
              <li>
                Am ersten Mai ist Tag der Arbeit. (The first of May is Labor
                Day.)
              </li>
              <li>
                Am ersten Juni ist Internationaler Kindertag. (On the first of
                June is International Children’s Day.)
              </li>
              <li>
                Am ersten August ist Internationaler Frauentag. (On the first of
                August is International Women’s Day.)
              </li>
              <li>
                Am dritten Oktober ist Tag der deutschen Einheit. (On the third
                of October is the day of German unity.)
              </li>
              <li>
                Am sechsundzwanzigsten Oktober ist Nationalfeiertag in
                Österreich. (On the twenty-sixth of October is national holiday
                in Austria.)
              </li>
              <li>
                Am fünfundzwanzigsten Dezember ist Weihnachten. (On the
                twenty-fifth of December is Christmas)
              </li>
              <li>
                Am einunddreißigsten Dezember ist Silvester. (On the
                thirty-first of December is New Year’s Eve.)
              </li>
              <li>
                Am achtzehnten Mai habe ich Geburtstag. (I have birthday on the
                eighteenth of May.)
              </li>
            </ul>
            <h2 className="u-margin-bottom-medium">What did we learn?</h2>
            <p>
              In this lesson, we have learnt the complete list of months of the
              year in order in German and also the seasons in German language.
              This lesson should be on your tips. Let us recall what we have
              done.
            </p>
            <ul className="u-margin-bottom-medium">
              <li>
                There are 12 months in a year; Januar, Februar, März, April,
                Mai, Juni, Juli, August, September, Oktober, November, Dezember.
              </li>
              <li>
                The month abbreviation are Jan, Feb, März, Apr, Mai, Juni, Juli,
                Aug, Sept, Okt, Nov, Dez.
              </li>

              <li>
                We also did what is year, leap year, month, week, day and season
                called in German.
              </li>

              <li>
                There are 4 seasons in a year; Frühling, Sommer, Herbst and
                Winter.
              </li>

              <li>
                Festivals that are celebrated in Germany along with months and
                dates. You also learnt how to tell your birthday in German.
              </li>
            </ul>
            <p>
              So now do you feel confident with German months and seasons? If
              you know this, you can jump to the next step in learning German
              language.
            </p>
          </div>
          <div className="article__blog-container__bottom">
            <div
              className="article__blog-container__bottom__left"
              to="/learn-german"
            >
              <Link to="/learn-german">
                <img
                  className="article__blog-container__bottom__image"
                  src={German}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/german-alphabet-letters/">
                <li className="article__blog-container__bottom__posts__title__item">
                  German Alphabets & Letters
                </li>
              </Link>
              <Link to="/german-numbers/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Numbers in German
                </li>
              </Link>

              <Link to="/german-days-of-the-week/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Days of the Week in German
                </li>
              </Link>

              <Link to="/german-umlaut/">
                <li className="article__blog-container__bottom__posts__title__item">
                  German Umlaut
                </li>
              </Link>
              <Link to="/time-in-german/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Time in German
                </li>
              </Link>
              <Link to="/german-colors/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Colors in German
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default germanMonths;
