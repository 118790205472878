import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

import '../sass/main.scss';
import Image from '../assets/Korean-numbers.jpg';
import Image1 from '../assets/one-in-korean.jpg';
import Language from '../assets/language_course_bottom.png';

const koreanNumbers = () => {
  const head = () => (
    <MetaTags>
      <title>
        Beginner&#039;s Guide to Korean Numbers | Counting in Korean |
        LinguaPsych
      </title>
      <meta
        name="description"
        content="Want to learn Korean numbers? This is the beginners guide to learn counting numbers in Korean. A comprehensive guide to counting Korean number."
      />{' '}
      <link rel="canonical" href="https://linguapsych.com/korean-numbers/" />
      <meta
        property="og:title"
        content="Want to learn Korean numbers? This is the beginners guide to learn counting numbers in Korean. A comprehensive guide to counting Korean number."
      />{' '}
      <meta
        property="og:description"
        content="Want to learn Korean numbers? This is the beginners guide to learn counting numbers in Korean. A comprehensive guide to counting Korean number."
      />{' '}
      <meta property="og:type" content="website" />
      <meta property="og:url" href="https://linguapsych.com/korean-numbers/" />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/Korean-numbers.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/Korean-numbers.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}

      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_DOMAIN_PRODUCTION}`}>
          <div className="article__general-banner">
            <div className="article__general-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="spanish-articles"
            className="article__featured-image"
          />
          <div className="article__header-elements">
            <h1 className="article__title ">
              {' '}
              <span
                style={{ fontSize: '5rem' }}
                className="article__highlight--black "
              >
                {' '}
                Learn Counting Korean Numbers
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <p>
              There are two sets of numbers in Korean: the native Korean system
              and the Sino-Korean system.
            </p>
            <p>
              The Native numbers are used for numbers of items (1-99) and age,
              while the Sino-Korean system is based on Chinese numbers and are
              used for dates, money, addresses, phone numbers, and numbers above
              100.
            </p>
            <h2 className="u-margin-bottom-medium">
              Native Korean (Korea System) and Sino-Korean (China System)
            </h2>
            <table style={{ textAlign: 'center' }}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      <span>
                        <strong>Numeral</strong>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>
                        <strong>Native Korean (Korea System)</strong>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>
                        <span>Pronounciation</span>
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>
                        <span>Sino-Korean (China System)</span>
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>
                        <span>Pronounciation</span>
                      </strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>1</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>하나</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>hana</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span> 일</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>il</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>2</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>둘</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>dul</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span> 이</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>e</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>3</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>셋</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>Set</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span> 삼</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>sam</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>4</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>넷</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>net</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span> 사</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>sa</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>5</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>다섯</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>daseot</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span> 오</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>o</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>6</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>여섯</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>yeoseot</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span> 육</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>yuk</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>7</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>일곱</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>Ilgop</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span> 칠</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>chil</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>8</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>여덟</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>yeodeol</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span> 팔</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>pal</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>9</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>아홉</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>ahop</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span> 구</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>gu</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>10</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>열</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>yeol</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span> 십</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>sip</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>11</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>열하나</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>yeolhana</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>십일</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>sibil</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>12</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>열둘</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>yeoldul</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>십이</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>sibi</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>13</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>열셋</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>yeolset</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>십삼</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>sipsam</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>14</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>열넷</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>yeolnet</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>십사</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>sipsa</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>15</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>열다섯</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>yeoldaseot</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>십오</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>sibo</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>16</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>열여섯</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>yeolyeoseot</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>십육</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>sibyuk</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>17</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>열일곱</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>yeolilgob</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>십칠</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>sipchil</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>18</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>열여덟</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>yeolyeodeol</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>십팔</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>sip-pal</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>19</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>열아홉</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>yeolahop</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>십구</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>sipgu</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>20</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>스물</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>seumul</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>이십</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>isip</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>30</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>서른</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>seoreun</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>삼십</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>samsip</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>40</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>마흔</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>maheun</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>사십</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>sasip</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>50</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>쉰</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>swin</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>오십</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>osip</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>60</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>예순</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>yesun</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>육십</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>yuksip</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>70</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>일흔</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>ilheun</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>칠십</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>chilsip</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>80</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>여든</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>yeodeun</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>팔십</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>palsip</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>90</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>아흔</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>aheun</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>구십</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>gusip</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>100</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>백</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>baek</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>200</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>이백</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>ibaek</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>300</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>삼백</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>sambaek</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>400</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>사백</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>sabaek</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>500</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>오백</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>obaek</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>600</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>육백</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>yukbaek</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>700</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>칠백</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>chilbaek</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>800</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>팔백</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>palbaek</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>900</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>구백</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>gubaek</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>1000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>천</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>cheon</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>2000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>이천</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>icheon</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>3000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>삼천</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>samcheon</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>4000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>사천</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>sacheon</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>5000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>오천</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>ocheon</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>6000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>육천</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>yukcheon</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>7000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>칠천</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>chilcheon</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>8000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>팔천</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>palcheon</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>9000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>구천</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>gucheon</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>10000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>만</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>man</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>20000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>이만</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>iman</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>30000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>삼만</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>samman</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>40000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>사만</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>saman</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>50000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>오만</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>oman</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>60000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>육만</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>yungman</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>70000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>칠만</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>chilman</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>80000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>팔만</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>palman</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>90000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>구만</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>guman</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>100000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>십만</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>simman</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>1000000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>백만</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>baengman</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>10000000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>천만</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>cheonman</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>100000000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>일억</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>ireok</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>1000000000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>십억</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>sibeok</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>10000000000</span>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      <span>백억</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>baegeok</span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ backgroundColor: 'yellow', marginTop: '30px' }}>
              <p>Note: You can use 영 (yeong) or 공 ( gong) for zero.</p>
            </div>
            <h2 className="u-margin-bottom-medium">
              The Native Korean numbers are used:
            </h2>
            <ul>
              <li>You are counting things/people/actions</li>
              <li>Talking about the hour in time</li>
              <li className="u-margin-bottom-medium">
                Sometimes used when talking about months.
              </li>
              <h2 className="u-margin-bottom-medium">
                The Sino Korean numbers are used:
              </h2>
              <li>When counting/dealing with money</li>
              <li>When measuring</li>
              <li>When doing math</li>
              <li>In phone-numbers</li>
              <li>
                When talking about/counting time in any way except the hour
              </li>
              <li>The names of each month</li>
              <li className="u-margin-bottom-medium">
                Counting months (there is another way to count months using pure
                Korean numbers)
              </li>
            </ul>
            <h2 className="u-margin-bottom-medium">
              Patterns in Korean Numbers
            </h2>
            <h3>These are the Sino-Korean numbers as provided earlier:</h3>
            <div className="row">
              <div className="col-1-of-2">
                <ul>
                  <li>일 = one</li>
                  <li>이 = two</li>
                  <li>삼 = three</li>
                  <li>사 = four</li>
                  <li>오 = five</li>
                  <li>육 = six</li>
                  <li>칠 = seven</li>
                  <li>팔 = eight</li>
                  <li>구 = nine</li>
                  <li>십 = ten</li>
                  <li>백 = one hundred</li>
                  <li>천 = one thousand</li>
                  <li>만 = ten thousand</li>
                </ul>
              </div>
              <div className="col-1-of-2">
                <div style={{ textAlign: 'center' }}>
                  <img
                    style={{ width: '90%', marginTop: '30px' }}
                    src={Image1}
                    alt="one-in-korean"
                  />
                </div>
              </div>
            </div>
            <div style={{ backgroundColor: 'green', color: 'white' }}>
              <p>
                With only these numbers, you can create any number from 1 – 10
                million. You don’t need to learn all the numbers.
              </p>
            </div>
            <p>Korean counting isn’t so bad!</p>
            <p>
              Imagine you were learning English for the first time now. You’d
              have to learn a lot more numbers than you do in Korean, because
              each multiple of ten has a new name in English.
            </p>
            <p>
              In Korean, it’s just a matter of simple multiplication and All you
              need to do is put them together:
            </p>
            <p>So,</p>
            <p>How to create 11:</p>
            <p>십 (10) + 일 (1) = 십일 (11)</p>
            <p>What about 12? The same rule applies:</p>
            <p>십 (10) + 이 (2) = 십이 (12)</p>

            <h3>More Examples:</h3>
            <ul>
              <li>이십 = twenty (2 x 10)</li>
              <li>이십일 = twenty one (2 x 10 + 1)</li>
              <li>이십이 = twenty two (2 x 10 + 2)</li>
              <li>백일 = one hundred and one (100 + 1)</li>
              <li>백이 = one hundred and two (100 + 2)</li>
              <li>백구십 = one hundred and ninety (100 + 90)</li>
              <li>구백 = nine hundred (9 x 100)</li>
              <li>천구백 = one thousand nine hundred (1000 + 9 x 100)</li>
              <li>오천 = five thousand (5 x 1000)</li>
              <li>오천육백 = five thousand six hundred (5 x 1000 + 6 x 100)</li>
              <h3>Can it really be that easy? Yes!!!! 😀</h3>
              <p>The same rule applies to (Native) Korean Numbers:</p>
              <p>What’s 11?</p>
              <p>열 (10) + 하나 (1) = 열 하나 (11)</p>
              <h3>More Examples:</h3>
              <li>11: 열하나 (10 + 1)</li>
              <li>12: 열둘 (10 + 2)</li>
              <li>21: 스물하나 (20 + 1)</li>
              <li>59: 쉰아홉 (50 + 9)</li>
            </ul>
            <p>
              Whether you’re using Korean or Sino-Korean numbers, the same logic
              applies when it comes to adding numbers.
            </p>
            <h3>Lets Practice with few examples now:</h3>
            <ul>
              <li>My phone number is 0123-345-6789.</li>
              <p>
                Note: For Phone numbers we will always use Sino-Korean Numbers.
              </p>
              <p>So you will say 공일이– 삼사오– 육칠팔구</p>
              <li>14 people</li>
              <p>
                Note: For counting people, we should use Native-Korean numbers.
              </p>
              <p>So we will say 열넷명</p>
              <p>(명 = Myong = Counting unit for person)</p>
              <li></li>
              <p>My birthday is November 15th</p>
              <p>
                Note: For our birthday, we should use Native-Korean numbers.
              </p>
              <p>Month first and then date.</p>
              <p>So we will say 십일월 십오일</p>
              <p>(월 = Month, 일 = Day/Date)</p>
            </ul>
            <p>
              There you have it! The numbers in Korean may seem complex at
              first, but once you understand the basic principles and logic
              behind these two systems, it will be much easier to master
              counting.
            </p>
            <h3>Also read:</h3>
            <p>
              <a href="/korean-alphabet-letters/">
                Alphabets and Characters in Korean
              </a>
            </p>
          </div>
          <div className="article__blog-container__bottom">
            <div className="article__blog-container__bottom__left">
              <Link to="/">
                <img
                  className="article__blog-container__bottom__image"
                  src={Language}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/korean-alphabet-letters/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Korean Alphabets and Letters
                </li>
              </Link>

              <Link to="/korean-sentence-structure/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Sentence Structure in Korean
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default koreanNumbers;
