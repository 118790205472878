import React from 'react';
import { Link } from 'react-router-dom';
import '../sass/main.scss';
import MetaTags from 'react-meta-tags';
import Image from '../assets/german-blog.png';
import Image1 from '../assets/german-alphabet-letters.jpg';
import Image2 from '../assets/german-numbers.jpg';
import Image3 from '../assets/days-of-the-week-german.jpg';
import Image4 from '../assets/german-colors.jpg';
import Image5 from '../assets/german-months-seasons.jpg';
import Image6 from '../assets/german-umlaut.jpg';
import Image7 from '../assets/time-in-germany.jpg';

const german = () => {
  const head = () => (
    <MetaTags>
      <title>Learn German with LinguaPsych | LinguaPsych</title>
      <meta
        name="description"
        content="Learn german with easy blogs, learn about german grammar, german vocubalary and patterns with easy examples."
      />{' '}
      <link rel="canonical" href="https://linguapsych.com/german/" />
      <meta
        property="og:title"
        content="Learn german with easy blogs, learn about german grammar, german vocubalary and patterns with easy examples."
      />{' '}
      <meta
        property="og:description"
        content="Learn german with easy blogs, learn about german grammar, german vocubalary and patterns with easy examples."
      />{' '}
      <meta property="og:type" content="website" />
      <meta property="og:url" href="https://linguapsych.com/german/" />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/germany.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/germany.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}
      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_GERMAN_COURSE}`}>
          <div className="article__german-banner">
            <div className="article__german-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="Direct-object-Pronouns-Spanish"
            className="article__featured-image"
          />
          <div className="article__header--text">
            <h1 className="article__title ">
              {' '}
              <span> Learn German</span>{' '}
            </h1>
          </div>
        </header>
        <div className="row">
          <div className="col-1-of-3">
            <Link to="/german-alphabet-letters/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img src={Image1} style={{ width: '100%' }} />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>German Alphabet</h2>
                  </div>
                  <div>
                    <p>
                      5 Fun Ways To Learn German Alphabet With Pronunciation And
                      Sound
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-1-of-3">
            <Link to="/german-numbers/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img src={Image2} style={{ width: '100%' }} />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>German Numbers</h2>
                  </div>
                  <div>
                    <p>
                      Easiest Way To Count German Numbers With Pronunciation,
                      1-100
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-1-of-3">
            <Link to="/german-days-of-the-week/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img src={Image3} style={{ width: '100%' }} />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>German days of the week</h2>
                  </div>
                  <div>
                    <p>
                      Simple Guide to German Days of the Week with Pronunciation
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-1-of-3">
            <Link to="/german-colors/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img
                    src={Image4}
                    style={{ width: '100%', height: '205px' }}
                  />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>Let's learn German colors</h2>
                  </div>
                  <div>
                    <p>5 Unique Ways to Learn German Colors</p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-1-of-3">
            <Link to="/german-months-seasons/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img src={Image5} style={{ width: '100%' }} />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>German months & seasons</h2>
                  </div>
                  <div>
                    <p>
                      The Ultimate Guide to German Months, Seasons with
                      Pronunciation &amp; Abbreviation
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-1-of-3">
            <Link to="/german-umlaut/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img
                    src={Image6}
                    style={{ width: '100%', height: '170px' }}
                  />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>Umlauts in German: ä, ö, ü</h2>
                  </div>
                  <div>
                    <p>
                      The Intensive Guide to German Umlaut with pronunciation,
                      ä, ö, ü; Letters
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-1-of-3"> */}
          <Link to="/time-in-german/">
            <div className="blog-card__container">
              <div className="blog-image">
                <img src={Image7} style={{ width: '100%', height: '205px' }} />
              </div>
              <div className="blog-text">
                <div className="blog-title">
                  <h2>Time in German</h2>
                </div>
                <div>
                  <p>
                    Complete Guide on German Time | How to fluently tell time in
                    German
                  </p>
                </div>
              </div>
            </div>
          </Link>
          {/* </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default german;
