import React from 'react';
import { Link } from 'react-router-dom';

import '../sass/main.scss';
import MetaTags from 'react-meta-tags';
import Image from '../assets/particles-in-japanese.jpg';
import Table1 from '../assets/japanese-particles-table-1.PNG';
import Language from '../assets/language_course_bottom.png';

const japaneseParticles = () => {
  const head = () => (
    <MetaTags>
      <title>
        Japanese Particles: The Essential Guide to wa, ga, wo, ni, mo |
        LinguaPsych
      </title>
      <meta
        name="description"
        content="This is the only guide you will need for learning Japanese Particles, it contains as much as 14 Japanese particles with detailed explanation with lot of examples."
      />{' '}
      <link
        rel="canonical"
        href="https://linguapsych.com/japanese-particles/"
      />
      <meta
        property="og:title"
        content="This is the only guide you will need for learning Japanese Particles, it contains as much as 14 Japanese particles with detailed explanation with lot of examples."
      />{' '}
      <meta
        property="og:description"
        content="This is the only guide you will need for learning Japanese Particles, it contains as much as 14 Japanese particles with detailed explanation with lot of examples."
      />{' '}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        href="https://linguapsych.com/japanese-particles/"
      />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/particles-in-japanese.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/particles-in-japanese.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}

      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_DOMAIN_PRODUCTION}`}>
          <div className="article__general-banner">
            <div className="article__general-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="particles-in-japanese"
            className="article__featured-image"
          />
          <div className="article__header-elements">
            <h1 className="article__title ">
              {' '}
              <span
                style={{ fontSize: '5rem' }}
                className="article__highlight--black "
              >
                {' '}
                Japanese Particles: The Essential Guide{' '}
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <p>
              {' '}
              Japanese is known for it’s complicated writing system which
              includes Hiragana, katakana and kanji. But once someone starts
              learning Japanese, particles in Japanese start to haunt them for a
              long long time, this happens because English and other European
              languages use prepositions and it takes time and practice for the
              students to get used to the particles.
            </p>
            <p>
              Particles are an essential part of Japanese Language. Particles,
              when used in a sentence give us a critical information about the
              meaning. so, if a wrong particle is used, the whole meaning of a
              sentence can change. That’s why it gets very important to know how
              to use the Japanese particles. A particle in Japanese is called
              joshi (助詞).
            </p>
            <h2 className="u-margin-bottom-medium">Japanese Particles List</h2>
            <p>
              Below is the list of Japanese particles which covers everything
              you’ll ever use in Japanese
            </p>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table1}
                alt="japanese-particles-table-1"
                className="article__big-images"
              />
            </div>
            <h2 className="u-margin-bottom-medium">
              Particle Wa は in Japanese
            </h2>
            <p>
              は (wa) is referred as the topic particle in Japanese, it always
              comes after the topic of a sentence and tells us what the sentence
              is about.
            </p>
            <p>
              If you want to know the English translation of は, it can be
              translated as
            </p>
            <h3>As for</h3>
            <h3>Speaking of</h3>
            <p>Ashita wa Kinyoubi desu</p>
            <p>あしたは金曜日です</p>
            <p>Tomorrow is Friday</p>
            <p>
              If you observe the above sentence, you’ll notice that the particle
              は was used after the topic of the sentence Tomorrow. Translating
              the sentence literally will become
            </p>
            <h4>As for Tomorrow, Friday is</h4>
            <h4>Speaking of Tomorrow, Friday is</h4>
            <h4>But the real meaning stays as Tomorrow is Friday.</h4>
            <p>
              Desu can be literally translated to is/am/are. If you want to
              learn the complete features of Desu,
              <a href="/desu-japanese/">click here.</a>
            </p>
            <p>
              Pronunciation of は: The particle は is pronounced as “wa” and not
              “ha”. It should be written as は. All other cases of “wa” should
              be written as わ.
            </p>
            <ul>
              <li>わたし の でんわ ばんごうは 303-404-505です。</li>
              <li>watashi no denwa bangou wa 303404505</li>
              <li>My phone number is 303404505</li>
              <p>
                Particle は is also used to show contrast between two objects,
                items or ideas. When you want to use the particle は in this
                way, it usually replaces the other particles が (ga) and を
                (wo).
              </p>
              <li>Subject + Object + Verb (Japanese sentence structure)</li>
              <p>
                Between an object and a verb, usually particles が and を are
                used. が is the subject marker and を is the object marker.
                Let’s look at the example
              </p>
              <li>わたし は ごはんをたべます</li>
              <p>Watashi wa gohan wo tabemasu</p>
              <p>I eat rice</p>
              <p>
                Here, ごはん is the object and たべます is the verb, particle を
                is used between them to mark the object
              </p>
              <p>
                If you want to put contrast between two items you can use は
                instead of を. But if you want to put a contrast on rice, you’ll
                need another object let’s say meat (niku). So, how will you use
                them both?
              </p>
              <li>ごはん は たべます。でも, にく は たべません</li>
              <p>Gohan wa tabmasu. Demo, niku wo tabemasen</p>
              <p>I eat rice, but I don’t eat meat</p>
              <p>
                In the above example we used は instead of を. By doing this we
                put a lot of contrast on the two objects Rice and meat. Let’s
                look at another example:
              </p>
              <li>夏が好きです。</li>
              <p>Natsu ga suki desu</p>
              <p>I love Summers</p>
              <li>冬が嫌いです。</li>
              <p>Fuyu ga kirai desu</p>
              <p>I hate Winters</p>
              <p>
                We use particle ga to put a contrast on the subject, but you can
                replace it with は to put contrast on two subjects.
              </p>
              <li>夏は好きです。でも、冬は嫌いです。</li>
              <p>Natsu ga suki desu. Demo, fuyu ga kirai desu</p>
              <p>I love Summers, but I hate Winters</p>
            </ul>
            <p>
              Here, by using the particle は, you put a contrast on Summers and
              Winters. Usually, to contrast an object with verb, particles が
              and を are used, but if you want to emphasize on the objects,
              you’ll use the particle は.
            </p>
            <h2 className="u-margin-bottom-medium">
              Particle Ga が in Japanese
            </h2>
            <p>
              We had a brief introduction of the particle が (ga) above. Now
              let’s study it in detail.
            </p>
            <p>
              This particles is referred to as the subject marking particle, it
              can be also used with an object with certain verbs and adjectives.
              Particle が is used to emphasize on the existence as a subject
              verb. Let’s try to understand the usage of the particle が by
              using an example:
            </p>
            <ul>
              <li>なかむら さん は 日本 に行きます</li>
              <p>Nakamura san wa nihon ni ikimasu</p>
              <p>Mr. Nakamura is going to Japan.</p>
              <p>
                In the above sentence, Nakamura is the subject with respect to
                the verb hence, topic particle は was used. Let’s take a
                situation where we both work in a same organization, we both
                know someone is going to Japan. But, I know who’s going but you
                don’t have that information. How will I emphasize that Mr.
                Nakamura is the one who’s going to Japan? By using the particle
                が
              </p>
              <li>なかむら さん が 日本 に行きます</li>
              <p>Nakamura san ga nihon ni ikimasu</p>
              <p>Mr. Nakamura is the one who’s going to Japan</p>
              <p>
                This sentence means the Mr. Nakamura is going to Japan, which in
                English will mean the same with extra stress on Mr. Nakamura.
                His name here, provided the new piece of information with the
                help of the particle が.
              </p>
              <h3>Functions of the Particle が</h3>
              <li>
                Particle が is used while asking a question and while answering
                a question using (誰 dare and 何 nani)
                <p>
                  You can ask a question in Japanese using 誰 (who) and 何
                  (what) with particle が, let’s see how
                </p>
              </li>
              <p>¿誰が 日本 に 行きます か? (Who’s going to Japan?)</p>
              <p>
                なかむら さん が 日本 に行きます(Mr. Nakamura is going to Japan)
              </p>
              <p>¿何が好きですか? (What do you like?)</p>
              <p>犬 が好き です (I like Dogs)</p>
              <p>
                Please not that a question in Japanese will never be followed by
                the particle は but by the article が. An answer to the question
                will be always given by the particle が.
              </p>
              <li>
                To indicate existence using あります (arimasu) and います
                (imasu)
                <p>
                  Particle が is always followed by あります (arimasu) and
                  います (imasu) when there is a need to indicate the existence.
                  あります (arimasu) is used to indicate existence of non living
                  objects, on the other hand, います (imasu) is used to indicate
                  the existence of living objects. They can be translated as
                  there is/are in English.
                </p>
                <p>私 は ペン が あります</p>
                <p>Watashi wa pen ga arimasu</p>
                <p>I have a pen</p>
                <p>わたし わ ぺん が ありません</p>
                <p>Watashi wa pen ga arimasen</p>
                <p>I don’t have a pen</p>
                <p>
                  Note that negative form of あります (there is ) is ありません
                  (there is no).
                </p>
                <p>
                  Also, あります is also used to indicate possession of
                  something. for example Pen in the above sentence
                </p>
                <p>あります is used to indicate only the non living things.</p>
                <p>私は 犬 が います</p>
                <p>Watashi wa inu ga imasu</p>
                <p>I have a dog</p>
                <p>私は 犬 が いますいます</p>
                <p>Watashi wa inu ga imasen</p>
                <p>I don’t have a dog</p>
                <p>
                  Please note that negative form of います (there is) is
                  いません (there is no)
                </p>
                <p>います and いません are only used with living things</p>
              </li>
              <li>
                We can also use this particle to mark an object of desire with
                the help of some verbs and adjectives.
              </li>
              <h4>Subject + Object + が + 欲しい (Adjective)</h4>
              <h4>Subject + Object + が + verb formたい です</h4>
              <p>
                欲しい (hoshii) is a Japanese adjective of desire which means I
                want in English. Whenever you want something, you can use this
                adjective after the particle が like mentioned in the structure
                above. Let’s look at some examples
              </p>
              <p>私 は 時間 が 欲しい です</p>
              <p>Watashi wa jikan ga hoshii desu</p>
              <p>I need time</p>
              <p>私は ナイフが欲しい です</p>
              <p>Watashi wa naifu ga hoshii desu</p>
              <p>I need Knife</p>
              <p>
                Please note that you can omit the 私 は as 欲しい is used just
                with the first person.
              </p>
              <p>
                たい form of a verb also showcases necessity or need but with
                the help of a verb, if you translate it to English, it comes
                down as I want to eat, I want to leave etc. To convert a verb to
                it’s tai form just take a root form of a verb and add tai to it.
                For example in the verb 飲みます (nomimasu) which means to drink
                , it’s root is 飲み (nomi) and when you add tai to it, it
                becomes 飲みたい (nomitai) which means I want to drink. Let’s
                look at use of the particle が with the help of two examples
              </p>
              <p>私は水が飲みたいです</p>
              <p>Watashi wa mizu ga nomitai desu</p>
              <p>I want to drink water</p>
              <p>私は天ぷらが食べたいです</p>
              <p>Watashi wa tempura ga tabetai desu</p>
              <p>I want to eat Tempura</p>
              <p>
                Here we saw that the particle が is used to ask a question or
                answer a question, to tell about the existence, to mark the
                subject and to show need or necessity.
              </p>
            </ul>
            <h2 className="u-margin-bottom-medium">
              Particle He へ in Japanese
            </h2>
            <p>
              Particle へ (he) in Japanese is referred to as the direction
              particle as it helps to indicate a direction or a destination.
            </p>
            <ul>
              <li>日本へ行きます</li>
              <li>I’m going to Japan</li>
              <p></p>
              <li>家へ 帰ります</li>
              <li>I’m going back home</li>

              <p>
                As we can see in the above examples, particle へ is helping us
                to refer to a destination Home and Japan.
              </p>
              <p>
                Particleへ is also used to mark the receiver of an action. Let’s
                see how it helps to indicate the receiver of an action
              </p>
              <li>友達へ電話した</li>
              <li>I called my friend</li>
              <p></p>
              <li>母へ郵送しました</li>
              <li>I mailed my mother</li>
            </ul>
            <p>
              In the above examples, we can see that particle へ helped us to
              mark the receiver of the action Friend and Mother.
            </p>
            <p>
              Pronunciation of particle へ: When へ is used hiragana in a
              sentence, it will be pronounced as he but when used as a particle,
              it will sound as e and not he.
            </p>
            <h2 className="u-margin-bottom-medium">
              Particle Ni に in Japanese
            </h2>
            <p>
              The particle に is referred as the location particle or the time
              particle in Japanese. Particle に (ni) can be used to indicate
              time, time duration, location, purpose or movement .
            </p>
            <p>
              When used as a location particle, it helps us to point out the
              existence using あります and います which means to be. Let’s look
              at the examples
            </p>
            <ul>
              <li>家 に ビル が あります</li>
              <li>I have beer at home</li>
              <p>
                If you see closely, particle に follows the location pointed out
                in the sentence, in this case it was 家 (home). You can make a
                lot of sentences using the same structure, i.e. Location + に +
                Object/person ga arimasu/imasu.
              </p>
              <p>
                When used as a time marking particle, it refers to a point of
                time where something takes place. For example
              </p>
              <li>6時に試験を受けます</li>
              <li>I have an exam at 6</li>
              <p></p>
              <li>11月にスペインに行きます</li>
              <li>I’m going to Spain in November</li>
              <p>
                As you can see, particle に always follows the time reference to
                put a focus.
              </p>
              <p>
                It can be translated as in, at or on in English. Note that
                English puts different prepositions for months, weeks and time
                duration but Japanese uses just the preposition に for all of
                them.
              </p>
              <p>
                Please note that preposition に is not used with relative time
                expressions like today, tomorrow, this week, next month etc
              </p>
            </ul>
            <h2 className="u-margin-bottom-medium">
              Particle Wo を in Japanese
            </h2>
            <p>
              The particleを is referred as an object particle or an object
              marker, as it follows the direct object of an action. Let’s see
              how it works
            </p>
            <ul>
              <li>Direct Object + を + Verb</li>
              <p></p>
              <p></p>
              <li>私はパンを食べます</li>
              <li>I eat Bread</li>
              <p>
                As you can see, the particle を here came just after direct
                object which is パン (Bread), the particle then, was followed by
                a verb which is 食べます(to eat). You can take other verbs as
                well and use it with a direct object and the particle を.
              </p>
              <li>彼はビールを飲みます</li>
              <li>He drinks beer</li>
              <p>
                Particle を can be also used to indicate the place of movement.
                When used in this regard, it usually takes verbs which describe
                motion such as to crawl, to jump, to cross, to walk etc. Let’s
                see how we use it in this context
              </p>
              <li>毎日橋を渡ります</li>
              <li>I cross a bridge everyday</li>
              <p></p>
              <li>私は道を飛び越える</li>
              <li>I jump the road</li>
              <p>
                Here as well, the particle を was positioned just after the
                direct object of the action which here were Bridge and Road.
              </p>
            </ul>
            <h2 className="u-margin-bottom-medium">
              Particle De で in Japanese
            </h2>

            <p>
              The particleで is referred as the location particle and means
              particle . It is used to mark locations, to focus on materials
              from which something is made, means of doing something, means of
              transport and also for a reason to do something
            </p>
            <p>
              When used as a location particle, it indicates the location where
              the action takes place
            </p>
            <ul>
              <li>東京で日本語を勉強します</li>
              <li>I’ll study Japanese in Tokyo</li>
              <p></p>
              <li>図書館で勉強しましょう</li>
              <li>Let’s study at Library</li>
              <p></p>
              <p>
                In the above examples, the particle で was used just after the
                location where the action took place which were 東京 (Tokyo) and
                図書館 (Library). Please note that the location is always
                followed by the particle で.
              </p>
              <p>
                Please note that the particle で is never used with あります (to
                be), います (to be), 住みます (to live), 勤める (to work for),
                always use particle に with these verbs.
              </p>
              <p>
                When particle で is used as a means particle, it is used to
                indicate material or means of doing something. It can be
                translated to “by” or “with” in English.
              </p>
              <p>
                When used to focus on the material by which an object was made,
                particle で can be translated to “by”, “with” or “out of”. Let’s
                look at some examples
              </p>
              <li>紙でボートを作りました</li>
              <li>I made a boat from paper</li>
              <p></p>
              <li>砂で家を作りました</li>
              <li>I made a house out of the sand</li>
              <p>
                Here, materials 紙 (paper) and 砂 (sand) were followed by the
                particleで to focus on the materials.
              </p>
              <p>
                When used as a means of transport, the source is followed by the
                particle で
              </p>
              <li>電車で通勤しています</li>
              <li>I commute by train</li>
              <p></p>
              <li>バスで家に着いた</li>
              <li>I reached home by bus</li>
              <p>Means of transport is always followed by the particle で</p>
              <p>
                Whenever you want to show the action completed by tools or
                instrument, always use the particleで. Let’s see how
              </p>
              <li>ハサミで紙を切る</li>
              <li>I cut the paper with scissors</li>
              <p></p>
              <li>双眼鏡で山を見ることができます</li>
              <li>You can see the mountain with binoculars</li>
              <p>
                Also when using a language, use the particle で. Language works
                as a tool too
              </p>
              <li>英語で話してください</li>
              <li>Please speak in English</li>
            </ul>
            <p></p>
            <h2 className="u-margin-bottom-medium">
              Particle Mo も in Japanese
            </h2>
            <p>
              The particle も usually takes place after a noun. It can be
              translated as too or also in English. When used, it replaces the
              particles は, が and を. Let’s look at some examples
            </p>
            <ul>
              <li>Person 1: 私 は 犬 が 好き です</li>
              <li>I love dogs</li>
              <li>Person 2: 私 も 犬 が 好き です</li>
              <li>I love dogs too</li>
              <p>
                Look how particleもreplaced は to become “too”. Let’s look at an
                example where particle も replaces the particle が
              </p>
              <li>コーヒーが欲しい、お茶も欲しい</li>
              <li>I want coffee, I want tea too</li>
              <p>
                Remember we learnt that the adjective 欲しい takes the partcle
                が? Here it replaced the particle to focus on the necessity of
                another thing. Now let’s see, how it replaces the particle を
              </p>
              <li>私は魚を食べます、肉も食べます</li>
              <li>I eat fish, I eat meat too</li>
              <p>
                Look how it replaced the particle を to put emphasis on the
                second object and became “too”.
              </p>
              <p>
                Particle も is also used to put an emphasis on the amount or
                quantity of something. When used in this context, it is
                translated as “as many as” or “as much as” in English. Let’s see
                how we use it
              </p>
              <li>車は50万円もかかります</li>
              <li>The car costs as much as 500 000 yen</li>
              <p></p>
              <li>私はコインを50枚も持っています</li>
              <li>I have as much as 50 coins</li>
              <p>
                If you want to put emphasis on the amount or the quantity, just
                the use the particle も after the amount you want to emphasize
                on.
              </p>
              <p>
                There is one more use of the particle も, when you want to put
                emphasis on the subject, so as it becomes “even” in English.
                Just use it after the subject you want to emphasize, let’s see
                how
              </p>
              <li>子供も漢字が読める</li>
              <li>Even kids can read kanji</li>
              <p></p>
              <li>これはとても辛いで、インド人も食べられない</li>
              <li>This is so spicy, even Indians can’t eat it</li>
              <p>
                When you want to emphasize the noun, just use the particle も
                after the noun like in the sentences above
              </p>
            </ul>
            <h2 className="u-margin-bottom-medium">
              Particle Kara から in Japanese
            </h2>
            <p>
              This article is known as the starting point article, it tells you
              where or when something begins. It can be translated as “from” and
              when used in this way, it comes after the noun. Let’s look at some
              examples
            </p>
            <ul>
              <li>ミーティングは5時から始まります</li>
              <li>Meeting starts from 5 o’clock</li>
              <p></p>
              <li>ここからバスに乗ることができます</li>
              <li>From here, you can take the bus</li>
            </ul>
            <p>
              Please notice that the particle から came right after the starting
              point noun. Always use it this way, it can be always be translated
              to “from” in English. If you’re thinking that “from” is also used
              to denote to a material from which something is made, you’re
              correct. You can use から in that way as well.
            </p>
            <p>
              This particle is also used as a material marker,but we studied one
              more article which meant the same right? you’re correct, the
              particle で can also be used the same way. But what’s the
              difference between both of them? Let’s see
            </p>
            <p>Kara から vs De で</p>
            <p>
              Kara からis used when the material we’re referring to completely
              changes it’s state. For example I made Wine from rice. Here, rice
              completely changes it’s state from solid to liquid.
            </p>
            <p>
              De で on the other hand, is used when the material we’re referring
              to retain it’s state. For example I made table from wood. Here,
              wood retained it’s state. Also, you can use both of them in place
              of each other and it won’t be wrong, it’s just that using it in
              their assigned places will be more appropriate. Now let’s look at
              some examples
            </p>
            <ul>
              <li>ジュースはオレンジから作られています</li>
              <li>Juice is made from Orange</li>
              <p></p>
              <li>ワインはブドウから作られています</li>
              <li>Wine is made from grapes</li>
            </ul>
            <p>
              We chose kara から here instead ofで because material here changed
              it’s state completely
            </p>
            <h2 className="u-margin-bottom-medium">
              Particle Made まで in Japanese
            </h2>
            <p>
              This particle is called the limitation particle as it marks the
              time and place where the action ends. When used in this context,
              It can be translated as “until” or “to” in English. Let’s look at
              some examples
            </p>
            <ul>
              <li>今夜まで待ちます</li>
              <li>I’ll wait until tonight</li>
              <p></p>
              <li>会議は6時までです</li>
              <li>Meeting is until 6 o’clock</li>
              <p></p>
              <li>警察署まで歩いた</li>
              <li>I walked till the Police station</li>
            </ul>
            <p>
              As you can see, the particleまで took a position just after the
              end time or position.
            </p>
            <p>
              The particle まで is also used to indicate a limit, extent of
              something, or an unexpected object. Let’s see how the particles is
              used in that way
            </p>
            <p>When used to indicate a limit</p>
            <ul>
              <li>子供までこれを知っています</li>
              <li>Even kids know this</li>
              <p></p>
              <li>大人まで漢字が読めない</li>
              <li>Even Adults can’t read kanji</li>
              <p>
                In the above examples, kids and adults are the limits which are
                marked by the particle まで.
              </p>
              <p>When used to indicate an extent of something</p>
              <li>カレーはとても美味しいで, 指まで食べました</li>
              <li>The curry is so good, I even ate my fingers</li>
              <p>When used to mark an unexpected object</p>
              <li>彼はすみませんまでいってしまった</li>
              <li>He even said sorry to me</li>
            </ul>
            <p>
              Here him saying sorry was unexpected, hence the particle まで was
              used to emphasize on the unexpected thing that occurred.
            </p>
            <h2 className="u-margin-bottom-medium">
              Particle To と in Japanese
            </h2>
            <p>
              The particle と is referred to as connecting particle because we
              use it connect 2 or more elements. When used in this context, It
              is translated as “and” in English.
            </p>
            <p>
              You can make a list of elements by using と between them, for
              example if you want to say I want A, B, C and D. In English you’ll
              use “and” only in the end, but in Japanese you’ll use it every
              time, AとBとCとDが欲しい
            </p>
            <ul>
              <li>コーヒーとサンドイッチが欲しい</li>
              <li>I want Coffee and Sanwich</li>
              <p></p>
              <li>マイケルとジャスティンとマークが今日来ます</li>
              <li>Michael, Justin and Mark are coming today</li>
              <p>
                The particle と​is also used to indicate togetherness, it also
                means “with” or “together” in English. Let’s look at the
                examples
              </p>
              <li>明日彼女とデートする</li>
              <li>I have date with my girlfriend tomorrow</li>
              <p></p>
              <li>トムと買い物に行きました</li>
              <li>I went for shopping with Tom​</li>
            </ul>
            <h2 className="u-margin-bottom-medium">
              Particle Ka か in Japanese
            </h2>
            <p>
              The particleか is known as the question particle because when it
              comes at the end of the sentence, it makes the sentence a
              question. Please note that using particle か will make the
              question formal. Let’s take an example and make it a question
            </p>
            <ul>
              <li>今日は土曜日です</li>
              <li>Today is Saturday</li>
              <p>To make it a question, just add the particle か in the end</p>
              <li>¿今日は土曜日ですか?</li>
              <li>Is today Saturday?</li>
              <p>
                Unlike English, there is no need to change the sentence
                structure to make it a question. Just add the particle か in the
                end. Also Spanish uses two interrogation marks while asking a
                question, one upside down question mark (¿) in the beginning of
                a question and the normal one in the end (?).
              </p>
              <p>
                Particle か is also used as an alternative marker as you can
                make choices using this particle. In this context, it is
                translated to “or” in English. You can use it as Option 1 + か +
                Option 2, this will become option 1 or option 2. Let’s look at
                the examples
              </p>
              <li>¿犬か猫, 何が好きですか?</li>
              <li>Which one do you like, Cat or Dog?</li>
              <p></p>
              <li>¿お茶かコーヒーが欲しいですか?</li>
              <li>Do you want Tea or Coffee?</li>
            </ul>
            <h2 className="u-margin-bottom-medium">
              Particle No の in Japanese
            </h2>
            <p>
              The particle の can be referred to as the noun modifier article,
              it usually marks possession of an object and when used in this
              context, it can be translated as “of” in English. Also, do you
              know how to say My, your, his, her in Japanese? Well, use this
              particle
            </p>
            <ul>
              <li>My – わたし の</li>
              <li>Your – きみの/あなた の</li>
              <li>His – かれ の</li>
              <li>Her – かのじょ の</li>
              <p>
                The particle の is used to mark the possession so if you want to
                say Mark’s, Julia’s, use this particle after the name to mark
                possession.
              </p>
              <p>
                This particle also marks the attributes of a noun such as it’s
                category or material. Let’s look at some examples
              </p>
              <li>彼女は日本語の先生です</li>
              <li>She’s a teacher of Japanese</li>
              <p></p>
              <li>これはご米のワインです</li>
              <li>This wine is of Rice.</li>
              <p>
                Also, we discussed how to make a formal question by adding the
                particle か at the end of the sentence. What if you want to make
                an informal question? Simple, add the particle の at the end of
                the sentence. Let’s see how
              </p>
              <li>¿ライターがあるの?</li>
              <li>Do you have a lighter?</li>
              <p></p>
              <li>¿買い物 に 行くの?</li>
              <li>Will you go to shopping?</li>
            </ul>
            <h2 className="u-margin-bottom-medium">
              Particle Ne ね in Japanese
            </h2>
            <p>
              The particle ね is always used at the end of the sentence to seek
              confirmation. It translates to “isn’t it” in English. It’s a part
              of daily speech and not necessary that your answer is required
              every time someone says ね at the end of the sentence. Some useful
              examples are
            </p>
            <ul>
              <li>今日はいい天気ですね</li>
              <li>Today weather is good isn’t it?</li>
              <p></p>
              <li>今日は早いですね</li>
              <li>You’re early today, isn’t it?</li>
            </ul>
            <p>
              Please note that there is no need to add a question mark when
              using the particleね at the end a sentence.
            </p>
            <h2 className="u-margin-bottom-medium">
              Particle Yo よ in Japanese
            </h2>
            <p>
              The particle よ is always used at the end of a sentence, is us
              used when the speaker has a strong opinion on something or the
              speaker is declaring something. Let’s look at some examples
            </p>
            <ul>
              <li>明日スペインに行きますよ</li>
              <li>I’m going to Spain tomorrow (declaring something)</li>
              <p></p>
              <li>日本が大好きですよ</li>
              <li>I love Japan (strong opinion)</li>
            </ul>
            <h2 className="u-margin-bottom-medium">Conclusion</h2>
            <p>
              Today we learnt all the particles available in the Japanese. We
              learnt the particles wa, ga, wo, ni, he, de, mo, kara, made, to,
              ka, no, ne and yo. All individual uses of them are discussed, you
              can come back to this guide anytime you have any confusion.
            </p>
          </div>
          <div className="article__blog-container__bottom">
            <div className="article__blog-container__bottom__left">
              <Link to="/">
                <img
                  className="article__blog-container__bottom__image"
                  src={Language}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/desu-japanese/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Desu in Japanese
                </li>
              </Link>
              <Link to="/learn-japanese-in-delhi/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Learn Japanese in Delhi
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default japaneseParticles;
