import React from 'react';
import { Link } from 'react-router-dom';
import '../sass/main.scss';
import MetaTags from 'react-meta-tags';
import Image from '../assets/days-of-the-week-german.jpg';
import Monday from '../assets/monday-in-german.png';
import Tuesday from '../assets/tuesday-in-german.png';
import Wednesday from '../assets/wednesday-in-german.png';
import Thursday from '../assets/thirsday-in-german.png';
import Friday from '../assets/friday-in-german.png';
import Saturday from '../assets/saturday-in-german.png';
import Sunday from '../assets/sunday-in-german.png';
import German from '../assets/german_course_bottom.png';

const germanDays = () => {
  const head = () => (
    <MetaTags>
      <title>
        Simple Guide to German Days of the Week with Pronunciation | Monday to
        Sunday | LinguaPsych
      </title>
      <meta
        name="description"
        content="Complete guide on how to learn German Days of the Week and tips on how to use them daily. Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday in German."
      />{' '}
      <link
        rel="canonical"
        href="https://linguapsych.com/german-days-of-the-week/"
      />
      <meta
        property="og:title"
        content="Complete guide on how to learn German Days of the Week and tips on how to use them daily. Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday in German."
      />{' '}
      <meta
        property="og:description"
        content="Complete guide on how to learn German Days of the Week and tips on how to use them daily. Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday in German."
      />{' '}
      <meta property="og:type" content="webiste" />
      <meta
        property="og:url"
        href="https://linguapsych.com/german-days-of-the-week/"
      />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/days-of-the-week-german.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/days-of-the-week-german.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );
  return (
    <React.Fragment>
      {head()}
      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_GERMAN_COURSE}`}>
          <div className="article__german-banner">
            <div className="article__german-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="days-of-the-week-german"
            className="article__featured-image"
          />
          <div
            style={{ marginTop: '-64px' }}
            className="article__header-elements"
          >
            <h1 className="article__title">
              {' '}
              <span className="article__highlight--black">
                {' '}
                GERMAN DAYS OF THE WEEK
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <p>
              Do you want to learn how to say days of the week in German? You
              are at the right place. In this article, we will learn days name
              in German along with their pronunciation. We will make use of
              examples for better understanding and I will also give you tips on
              how to learn and remember days of the week forever. We will learn
              how to say Monday, Tuesday, Wednesday, Thursday, Friday, Saturday,
              and Sunday in German.
            </p>
            <h2 className="u-margin-bottom-medium">
              Why you should learn German Days of the Week?
            </h2>
            <p>
              Before we get into learning days of the week in German, we should
              know the need to learn them.
            </p>
            <p>
              Imagine you call one of your German friend to tell him you are in
              Germany and he tells you over phone to meet him on Samstag. And
              you were not able to understand what he said to meet up on. The
              situation is quite embarrassing when you claim you know German but
              could not even understand Saturday in German.
            </p>
            <p>
              Days of the week are used in day to day conversations. So it is
              very important that you while learning German language, you pay
              attention to days name as well. There are 7 days in a week. Most
              of the day resemble to those in English language.
            </p>
            <h2 className="u-margin-bottom-medium">
              What are the Days of the Week in German?
            </h2>
            <blockquote
              className="u-margin-bottom-medium"
              style={{ color: 'brown' }}
            >
              All the days in German end with the word “Tag” which mean “day”;
              except for Wednesday which ends with “Woch” which means “week”.
            </blockquote>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '50%', marginTop: '-30px' }}
                  src={Monday}
                  alt=" monday-in-german"
                />
              </div>
              <div className="col-1-of-2 ">
                <h3>Monday</h3>
                <p>
                  Monday in German is Montag. It is pronounced as “Mohntaag”.
                  The name comes from the word mond which means moon. Montag and
                  Monday are quite relatable and look similar. In Germany,
                  Monday is the first day of the week.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '50%', marginTop: '-30px' }}
                  src={Tuesday}
                  alt=" tuesday-in-german"
                />
              </div>
              <div className="col-1-of-2 ">
                <h3>Tuesday</h3>
                <p>
                  Tuesday in German is Dienstag. It is pronounced as
                  “Deenstaag”. The name comes from the German god of the war.
                  There is no relatable connection between the German and
                  English word of Tuesday. So you will have to learn this.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '50%', marginTop: '-30px' }}
                  src={Wednesday}
                  alt="wednesday-in-german"
                />
              </div>
              <div className="col-1-of-2 ">
                <h3>Wednesday</h3>
                <p>
                  Wednesday in German is Mittwoch. It is pronounced as
                  “Mittwokh”. Mittwoch literally means “mid week”. So you can
                  learn this by remembering that Wednesday is comes in the
                  middle of the week.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '50%', marginTop: '-30px' }}
                  src={Thursday}
                  alt="thirsday-in-german"
                />
              </div>
              <div className="col-1-of-2 ">
                <h3>Thursday</h3>
                <p>
                  Thursday in German is Donnerstag. It is pronounced as
                  “donorstaag”. Donnerstag literally means “thunder’s day”. It
                  is named after the god of thunder.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '50%', marginTop: '-30px' }}
                  src={Friday}
                  alt="friday-in-german"
                />
              </div>
              <div className="col-1-of-2 ">
                <h3>Friday</h3>
                <p>
                  Friday in German is Freitag. It is pronounced as “Frytaag”.
                  Freitag literally means “free day”. So can remember it by that
                  since Friday is technically the last working day of the week
                  and it brings along weekend.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '50%', marginTop: '-30px' }}
                  src={Saturday}
                  alt="saturday-in-german"
                />
              </div>
              <div className="col-1-of-2 ">
                <h3>Saturday</h3>
                <p>
                  Saturday in German is Samstag. It is pronounced as
                  “Zaamstaag”. In some parts of Germany, Saturday is also called
                  Sonnabend, which means “sunday eve“.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '50%', marginTop: '-30px' }}
                  src={Sunday}
                  alt="sunday-in-german"
                />
              </div>
              <div className="col-1-of-2 ">
                <h3>Sunday</h3>
                <p>
                  Sunday in German is Sonntag. It is pronounced as “Zohntaag”.
                  It literally means “sun day” as said in English. So this is
                  the easiest one to remember. It is named after the Sun god.
                </p>
              </div>
            </div>
            <h2 className="u-margin-bottom-medium">
              Abbreviations in German Days of the Week
            </h2>
            <p>
              Abbreviation are equally important to learn as the days in a week
              are. Most of the time while writing we prefer to use abbreviations
              for Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, and
              Sunday. Abbreviations makes writing easier and faster.
            </p>
            <p>
              In English, abbreviations for days are Mon, Tue, Wed, Thu, Fri,
              Sat, Son. In English, we write just the first three letters of the
              week days as abbreviations.
            </p>
            <p>
              But it is a little different in German. In German, to write
              abbreviation for week days, we write just the initial two letters.
              Thus, Montag abbreviation is Mo. Likewise, for Dienstag, Mittwoch,
              Donnerstag, Freitag, Samstag, and Sonntag, abbreviation will be
              Di, Mi, Do, Fr, Sa, and So respectively.
            </p>
            <p className="u-margin-bottom-medium">
              So next time you visit Germany and see something like this written
              on boards, you will be able to well understand.
            </p>
            <h2 className="u-margin-bottom-medium">
              Related Grammar You Must Know
            </h2>
            <p>
              We have learnt all the days in German. Now we should also know
              some grammar related to this topic.
            </p>
            <p style={{ fontWeight: 'bold', color: 'black' }}>
              Capitalized Nouns{' '}
            </p>
            <p>
              All nouns in German language are written with first letter as
              capital. This is the rule followed all through German language.
              Following this rule, all days of the week will be capitalized in
              almost all cases. Just one exception to this rule. When you are
              saying something like ‘on Mondays i go to school’, you will not
              capitalize Mondays. This rule is followed when something happens
              habitually. So it becomes ‘Ich gehe montags zur Schule’.
              <p></p> More example, Ich spiele sonntags mit meinen Freunden. (On
              sundays i play with my friends.)
            </p>
            <p style={{ fontWeight: 'bold', color: 'black' }}>Gender of Days</p>
            <p>
              All nouns are either Masculine, Feminine or Neutral. All the days
              of the week are Masculine. So it takes the article der (definite
              article) and ein (indefinite article). So it is always der Montag,
              der Dienstag, der Mittwoch, der Donnerstag, der Freitag, der
              Samstag, der Sonntag.
            </p>
            <p style={{ fontWeight: 'bold', color: 'black' }}>
              To ask “what day of the week is it”
            </p>
            <p>
              To ask someone what day is it today, you can ask “Welchen
              Wochentag haben wir heute?” or “Welchen Tag haben wir heute”.
            </p>
            <p style={{ fontWeight: 'bold', color: 'black' }}>
              To say “on Monday/Tuesday”
            </p>
            <p>
              How would you say “On Monday, i will go to gym”? The answer is
              simple. For saying on Monday, you use “am” before day. Am is made
              up if two words “an + dem”. An means on and dem is the in dativ
              case.
            </p>
            <p>So it becomes, “Am Montag werde ich ins Fitnessstudio gehen”</p>
            <p>
              More examples, Am Dienstag ist es Valentinstag. (On Tuesday, it is
              Valentine’s Day)
            </p>
            <p style={{ fontWeight: 'bold', color: 'black' }}>
              To say from…to…
            </p>
            <p className="u-margin-bottom-medium">
              Do you know how to say “From Monday to Friday, I go to school”? It
              is very simple. Von is used for “from” and bis is used for
              “to/till”. Von Montag bis Freitag, gehe ich zur Schule. (From
              Monday to Friday, I go to school)
            </p>
            <h2 className="u-margin-bottom-medium">
              Vocabulary Related to Days in German
            </h2>
            <p>
              We will be master of German days of the week when we know complete
              vocabulary as well related to days. You will not be able to make
              fluent sentences in German related to days unless you know this
              basic set of vocabulary.Below are some very useful words which are
              going to be used very often with the German days of the week, I
              have added some examples too, so you won’t find it difficult to
              understand the context. These are some of the basic words, which
              are used almost everyday.
            </p>
            <p className="u-margin-bottom-medium" style={{ textAlign: 'left' }}>
              There are normally six times of the day:
            </p>
            <h3 style={{ fontWeight: 'bold', color: 'black' }}>Morgen </h3>
            <p>
              Morning is der Morgen in German. It is one of the important words
              in German, which is needed in day to day conversations. When we
              are talking about what time of the day it is, we mean morning. Let
              us look at some examples : Ich gehe morgens in die Schule. (I go
              to school in the morning) Ich habe morgens ein Fußballspiel. (I
              have football match in the morning.)
            </p>
            <h3 style={{ fontWeight: 'bold', color: 'black' }}>Vormittag </h3>
            <p>
              Forenoon is der Vormittag in German. This word is not very
              frequently used, but you must know what are all times in the day
              called. Daily we greet each other in the morning, good morning in
              German is “Guten Morgen”. You can wish someone good morning in
              German from 6 am till 11 am. Let us look at its example:
            </p>
            <p>
              Ich habe vormittags einen Freund getroffen. (I met a friend in the
              forenoon.)
            </p>
            <h3 style={{ fontWeight: 'bold', color: 'black' }}>Mittag </h3>
            <p>
              Noon is der Mittag in German. Let us look at an example for noon:
            </p>
            <p>Ich lerne mittags. (I study in the noon.)</p>
          </div>
          <h3
            className="u-margin-bottom-small"
            style={{ fontWeight: 'bold', color: 'black' }}
          >
            Nachmittag{' '}
          </h3>
          <p>
            Afternoon is der Nachmittag in German. Let us look at an example for
            afternoon:
          </p>
          <p>
            Ich spiele nachmittags Fußball. (I play football in the afternoon.)
          </p>
          <h3
            className="u-margin-bottom-small"
            style={{ fontWeight: 'bold', color: 'black' }}
          >
            Abend{' '}
          </h3>
          <p>
            Evening is der Abend in German. Very frequently we greet each other
            with good evening. Good evening in German is “Guten Abend”. You can
            wish someone good evening in German from time 17.00 till 24.00. Let
            us look at its example:
          </p>
          <p>
            Abends mache ich einen Spaziergang. (I take a walk in the evening.)
          </p>
          <h3
            className="u-margin-bottom-small"
            style={{ fontWeight: 'bold', color: 'black' }}
          >
            Nacht{' '}
          </h3>
          <p>
            Night is die Nacht in German. Night is the time to sleep and we all
            love to wish our loved ones good night. You must learn it too. Good
            night in German is “Gute Nacht”. Let us look at its example:
          </p>
          <p>Ich gehe nachts schlafen. (I go to sleep at night.)</p>
          <p className="u-margin-bottom-medium" style={{ textAlign: 'left' }}>
            Other important vocabulary that you must know:
          </p>
          <h3
            className="u-margin-bottom-small"
            style={{ fontWeight: 'bold', color: 'black' }}
          >
            Vorgestern{' '}
          </h3>
          <p>
            Day before yesterday is Vorgestern in German. An important word if
            you want to talk about the past is day before yesterday. Many times
            people know how to say yesterday in German but they do not know how
            to say day before yesterday in German. Let us take a look at
            examples:
          </p>
          <p>
            Vorgestern war mein Geburtstag. (Day before yesterday was my
            Birthday)
          </p>
          <p>Vorgestern war Dienstag. (Day before yesterday was Tuesday)</p>
          <h3
            className="u-margin-bottom-small"
            style={{ fontWeight: 'bold', color: 'black' }}
          >
            Gestern{' '}
          </h3>
          <p>
            Yesterday is Gestern in German. Talking about past is as important
            as talking about present or future. If you want to say yesterday was
            Monday, how would you say it? Let’s take a look at an example:
          </p>
          <p>Gestern war Montag. (Yesterday was Monday.)</p>
          <p>
            Heute ist es besser, als gestern. (Today is better than yesterday)
          </p>
          <h3
            className="u-margin-bottom-small"
            style={{ fontWeight: 'bold', color: 'black' }}
          >
            Heute{' '}
          </h3>
          <p>
            Today is Heute in German. It is undoubtedly one of the most
            important terms that you’ll use with days of the week. Let us look
            at its example:
          </p>
          <p>Heute ist Sonntag. (Today is Sunday.)</p>
          <p>Heute gehe ich nach Deutschland. (Today i am going to Germany.)</p>
          <h3
            className="u-margin-bottom-small"
            style={{ fontWeight: 'bold', color: 'black' }}
          >
            Morgen{' '}
          </h3>
          <p>
            Tomorrow is Morgen in German. As I had stated before, morgen has two
            meaning, one morning and another tomorrow. We have already discussed
            how we use morning. Now for saying tomorrow as well, you will use
            morgen. It will be more clear with the help of examples below:
          </p>
          <p>Morgen ist Donnerstag. (Tomorrow is Thursday.)</p>
          <p>
            Morgen komme ich nicht zum Unterricht. (Tomorrow I am not coming to
            class)
          </p>
          <h3
            className="u-margin-bottom-small"
            style={{ fontWeight: 'bold', color: 'black' }}
          >
            Übermorgen{' '}
          </h3>
          <p>
            Day after tomorrow is Übermorgen in German. This is also one word
            people often forget to remember. But if are using such words in your
            conversations, it can really create an impact. Just like for day
            before yesterday, people know how to say tomorrow, but they do not
            know how to say day after tomorrow. Let use understand with the help
            of examples:
          </p>
          <p>Übermorgen ist Montag. (Day after tomorrow is monday)</p>
          <p>Übermorgen ist meine Prüfung. (Day after tomorrow is my exam.)</p>
          <h3
            className="u-margin-bottom-small"
            style={{ fontWeight: 'bold', color: 'black' }}
          >
            Tag{' '}
          </h3>
          <p>
            The day is der Tag in German. This is the word which you would
            probably know as it’s used everywhere, any paragraph you read is
            incomplete without using this word. Tag is always masculine. And the
            days name are also masculine, so their article is “der”. Some
            examples for the same:
          </p>
          <p>Welcher Tag ist heute? (What day is it today?)</p>
          <p>Wie war dein Tag? (How was your day?)</p>
          <p>Ein Tag hat 24 Stunden. (A day has 24 hours.)</p>
          <h3
            className="u-margin-bottom-small"
            style={{ fontWeight: 'bold', color: 'black' }}
          >
            Woche{' '}
          </h3>
          <p>
            The week is die Woche in German. For learning days of the week, you
            must the how to say day and week in German. Woche is a feminine
            word, thus it takes the article as “die”. Let’s look at some
            examples now
          </p>
          <p>Eine Woche hat sieben Tage. (A week has seven days)</p>
          <p>Ich habe diese Woche eine Prüfung. (I have an exam this week)</p>
          <p>Sieben Tage sind eine Woche. (Seven days is one week.)</p>
          <p>Vier Wochen sind ein Monat. (Four weeks is one month.)</p>
          <img
            src="../assets/german-days-important.png"
            alt="german-days-important"
            className="u-margin-bottom-medium"
            style={{ width: '100%' }}
          />
          <h2 style={{ lineHeight: '25px' }} className="u-margin-bottom-medium">
            Finally mastered Days of the Week in German...
          </h2>
          <p>
            Now you know German days of the week. You also went through all the
            grammar topics related to days and how to be part of any
            conversation involving days in German. Now make sure you go through
            this at least once daily in the initial phase of learning German
            language, since these small topics form the base for further
            learning process. Try making small sentences related to this topic.
          </p>
          <p>
            Let me give you a task for the day. Make any five sentences
            involving days in German and you can write to me at
            info@linguapsych.com
          </p>
          <div className="article__blog-container__bottom">
            <div
              className="article__blog-container__bottom__left"
              to="/learn-german"
            >
              <Link to="/learn-german">
                <img
                  className="article__blog-container__bottom__image"
                  src={German}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/german-alphabet-letters/">
                <li className="article__blog-container__bottom__posts__title__item">
                  German Alphabets & Letters
                </li>
              </Link>
              <Link to="/german-numbers/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Numbers in German
                </li>
              </Link>

              <Link to="/german-months-seasons/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Months & Seasons in German
                </li>
              </Link>
              <Link to="/german-umlaut/">
                <li className="article__blog-container__bottom__posts__title__item">
                  German Umlaut
                </li>
              </Link>
              <Link to="/time-in-german/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Time in German
                </li>
              </Link>
              <Link to="/german-colors/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Colors in German
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default germanDays;
