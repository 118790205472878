import React from 'react';
import { Link } from 'react-router-dom';

import '../sass/main.scss';
import MetaTags from 'react-meta-tags';
import Pronouns from '../assets/Direct-object-Pronouns-Spanish.jpeg';
import Spanish from '../assets/spanish_course_bottom.png';

const directObjectPronouns = () => {
  const head = () => (
    <MetaTags>
      <title>
        Easy guide to Spanish Direct and Indirect Object pronouns | LinguaPsych
      </title>
      <meta
        name="description"
        content="Spanish Direct and Indirect object pronouns often create confusion for beginners, this lesson is created after taking all the beginners problem into consideration."
      />{' '}
      <link
        rel="canonical"
        href="https://linguapsych.com/direct-indirect-object-pronouns/"
      />
      <meta
        property="og:title"
        content="Spanish Direct and Indirect object pronouns often create confusion for beginners, this lesson is created after taking all the beginners problem into consideration."
      />{' '}
      <meta
        property="og:description"
        content="Spanish Direct and Indirect object pronouns often create confusion for beginners, this lesson is created after taking all the beginners problem into consideration."
      />{' '}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        href="https://linguapsych.com/direct-indirect-object-pronouns/"
      />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/Direct-object-Pronouns-Spanish.jpeg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/Direct-object-Pronouns-Spanish.jpeg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}

      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_SPANISH_COURSE}`}>
          <div className="article__spanish-banner">
            <div className="article__spanish-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Pronouns}
            alt="Direct-object-Pronouns-Spanish"
            className="article__featured-image"
          />
          <div className="article__header-elements">
            <h1 className="article__title ">
              {' '}
              <span
                style={{ fontSize: '5rem' }}
                className="article__highlight--black "
              >
                {' '}
                Spanish Direct and Indirect Object Pronouns
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <p>
              Everyone says Spanish is the easiest language to learn for an
              English speaker. Although, I can’t completely deny the fact but
              every language has it’s easy and difficult parts. When it comes to
              Spanish, direct and Indirect object pronoun is the topic where
              every beginner struggles. This lesson is very important and I’m
              gonna try my best to explain every single detail you need to know.
              Let’s start fellas
            </p>
            <h2 className="u-margin-bottom-medium">
              What is an Object Pronoun?
            </h2>
            <p>
              Just like Subject Pronouns, object Pronouns replace the same noun
              in latter sentences when they were already stated or were known.
              Object Pronoun replaces the noun which takes a direct or an
              indirect action of the verb. We’ll see further in the lesson.
              Let’s see how Subject Pronouns become Obejct Pronouns in English:
            </p>
            <ul>
              <li>I becomes me</li>
              <li>You becomes you (Singular and Plural)</li>
              <li>He becomes him</li>
              <li>She becomes her</li>
              <li>It stays it</li>
              <li>We becomes us</li>
              <li>They becomes us</li>
            </ul>
            <p>
              If you understand how the object pronouns work in English it’ll be
              very easy for you to understand how it works in Spanish. To make
              it easy, here is a little tip, whenever you want to find the
              object in the sentence, look at the sentence carefully, focus on a
              noun or a pronoun which is taking the action, it will always be
              the object. If it’s not a noun it will be an Object Pronoun. Let’s
              look at some sentences to figure out the Object:
            </p>
            <h2 className="u-margin-bottom-medium">Object Pronoun Examples</h2>
            <p>
              In the sentences below, subject is bold, object is bold and
              underlined.
            </p>
            <ul>
              <li>
                <span style={{ fontWeight: 'bold' }}> John </span> called{' '}
                <span
                  style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                >
                  her{' '}
                </span>
                yesterday.
              </li>
              <li>
                {' '}
                <span style={{ fontWeight: 'bold' }}> Marcus </span> hit{' '}
                <span
                  style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                >
                  him{' '}
                </span>{' '}
                on head.
              </li>
              <li>
                {' '}
                Can <span style={{ fontWeight: 'bold' }}> you </span> tell{' '}
                <span
                  style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                >
                  them{' '}
                </span>{' '}
                about the party?
              </li>
              <li>
                {' '}
                <span style={{ fontWeight: 'bold' }}> Teacher</span> gave{' '}
                <span
                  style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                >
                  {' '}
                  us
                </span>{' '}
                a present.
              </li>
              <li>
                My <span style={{ fontWeight: 'bold' }}>father</span> asked{' '}
                <span
                  style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                >
                  me
                </span>{' '}
                a question.
              </li>
            </ul>
            <p>
              In a sentence, we have a subject who is the doer, a verb which
              describes an action and there is an object which takes the action
              directly or indirectly.
            </p>
            <ul>
              <li>
                A direct object is directly affected by the action of the verb.
              </li>
              <li>
                An Indirect object is indirectly affected by the action of the
                verb.
              </li>
              <li>
                Direct object Pronouns replace the direct object of the
                sentence.
              </li>
              <li>
                Indirect object pronouns replace the indirect object of the
                sentence.
              </li>
            </ul>
            <p>
              A Subject cannot describe if the action that is taking place is
              directly affecting the object or indirectly, so it has to be the
              verb which can actually give clarity on the same. There are some
              verbs which will always directly affect an object. Here are few
              verbs:
            </p>
            <ul>
              <li>To eat</li>
              <li>To drink</li>
              <li>To smell</li>
              <li>To smoke</li>
            </ul>
            <p>
              They were just few of too many verbs which affect an object
              directly. I hope they were enough to tell you the context of it.
            </p>
            <p>
              Also, there are verbs which can be really be confusing as they can
              directly or indirectly affect the object. These are the verbs
              which can be followed by the Direct Object Pronouns and Indirect
              Object Pronouns which we will study further. Let’s look at the
              verbs first:
            </p>
            <ul>
              <li>To teach ( You can teach a lesson or teach students)</li>
              <li>To write ( You can write a letter or write your parents)</li>
              <li>
                To understand ( You can understand the situation or understand
                your friends)
              </li>
              <li>To leave ( Leave home or leave your girlfriend)</li>
            </ul>
            <p>
              I hope it gave you guys some kind of hint on how verbs can give
              you hints on identifying the object Pronouns. Now that we
              understood the concept behind Direct and Indirect Object Pronouns,
              let’s talk about their positioning.
            </p>
            <h2 className="u-margin-bottom-medium">
              Where to use Direct Object Pronouns in Spanish?
            </h2>
            <p>
              In English, object pronouns come just after the verb but in
              Spanish, there is a rule to follow. Let’s look at the placement:
            </p>
            <ol>
              <li>
                Before the verb when the verb is in it’s conjugated form. Let’s
                see here:
              </li>
              <p>John eats food, he loved it.</p>
              <p>
                Here, food is the direct object, once food is mentioned there is
                no need to repeat it. Hence,” it” as an object pronoun took it’s
                position after the verb “loved“.
              </p>
              <p>Now let’s look at it’s Spanish version:</p>
              <p>Juan come la comida, la encantó</p>
              <p>
                Here, comida is the direct object, once comida is mentioned
                there is no need to repeat it. Hence, “la” as an object pronoun
                for comida took it’s position before the noun because the verb
                is conjugated.
              </p>
              <p>
                Note: Always use the Spanish Object Pronouns before the verb
                when the verb is in the conjugated form.
              </p>
              <li>
                Use it after the verb when the verb is in it’s infinitive form
                (non-conjugated), gerunds form or it’s imperative form. Let’s
                look at some examples:
              </li>
              <h3>Infinitive form</h3>
              <p>Spanish: Me encanta el chocolate, me voy a comerlo.</p>
              <p>English: I love chocolates, I’m going to eat it.</p>
              <p>
                Here the verb comer was not conjugated, hence we used the direct
                object pronoun “lo” after the verb.
              </p>
              <h3>Gerunds form</h3>

              <p>Spanish: Estoy comiendola.</p>
              <p>English: I’m eating it.</p>
              <p>
                Here the verb comer is in it’s gerunds form comiendo, hence, the
                direct object pronoun is used after the verb.
              </p>

              <h3>Imperative form</h3>

              <p>Spanish: Hazlo.</p>
              <p>English: Do it.</p>
              <p>
                Here the verb comer is in it’s gerunds form comiendo, hence, the
                direct object pronoun is used after the verb.
              </p>
            </ol>
            <p>
              A complete sentence has a subject, an object and a verb, objects
              can be Direct or Indirect. Direct object usually answers what? or
              whom? Let’s look at some examples:
            </p>

            <p>Question: What are you eating?</p>
            <p>Answer: I’m eating an ice cream</p>
            <p>Here, what is answered by the direct object ice cream.</p>
            <p>Question: This is for whom?</p>
            <p>Answer: This is for John.</p>
            <p>Here, whom is answered by the direct object John.</p>

            <h2 className="u-margin-bottom-medium">
              When to use Indirect Object Pronouns in Spanish?
            </h2>

            <p>
              Talking about the placement of Indirect Object Pronouns, there is
              no different than what we discussed above. If a verb is in it’s
              conjugated form, use it before the verb. In all the other cases
              use it afterwards.
            </p>
            <p>
              If we want to use an Indirect object Pronoun, we’ll need a verb
              which can take the pronoun of Indirect Object. We discussed some
              verbs in English, now let’s look at some verbs in Spanish with
              examples:
            </p>
            <p>English: Will you give him the keys?</p>
            <p>Spanish: ¿Le das las llaves?</p>
            <p>
              You can see that the Indirect object above is keys, direct object
              is keys as they are directly getting affected by the action “to
              give”.
            </p>
            <p>English: Will you tell her the secret?</p>
            <p>Spanish: ¿Le dices el secreto?</p>
            <p>
              Here “her” is the indirect object because she will be indirectly
              affected by you telling her the secret.
            </p>
            <p>Last one:</p>
            <p>English: I will sell him the chocolates.</p>
            <p>Spanish: Le venderé los chocolates a el.</p>
            <p>
              Here Chocolates are direct object and “him” is the indirect object
              pronoun as it is indirectly getting affected by me selling him the
              chocolates.
            </p>
            <p>
              In the above example, if you noticed, with the indirect object
              pronoun “le” I also used a el. This was just to emphasize that the
              receiver was a male and could have been omitted as well. But the
              Indirect object pronoun “le” cannot be omitted here as it will not
              sound natural at all.
            </p>
            <p>
              You can use “a el”, “a ella”, “a ellos” and “a ellas” if it is
              important to put focus on the gender of the Indirect object,
              otherwise, using le and les will do the trick.
            </p>
            <h2 className="u-margin-bottom-medium">
              Using direct and indirect object pronouns Spanish together?
            </h2>
            <p>
              Now comes the part I love the most. This little guy creates the
              most confusion. But why am I here for then?
            </p>
            <p>
              We generally use D.O.P and I.O.P together when the Object noun is
              already mentioned previously. This can usually happen when you are
              answering to a question or narrating a story when you have already
              mentioned the object pronouns. Let’s look at the examples for both
              occasions:
            </p>
            <h3>Answering to a question</h3>
            <p>English: Will you give me the pen?</p>
            <p>Spanish: ¿Me das la pluma?</p>
            <p>English: Yes, I’ll give it to you.</p>
            <p>Spanish: Sí, te la doy.</p>
            <p>
              In the example above, la is the direct object pronoun and te is
              the indirect object pronoun.
            </p>
            <h3>
              Replacing the object nouns with direct and indirect object
              pronouns when they are already mentioned
            </h3>
            <p>English: I told John my secret. Yes, I told him that.</p>
            <p>Spanish: Le dije a John el secreto. Sí, le lo dije.</p>
            <p>
              In the above sentence, we know that “el secreto” was the direct
              object and “le” was an indirect object. As both of them were
              already mentioned previously, there was no need to repeat them
              again.
            </p>
            <p>
              Now, if you have to use a direct object pronoun and an indirect
              object pronoun together, how are you going to do it? Let’s see
              what happens when they get combined.
            </p>
            <p>Let’s take an example:</p>
            <p>English: Will you give it to me?</p>
            <p>Spanish: ¿Me lo das?</p>
            <p>English: Will you give it to him?</p>
            <p>Spanish: ¿le lo das?</p>
            <p>English: Will you give to us?</p>
            <p>Spanish: ¿Nos lo das?</p>
            <p>English: Will you give it to them?</p>
            <p>Spanish: ¿Les lo das?</p>
            <p>
              Now you must be wondering, how come le lo and les lo are not
              correct this is what we studied right? In Spanish, we have
              exception where whenever the direct object pronouns and indirect
              object pronouns are used together. The ones below will change to
              the ones in their right. Let’s take a look:
            </p>
            <div style={{ textAlign: 'center' }}>
              <p>Le lo → se lo</p>
              <p>Le la → se la</p>
              <p>Le los → se los</p>
              <p>Le las → se las</p>
              <p>Les lo → se lo</p>
              <p>Les la → se la</p>
              <p>Les los → se los</p>
              <p>Les las → se las</p>
            </div>
            <p>
              It seems to be too much though, but don’t worry, let’s tackle them
              one by one with simple examples. As described earlier, the
              position of the direct and indirect object pronouns will be
            </p>
            <ul>
              <li>Before the verb when the verb is in it’s conjugated form.</li>
              <li>
                After the verb when the verb is in it’s infinitive form
                (non-conjugated), imperative form and gerunds form.
              </li>
            </ul>
            <p>
              Let’s take the examples, we’ll keep the same verb dar, and will
              change the pronouns:
            </p>
            <p>
              English: Will you give it to him/her? (him/her take the same
              indirect object pronoun”le”)
            </p>
            <p>Spanish: ¿Le lo das? (Incorrect)</p>
            <p>Spanish: ¿Se lo das? (Correct)</p>
            <p>English:Will you give her the apple?</p>
            <p>Spanish: ¿ le das la manzana a ella?</p>
            <p>Answer: sí, le la doy</p>
            <p>Answer: sí, se la doy (Correct)</p>
            <p>English:Will you give her the apples?</p>
            <p>Spanish: ¿ le das las manzanas a ella?</p>

            <p>Answer: sí, le las doy</p>
            <p>Answer: sí, se las doy (Correct)</p>
            <p>English:Will you give them the apple?</p>
            <p>Spanish: ¿ les das la manzana a ellos?</p>
            <p>Answer: sí, les la doy</p>
            <p>Answer: sí, se la doy (Correct)</p>
            <p>English:Will you give them the apples?</p>
            <p>Spanish: ¿ les das las manzanas a ellos?</p>
            <p>Answer: sí, les las doy</p>
            <p>Answer: sí, se las doy (Correct)</p>

            <p>
              In the examples above we also saw that after using the indirect
              object pronoun “le” we also used “a el/ella/ellas/ellos” at the
              end of the sentence. Well, the position is not fixed and can be
              used at the beginning of the sentence.Let’s see her:
            </p>
            <p>English: Will you give her the apple?</p>
            <p>Spanish: ¿le das la manzana a ella?</p>
            <p>Also, ¿ a ella le das la manzana?</p>
            <p>
              Position of a ella is correct both the ways. Let me tell you,
              there is not hard and fast rule that you must use the Object
              Pronouns instead of the Object of the sentence, you have complete
              control over it. So, use it whenever you feel like doing it.
            </p>
            <h3> DOP VS IOP “LO”</h3>
            <p>
              The direct object pronoun “lo” is used more than often in Spanish.
              It is usually used to refer to a point which is already mentioned
              before or any piece of information which has already been given.
              Some uses of “lo” below:
            </p>
            <p>Every time you want to say I don’t know</p>
            <p>Spanish: No lo sé (It’s a fixed structure)</p>
            <p>English: They didn’t tell us that</p>
            <p>Spanish: No nos lo dijieron.</p>
            <h3>What did we learn?</h3>
            <p>
              It was an important lesson as a lot of people face trouble in DOP
              and IOP. We learnt what is object pronoun and it’s functions. We
              studied the difference between DOP and IOP and it’s usage in
              Spanish. We studied when to position the pronouns before or after
              the verbs.
            </p>
            <p>
              We studied the exception where le lo will change to se lo and many
              more. I’m sure once you start practicing it, you will fall in love
              with this. This is a grammar structure which differentiates an
              amateur and an advanced speaker. I hope I could help you today.
              Please feel free to request for more lessons.
            </p>
            <p>Also check our exclusive guide on:</p>
            <a
              style={{ display: 'block' }}
              href="/direct-indirect-object-pronouns/"
            >
              {' '}
              Basics of Spanish Nouns and Articles
            </a>
            <a style={{ display: 'block' }} href="spanish-plural-nouns/">
              {' '}
              How to make Nouns Plural in Spanish
            </a>
            <a
              href="/spanish-definite-indefinite-articles/"
              style={{ display: 'block' }}
            >
              Complete guide on Definite and Indefinite Articles
            </a>
            <a
              href="/subject-personal-pronouns-in-spanish/"
              style={{ display: 'block' }}
            >
              Easy Guide On Subject Pronouns In Spanish
            </a>
          </div>
          <div className="article__blog-container__bottom">
            <div
              className="article__blog-container__bottom__left"
              to="/learn-spanish"
            >
              <Link to="/learn-spanish">
                <img
                  className="article__blog-container__bottom__image"
                  src={Spanish}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/spanish-definite-indefinte-articles/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Definite & Indefinite Articles
                </li>
              </Link>
              <Link to="/subject-personal-pronouns-in-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Subject Personal Pronouns
                </li>
              </Link>

              <Link to="/spanish-nouns-articles-genders/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Nouns, articles and Genders
                </li>
              </Link>
              <Link to="/spanish-alphabets-accents-pronunciation/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Alphabets in Spanish
                </li>
              </Link>
              <Link to="/possessive-adjectives-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Possessive Adjectives
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default directObjectPronouns;
