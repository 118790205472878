import React, { useState } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import Backdrop from "./Backdrop";
import { subscribe } from "../../actions/user";
import "../../sass/main.scss";
import Gift from "../../assets/gift.png";
import Arrow from "../../assets/arrow.png";
import Wave from "../../assets/wave.png";
import Loader from "../Loader";

const ModalOverlay = (props) => {
  const [thanks, setThanks] = useState(false);
  const [values, setValues] = useState({
    email: "",
    username: "",
    language: "",
    error: "",
    loading: false,
  });

  const { email, username, language, error, loading } = values;

  const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const handleSubscribe = (e) => {
    e.preventDefault();
    if (email.match(mailformat) && username && language ) {
      subscribe({ username, email, language }).then((data) => {
        setValues({
          ...values,
          loading: true,
        });
        if (data.error) {
          setValues({
            ...values,
            error: "Pleae enter valid information",
          });
        } else {
          setValues({
            ...values,
            loading: false,
          });
          setThanks(true);
        }
      });
    } else {
      setValues({
        ...values,
        error: "Pleae enter valid information",
      });
    }
  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, error: false, [name]: e.target.value });
  };

  const content = (
    <div className="subscribe">
      <div onClick={props.onCancel} className="subscribe-cancel">
        <i className="fas fa-times"></i>
     
      </div>
      {thanks && !loading ? (
        <div className="subscribe-thanks">
          <img className="subscribe-thanks__image" src={Wave} alt="wave" />
          <div className="subscribe-thanks__thanks"> Thank you! </div>
          <div className="subscribe-thanks__para">
            {" "}
            Thank you for signing up! We'll get back to you shortly!{" "}
          </div>

          <div className="subscribe-form__bottom">
            <div className="subscribe-form__bottom__login">
              You can also follow us on
            </div>
            <div className="subscribe-form__bottom__icons">
              <div className="subscribe-form__bottom__icons__button">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/linguapsych_official/"
                >
                  <i className="fab fa-instagram icon"></i>
                </a>
                <div className="fill"></div>
              </div>
              <div className="subscribe-form__bottom__icons__button">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/linguapsychofficial"
                >
                  <i className="fab fa-facebook-square icon "></i>
                </a>
                <div className="fill"></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="subscribe-body">
          {loading ? (
            <div>
              <Loader />{" "}
            </div>
          ) : (
            <div>
              {" "}
              <div className="subscribe-body__top">
                <div className="subscribe-body__left">
                  <img
                    className="subscribe-body__left__img"
                    alt="gift"
                    src={Gift}
                  />
                </div>

                <div className="subscribe-body__right">
                  <div className="subscribe-body__right__top">
                    Win the best online Spanish/German course!
                  </div>
                  <div className="subscribe-body__right__para-1">
                    Enter your email to win our mega courses perfect for you to
                    start your language learning with ease.
                  </div>

                  <div className="subscribe-body__right__para-2">
                    All you need to do is drop your email here and follow us on
                    social media. And don't forget to share with your friends!
                  </div>
                  <div className="subscribe-body__right__bottom">
                    <div className="subscribe-body__right__bottom__para-3">
                      Winners will be announced shortly...
                    </div>

                    {/* <div className="subscribe-body__right__bottom__img"> */}

                    <img
                      className="subscribe-body__right__bottom__img"
                      alt="arrow"
                      src={Arrow}
                    />

                    {/* </div> */}
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubscribe} className="subscribe-form">
                <div className="subscribe-form__options">
                  <select
                    onChange={handleChange("language")}
                    value={language}
                    className="subscribe-form__select"
                    name="language"
                  >
                    <option hidden>Language</option>
                    <option value="Spanish">Spanish</option>
                    <option value="German">German</option>
                  </select>
                  <input
                    onChange={handleChange("username")}
                    value={username}
                    className="subscribe-form__input"
                    placeholder="Your name"
                  />
                  <input
                    onChange={handleChange("email")}
                    value={email}
                    className="subscribe-form__input"
                    placeholder="Your email"
                  />
                </div>
                <div style={{color: "red", fontSize: "18px", marginTop: "10px"}}  >
                {error}
                </div>
               
                <button
                  onSubmit={handleSubscribe}
                  className="subscribe-form__button"
                >
                  I want the course
                </button>
              </form>
            </div>
          )}
        </div>
      )}
    </div>
  );

  return ReactDOM.createPortal(content, document.getElementById("modal"));
};

const Modal = (props) => {
  return (
    <React.Fragment>
      {props.show && <Backdrop onClick={props.onCancel} />}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={500}
        classNames="modal"
      >
        <ModalOverlay {...props} />
      </CSSTransition>
    </React.Fragment>
  );
};

export default Modal;
