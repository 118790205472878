import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { getCookie } from '../actions/auth';
import cookie from 'js-cookie';

import Modal from '../components/modal/Modal';
import '../sass/main.scss';
import logo from '../assets/logo.png';

//  LOGO COLOR #FFCC00 - yellow
// LOGO COLOR #EB4D55 - red

const Header = () => {
  const [showBlogs, setShowBlogs] = useState(false);
  const [showCourses, setShowCourses] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const showPromotion = () => {
    
      const value = getCookie('offer');
      if (!value) {
        setTimeout(() => openModal(), 30000);
        if (process.browser) {
          cookie.set('offer', true);
        }
      }
   
  };

  useEffect(() => {
    !landing.includes(window.location.pathname) && showPromotion();
  },[] )

  const landing = [
    "/spanish-workshop", "/webinar-thankyou", "/learn-german", "/learn-spanish"
  ]

  window.onscroll = function () {
    myFunction();
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowBlogs(false);
          setShowCourses(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  /**
   * Component that alerts if you click outside of it
   */
  function OutsideAlerter(props) {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return <div ref={wrapperRef}>{props.children}</div>;
  }

  OutsideAlerter.propTypes = {
    children: PropTypes.element.isRequired,
  };

  function myFunction() {
    
    if(landing.includes(window.location.pathname)) {
        return null
    } else {
      var winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    var height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;
    document.getElementById('myBar').style.width = scrolled + '%';
    }
  }

    const showHeader = () => {
      if  (landing.includes(window.location.pathname)) {
        console.log(window.location.pathname)
        return  null
      } else {
       return (
        <OutsideAlerter>
        <Modal
         show={showModal}
         onCancel={() => closeModal()}
         contentClass="place-item__modal-content"
         footerClass="place-item__modal-actions"
       />
       <div
         onMouseLeave={() => {
           setShowCourses(false);
           setShowBlogs(false);
         }}
         className="nav-container"
       >
         <header className="nav-header">
           <Link to="/">
             <img src={logo} alt="Linguafy logo" className="nav-logo" />
           </Link>
    
           <nav className="user-nav">
             <Link to="/" className="user-nav__icon-box">
               Home
             </Link>
             <div
               onMouseEnter={() => {
                 setShowBlogs(true);
                 setShowCourses(false);
               }}
               onClick={() => {
                 setShowBlogs(!showBlogs);
                 setShowCourses(false);
               }}
               className="user-nav__icon-box"
             >
               Blogs
               {showBlogs && (
                 <div className="user-nav__icon-box__content">
                   <Link to="/spanish" className="user-nav__icon-box">
                     Spanish
                   </Link>
                   <Link to="/german" className="user-nav__icon-box">
                     German
                   </Link>
                   <Link to="/japanese" className="user-nav__icon-box">
                     Japanese
                   </Link>
                   <Link to="/korean" className="user-nav__icon-box">
                     Korean
                   </Link>
                 </div>
               )}
             </div>
             <Link
               onMouseEnter={() => {
                 setShowBlogs(false);
                 setShowCourses(true);
               }}
               onClick={() => {
                 setShowBlogs(false);
                 setShowCourses(!showCourses);
               }}
               to="/"
               className="user-nav__icon-box"
             >
               Courses
               {showCourses && (
                 <div className="user-nav__icon-box__content-course">
                   <Link to="/learn-spanish" className="user-nav__icon-box">
                     Spanish
                   </Link>
                   <Link to="/learn-german" className="user-nav__icon-box">
                     German
                   </Link>
                 </div>
               )}
             </Link>
           </nav>
         </header>
         <div className="nav-header__progress">
           <div className="nav-header__progress-container">
             <div className="nav-header__progress-bar" id="myBar"></div>
           </div>
         </div>
       </div>
     </OutsideAlerter>
       )
      }
    }

  return (
 <>
{showHeader()}
</>
  );
};

export default withRouter(Header);
