import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

import Conditional from '../assets/Conditional tense spanish.jpg';
import Table1 from '../assets/conditional-table-1.PNG';
import Table2 from '../assets/conditional-table-2.PNG';
import Spanish1 from '../assets/Conditional tense spanish1.jpg';
import Situation from '../assets/Conditional Situation in Spanish.jpg';
import Spanish from '../assets/spanish_course_bottom.png';

import '../sass/main.scss';

const conditional = () => {
  const head = () => (
    <MetaTags>
      <title>
        Complete Guide To Conditional Tense In Spanish With Examples |
        LinguaPsych
      </title>
      <meta
        name="description"
        content="This is a complete guide to Conditional Tense in Spanish and includes how to form conditionals and where to use them with a lot of useful examples. Easy guide"
      />{' '}
      <link
        rel="canonical"
        href="https://linguapsych.com/conditional-tense-spanish/"
      />
      <meta
        property="og:title"
        content="This is a complete guide to Conditional Tense in Spanish and includes how to form conditionals and where to use them with a lot of useful examples. Easy guide"
      />{' '}
      <meta
        property="og:description"
        content="This is a complete guide to Conditional Tense in Spanish and includes how to form conditionals and where to use them with a lot of useful examples. Easy guide"
      />{' '}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        href="https://linguapsych.com/conditional-tense-spanish/"
      />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/Conditional Tense Spanish.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/Conditional Tense Spanish.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}

      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_SPANISH_COURSE}`}>
          <div className="article__spanish-banner">
            <div className="article__spanish-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Conditional}
            alt="Conditional Tense Spanish"
            className="article__featured-image"
          />

          <div className="article__header-elements">
            <h1 className="article__title ">
              {' '}
              <span className="article__highlight--black ">
                {' '}
                Conditional Tense in Spanish
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <p>
              When you’re learning a new language, there are certain levels,
              from giving your self introduction till talking about global
              warming, you develop skills with time. Conditional tense can be
              considered as Intermediate Spanish. I’m saying it because whenever
              you use conditionals while talking, you aren’t a beginner anymore,
              the person you’re talking to will straight away assume that they
              can talk to you about anything. Let’s talk about the Conditional
              Tense:
            </p>
            <p>
              We just talked about how this Spanish tense is considered as an
              Intermediate level grammar, one good thing about Conditionals are
              that they are comparatively fairly easy to conjugate than other
              tenses in Spanish. In English, there are many ways where you
              present conditions, but in Spanish, once you learn them, you can
              straightaway start using them without confusion.
            </p>
            <p>So Let’s begin with how to form Conditional Tenses in Spanish</p>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Spanish1}
                alt="Conditional tense in Spanish"
                className="u-margin-bottom-medium"
                style={{ width: '90%', textAlign: 'center' }}
              />
            </div>
            <p className="u-margin-bottom-medium">
              We just discussed how Conditional tense is easy to make in
              Spanish, so what do we consider as easy? This could be anything
              which follows the same pattern throughout and have minimum of
              exceptions. Well, Conditional is just that, with very few
              exceptions and the same pattern which is followed throughout,
              let’s start off with the Regular Verbs
            </p>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table1}
                alt="conditional-table-1"
                className="u-margin-bottom-medium"
                style={{ width: '70%' }}
              />
            </div>
            <p>
              What we’re doing above is adding the endings to an infinitive verb
              to make it conditional. For example if the verb is comer which
              means to eat, you will have to add the endings like shown in the
              table above, let’s see how
            </p>
            <ul style={{ marginLeft: '60px' }}>
              <li>yo – comería</li>
              <li>tú – comerías</li>
              <li>el/ella/usted – comería</li>
              <li>nosotros – comeríamos</li>
              <li>vosotros – comeríais</li>
              <li>ellos/ellas/ustedes – comerían</li>
            </ul>
            <p>
              {' '}
              There are very few irregular verbs in Spanish conditionals which
              makes it even easier to remember, if you remember the irregular
              verbs in Spanish future tense, you’ll find it very easy to
              remember them as the roots stay the same in both Spanish future
              tense and Conditional tense. If you haven’t studied the Spanish
              Future tense, you can follow the link.
            </p>

            <p>
              Talking about the Irregular verbs, like I mentioned there are very
              few irregular verbs in conditional form which is similar to the
              Simple future tense in Spanish. Let’s take a look at them. Please
              focus on the roots as they will take the conditional endings after
              them.
            </p>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table2}
                alt="conditional-table-2"
                className="u-margin-bottom-medium"
                style={{ width: '90%', textAlign: 'center' }}
              />
            </div>
            <h2 className="u-margin-bottom-medium">
              When to use Conditional Tense in Spanish?
            </h2>
            <p>
              Learning how to conjugate conditional is done now comes the part
              which is even more essential, here we will learn all the
              situations where we must use the conditional tense in Spanish.
              Almost every case is relatable to English but some are really
              different so I will ask you guys to not confuse these with the
              English cases.
            </p>
            <ul style={{ marginLeft: '60px' }}>
              <li>
                {' '}
                <h3>To express desires</h3>{' '}
              </li>
              <p>
                Conditional tense in Spanish is used to express the desires
                which are difficult to execute or even impossible to do. In
                English, this can be translated to “I would like to do
                something” which portrays uncertainty which “I will do
                something” in English doesn’t portray.
              </p>
              <p>
                Please take into consideration that in English you have to add
                “I would” before any verb to make it appear like a desire, for
                example I would like to eat, I would like to sleep. In Spanish
                you just add the suffix that we mentioned after the end of the
                verb in regular verbs and add the suffix to the root in
                Irregular verbs. Let’s take a look at some examples
              </p>
              <p>me gustaría visitar japón</p>
              <p style={{ marginTop: '-25px' }}>I would like to visit Japan</p>
              <p>
                Qué sueño! me iría a dormir ahora mismo. (pero estoy en la
                oficina y todavía no he terminado de trabajar)
              </p>
              <p style={{ marginTop: '-25px' }}>
                What a dream! I would go to sleep right now. ( but I’m in the
                office and haven’t finished my work yet)
              </p>
              <li>
                {' '}
                <h3> To Advice/ Suggest</h3>
              </li>
              <p>
                We can use conditionals to give an advice or a suggestion,
                although, it’s not the only way to give an advice but still it’s
                very common to use conditional while doing so. Let’s take a look
                at some ways where we can use other forms as well as
                conditionals to give a suggestion or an advice.
              </p>
              <h3>Deberías/Podrías + Infinitive</h3>
              <p>
                This can be translated to “you should/could” in English, let’s
                look at some examples
              </p>
              <p>
                Hoy es vuestro aniversario de boda. ¿no? pues deberías comprarle
                un un regalo a tu mujer
              </p>
              <p style={{ marginTop: '-25px' }}>
                Today’s your marriage anniversary right? Well, you can buy her a
                gift
              </p>
              <p>podrías regalarle un viaje</p>
              <p style={{ marginTop: '-25px' }}>You could gift her a trip</p>
              <li>
                <h3>¿ Por qué No + present indicative?</h3>
              </li>
              <p>
                This is not conditional but another useful form for giving
                suggestions or advice. This can be translated to “Why don’t you”
                in English. Let’s look at some examples
              </p>
              <p>¿Por qué no duermes si estás cansado?</p>
              <p style={{ marginTop: '-25px' }}>
                Why don’t you sleep if you’re tired?
              </p>
              <p>¿Por qué no estudias y apruebas el examen?</p>
              <p style={{ marginTop: '-25px' }}>
                why don’t you study and pass the exam?
              </p>
              <li>
                <h3>
                  Yo en tu lugar/ Si estuviera en tu lagar/ Yo que tú/ Yo +
                  Conditional
                </h3>
              </li>
              <p>
                These are the structures that are always used with the
                conditionals, let’s see the meaning first and then procees with
                the examples
              </p>
              <p>Yo en tu lugar = me in your place</p>
              <p style={{ marginTop: '-25px' }}>
                Si estuviera en tu lugar = If I were in your place
              </p>
              <p style={{ marginTop: '-25px' }}>Yo que tú = If I were you</p>
              <p>
                If take the English translations of the same words, you’ll
                notice that these structures are always followed by “would”
                which again is a conditional. In Spanish as well, these
                structures will be followed by conditionals, let’s look at the
                examples
              </p>
              <p>yo en tu lugar, le regalaría un libro a ella.</p>
              <p style={{ marginTop: '-25px' }}>
                Me in your place would give her a book.
              </p>
              <p>Si yo estuviera en tu lugar, haría exactamente lo mismo</p>
              <p style={{ marginTop: '-25px' }}>
                If I were in your place, I would do exactly the same
              </p>
              <p>yo que tú, hablaría con ella. se lo debes</p>
              <p style={{ marginTop: '-25px' }}>
                If I were you, I would talk to her. you owe it to him
              </p>
              <li>
                <h3>
                  Te/Le/Os/Les Recomiendo/ Sugerio/ Aconsejo que + Present
                  Subjunctive
                </h3>
              </li>
              <p>
                This is the most common way to give an advice or a suggestion,
                this can be translated to “I advice/suggest/recommend
                you/him/her/you guys to eat/drink/etc.” But after the structure,
                unlike in English, subjunctive is used, so its very important to
                learn subjunctives before using this structure. Let’s look at
                some examples now
              </p>
              <p>Te recomiendo que pruebes el cordero asado.</p>
              <p style={{ marginTop: '-25px' }}>
                I recommend you try the roast lamb.
              </p>
              <p>
                le sugiero que tome unas cuantas clases de conducción antes del
                examen
              </p>
              <p style={{ marginTop: '-25px' }}>
                I suggest you take a few driving lessons before the test
              </p>
              <p>Os aconsejo que no le moléis mucho hoy. Está muy agobiado</p>
              <p style={{ marginTop: '-25px' }}>
                I advise you not to trouble him much today. He is very
                overwhelmed
              </p>
              <li>
                <h3>To politely ask someone to do something</h3>
              </li>
              <p>
                Conditionals in Spanish is also used to ask for favors in a very
                polite way, let’s look at some examples
              </p>
              <p>¿Te importaría ayudarme con la tarea?</p>
              <p style={{ marginTop: '-25px' }}>
                would you mind helping me with the homework?
              </p>
              <p>¿Podrías sujetar esto un momento, por favor?</p>
              <p style={{ marginTop: '-25px' }}>
                Could you hold this for a moment, please?
              </p>
              <li>
                <h3>To create Imaginary Situations</h3>
              </li>
              <p>
                When talking about situations that don’t exist, we use
                conditionals or are created hypothetically, conditionals are
                used to describe them. Let’s look at some examples
              </p>
              <p>¿En qué parte del mundo te gustaría vivir?</p>
              <p style={{ marginTop: '-25px' }}>
                In which part of the world would you like to live?
              </p>
              <li>
                <h3>si + pretérito imperfect of Subjunctive, conditional</h3>
              </li>
              <p>
                Conditionals in Spanish are also used to put your strong opinion
                on something,an English version of this can be “I would never do
                something like this”. Here, although you’re not in that
                situation, but you’re giving your opinion on the situation,
                putting yourself in the same situation. Let’s look at some more
                examples
              </p>
              <p>Nunca me casaría con alguien por dinero</p>
              <p style={{ marginTop: '-25px' }}>
                I would never marry someone for money
              </p>
              <p>yo nunca lo haría</p>
              <p style={{ marginTop: '-25px' }}>I would never do it</p>
              <p>Nunca correría por dinero</p>
              <p style={{ marginTop: '-25px' }}>I would never run for money</p>
              <h2>Conclusion</h2>
              <p>
                In Spanish, we use the Conditionals for various things:
                expressing desires which are difficult to execute, to give
                advice/suggestions, to make a polite request to someone to do
                something, to create imaginary situations, to give your opinion
                on an action or a behavior. Let’s look at all the examples
                combined
              </p>
              <p>¡Qué sed! Me beberia un litro de agua</p>
              <p style={{ marginTop: '-25px' }}>
                I’m thirsty! I would drink a liter of water
              </p>
              <p>Deberías ir al doctor. Tienes mala cara</p>
              <p style={{ marginTop: '-25px' }}>
                you should go to the doctor. You look bad
              </p>
              <p>¿podrías llevarme a casa?</p>
              <p style={{ marginTop: '-25px' }}>Could you take me home?</p>
              <p>Si fuera millonario, me compraria un yate</p>
              <p style={{ marginTop: '-25px' }}>
                If I were a millionaire, would you buy me a yacht
              </p>
              <p>Yo me haría nunca un tatuaje</p>
              <p style={{ marginTop: '-25px' }}>I would never get a tattoo</p>
            </ul>
            <h2
              style={{
                textAlign: 'center',
                fontSize: '4rem',
                marginBottom: '30px',
              }}
            >
              Exercise: If I were a President
            </h2>
            <div className="row">
              <div className="col-1-of-2">
                <p>
                  In this exercise, think that you are not yourself but someone
                  else, what would it be?
                </p>
                <ul>
                  <li>Si fuera un animal, sería…</li>
                  <li>Si fuera un flor, sería…</li>
                  <li>Si fuera un país, sería…</li>
                  <li>Si fuera un objeto, sería…</li>
                  <li>Si fuera un libro, sería…</li>
                  <li>Si fuera una estrella, sería…</li>
                </ul>
              </div>
              <div className="col-1-of-2">
                <img
                  src={Situation}
                  alt="Conditional Situation in Spanish"
                  className="u-margin-bottom-medium"
                  style={{ width: '50%' }}
                />
              </div>
            </div>
          </div>
          <div className="article__blog-container__bottom">
            <div
              className="article__blog-container__bottom__left"
              to="/learn-spanish"
            >
              <Link to="/learn-spanish">
                <img
                  className="article__blog-container__bottom__image"
                  src={Spanish}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/spanish-definite-indefinte-articles/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Definite & Indefinite Articles
                </li>
              </Link>
              <Link to="/subject-personal-pronouns-in-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Subject Personal Pronouns
                </li>
              </Link>
              <Link to="/direct-indirect-object-pronouns/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Direct & Indirect Object Pronouns
                </li>
              </Link>
              <Link to="/spanish-nouns-articles-genders/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Nouns, articles and Genders
                </li>
              </Link>
              <Link to="/spanish-alphabets-accents-pronunciation/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Alphabets in Spanish
                </li>
              </Link>
              <Link to="/possessive-adjectives-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Possessive Adjectives
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default conditional;
