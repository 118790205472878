import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

import '../sass/main.scss';
import Image from '../assets/german-blog.png';
import SpanishAlphabets from '../assets/german-alphabet-letters.jpg';
import SpanishNumbers from '../assets/Spanish-Ordinal-number.jpg';
import SpanishWeek from '../assets/days-of-the-week-in-spanish.jpg';
import SpanishMonths from '../assets/months-in-spanish.jpg';
import SpanishDefArticles from '../assets/Spanish-def-indef-articles.png';
import SpanishPronouns from '../assets/Spanish-Subject-Personal-Pronouns.jpeg';
import SpanishObjectPronouns from '../assets/Direct-object-Pronouns-Spanish.jpeg';
import SpanishArticles from '../assets/spanish-articles.png';
import SpanishPlural from '../assets/spanish-plural-noun.jpg';
import SpanishPossessive from '../assets/possessive-adjectives-spanish.jpg';
import Preterite from '../assets/Preterite-ve-Imperfect.jpg';
import Future from '../assets/future.jpg';
import Conditional from '../assets/Conditional tense spanish.jpg';
import Imperative from '../assets/Spanish-imperative.jpg';

const spanish = () => {
  const head = () => (
    <MetaTags>
      <title>Learn spanish with LinguaPsych | LinguaPsych</title>
      <meta
        name="description"
        content="Learn spanish with easy blogs, learn about spanish grammar, spanish vocubalary and patterns with easy examples."
      />{' '}
      <link rel="canonical" href="https://linguapsych.com/spanish/" />
      <meta
        property="og:title"
        content="Learn spanish with easy blogs, learn about spanish grammar, spanish vocubalary and patterns with easy examples."
      />{' '}
      <meta
        property="og:description"
        content="Learn spanish with easy blogs, learn about spanish grammar, spanish vocubalary and patterns with easy examples."
      />{' '}
      <meta property="og:type" content="webiste" />
      <meta property="og:url" href="https://linguapsych.com/spanish/" />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/germany.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/germany.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}

      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_SPANISH_COURSE}`}>
          <div className="article__spanish-banner">
            <div className="article__spanish-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="Direct-object-Pronouns-Spanish"
            className="article__featured-image"
          />
          <div className="article__header--text">
            <h1 className="article__title ">
              {' '}
              <span> Learn spanish</span>{' '}
            </h1>
          </div>
        </header>
        <div className="row">
          <div className="col-1-of-3">
            <Link to="/spanish-alphabets-accents-pronunciation/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img src={SpanishAlphabets} style={{ width: '100%' }} />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>Spanish Alphabets & letters</h2>
                  </div>
                  <div>
                    <p>
                      Kick start Spanish Alphabets, Pronunciation &amp; Accents.
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-1-of-3">
            <Link to="/spanish-numbers/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img
                    src={SpanishNumbers}
                    style={{ width: '100%' }}
                    height="170px"
                  />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>Spanish Numbers</h2>
                  </div>
                  <div>
                    <p>
                      Learn How To Count Spanish Numbers 1-100, 1-1000, 1-10000
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-1-of-3">
            <Link to="/spanish-days-of-the-week/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img src={SpanishWeek} style={{ width: '100%' }} />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>Spanish days of the week</h2>
                  </div>
                  <div>
                    <p>
                      Simple Guide to spanish Days of the Week with
                      Pronunciation
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-1-of-3">
            <Link to="/months-in-spanish/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img
                    src={SpanishMonths}
                    style={{ width: '100%', height: '205px' }}
                  />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>Learn Months In Spanish</h2>
                  </div>
                  <div>
                    <p>
                      Learn Months in Spanish in just 5 minutes using related
                      words
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-1-of-3">
            <Link to="/spanish-definite-indefinte-articles/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img
                    src={SpanishDefArticles}
                    style={{ width: '100%' }}
                    height="176px"
                  />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>Spanish definite & indefinite articles</h2>
                  </div>
                  <div>
                    <p>
                      Complete guide to Spanish definite and Indefinite articles
                      with examples.
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-1-of-3">
            <Link to="/subject-personal-pronouns-in-spanish/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img
                    src={SpanishPronouns}
                    style={{ width: '100%', height: '215px' }}
                  />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>Subject personal pronouns in Spanish</h2>
                  </div>
                  <div>
                    <p>Easy Guide On Subject (Personal) Pronouns In Spanish</p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-1-of-3">
            <Link to="/direct-indirect-object-pronouns/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img
                    src={SpanishObjectPronouns}
                    style={{ width: '100%', height: '205px' }}
                  />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>Spanish Direct and Indirect Object Pronouns</h2>
                  </div>
                  <div>
                    <p>
                      Easy guide to Spanish Direct and Indirect Object pronouns
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-1-of-3">
            <Link to="/spanish-nouns-articles-genders">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img
                    src={SpanishArticles}
                    style={{ width: '100%', height: '205px' }}
                  />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>Spanish Nouns Genders & Articles</h2>
                  </div>
                  <div>
                    <p>
                      5 easy ways to understand Spanish nouns &amp; Articles
                      with examples.
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-1-of-3">
            <Link to="/spanish-plural-nouns/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img
                    src={SpanishPlural}
                    style={{ width: '100%', height: '205px' }}
                  />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2> Make Spanish Nouns Plural</h2>
                  </div>
                  <div>
                    <p>
                      5 easy ways to make Spanish Plural Nouns with a lot of
                      examples.
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-1-of-3">
            <Link to="/possessive-adjectives-spanish/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img
                    src={SpanishPossessive}
                    style={{ width: '100%', height: '205px' }}
                  />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>Possessive Adjectives Spanish</h2>
                  </div>
                  <div>
                    <p>
                      Easy Guide to Possessive Adjectives In Spanish With
                      Exercises
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-1-of-3">
            <Link to="/preterite-vs-imperfect/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img
                    src={Preterite}
                    style={{ width: '100%', height: '205px' }}
                  />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>When to Use Preterite vs Imperfect?</h2>
                  </div>
                  <div>
                    <p>
                      Spanish Preterite vs Imperfect The complete Past Tense
                      Guide
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-1-of-3">
            <Link to="/future-tense-spanish/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img
                    src={Future}
                    style={{ width: '100%', height: '205px' }}
                  />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>Complete guide to Future Tense Spanish</h2>
                  </div>
                  <div>
                    <p>
                      Future Tense Spanish: Easy Guide | Everything you need to
                      know
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-1-of-2">
            <Link to="/conditional-tense-spanish/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img
                    src={Conditional}
                    style={{ width: '100%', height: '205px' }}
                  />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>Conditional Tense in Spanish</h2>
                  </div>
                  <div>
                    <p>
                      Complete Guide To Conditional Tense In Spanish With
                      Examples
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-1-of-2">
            <Link to="/imperative-spanish/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img
                    src={Imperative}
                    style={{ width: '100%', height: '205px' }}
                  />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>Learn Spanish Imperative in 10 minutes</h2>
                  </div>
                  <div>
                    <p>
                      10 minutes Spanish Imperatives| Learn regular &amp;
                      irregular verbs
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default spanish;
