import React from 'react';
import { Link } from 'react-router-dom';

import '../sass/main.scss';
import MetaTags from 'react-meta-tags';
import Image from '../assets/Spanish-Ordinal-number.jpg';
import Image1 from '../assets/Spanish-Ordinal-number.jpg';
import Spanish from '../assets/spanish_course_bottom.png';

const spanishNumbers = () => {
  const head = () => (
    <MetaTags>
      <title>
        Learn How To Count Spanish Numbers 1-100, 1-1000, 1-10000 | LinguaPsych
      </title>
      <meta
        name="description"
        content="This is the compete Beginners guide to Numbers in Spanish from 1 to 100,1 to 1000 &amp; beyond, Includes every detail to remember Spanish Words. Easy and well Explained"
      />

      <link rel="canonical" href="https://linguapsych.com/spanish-numbers/" />
      <meta
        property="og:title"
        content="This is the compete Beginners guide to Numbers in Spanish from 1 to 100,1 to 1000 &amp; beyond, Includes every detail to remember Spanish Words. Easy and well Explained"
      />

      <meta
        property="og:description"
        content="This is the compete Beginners guide to Numbers in Spanish from 1 to 100,1 to 1000 &amp; beyond, Includes every detail to remember Spanish Words. Easy and well Explained"
      />

      <meta property="og:type" content="website" />
      <meta property="og:url" href="https://linguapsych.com/spanish-numbers/" />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/Spanish-Ordinal-number.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/Spanish-Ordinal-number.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}

      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_SPANISH_COURSE}`}>
          <div className="article__spanish-banner">
            <div className="article__spanish-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="Spanish Numbers"
            className="article__featured-image"
          />
          <div className="article__header-elements">
            <h1 className="article__title ">
              {' '}
              <span
                style={{ fontSize: '5rem' }}
                className="article__highlight--black "
              >
                {' '}
                SPANISH NUMBERS | How to count in Spanish
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <h2 className="u-margin-bottom-medium">Spanish Numbers 1-100</h2>
            <h3 className="u-margin-bottom-medium">
              Let’s look at the numbers first, we’ll get to the explanation
              after looking at them.
            </h3>
            <div
              className="row"
              style={{
                color: 'white',
                textAlign: 'center',
              }}
            >
              <div
                className="col-1-of-3"
                style={{
                  backgroundColor: 'maroon',
                }}
              >
                <span>0 cero</span>
                <span> 1 un(o/a)</span>
                <span> 2 dos</span>
                <span>3 tres</span>
                <span> 4 cuatro</span>
                <span> 5 cinco</span>
                <span>6 seis</span>
                <span> 7 siete</span>
                <span> 8 ocho</span>
                <span> 9 nueve</span>
                <span> 10 diez</span>
                <span>11 once</span>
                <span>12 doce</span>
                <span>13 trece</span>
                <span>14 catorce</span>
                <span>15 quince</span>
              </div>
              <div
                style={{
                  backgroundColor: 'maroon',
                }}
                className="col-1-of-3"
              >
                <span>16 dieciséis</span>
                <span> 17 diecisiete</span>
                <span> 18 dieciocho</span>
                <span>19 diecinueve</span>
                <span> 20 veinte</span>
                <span> 21 veintiún(o/a)</span>
                <span>22 vientidos</span>
                <span> 23 veintitrés</span>
                <span> 24 veinticuatro</span>
                <span>25 veinticinco</span>
                <span> 26 veintiséis</span>
                <span>27 veintisiete</span>
                <span>28 veintiocho</span>
                <span>29 veintinueve</span>
                <span>30 treinta</span>
                <span>31 treinta y un(o/a) </span>
              </div>
              <div
                style={{
                  backgroundColor: 'maroon',
                }}
                className="col-1-of-3"
              >
                <span>32 treinta y dos</span>
                <span> 33 treinta y tres</span>
                <span> 34 treinta y cuatro</span>
                <span>35 treinta y cinco</span>
                <span> 36 treinta y seis</span>
                <span> 37 treinta y siete</span>
                <span>38 treinta y ocho</span>
                <span> 39 treinta y nueve</span>
                <span> 40 cuarenta</span>
                <span>50 cincuenta</span>
                <span> 60 sesenta</span>
                <span>70 setenta</span>
                <span>80 ochenta</span>
                <span>90 noventa</span>
                <span> 99 noventa y nueve</span>
                <span>100 cien</span>
              </div>
            </div>
            <p>
              It might look like a lot of stuff to remember but trust me the
              rules are going to be simple & not a lot of stuff to mug up. Let’s
              start with the basics
            </p>
            <h2 className="u-margin-bottom-medium">Spanish Numbers 1-20</h2>
            <ul>
              <li className="u-margin-bottom-medium">
                The number 1 has three forms : un/una when comes before a
                masculine or a feminine noun (Tengo un hermano / Tengo una
                hermana) and uno when it’s used alone and referring to a
                masculine noun ( El mente es muy importante, solo tenemos uno).
              </li>
              <li>
                {' '}
                Learning Spanish numbers from 1-15 doesn’t have an actual
                pattern, it is advisable to learn numbers till 15 by heart and
                after that there’s a pattern which we’re going to follow.
              </li>
              <li>16 =10 +6 diez + seis = dieciséis</li>
              <li>17= 10+7 diez + siete= diecisiete</li>
              <li>18=10+8 diez+ocho= dieciocho</li>
              <li>19=10+9 diez+nueve= diecinueve</li>
              <li className="u-margin-bottom-medium">20 is just veinte</li>
            </ul>
            <h2 className="u-margin-bottom-medium">
              Learn Spanish Numbers 20-100
            </h2>
            <ul>
              <li>
                20,21,22…29 is the only series where the whole digit will be
                written as one single word: veintidos, veintitrés till
                veintinueve.
              </li>
              <li>
                Except 20 (veinte) & 30 (treinta) the 10’s series like 40, 50
                etc. ends with –ente: cuarenta, cincuenta etc.
              </li>
              <li>
                {' '}
                After learning Spanish numbers till 20, learn the numbers in
                pattern of 10’s Treinta, cuarenta, cincuenta, sesenta, setenta,
                ochenta, noventa.
              </li>
              <li>
                Note that the multiple of 10’s start with their single digit
                versions: tres – treinta, cuatro – cuarenta.
              </li>
              <li>
                After learning the multiple of 10’s just add the numbers: 32= 30
                & 2, treinta y dos: 45= 40 & 5, cuarenta y cinco. This rule will
                be followed till 99= 90 & 9, noventa y nueve.
              </li>
              <li className="u-margin-bottom-medium">
                {' '}
                Finally, 100 can be cien or ciento, let’s see how
              </li>
            </ul>
            <h2 className="u-margin-bottom-medium">
              100 in Spanish, cien or ciento?
            </h2>
            <p>
              Cien & Ciento both refer to a Spanish 100, but what’s the
              difference?
            </p>
            <ul>
              <li>
                Cien is used when the amount is an exact 100, not one point more
                or less: Tengo cien libros, hay cien personas en la clase.
              </li>
              <li>
                Ciento is used when the amount is anything more than a 100
              </li>
              <li>101= ciento uno</li>
              <li className="u-margin-bottom-medium">150= ciento cincuenta.</li>
            </ul>
            <h2 className="u-margin-bottom-medium">
              Learn Spanish Numbers 100-1000
            </h2>
            <div
              className="row"
              style={{
                backgroundColor: 'maroon',
                color: 'white',
                textAlign: 'center',
              }}
            >
              <p> 101 ciento un(o/a)</p>
              <p>102 ciento dos</p>
              <p>200 doscientos/as</p>
              <p>300 trescientos/as</p>
              <p> 400 cuatrocientos/as</p>
              <p>500 quinientos/as</p>
              <p>600 seiscientos/as</p>
              <p>700 setecientos/as</p>
              <p>800 ochocientos/as</p>
              <p>900 novecientos/as</p>
              <p> 999 novecientos noventa</p>
              <p>1000 mil</p>
            </div>
            <p>
              Welcome back, things are getting easier now. Not much vocabulary
              to learn, just look at the rules to remember the numbers 100-1000:
            </p>
            <ol>
              <li>
                When we are using exact denomination of 100 i.e 200, 300 they
                can be called doscientos/as, trescientos/as depending on the
                gender of the noun
              </li>
              <p>Yo tengo doscientos hermanos</p>
              <p>Yo tengo doscientas hermanas</p>
              <li>
                When the amount is anything beyond the denominator of 100 i.e
                101, 202 etc. use doscientos, trescientos & not doscientas,
                trescientas.
              </li>
              <li>
                When there are numbers which are not exact 200 or 900, you can
                write them by dividing them
              </li>
              <p>333 = 300+30+3 (Trescientos treinta y tres)</p>
              <p>456= 400+50+6 (Cuatrocientos cincuenta y seis)</p>
              <p className="u-margin-bottom-medium">
                Note: Unlike 2 digit numbers like 34 (treinta y cuatro), 3 digit
                numbers don’t take y (and) as a divider between hundred and ten
                digit: 333= Trescientos treinta y cuatro & not trescientos y
                treinta y cuatro.
              </p>
            </ol>
            <h2 className="u-margin-bottom-medium">
              Learn Spanish Numbers 1000-1000000000
            </h2>
            <div
              className="row"
              style={{
                color: 'white',
                textAlign: 'center',
                backgroundColor: 'maroon',
              }}
            >
              <p>1000 mil</p>
              <p>2000 dos mil</p>
              <p>10 000 diez mil</p>
              <p>100 000 cien mil</p>
              <p>200 000 doscientos/as mil</p>
              <p>1 000 000 un millón</p>
              <p>2 000 000 dos millones</p>
              <p>1 000 000 000 mil millones</p>
            </div>
            <ul>
              <li>
                Big numbers mean difficult readings right? NOO, there is no need
                to learn a new word now, you’ve read everything already
                CONGRATS. Let’s look at the rules now:
              </li>
              <li>
                1000 in Spanish is mil & it’s plural unlike 100 (ciento) becomes
                cientos, mil stays mil & not miles.
              </li>
              <li>
                {' '}
                2346= 2000 + 300+40+6 (dos mil trescientos cuarenta y seis)
              </li>
              <li>
                9999= 9000+900+90+9 ( nueve mil novecientos noventa y nueve)
              </li>
              <p>
                {' '}
                Note: Like ciento or cientos don’t take y (and), mil doesn’t
                take y between mil and ciento or any 10’s digit.
              </p>
              <li>
                1 00 000 will be cien mil, 2 00 000 will be doscientos/as mil
                rest of the series will stay the same.
              </li>
              <li>
                When the numbers become 1000 000 it will not me un mil mil, it
                will become un millón.
              </li>
              <li>
                Un millón will take the accent on ó, once it becomes plural i.e
                dos millones, tres millones it will omit the accent mark.
              </li>
              <li>
                {' '}
                Rest of the rules will stay the same like 1 000 000 000 will be
                mil millones.
              </li>
              <li>
                With Millones, use the preposition de when the the quantity is
                an exact millón or millones. For example Venezuela tiene
                cuarenta millones de habitantes. If the quantity is anything
                more than the exact million, there is no need to use the
                preposition de.
              </li>
              <li className="u-margin-bottom-medium">
                1 Billion in Spanish is un millón de millones.
              </li>
            </ul>
            <h2 className="u-margin-bottom-medium">
              Ordinal Numbers in Spanish
            </h2>
            <div>
              <img
                src={Image1}
                alt="Spanish-Ordinal-number"
                className="article__featured-image"
              />
            </div>
            <p>
              When talking about the numbers, we can’t make Ordinal numbers feel
              left out. Learning one, two, three is important, learning how to
              say first, second third is equally important. Ler’s look at the
              Ordinal numbers in Spanish now
            </p>
            <div className="row">
              <div className="col-1-of-2" style={{ textAlign: 'center' }}>
                <div
                  style={{
                    backgroundColor: 'maroon',
                    color: 'white',
                  }}
                >
                  <p>ENGLISH</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>FIRST</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>SECOND</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>THIRD</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>FOURTH</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>FIFTH</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>SIXTH</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>SEVENTH</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>EIGHTH</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>NINTH</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>TENTH</p>
                </div>
              </div>
              <div className="col-1-of-2" style={{ textAlign: 'center' }}>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>SPANISH</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>PRIMERO</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>SEGUNDO</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>TERCERO</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>CUARTO</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>QUINTO</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>SEXTO</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>SÉPTIMO</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>OCTAVO</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>NOVENO</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>DÉCIMO</p>
                </div>
              </div>
            </div>
            <p>
              Ordinal numbers are used before a noun and are adjectives which
              tell us about the physical position of the noun. Ordinal Numbers
              are used before the noun and the ending of the ordinal number will
              change according to the gender of the noun
            </p>
            <ul>
              <li>First time – Primera vez</li>
              <li>Fourth floor – Cuarto Piso</li>
              <li> Eighth car – Octavo Coche</li>
              <h3>
                Note: Primero and Tercero omit (o) when used with singular
                masculine nouns:
              </h3>
              <li>First day = Primer dia</li>
              <li>Third Floor – Tercer Piso</li>
            </ul>
            <p>
              These were just the basics and if you want to learn it even
              further, let’s look at the table below. It will be a good idea to
              learn it in the multiple of 10 and then add the single digit
              numbers to it to get numbers like 22nd, 44th etc.
            </p>
            <div className="row">
              <div className="col-1-of-2" style={{ textAlign: 'center' }}>
                <div
                  style={{
                    backgroundColor: 'maroon',
                    color: 'white',
                  }}
                >
                  <p>ENGLISH</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>Twentieth</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>Thirtieth</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>Fourtieth</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>Fiftieth</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>Sixtieth</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>Seventieth</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>Eightieth</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>Ninetieth</p>
                </div>
              </div>
              <div className="col-1-of-2" style={{ textAlign: 'center' }}>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>SPANISH</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>Vigésimo</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>Trigésimo</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>Cuadragésimo</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>Quincuagésimo</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>Sexagésimo</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>Septuagésimo</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>Octogésimo</p>
                </div>
                <div style={{ backgroundColor: 'maroon', color: 'white' }}>
                  <p>Nonagésimo</p>
                </div>
              </div>
            </div>
            <ul>
              <li>
                After learning the Ordinal numbers for single digits and ten’s
                digit, next step is to combine them to form any ordinal numbers
                combination, let’s see how:
              </li>
              <li>15th floor = 10th+5th = Décimo quinto piso</li>
              <li>28th cat = 20th+8th = Vigésimo octavo gato</li>
              <li>49th floor = 40th +9th= Cuadragésimo noveno piso</li>
              <p>
                Like any other adjective in Spanish, ordinal numbers change
                their ending depending on the gender of the noun. Please note
                that if the noun is feminine in nature, 10’s digit ordinal
                number and single digit ordinal number will both change their
                ending to complement a feminine noun. Let’s see how
              </p>
              <li>34th version = 30th+4th= Trigésima cuarta versión</li>
              <li>88th floor= 80th+8th= Octogésima octava planta</li>
              <li>97th woman= 90th +7th= Nonagésima séptima mujer</li>
              <p>
                Just like cardinal numbers in Spanish from 11 to 19, ordinal
                numbers are written as a single word and not two:
              </p>
              <li> 11th – Decimoprimero & Décimo primero</li>
              <li>15th – Decimocinco & Décimo cinco</li>
              <li>18th- Decimoctavo & Décimo octavo</li>

              <p>
                Note: When you’re combining Décimo with single digit ordinal
                number, the accent mark over e will be omitted.
              </p>
              <p>
                If this is a big task to take, tension not! numbers after Décimo
                are not used very often in daily Spanish, using the noun first
                and using the number for it is more common, let’s see how
              </p>
              <li>15th floor – el piso quince</li>
              <li>18th digit- el dígito dieciocho</li>
              <h3>Let's see if you remember what we did today</h3>
              <h4>Write down these numbers in the comment section below</h4>
              <li> 1111</li>
              <li>22222</li>
              <li>333333</li>
              <li>444444</li>
              <li>9999999</li>
            </ul>
          </div>
          <div className="article__blog-container__bottom">
            <div
              className="article__blog-container__bottom__left"
              to="/learn-spanish"
            >
              <Link to="/learn-spanish">
                <img
                  className="article__blog-container__bottom__image"
                  src={Spanish}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/spanish-definite-indefinte-articles/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Definite & Indefinite Articles
                </li>
              </Link>
              <Link to="/subject-personal-pronouns-in-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Subject Personal Pronouns
                </li>
              </Link>
              <Link to="/direct-indirect-object-pronouns/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Direct & Indirect Object Pronouns
                </li>
              </Link>
              <Link to="/spanish-nouns-articles-genders/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Nouns, articles and Genders
                </li>
              </Link>
              <Link to="/spanish-alphabets-accents-pronunciation/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Alphabets in Spanish
                </li>
              </Link>
              <Link to="/possessive-adjectives-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Possessive Adjectives
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default spanishNumbers;
