import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { Helmet } from 'react-helmet';
import Image from '../assets/korean-alphabet.jpg';
import Pattern from '../assets/korean-alphabets-pattern.JPG';
import Chart1 from '../assets/Korean-alphabet-chart-1.jpg';
import Chart2 from '../assets/Korean-alphabet-chart-2.jpg';
import Chart3 from '../assets/Korean-alphabet-chart-3.jpg';
import Chart4 from '../assets/Korean-alphabet-chart-4.jpg';
import Chart5 from '../assets/Korean-alphabet-chart-5.jpg';
import Language from '../assets/language_course_bottom.png';
import '../sass/main.scss';

const koreanLetters = () => {
  const head = () => (
    <Helmet>
      <title>
        Intensive Guide to Korean Alphabet, Letters, Characters with
        Pronunciation | LinguaPsych
      </title>
      <meta
        name="description"
        content="Want to learn Korean Alphabet? This is an intensive guide on Korean Letters and Korean Characters with pronunciation you need to know to learn Korean."
      />{' '}
      <link
        rel="canonical"
        href="https://linguapsych.com/korean-alphabet-letters/"
      />
      <meta
        property="og:title"
        content="Want to learn Korean Alphabet? This is an intensive guide on Korean Letters and Korean Characters with pronunciation you need to know to learn Korean."
      />{' '}
      <meta
        property="og:description"
        content="Want to learn Korean Alphabet? This is an intensive guide on Korean Letters and Korean Characters with pronunciation you need to know to learn Korean."
      />{' '}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        href="https://linguapsych.com/korean-alphabet-letters/"
      />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}/assets/korean-alphabet.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}/assets/korean-alphabet.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </Helmet>
  );

  return (
    <React.Fragment>
      {head()}

      <div className="article">
        <MetaTags>
          <title>
            Intensive Guide to Korean Alphabet, Letters, Characters with
            Pronunciation | LinguaPsych
          </title>
          {console.log('KOREAN ALPHABETS')}
          <meta
            name="description"
            content="Want to learn Korean Alphabet? This is an intensive guide on Korean Letters and Korean Characters with pronunciation you need to know to learn Korean."
          />{' '}
          <link
            rel="canonical"
            href="https://linguapsych.com/korean-alphabet-letters/"
          />
          <meta
            property="og:title"
            content="Want to learn Korean Alphabet? This is an intensive guide on Korean Letters and Korean Characters with pronunciation you need to know to learn Korean."
          />{' '}
          <meta
            property="og:description"
            content="Want to learn Korean Alphabet? This is an intensive guide on Korean Letters and Korean Characters with pronunciation you need to know to learn Korean."
          />{' '}
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            href="https://linguapsych.com/korean-alphabet-letters/"
          />
          <meta property="og:site_name" content="https://linguapsych.com/" />
          <meta
            property="og:image"
            content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}/assets/korean-alphabet.jpg`}
          />
          <meta
            property="og:image:secure_url"
            content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}/assets/korean-alphabet.jpg`}
          />
          <meta property="og:image:type" content="image/jpg" />
          <meta
            property="fb:app_id"
            content={`${process.env.REACT_APP_FB_APP_ID}`}
          />
        </MetaTags>

        <a target="_blank" href={`${process.env.REACT_APP_DOMAIN_PRODUCTION}`}>
          <div className="article__general-banner">
            <div className="article__general-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="spanish-articles"
            className="article__featured-image"
          />
          <div className="article__header-elements">
            <h1 className="article__title ">
              {' '}
              <span
                style={{ fontSize: '5rem' }}
                className="article__highlight--black "
              >
                {' '}
                Korean Alphabet and Accents
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <p>
              Hangul is the official alphabet of the Korean language and it’s
              used in both South and North Korea. The alphabet was created in
              the year 1443 in the Joseon Dynasty.
            </p>
            <p>
              The name of the Korean alphabet, Hangul (한글) means great script
              in Korean. Han (한) means great and Geul (글) means script.
            </p>
            <p>
              The most interesting feature of the Korean Alphabet is the design
              of the letters. The shape of each letter is designed after the
              features of the sounds they represent. Consonants are based on the
              shape your mouth makes when you pronounce them while vowels are
              made from easy to identify horizontal or vertical lines. The
              character ㄱ for example is the shape your tongue takes when you
              make a “g” sound.
            </p>
            <p>
              The Korean alphabet is made up of 19 consonant letters and 21
              vowel characters for a total of 40 main letters. There are some
              obsolete characters and combination characters as well but the
              main alphabet is 40 letters.
            </p>
            <p>
              The Korean alphabet has ten basic consonants and nine variations
              on them.
            </p>
            <h2 className="u-margin-bottom-medium">
              What are Korean Consonants (자음)?
            </h2>

            <table style={{ width: '100%', textAlign: 'center' }}>
              <tbody>
                <tr>
                  <td>
                    <span style={{ fontSize: '18pt' }}>
                      <strong>Consonants</strong>
                    </span>
                  </td>
                  <td>
                    <span style={{ fontSize: '18pt' }}>
                      <strong>Pronounciations</strong>
                    </span>
                  </td>
                  <td>
                    <span style={{ fontSize: '18pt' }}>
                      <strong>Examples with Pronounciation</strong>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontSize: '14pt' }}>ㄱ</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>g or k</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>
                      가방 (Bag) – Ga-baang
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <span style={{ fontSize: '14pt', color: '#ff0000' }}>
                        ㄲ
                      </span>
                    </strong>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>K</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>꽃 (Flower) – Kot</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontSize: '14pt' }}>ㄴ</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>n</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>년 (Year) – Nyon</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontSize: '14pt' }}>ㄷ</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>d or t</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>
                      도마도(Tomato) – to-mado
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontSize: '14pt', color: '#ff0000' }}>
                      <strong>ㄸ</strong>
                    </span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>t</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>
                      땅(Land) &#8211; Taang
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontSize: '14pt' }}>ㄹ *</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>r or l</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>
                      라디오 (Radio) – Raa-dio and 물(Water) &#8211; Mul
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontSize: '14pt' }}>ㅁ</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>m</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>
                      몸 (Body) &#8211; Mom
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontSize: '14pt' }}>ㅂ</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>b</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>
                      비 (Rain) &#8211; Bi
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontSize: '14pt', color: '#ff0000' }}>
                      <strong>ㅃ</strong>
                    </span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>p</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>
                      빵 (Bread) &#8211; Paang
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontSize: '14pt' }}>ㅅ</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>s</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>
                      수도 (Capital) – Su-do
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontSize: '14pt', color: '#ff0000' }}>
                      <strong>ㅆ</strong>
                    </span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>s</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>
                      쌀 (Rice) &#8211; Saal
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontSize: '14pt' }}>ㅇ*</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>silent / ng</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>강 (River) – Gang</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontSize: '14pt' }}>ㅈ</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>j</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>
                      조금 (Little) – Jogam
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontSize: '14pt', color: '#ff0000' }}>
                      <strong>ㅉ</strong>
                    </span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>tch</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>
                      찍다 (to take photos) – tchig-daa
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontSize: '14pt' }}>ㅊ</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>ch</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>
                      친구 (Friend) &#8211; Chingu
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontSize: '14pt' }}>ㅋ</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>kh</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>
                      코 (Nose) &#8211; Kho
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontSize: '14pt' }}>ㅌ</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>th</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>
                      턱 (Chin) &#8211; Thaug
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontSize: '14pt' }}>ㅍ</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>ph</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>
                      펜 (Pen) &#8211; Phen
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontSize: '14pt' }}>ㅎ</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>h</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '14pt' }}>
                      하나 (One) &#8211; Haanaa
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <div
              style={{
                backgroundColor: 'pink',
                color: 'white',
                marginTop: '3rem',
              }}
            >
              <p>
                All the 5 red marked consonants are called double consonants.
                All the double consonants have a harder sound than their single
                counterparts and are pronounced with no expulsion of air.
              </p>
            </div>
            <h2 className="u-margin-bottom-medium">
              Do you know Korean Vowels (모음)?
            </h2>
            <table style={{ width: '100%', textAlign: 'center' }}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      <span style={{ fontSize: '18pt' }}>
                        <strong>Vowels</strong>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>
                        <span style={{ fontSize: '18pt' }}>Pronounciation</span>
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '18pt' }}>
                        <strong>Examples with Pronounciation</strong>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ㅏ</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}> Aa</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>
                        아이 (Child) &#8211; Aai
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ㅓ</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>Aww</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>
                        어머니 (Mother) – Aw-maw-ni
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ㅗ</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>o</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>
                        옷 (Clothes) &#8211; Ot
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ㅜ</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>oo</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>
                        우리 (We) &#8211; Oori
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ㅡ</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>
                        eu (groaning sound)
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>
                        그 (He)  &#8211; Geu…..
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ㅣ</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>eee</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>
                        잎 (Leaf) &#8211; Iph
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ㅔ</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>a</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>
                        네 (Yes) &#8211; Ne
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ㅐ</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ae</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>
                        태양 (Sun) – Thae- Yaang
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ㅚ</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ve</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>
                        쇠 (Iron) &#8211; Sve
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ㅟ</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>Wi</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>
                        위 (Stomach) &#8211; Wi
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ㅑ</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>Yaa</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>
                        약 (Medicine) &#8211; Yaak
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ㅕ</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>Yaaw</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>
                        여기 (Here) – Yaw-gi
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ㅛ</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>yo</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>
                        교실 (Classroom) – Gyo-sil
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ㅠ</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>you</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>
                        유리 (Glass) – you-ri
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ㅒ</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>yae</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>
                        얘기 (Story) &#8211; Yaegi
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ㅖ</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ye</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>
                        예산 (Budget) – Ye-saan
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ㅢ</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ui</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>
                        의사 (Doctor) – ui-saa
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ㅘ</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>Waa</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>
                        과일 (Fruit) – Gwaa-il
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ㅝ</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>Wo</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>
                        원 (Korean currency) &#8211; won
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ㅙ</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>Wae</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>
                        왜 (Why) &#8211; Wae
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>ㅞ</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>uwae</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ fontSize: '14pt' }}>
                        궤 (Box) &#8211; Guwae
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ textAlign: 'center' }}>
              <img
                style={{ width: '50%', marginTop: '30px' }}
                src={Pattern}
                alt="korean-alphabets-pattern"
              />
            </div>
            <p>
              In this picture, it should be clear that the ones on the left are
              drawn vertically, and the ones on the right are drawn
              horizontally. The difference is very important because the way
              every Korean letter is written depends on if the vowel is drawn
              vertically or horizontally.
            </p>
            <h3>Let’s practice a few:</h3>
            <ul>
              <li>ㅂ = b</li>
              <p>ㅏ = a</p>
              <p>ㅂ = b</p>
              <p>
                ㅏ is vertically aligned, so if we make a syllable we would
                write: 밥 (Bab)
              </p>
              <li>ㅈ = j</li>
              <p>ㅜ = u</p>
              <p>
                ㅜ is horizontally aligned, so if we make a syllable we would
                write: 주 (ju)
              </p>
            </ul>
            <h2 className="u-margin-bottom-medium">
              What are Korean Syllables?
            </h2>
            <p>
              The letters are combined together into syllable blocks. Korean is
              written into “blocks” that make up one syllable.
            </p>
            <p>
              Syllables are always constructed beginning with a consonant. Then
              a vowel follows. The syllable could stop here, or there could be
              another vowel, or consonant, or both. The syllable begins with the
              initial consonant on the left or top and the vowel(s) and other
              consonant(s) follow to the right or bottom.
            </p>
            <p>
              For example, Hangeul is written: 한 (han) = ᄒ (h) + ᅡ (a) + ᄂ
              (n) and 글 (geul) = ᄀ (g) + ᅳ (eu) + ᄅ (l)
            </p>
            <p>
              When constructing syllables, always move from left to right and
              top to bottom. This is the same for reading. Sound out the initial
              consonant and then the following vowel(s), making it flow into the
              other ending consonant(s), if any. It’s just like reading in
              English. Read left to right, sounding out each letter. And there
              you have the pronunciation of the syllable. Then move on to the
              next syllable. Words are separated by spaces, and syllables are
              right next to each other. Again, just like in English.
            </p>
            <h2 className="u-margin-bottom-medium">
              Usual Korean Syllables Formations
            </h2>
            <p>Following are the usual syllable formations in Korean:</p>
            <p>Here, C = Consonants, V = Vowels</p>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '20%', height: '130px', marginTop: '30px' }}
                  src={Chart1}
                  alt="Korean-alphabet-chart-1"
                />
              </div>
              <div className="col-1-of-2">
                <p>Example: 한 (han) = ᄒ (h) + ᅡ (a) + ᄂ (n)</p>
                <p>Note: Only Vertical drawn Vowel will be used</p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '20%', height: '130px', marginTop: '30px' }}
                  src={Chart2}
                  alt="Korean-alphabet-chart-2"
                />
              </div>
              <div className="col-1-of-2">
                <p>Example: 물 (mul) = ㅁ (m) + ㅜ (oo) + ㄹ (l)</p>
                <p>Note: Only Horizontal drawn Vowel will be used</p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '20%', height: '130px', marginTop: '30px' }}
                  src={Chart3}
                  alt="Korean-alphabet-chart-3"
                />
              </div>
              <div className="col-1-of-2">
                <p>Example: 보 (Bo) = ㅂ (B) + ㅗ (o)</p>
                <p>Note: Only Horizontal drawn Vowel will be used</p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '20%', height: '130px', marginTop: '30px' }}
                  src={Chart4}
                  alt="Korean-alphabet-chart-4"
                />
              </div>
              <div className="col-1-of-2">
                <p>Example: 비 (Bee) = ㅂ (B) + ㅣ (ee)</p>
                <p>Note: Only Vertical drawn Vowel will be used</p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '20%', height: '130px', marginTop: '30px' }}
                  src={Chart5}
                  alt="Korean-alphabet-chart-5"
                />
              </div>
              <div className="col-1-of-2">
                <p>
                  Example: 읽 (Ilg) = ㅇ (silent) + ㅣ (ee) + ㄹ (l) + ㄱ (g)
                </p>
                <p>Note: Only Vertical drawn Vowel will be used</p>
              </div>
            </div>
            <h2 className="u-margin-bottom-medium">Korean Pronunciation</h2>
            <ul>
              <li>
                <h3>ㅇ</h3>
              </li>
              <p>
                O (ang) is a special letter in Korean, because sometimes it
                makes a sound and sometimes it doesn’t. This is determined by
                whether it is in the initial or final position.
              </p>
              <p>
                In initial position, such as in the word 엄마 ([eomma],
                “mother”) ㅇ is not pronounced, and the vowel becomes the
                initial sound.
              </p>
              <p>
                In Final Position, if ㅇ is in the final position, such as in 강
                ([kaŋ], “river”) or 영어 ([jʌŋʌ], “English language”), then it
                is pronounced ang, similar to the ng in the English word “sing”.
              </p>
              <p>Examples:</p>
              <p>엄마 ([ʌmma]): ㅇ in initial position is not pronounced.</p>
              <p>
                아이 ([ai]): ㅇ in middle position with no preceding consonant
                is silent.
              </p>
              <p>강 ([kaŋ]): ㅇ in final position is similar to ng sound.</p>

              <li>
                <h3>ㄹ</h3>
              </li>
              <p>
                Proper pronunciation of the Korean letter ㄹ takes some practice
                for most English speakers. It is pronounced sort of like a half
                r and half l sound.
              </p>
              <p>
                ㄹ is pronounced as R when used in the beginning/middle of the
                syllable.
              </p>
              <p>
                ㄹ is pronounced as L when used as a final consonant/lower part
                of the syllable.
              </p>
              <p>Examples</p>
              <p>라지오 – Raa-dio</p>
              <p>우리 – Oo-Ri</p>
              <p>물 – Mul</p>
              <li>
                <h3>T-stops</h3>
                <p>
                  There are a few consonants that, when they are in the final
                  position, are pronounced [t] (an unreleased t, like in the
                  English word “atlas”). These characters are: ㄷ, ㅌ, ㅅ, ㅆ,
                  ㅈ, and ㅊ
                </p>
                <p>Examples:</p>
                <p>맛 ([mat], “flavor, taste”)</p>
                <p>꽃 ([g̬ot], “flower”)</p>
                <p>끝 ([g̬ut], “end”)</p>
                <p>돋보기 ([totpogi], “magnifying glass”)</p>
                <p>맞다 ([matda], “to correct”)</p>
                <p>있다 ([ittda], “to exist”)</p>
              </li>

              <li>
                • However, if an ㅇ (ieung) follows a t-stop letter, then the
                normal sound is simply carried over:
              </li>
              <p>Example: 맛이 (Maasi, as if it were spelled “마시”)</p>
              <li>
                • If a character ending with ‘ㅅ’ is followed by another word
                that begins with ‘ㅇ’, the t-stop sound is carried over:
              </li>
              <p>
                Example: 첫 인상 (Chaw-Tin-saang) instead of (Chawt-In-Saang)
              </p>
              <li>
                <h3>Final Consonants Pronounciation</h3>
              </li>
              <p>
                Now moving onto the Final Consonants, these come at the end of a
                Korean character and always after a vowel. Here is a list of the
                different final consonants and the sounds that they make at the
                final position:
              </p>
            </ul>
            <table style={{ width: '75.862%', textAlign: 'center' }}>
              <tbody>
                <tr>
                  <td style={{ width: '11.9319%' }}>
                    <p>
                      <span style={{ fontSize: '18pt' }}>
                        <strong>KOREAN</strong>
                      </span>
                    </p>
                  </td>
                  <td style={{ width: '13.029%' }}>
                    <p style={{ textAlign: 'center' }}>
                      <span style={{ fontSize: '18pt' }}>
                        <strong>ENGLISH</strong>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: 'center',
                      width: '198',
                    }}
                  >
                    <p style={{ textAlign: 'center' }}>ㄱ</p>
                  </td>
                  <td style={{ width: '13.029%', textAlign: 'center' }}>
                    <p>K</p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: 'center',
                      width: '198',
                    }}
                  >
                    <p>
                      <strong>
                        <span style={{ color: '#ff0000' }}>ㄲ</span>
                      </strong>
                    </p>
                  </td>
                  <td style={{ width: '13.029%', textAlign: 'center' }}>
                    <p>K</p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: 'center',
                      width: '198',
                    }}
                  >
                    <p>ㅋ</p>
                  </td>
                  <td style={{ width: '13.029%', textAlign: 'center' }}>
                    <p>K</p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: 'center',
                      width: '198',
                    }}
                  >
                    <p>ㄴ</p>
                  </td>
                  <td style={{ width: '13.029%', textAlign: 'center' }}>
                    <p>N</p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: 'center',
                      width: '198',
                    }}
                  >
                    <p>ㄷ</p>
                  </td>
                  <td style={{ width: '13.029%', textAlign: 'center' }}>
                    <p>D</p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: 'center',
                      width: '198',
                    }}
                  >
                    <p>ㄹ *</p>
                  </td>
                  <td style={{ width: '13.029%', textAlign: 'center' }}>
                    <p>L</p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: 'center',
                      width: '198',
                    }}
                  >
                    <p>ㅁ</p>
                  </td>
                  <td style={{ width: '13.029%', textAlign: 'center' }}>
                    <p>M</p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: 'center',
                      width: '198',
                    }}
                  >
                    <p>ㅇ</p>
                  </td>
                  <td style={{ width: '13.029%', textAlign: 'center' }}>
                    <p>NG</p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: 'center',
                      width: '198',
                    }}
                  >
                    <p>ㅍ</p>
                  </td>
                  <td
                    style={{ width: '13.029%', textAlign: 'center' }}
                    rowSpan="2"
                  >
                    <p> </p>
                    <p>P</p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: 'center',
                      width: '198',
                    }}
                  >
                    <p>ㅂ</p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: 'center',
                      width: '198',
                    }}
                  >
                    <p>ㅌ</p>
                  </td>
                  <td
                    style={{ width: '13.029%', textAlign: 'center' }}
                    rowSpan="6"
                  >
                    <p> </p>
                    <p> </p>
                    <p> </p>
                    <p>T</p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: 'center',
                      width: '198',
                    }}
                  >
                    <p>ㅈ</p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: 'center',
                      width: '198',
                    }}
                  >
                    <p>ㅅ</p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: 'center',
                      width: '198',
                    }}
                  >
                    <p>ㅆ</p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: 'center',
                      width: '198',
                    }}
                  >
                    <p>ㅊ</p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: 'center',
                      width: '198',
                    }}
                  >
                    <p>ㅎ</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <h2 className="u-margin-bottom-medium">Things to Remember!</h2>
            <ul>
              <li>
                Remember that the position of the last consonant(s) is called
                “Batchim” (받침) and it means “supporter”.
              </li>
              <li>
                The sounds of some consonants change depending on whether they
                appear at the beginning, in the middle, or at the end of a
                syllable.
              </li>
              <li>
                We cannot start a Syllable with a vowel but we can start a
                syllable with “o” which acts as a constant and then add a vowel
                so in this case “o” will have no sound and will be silent. Ex:
                안녕
              </li>
              <li>
                All the vertical drawn vowels will always be used in below
                structure :
              </li>
              <div style={{ marginTop: '50px' }}>
                <img
                  style={{ width: '10%', marginTop: '-30px' }}
                  src={Chart1}
                  alt="saturday-in-spanish"
                />
                <img
                  style={{ width: '10%', marginTop: '-30px' }}
                  src={Chart4}
                  alt="saturday-in-spanish"
                />
                <img
                  style={{ width: '10', marginTop: '-30px' }}
                  src={Chart5}
                  alt="saturday-in-spanish"
                />
              </div>
              <li>
                All the Horizontal drawn Vowels will always be used in the below
                structure:
              </li>
              <div style={{ marginTop: '50px' }}>
                <img
                  style={{ width: '10%', marginTop: '-30px' }}
                  src={Chart2}
                  alt="saturday-in-spanish"
                />
                <img
                  style={{ width: '10%', marginTop: '-30px' }}
                  src={Chart3}
                  alt="saturday-in-spanish"
                />
              </div>
            </ul>
            <h3>Also read:</h3>
            <p>
              <a href="/korean-numbers/">Numbers in Korean</a>
            </p>
          </div>
          <div className="article__blog-container__bottom">
            <div className="article__blog-container__bottom__left">
              <Link to="/">
                <img
                  className="article__blog-container__bottom__image"
                  src={Language}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>

              <Link to="/korean-numbers/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Numbers in Korean
                </li>
              </Link>
              <Link to="/korean-sentence-structure/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Sentence Structure in Korean
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default koreanLetters;
