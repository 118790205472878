import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

import '../sass/main.scss';
import Image from '../assets/months-in-spanish.jpg';
import Image1 from '../assets/learn-months-spanish.jpg';
import Image2 from '../assets/spanish-months.jpg';
import Spanish from '../assets/spanish_course_bottom.png';

const spanishMonths = () => {
  const head = () => (
    <MetaTags>
      <title>
        Learn Months in Spanish in just 5 minutes using related words |
        LinguaPsych
      </title>
      <meta
        name="description"
        content="New to Spanish? Curious to know about the months in Spanish? Welcome to the easy guide to learn months in Spanish, learn the related words and grammar."
      />{' '}
      <link rel="canonical" href="https://linguapsych.com/months-in-spanish/" />
      <meta
        property="og:title"
        content="New to Spanish? Curious to know about the months in Spanish? Welcome to the easy guide to learn months in Spanish, learn the related words and grammar."
      />{' '}
      <meta
        property="og:description"
        content="New to Spanish? Curious to know about the months in Spanish? Welcome to the easy guide to learn months in Spanish, learn the related words and grammar."
      />{' '}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        href="https://linguapsych.com/months-in-spanish/"
      />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/months-in-spanish.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/months-in-spanish.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}

      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_SPANISH_COURSE}`}>
          <div className="article__spanish-banner">
            <div className="article__spanish-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="When to use Preterite ve Imperfect"
            className="article__featured-image"
          />
          <div className="article__header-elements">
            <h1 className="article__title ">
              {' '}
              <span
                style={{ fontSize: '5rem' }}
                className="article__highlight--black "
              >
                {' '}
                Learn Months In Spanish
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <p>
              Do you want to know how to name months and seasons in Spanish?
              well, we’re going to do just that today. In this lesson, we will
              learn all the Spanish months and seasons along with their
              pronunciation and abbreviations. Just knowing how to say is not
              enough, today you’ll be able to talk about months and seasons and
              also be able to spell them.
            </p>
            <p>
              As we all know, there are twelve months in a year; January,
              February, March, April, May, June, July, August, September,
              October, November & December. This is how we call it in English.
              Vocabulary in English and other Latin languages share a lot
              between them, and the same happens with name of the months as
              well. After this lesson, you’ll understand the similarities in
              both languages and it will be easier for you to learn the names of
              the month and also the seasons. Let’s start with the name of the
              months
            </p>
            <h2 className="u-margin-bottom-medium">
              What are the Months of the Year in Spanish?
            </h2>
            <p>
              The twelve months of the year in Spanish are written in the
              following order from January to December:
            </p>
            <div
              className="row"
              style={{
                backgroundColor: 'pink',
                color: 'black',
                textAlign: 'center',
              }}
            >
              <div className="col-1-of-2">January</div>
              <div className="col-1-of-2">enero</div>
            </div>
            <div
              className="row"
              style={{
                backgroundColor: 'pink',
                color: 'black',
                textAlign: 'center',
              }}
            >
              <div className="col-1-of-2">February</div>
              <div className="col-1-of-2">febrero</div>
            </div>
            <div
              className="row"
              style={{
                backgroundColor: 'pink',
                color: 'black',
                textAlign: 'center',
              }}
            >
              <div className="col-1-of-2">March</div>
              <div className="col-1-of-2">marzo</div>
            </div>
            <div
              className="row"
              style={{
                backgroundColor: 'pink',
                color: 'black',
                textAlign: 'center',
              }}
            >
              <div className="col-1-of-2">April</div>
              <div className="col-1-of-2">abril</div>
            </div>
            <div
              className="row"
              style={{
                backgroundColor: 'pink',
                color: 'black',
                textAlign: 'center',
              }}
            >
              <div className="col-1-of-2">May</div>
              <div className="col-1-of-2">mayo</div>
            </div>
            <div
              className="row"
              style={{
                backgroundColor: 'pink',
                color: 'black',
                textAlign: 'center',
              }}
            >
              <div className="col-1-of-2">June</div>
              <div className="col-1-of-2">junio</div>
            </div>
            <div
              className="row"
              style={{
                backgroundColor: 'pink',
                color: 'black',
                textAlign: 'center',
              }}
            >
              <div className="col-1-of-2">July</div>
              <div className="col-1-of-2">julio</div>
            </div>
            <div
              className="row"
              style={{
                backgroundColor: 'pink',
                color: 'black',
                textAlign: 'center',
              }}
            >
              <div className="col-1-of-2">August</div>
              <div className="col-1-of-2">agosto</div>
            </div>
            <div
              className="row"
              style={{
                backgroundColor: 'pink',
                color: 'black',
                textAlign: 'center',
              }}
            >
              <div className="col-1-of-2">September</div>
              <div className="col-1-of-2">septiembre</div>
            </div>
            <div
              className="row"
              style={{
                backgroundColor: 'pink',
                color: 'black',
                textAlign: 'center',
              }}
            >
              <div className="col-1-of-2">October</div>
              <div className="col-1-of-2">octubre</div>
            </div>
            <div
              className="row"
              style={{
                backgroundColor: 'pink',
                color: 'black',
                textAlign: 'center',
              }}
            >
              <div className="col-1-of-2">November</div>
              <div className="col-1-of-2">noviembre</div>
            </div>
            <div
              className="row"
              style={{
                backgroundColor: 'pink',
                color: 'black',
                textAlign: 'center',
              }}
            >
              <div className="col-1-of-2">December</div>
              <div className="col-1-of-2">deciembre</div>
            </div>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Image1}
                alt="learn-months-spanish"
                className="article__big-images"
                style={{ width: '60%' }}
              />
            </div>
            <h2 className="u-margin-bottom-medium">
              How to pronounce Months in Spanish?
            </h2>
            <p>
              Months in Spanish language are quite similar to the months name in
              English. So remembering this would not be tough for you and you
              will be able to easily grasp Spanish months.
            </p>
            <ul>
              <li>January is enero in Spanish, pronounced as “Aye-nero“</li>
              <li>
                February is febrero in Spanish, pronounced as “Feb-ray-ro“
              </li>
              <li>March is marzo in Spanish, pronounced as “Mar-zo“</li>
              <li>April is abril in Spanish, pronounced as “Aab-reel“</li>
              <li>May is mayo in Spanish, pronounced as “Maa-yo“</li>
              <li>June is junio in Spanish, pronounced as “Khoo-nio“</li>
              <li>July is julio in Spanish, pronounced as “Khoo-leo“</li>
              <li>August is agosto in Spanish, pronounced as “Ah-gosto“</li>
              <li>
                September is septiembre in Spanish, pronounced as “Sep-t-m-bray“
              </li>
              <li>
                October is octubre in Spanish, pronounced as “Okh-too-bray“
              </li>
              <li>
                November is noviembre in Spanish, pronounced as “No-v-m-bray“
              </li>
              <li className="u-margin-bottom-medium">
                December is deciembre in Spanish, pronounced as “Day-c-m-bray“
              </li>
            </ul>
            <h2 className="u-margin-bottom-medium">
              Month Abbreviation in English
            </h2>
            <p>
              Most of the time we use abbreviations for months. Even if the name
              of the month is not that large, we are used to using
              abbreviations. Yes we are that lazy!
            </p>
            <p>First let us see what are abbreviation for months in English.</p>
            <ul>
              <li>January – Jan.</li>
              <li>February – Feb.</li>
              <li>March – Mar.</li>
              <li>April – Apr.</li>
              <li>May – May</li>
              <li>June – June</li>
              <li>July – July</li>
              <li>August – Aug.</li>
              <li>September – Sept</li>
              <li>October – Oct.</li>
              <li>November – Nov.</li>
              <li className="u-margin-bottom-medium">December – Dec.</li>
            </ul>
            <p>
              Usually, in English months abbreviation are written as first 3
              letters of the month. Exceptions being May, June and July; since
              they are already three or four letter words. So no point
              abbreviating them.
            </p>
            <h2 className="u-margin-bottom-medium">
              Month Abbreviation in Spanish
            </h2>
            <p>
              Moving on to the month abbreviations in Spanish, they are almost
              similar to those in English. you just need to know month names in
              Spanish. Let us look into the abbreviation for January, February,
              March, April, May, June, July, August, September, October,
              November and December.
            </p>
            <ul>
              <li>enero – enero.</li>
              <li>febrero – feb.</li>
              <li>marzo – mar.</li>
              <li>abril – abr.</li>
              <li>mayo – mayo.</li>
              <li>junio – jun.</li>
              <li>julio – jul.</li>
              <li>agosto – agosto.</li>
              <li>septiembre – sept/set.</li>
              <li>octubre – oct.</li>
              <li>noviembre – nov.</li>
              <li className="u-margin-bottom-medium">deciembre – dic.</li>
            </ul>
            <p>
              In Spanish, abbreviation for enero, mayo and agosto is the entire
              word itself.
            </p>
            <h2 className="u-margin-bottom-medium">
              Related Vocabulary you must know
            </h2>
            <p>
              At this stage, we know how to say the months of year in Spanish,
              but knowing that is not enough if you want to make sentences
              related to this topic. Below are some very useful words which are
              going to be used very often with the months in Spanish. I have
              added some examples too, so you won’t find it difficult to
              understand the context.
            </p>
            <ul>
              <li>Year</li>
              <p>
                Year is el año in Spanish. It’s article is el, as it is a
                masculine noun. The plural of año will be los años. We talk
                about years very frequently; while remembering old memories, or
                planning about future, or in your birthdays. Let us look at it’s
                examples
              </p>
              <p>El año tiene 365 días. (One year has 365 days.)</p>
              <li>Leap Year</li>
              <p>
                Leap year is called el año bisiesto in Spanish. It’s article is
                el, since it is masculine. The plural of el año bisiesto will be
                los años bisiestos. Here’s an example
              </p>
              <p>
                Algunas veces el año tiene 366 días, se llama el año bisiesto
                (Sometimes the year also has 365 days. It’s called leap year.)
              </p>
              <li>Month</li>
              <p>
                Month is called el mes in Spanish. It’s article is el, since it
                is masculine. The plural of el mes will be los meses. Here’s an
                example to this
              </p>
              <p>el mes tiense cuatros semanas (A month has 4 weeks)</p>
              <li>Week</li>
              <p>
                Week is la semana in Spanish. It’s article is la, since it is
                feminine. The plural of la semana will be las semanas.
              </p>
              <p>
                Siete días son un mes. (Seven days is one week.) Check out the
                complete guide here
              </p>
              <li>Day</li>
              <p>
                Day is el día in Spanish. It’s article is el, since it is
                masculine. The plural of Tag will be los días
              </p>
              <p>El día tiene 24 horas. (A day has 24 hours.)</p>
              <li>Season</li>
              <p>
                Season is la estación in Spanish. It’s article is la, since it
                is feminine. The plural of estación will be las estaciónes.
              </p>
              <p>el año tiene cuatro estaciónes (One year has four seasons.)</p>
              <h2 className="u-margin-bottom-medium">
                Related Grammar you must know
              </h2>
              <p>
                We have learnt all the days in Spanish. Now we should also know
                some grammar related to this topic.
              </p>
              <li>Capitalized Nouns</li>
              <p>
                Spanish uses fewer capitalized nouns than English, when Spanish
                capitalizes a word, English does the same thing to that word
                but, opposite to that is not true, Spanish doesn’t capitalize
                all the words that English does. So some nouns that are
                capitalized in Spanish are proper nouns for people, places,
                newspapers, personal titles, name of the Sun and the Moon, first
                word in the titles for example movies, books, albums etc.
              </p>
              <li>Gender of Spanish months</li>
              <p>
                All the months of the year are Masculine and not capitalized. On
                the other hand, it is not very common to use the definite
                articles with months in Spanish. When you’re talking about the
                month “mes” you’ll use the article “el” but not with name of the
                months. Let’s look at some examples
              </p>
              <p>“El mes de febrero es corto.”</p>
              <p>“Junio y julio son calientes”.</p>

              <li>To ask “what month of the year is it”</li>
              <p>
                To ask someone what day is it today, you can ask “¿Qué mes del
                año es? or “¿Qué mes es esto?”
              </p>
              <li>To say “on January/February”</li>
              <p>
                How would you say “I will go to Spain in January”? The answer is
                simple. For saying in January, we use the preposition “en”
                before the month. There is no other preposition that we use with
                months so using the preposition “en” will always work.
              </p>
              <p>So it becomes, “iré al España en lunes“</p>
              <p>
                Another example: En febrero es San Valentín. (In February, it is
                Valentine’s Day)
              </p>
              <li>To say from…to…</li>
              <p>
                Do you know how to say “From March to June, I will go to
                school”?
              </p>
              <p>
                It is very simple. de is used for “from” and a is used for
                “to/till”.
              </p>
              <p>
                iré a la escuela de marzo a junio. (From March to June, I will
                go to the school)
              </p>
            </ul>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Image2}
                alt="learn-months-spanish"
                className="article__big-images"
                style={{ width: '60%' }}
              />
            </div>
            <h2 className="u-margin-bottom-medium">
              How to write dates in Spanish?
            </h2>
            <p>
              Writing dates in Spanish is quite different from writing them in
              English. First thing to notice is that the days are written before
              the months followed by the year. Once you get used to it, it’ll be
              walk in the park. Let’s start
            </p>
            <ol>
              <li>Date before the month</li>
              <p>
                In Spanish, unlike English, you need to put the days first
                before the month and the year. Use the definite article “el”
                before the dates, you can use period, dash or slash to separate
                the three of them. For example if you want to write the date May
                18th, 1993, you can write “18-05-1993” or “18/05/1993” instead.
              </p>
              <li> Use the preposition “de” as a separator</li>
              <p>
                When you want to call out the dates, let’s say “18/05/1993”, as
                a separator you’ll use the preposition “de”, lets’s see how
                you’ll call it out. You’ll use the article “el” first, and the
                date will be called out as “el dieciocho de mayo de 1993“. Don’t
                forget to take the name of the month rather calling it by the
                number of the month.
              </p>
              <li> Don’t capitalize the name of the month</li>
              <p>
                I have mentioned this above as well, I’m repeating it as is the
                most common mistake that English speakers do. Unlike in English,
                Spanish months are never capitalized until it is the first word
                of the sentence. so, the date will be “18 de mayo” and not “18
                de Mayo”.
              </p>
              <li> Using primero for 1st of any month</li>
              <p>
                In English, if the date is 1st, we say first of January, first
                of February etc,. In Spanish as well, if the date is 1st of any
                month, you’ll say “el primero de enero”, “el primero de marzo”
                etc.
              </p>
              <li>Learning the abbreviations</li>
              <p>
                Learning the abbreviations is very useful in Spanish, I have
                mentioned the abbreviation for each month, whenever you want to
                use the shortened form of a date, use the abbreviations such as
                “11-jul-1993”, “18/feb/2003” etc to make it handy.
              </p>
            </ol>
            <h2 className="u-margin-bottom-medium">What did we learn?</h2>
            <p>
              In this lesson, we have learnt the complete list of months of the
              year in order in German and also the seasons in German language.
              This lesson should be on your tips. Let us recall what we have
              done.
            </p>
            <ul>
              <li>
                There are 12 months in a year; enero, Febrero, marzo, abril,
                mayo, junio, julio, agosto, septiembre, octobre, noviembre,
                deciembre.
              </li>
              <li>
                The month abbreviation are enero, feb, mar, abr, mayo, Jun, Jul,
                agosto, sept, oct, nov, dic.
              </li>
              <li>
                We also did what is year, leap year, month, week, day and season
                called in Spanish.
              </li>
              <li className="u-margin-bottom-medium">
                How to write dates in Spanish?
              </li>
            </ul>
            <p>
              So now do you feel confident with Spanish months? If you know
              this, you can jump to the next step in learning Spanish language.
            </p>
          </div>
          <div className="article__blog-container__bottom">
            <div
              className="article__blog-container__bottom__left"
              to="/learn-spanish"
            >
              <Link to="/learn-spanish">
                <img
                  className="article__blog-container__bottom__image"
                  src={Spanish}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/spanish-definite-indefinte-articles/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Definite & Indefinite Articles
                </li>
              </Link>
              <Link to="/subject-personal-pronouns-in-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Subject Personal Pronouns
                </li>
              </Link>

              <Link to="/spanish-nouns-articles-genders/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Nouns, articles and Genders
                </li>
              </Link>
              <Link to="/spanish-alphabets-accents-pronunciation/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Alphabets in Spanish
                </li>
              </Link>
              <Link to="/possessive-adjectives-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Possessive Adjectives
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default spanishMonths;
