import React from 'react';
import { Link } from 'react-router-dom';

import '../sass/main.scss';
import MetaTags from 'react-meta-tags';
import Image from '../assets/spanish-articles.png';
import Table from '../assets/spanish-articles-table.PNG';
import Table2 from '../assets/spanish-articles-table-2.PNG';
import Table3 from '../assets/spanish-articles-table-3.PNG';
import Spanish from '../assets/spanish_course_bottom.png';

const spanishArticles = () => {
  const head = () => (
    <MetaTags>
      <title>
        5 easy ways to understand Spanish nouns &amp; Articles | LinguaPsych
      </title>
      <meta
        name="description"
        content="You&#039;ll be learning the basic concept behind Spanish nouns. Here are 5 easy ways to remember all the nouns with it&#039;s articles. Lesson is for complete beginners."
      />{' '}
      <link
        rel="canonical"
        href="https://linguapsych.com/spanish-nouns-articles-genders/"
      />
      <meta
        property="og:title"
        content="You&#039;ll be learning the basic concept behind Spanish nouns. Here are 5 easy ways to remember all the nouns with it&#039;s articles. Lesson is for complete beginners."
      />{' '}
      <meta
        property="og:description"
        content="You&#039;ll be learning the basic concept behind Spanish nouns. Here are 5 easy ways to remember all the nouns with it&#039;s articles. Lesson is for complete beginners."
      />{' '}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        href="https://linguapsych.com/spanish-nouns-articles-genders/"
      />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/spanish-articles.png`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/spanish-articles.png`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}

      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_SPANISH_COURSE}`}>
          <div className="article__spanish-banner">
            <div className="article__spanish-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="spanish-articles"
            className="article__featured-image"
          />
          <div className="article__header-elements">
            <h1 className="article__title ">
              {' '}
              <span
                style={{ fontSize: '5rem' }}
                className="article__highlight--black "
              >
                {' '}
                Spanish Nouns Genders and Articles
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <p>
              We all have heard that Spanish is the easiest language to learn
              for English speakers. Although, I can’t deny to the fact but there
              are certain things which all of us English speakers need to
              unlearn before learning Spanish.
            </p>
            <p>
              Starting with what noun is, it is a name which is used to identify
              any place, person or a thing. Like in any other language, a noun
              is either singular or plural. But here’s a fun part, Spanish nouns
              have genders. They are either masculine or feminine. Masculine
              article is “el” and feminine article is “la”.
            </p>
            <p>
              I will strongly recommend you guys to learn a noun with the
              article. For example, if you learn the word ‘bicicleta’ which
              means bicycle in Spanish, you should know that it is a feminine
              word which will always be used with the article ‘La’.
            </p>
            <p>For example :-</p>
            <p>English – I love Bicycle</p>
            <p>Spanish – Me gusta la bicicleta</p>
            <p>
              If you use ‘ me gusta bicicleta’ without the article it is not
              going to sound natural. I know it’s a very new thing for you guys
              and it’s not going to be very easy to remember all the nouns with
              the gender or article. But don’t worry I’m going to tell you the
              shortcuts which will help you to learn the Spanish nouns with
              their respective articles and it will be come out naturally.
              Sounds great right?
            </p>
            <p>So Let’s begin….</p>
            <h2 className="u-margin-bottom-medium">
              Spanish nouns ending in o
            </h2>
            <p>
              In Spanish, nouns that end with the alphabet o are generally
              masculine nouns. Although, there are some exceptions but we are
              going to discuss that later in the lesson. So let’s see how it
              works
            </p>
            <p>The list of masculine nouns in Spanish that end with o.</p>
            <h3 style={{ textAlign: 'center' }}>
              Masculine Spanish nouns ending with o
            </h3>

            <div style={{ textAlign: 'center' }}>
              <p>La mano - The hand</p>
              <p>La radio - The radio</p>
              <p>La foto - The photo</p>
              <p>La moto - The bike</p>
              <p>La polio - The polio</p>
            </div>

            <p>
              So most of the nouns in Spanish that end with o are masculine. Did
              I say most of them ending with O and not every one of them? COOL..
              In Spanish we have some exceptions where some nouns that end with
              o are feminine in nature and there is no theory behind it but just
              the nature. So here are some of the Spanish nouns that end with O
              which are feminine.
            </p>
            <h3 style={{ textAlign: 'center' }}>
              Feminine Nouns that end with o
            </h3>
            <div style={{ textAlign: 'center' }}>
              <p>La mano - The boy</p>
              <p>El libro - The book</p>
              <p>El hijo - The son</p>
              <p>El perro - The dog</p>
              <p>El vaso - The glass</p>
              <p>El carro - The car</p>
              <p>El pelo - The hair</p>
            </div>
            <h2 className="u-margin-bottom-medium">
              Spanish nouns ending in a
            </h2>
            <p>
              In Spanish, nouns that end with the alphabet a are generally
              feminine nouns in Spanish. Although there are some exceptions but
              we are going to discuss that later in the lesson. So below is
            </p>
            <p>The list of nouns in Spanish that end with a.</p>
            <h3 style={{ textAlign: 'center' }}>
              Feminine nouns that end with a
            </h3>
            <div style={{ textAlign: 'center' }}>
              <p>La lampara - The lamp</p>
              <p>La hermana - The sister</p>
              <p>La perra - The female dog</p>
              <p>La hija - The daughter</p>
              <p>La playa - The beach</p>
              <p>La mesa - The table</p>
              <p>La silla - The chair</p>
            </div>
            <p>
              Again most of the nouns that end with A are feminine. I remember
              saying that this time.. I know it is too much to remember at once
              but trust me there are very few exceptions and it won’t be a
              problem as you’ll start using them very soon.
            </p>
            <p>So below is the list of masculine nouns that end with a</p>
            <h3 style={{ textAlign: 'center' }}>
              Masculine nouns that end with a
            </h3>
            <div style={{ textAlign: 'center' }}>
              <p>El Sistema - The system</p>
              <p>El problema - The problem</p>
              <p>El agua - The water</p>
              <p>El mapa - The map</p>
              <p>El tema - The theme</p>
              <p>El clima - The climate</p>
              <p>El idioma - The language</p>
            </div>
            <p>
              Well, while looking at the table above you must be assuming that
              all the Spanish nouns ending with –ma must be masculine. But
              unfortunately, even here we have exceptions, la forma which means
              ‘the form’ is a feminine noun.
            </p>
            <p>
              So it’s all about learning the basic rules and gulping down the
              exceptions.
            </p>
            <h2 className="u-margin-bottom-medium">
              Spanish Nouns ending for Professions
            </h2>
            <p>
              As we know there are professions which involve two genders and if
              we want to address those professionals with the professions, how
              are we going to call them? Good question.. There are a lot of
              profession nouns that change their endings depending on the
              gender, remember that masculine nouns end with o and feminine
              nouns with a?
            </p>
            <p>So now we are going to use them..</p>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table}
                alt="spanish-articles-table"
                className="article__big-images"
              />
            </div>
            <p>
              Now you must be wondering that you can change the gender of the
              profession nouns by alternating their endings. But unfortunately
              that is not the case. Let’s look at the next point below
            </p>
            <h2 className="u-margin-bottom-medium">
              Spanish Nouns that don't change gender
            </h2>
            <p>
              I know it is too much for the first lesson but bare with me for
              some more time..
            </p>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table2}
                alt="spanish-articles-table-2"
                className="article__big-images"
              />
            </div>
            <h2 className="u-margin-bottom-medium">
              Spanish Nouns ending with -dad, -z and -ión
            </h2>
            <p>
              Most of the nouns used in daily conversation that end with -dad,
              -z and -ión are usually feminine. Although there are some
              exceptions but before that it is very important to learn the rule
              above because 90% of the times you’ll come across the nouns that
              end with dad, -z and -ión will be feminine.
            </p>
            <p>Here are some examples.</p>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table3}
                alt="spanish-articles-table-3"
                className="article__big-images"
              />
            </div>
            <h2 className="u-margin-bottom-medium">Conclusion</h2>
            <p>
              So in this lesson we learned 5 easy ways to remember the articles
              or gender of the Spanish nouns.
            </p>
            <ol>
              <li>
                We learned that most of the Spanish nouns that end with O were
                masculine in nature, we also looked at some exceptions.
              </li>
              <li>
                We learned that most of the Spanish nouns that end with A were
                feminine in nature, we also looked at some exceptions.
              </li>
              <li>
                We looked at some Profession nouns that changed their endings
                with the gender associated to them.
              </li>
              <li>
                We also looked at some Professions that remained same despite
                the change in gender.
              </li>
              <li>
                We looked at some nouns that ended with -dad, -z and -ión. We
                learned that all these nouns were generally feminine in nature.
              </li>
            </ol>
            <p>
              I hope you liked the first lesson, in the next lesson we will
              learn how to make a noun plural and how the articles change when
              they are plural. Also check out our exclusive guide on
            </p>
            <ul>
              <li>
                <a href="/spanish-nouns-articles-genders/">
                  Spanish Direct and Indirect Object Pronouns
                </a>
              </li>
              <li>
                <a href="/subject-personal-pronouns-in-spanish/">
                  Complete guide on Subject Pronouns in Spanish
                </a>
              </li>
              <li>
                <a href="/spanish-definite-indefinte-articles/">
                  Guide to Spanish Definite and Indefinite Articles
                </a>
              </li>
            </ul>
          </div>
          <div className="article__blog-container__bottom">
            <div
              className="article__blog-container__bottom__left"
              to="/learn-spanish"
            >
              <Link to="/learn-spanish">
                <img
                  className="article__blog-container__bottom__image"
                  src={Spanish}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/spanish-days-of-the-week/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Days of the Week in Spanish
                </li>
              </Link>
              <Link to="/subject-personal-pronouns-in-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Subject Personal Pronouns
                </li>
              </Link>

              <Link to="/spanish-definite-indefinte-articles/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Definite and Indefinite Articles
                </li>
              </Link>
              <Link to="/preterite-vs-imperfect/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Preterite vs Imperfect
                </li>
              </Link>
              <Link to="/months-in-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Months in Spanish
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default spanishArticles;
