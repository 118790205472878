import React from 'react';
import { Link } from 'react-router-dom';

import '../sass/main.scss';
import MetaTags from 'react-meta-tags';
import Image from '../assets/german-umlaut.jpg';
import German from '../assets/german_course_bottom.png';

const germanUmlaut = () => {
  const head = () => (
    <MetaTags>
      <title>
        The Intensive Guide to German Umlaut with pronunciation, ä, ö, ü;
        Letters, Text | LinguaPsych
      </title>
      <meta
        name="description"
        content="Complete Guide to learn umlaut in German; ä, ö, ü; along with sound and pronunciation. Learn to write Germanic symbols with 2 dots. German a, o and u with accents."
      />

      <link rel="canonical" href="https://linguapsych.com/german-umlaut/" />
      <meta
        property="og:title"
        content="Complete Guide to learn umlaut in German; ä, ö, ü; along with sound and pronunciation. Learn to write Germanic symbols with 2 dots. German a, o and u with accents."
      />
      <meta
        property="og:description"
        content="Complete Guide to learn umlaut in German; ä, ö, ü; along with sound and pronunciation. Learn to write Germanic symbols with 2 dots. German a, o and u with accents."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" href="https://linguapsych.com/german-umlaut/" />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/german-umlaut.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/german-umlaut.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}
      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_GERMAN_COURSE}`}>
          <div className="article__german-banner">
            <div className="article__german-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="german-umlaut"
            className="article__featured-image"
          />
          <div className="article__header-elements">
            <h1 className="article__title ">
              {' '}
              <span className="article__highlight--black ">
                {' '}
                Umlauts in German: ä, ö, ü
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <p>
              Hey, are you in the process of learning German language? I would
              suggest to make all your basics clear in the initial days itself,
              so that once you reach intermediate or expert level, you should
              not be making basic German mistakes. In this lesson, we will be
              learning about umlaut in German language.
            </p>
            <p className="u-margin-bottom-medium">
              You know there are 26 letters in German alphabet. These 26 letters
              are the same as in English.Their pronunciation is a little
              different. They are very important to master German spelling,
              pronunciation and diphthongs. You can revise your alphabets :{' '}
              <a href="/german-alphabet-letters/">
                {' '}
                5 Fun Ways To Learn German Alphabet With Pronunciation And Sound
              </a>
            </p>
            <h2 className="u-margin-bottom-medium">What is an Umlaut?</h2>
            <p>
              Do you know what is an Umlaut? In German, we have 3 extra letters
              ä, ö, ü. They may seem like normal vowels a, o, u. But these
              German characters are accompanied by two dots on top. The two dots
              above a letter are called Umlaut in German. Do you know how to
              pronounce the word “umlaut”? Umlaut is pronounced as “oom-laaoot”.
            </p>
            <p>
              Only these three letters, a, o and u, have the privilege of having
              those two dots on tops. So it becomes ä, ö and ü. It’s like a
              crown on top of these vowels. You cannot put umlauts on just any
              alphabet. There are five vowels; a, e, i, o and u. But you would
              not see any e umlaut of i umlaut. Umlaut e and i do not exist in
              German. You will see norm e and i but not i and e with an umlaut.
              Dots are called “Punkte” in German.
            </p>
            <p className="u-margin-bottom-medium">
              Wir haben zwei Punkte auf A, “ä”. (We have two dots on A.)
            </p>
            <h2 className="u-margin-bottom-medium">How to type an Umlaut?</h2>
            <p>
              To English speakers it may seem tricky, how to add those 2 dots
              over a letter to make them German Umlaut. But trust me, it is very
              simple. Just a simple trick and an algorithm and you will master
              how to type Umlauts. But remember to use umlauts wisely and not
              use them with letters other than a, o, u. Umlaut has the power to
              change the meaning of a word.
            </p>
            <p>
              In some laptops, these German symbols are inbuilt. They are
              present in the keyboard. Keyboards in Germany, already have umlaut
              letters. But in other laptops where such German symbol are not
              present, how to type a, o, u with two dots?
            </p>
            <ul style={{ textAlign: 'left' }}>
              <li>
                One way is to simply buy a German umlaut keyboard, which will
                have inbuilt German special characters. This will solve all your
                problems related to typing in German. If your work requires
                frequently typing in German, I would recommend you to buy a
                German keyboard if it is feasible. This will simplify your work.
              </li>
              <li>
                Another way to write umlaut in word is by using a combination of
                keys on your normal keyboard.
              </li>
              <ul
                style={{
                  marginLeft: '80px',
                  marginTop: '20px',
                  fontWeight: 'bold',
                }}
              >
                <li>a Umlaut (ä)</li>
                <p>Use key Alt + 0228</p>
                <li>A Umlaut (Ä)</li>
                <p>Use key Alt + 0196</p>
                <li>o Umlaut (ö)</li>
                <p>Use key Alt + 0246</p>
                <li>O Umlaut (Ö​)</li>
                <p>Use key Alt + 0214</p>
                <li>u Umlaut (ü​​​)</li>
                <p>Use key Alt + 0252</p>
                <li>U Umlaut (Ü​​​)</li>
                <p>Use key Alt + 0220</p>
              </ul>
              <li>
                If you still find it a task a write two dots above a letter, you
                can simply write “e” after the alphabets a, o and u. This is the
                easiest method to write a, o and u accent in German. Thus,
              </li>
              <ul
                style={{
                  marginLeft: '80px',
                  marginTop: '20px',
                  fontWeight: 'bold',
                }}
              >
                <li>a with two dots can be written as “ae“</li>
                <p>Äpfel can be written as Aepfel</p>
                <li>o with two dots can be written as “oe“</li>
                <p>Öffnen can we written as Oeffnen</p>
                <li>u with two dots can be written as “ue“</li>
                <p>Mülleimer can be written as Muelleimer</p>
              </ul>
            </ul>
            <p>
              Now I hope it won’t be tough for you to write a with umlaut, o
              with umlaut and u with umlaut. It might take you some time to
              remember these tricks but it is not that tough at all. By
              practicing it a number of times you can easily master how to write
              dots over letters.
            </p>
            <h2 className="u-margin-bottom-medium"> When to use an Umlaut?</h2>
            <p>
              German umlauts are an important part of German language. It is
              very important to distinguish between the meaning of words with
              umlaut and without umlaut. There are many cases where umlauts are
              used, but let us look at some of the scenarios when umlauts are
              used:
            </p>
            <ol style={{ textAlign: 'left' }}>
              <li style={{ fontWeight: 'bold' }}>Some verbs in Present form</li>
              <p>
                There are certain verbs which in their present form, make use of
                umlaut. Their conjugations also involve umlauts. You must be
                able to analyse the form of the verb by looking at the verbs
                with umlaut. Please note this is not a compulsion that every
                verb in present tense will have umlauts. Some modal verbs also
                have umlauts. Let us look at some of the examples:
              </p>
              <p>
                – Modal verbs like müssen (to have to/must), können (can/to be
                able to), dürfen (are allowed to), mögen (to like)
              </p>
              <p>
                – Other verbs like betrügen (to cheat), erlöschen (to
                expire/lapse), erwägen (to consider), hängen (to hang)
              </p>
              <li style={{ fontWeight: 'bold' }}>Plural Nouns</li>
              <p>
                Some nouns are written in their plural form with with an accent
                on top. Whenever you learn nouns in German, you come across
                their plural forms and you would have noticed that most of them
                have an umlaut. But remember not all of them use umlauts to make
                plural. Let us look at some of the examples:
              </p>
              <p>der Apfel becomes die Äpfel</p>
              <p>der Satz becomes die Sätze</p>
              <p>der Fuß becomes die Füße</p>
              <p>der Schwamm becomes deie Schwämme</p>
              <li style={{ fontWeight: 'bold' }}>
                Comparative and Superlative form
              </li>
              <p>
                Many adjectives form Kompartativ and Superlativ forms with
                umlauts in German. Comparative form is used in adjectives to
                compare two things (for example, older man, healthier heart,
                etc.). Superlative form is used to say highest or least of
                something (for example, oldest man, healthiest heart, etc.). You
                will notice a, o and u with dots in many comparative and
                superlative forms. Let us understand with some examples:
              </p>
              <p>alt (old) – älter (older) – am ältesten (oldest)</p>
              <p>
                gesund (healthy) – gesünder (healthier) – am gesündesten
                (healthiest)
              </p>
              <p>groß (big) – größer (bigger) – am größten (biggest)</p>
              <p>lang (long) – länger (longer) – am längsten (longest)</p>
              <p>warm (warm) – wärmer (warmer) – am wärmsten (warmest)</p>
            </ol>
            <p>
              {' '}
              These are the most common scenarios where you would discover
              umlauts in German. There can be other cases as well with such
              German sign. You would also notice German double s, it is called
              eszet in German. It is not part of umlauts.
            </p>
            <h2 className="u-margin-bottom-medium">
              {' '}
              How to pronounce German Umlauts?
            </h2>
            <p>
              The umlaut pronunciation in German does not exist in English
              language. But that does not mean it will be tough. Trust me, they
              are not really strange. These German accents are unique in this
              language. We will make use of examples to understand the sounds of
              umlauts.
            </p>
            <h3 style={{ fontWeight: 'bold', color: 'black' }}>Umlaut a (ä)</h3>
            <p>
              You know how to pronounce a in German. German a is pronounced as
              “aaa”; for example, the sound of a in “art”. German word Apfel
              will be pronounced as “aapfel”.
            </p>
            <p>
              Now you will be able to distinguish between a and a with two dots.
              ä will be pronounced as “eh”; for example, a in “age”, a in “air”,
              a in “aid” in English. Now let us look at some German words with ä
              sound:
            </p>
            <ul style={{ textAlign: 'left' }}>
              <li>Gäste</li>
              <p>Wir haben Gäste zu Hause. (We have guests at home.)</p>
              <li>Länder</li>
              <p>
                Ich bin in viele Länder gereist. (I have traveled to many
                countries)
              </p>
              <li>Spät</li>
              <p>Wir sind spät. (We are late.)</p>
            </ul>
            <p>
              While pronouncing words having a with an umlaut, your mouth opens
              up a bit and air comes out of your mouth. Try speaking more and
              more words having a with dots over it. You will be able to grasp
              German accent slowly.
            </p>
            <h3 style={{ fontWeight: 'bold', color: 'black' }}>Umlaut o (ö)</h3>
            <p>
              You know how to pronounce o in German. German o is pronounced as
              “oh”; for example, the sound of o in “told”. German word Brot will
              be pronounced as “Broht”; it sound more like English word ‘boat’
              but with r in it.
            </p>
            <p>
              Now you will be able to distinguish between o and o with two dots.
              ö will be pronounced as “eoh”. Let us look at some German words
              with ö sound:
            </p>
            <ul style={{ textAlign: 'left' }}>
              <li>Töpfe</li>
              <p>Mein Freund hat 3 Töpfe. (My friend has 3 pots.)</p>
              <li>können</li>
              <p>
                Wir können den wind nicht ändern. (We can not change the wind.)
              </p>
              <li>Schön</li>
              <p>Es ist schön dich zu treffen. (It is nice to meet you.)</p>
            </ul>
            <p>
              {' '}
              While pronouncing words having o with an umlaut, your mouth rolls
              up in front and you take air inside your mouth. Your mouth would
              look just like when you say informal “awww”. You can learn it by
              taking two sounds; first sound of e comes then o. Try speaking
              more and more words having o with dots over it.
            </p>
            <h3 style={{ fontWeight: 'bold', color: 'black' }}>Umlaut u (ü)</h3>
            <p>
              You know how to pronounce u in German. German u is pronounced as
              “ooo”; for example, the sound of u in “mood”. German word Gruppe
              will be pronounced as “Grooppe”.
            </p>
            <p>
              Now you will be able to distinguish between u and u with two dots.
              ü will be pronounced as the English word “you”; for example, u in
              “cute”. Let us look at some German words with ü sound:
            </p>
            <ul style={{ textAlign: 'left' }}>
              <li>Stück</li>
              <p>Ich möchte 1 Stück Kuchen. (I want 1 piece of cake.)</p>
              <li>üben</li>
              <p>
                Sie sollten für Ihre Tanzperformance üben. (You should practice
                for your dance performance.)
              </p>
              <li>Stühle</li>
              <p>
                Wie viele Stühle haben wir im Unterricht? (How many chairs do we
                have in class?)
              </p>
            </ul>
            <p>
              While pronouncing words having u with an umlaut, your mouth rolls
              up in front and air comes out of your mouth. You mouth would roll
              up just like while kissing someone. Try speaking more and more
              words having u with dots over it.
            </p>
            <h2 className="u-margin-bottom-medium"> Quick Revision</h2>
            <p>
              Finally, we have mastered the concept of umlaut. I hope German
              umlaut should not bother you in future. Let us recall what all we
              have done in short:
            </p>
            <ul style={{ textAlign: 'left' }}>
              <li>
                What is an umlaut? An umlaut is a vowel with two dots over it.
                In German, we have 3 umlauten(plural of umlaut); a umlaut (ä), o
                umlaut (ö) and u umlaut (ü).
              </li>
              <li>
                How to type an umalaut? We have learnt 3 ways of typing an
                umlaut: first, to use German keyboard; second, to learn key
                combinations with alt; third, by replacing two dots and writing
                e after a, o or e (ae, oe,ue).
              </li>
              <li>
                When you should use an Umlaut? We use umlaut mainly in three
                situations: with some plurals, some verbs in present tense and
                in comparative, superlative forms.
              </li>
              <li>
                At the end we did umlaut pronunciation in German, which is very
                important for understanding sounds in German.
              </li>
            </ul>
            <p>
              This is the complete guide on umlauts in German. You can always
              refer this for any confusion relating to German accents in regard
              to umlauts.
            </p>
            <h2> Also Read :</h2>
            <p>
              <a href="/german-alphabet-letters/"> Alphabets in German</a>
            </p>
            <p>
              <a href="/german-months-seasons/">
                {' '}
                Months and Seasons in German
              </a>
            </p>
            <p>
              <a href="/time-in-german/"> Time in German </a>
            </p>
          </div>
          <div className="article__blog-container__bottom">
            <div
              className="article__blog-container__bottom__left"
              to="/learn-german"
            >
              <Link to="/learn-german">
                <img
                  className="article__blog-container__bottom__image"
                  src={German}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/german-alphabet-letters/">
                <li className="article__blog-container__bottom__posts__title__item">
                  German Alphabets & Letters
                </li>
              </Link>
              <Link to="/german-numbers/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Numbers in German
                </li>
              </Link>

              <Link to="/german-days-of-the-week/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Days of the Week in German
                </li>
              </Link>
              <Link to="/german-months-seasons/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Months & Seasons in German
                </li>
              </Link>

              <Link to="/time-in-german/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Time in German
                </li>
              </Link>
              <Link to="/german-colors/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Colors in German
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default germanUmlaut;
