import React, { useEffect, useState } from 'react';

import GetStarted from '../components/GetStarted';

// import { location } from '../actions/location';

const HomePrice = () => {
  const [country, setCountry] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  useEffect(() => {
    // location().then((data) => {
    //   if (data.error) {
    //     console.log(data.error);
    //   } else {
    //     setCountry(data.country_name);
    //   }
    // });
    getLocation();
    return;
  }, []);

  const getLocation = () => {
    fetch(
      'https://geolocation-db.com/json/09ba3820-0f88-11eb-9ba6-e1dd7dece2b8'
    )
      .then((response) => response.json())
      .then((data) => setCountry(data.country_name));
  };

  return (
    <>
      <div className="home-price">
        <GetStarted
          show={showModal}
          onCancel={() => closeModal()}
          contentClass="place-item__modal-content"
          footerClass="place-item__modal-actions"
        />
        <div className="home-price__top">Ready to level up in 6 Weeks?</div>
        <div className="home-price__cards">
          <div className="home-price__cards__left">
            <div className="home-price__cards__left__top">
              Complete German for Beginners in 6 Weeks
            </div>
            <div className="home-price__cards__left__second">
              What you'll learn?
            </div>
            <div className="home-price__cards__left__pointer">
              <div className="home-price__cards__left__pointer__left">
                &#10003;
              </div>
              <div className="home-price__cards__left__pointer__right">
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  {' '}
                  Get conversational in German by the end of the course.
                </span>
              </div>
            </div>
            <div className="home-price__cards__left__pointer">
              <div className="home-price__cards__left__pointer__left">
                &#10003;
              </div>
              <div className="home-price__cards__left__pointer__right">
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  {' '}
                  Level-up your language skills with a well-guided step-by-step
                  curriculum.
                </span>
              </div>
            </div>
            <div className="home-price__cards__left__pointer">
              <div className="home-price__cards__left__pointer__left">
                &#10003;
              </div>
              <div className="home-price__cards__left__pointer__right">
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  {' '}
                  Get personalized feedback from us on your weekly
                  assignments/projects.
                </span>
              </div>
            </div>
            <div className="home-price__cards__left__pointer">
              <div className="home-price__cards__left__pointer__left">
                &#10003;
              </div>
              <div className="home-price__cards__left__pointer__right">
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  {' '}
                  Dive into some cultural aspects of the language.
                </span>
              </div>
            </div>
            <div className="home-price__cards__left__pointer">
              <div className="home-price__cards__left__pointer__left">
                &#10003;
              </div>
              <div className="home-price__cards__left__pointer__right">
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  {' '}
                  Learn at your own terms. Watch video lessons at your schedule
                  by taking out just 30-60 minutes a day.
                </span>
              </div>
            </div>
            <div className="home-price__cards__left__bottom">
              We offer a 100% money back guarantee until Day 3 of the course if
              you don’t love it.
            </div>
          </div>
          <div className="home-price__cards__right">
            <div className="home-price__cards__right__top">JOIN THE COURSE</div>
            <div className="home-price__cards__right__price">
              {country == 'India' ? '₹ 4999' : '$99.99'}
            </div>
            <div className="home-price__cards__right__desc">
              Special Launch Price
            </div>

            <div
              onClick={() => {
                openModal();
                window.scrollTo(0, 0);
              }}
              className="home-price__cards__right__button"
            >
              Join Waitlist
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePrice;
