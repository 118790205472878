import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { joinWaitlist} from '../actions/user';
import Backdrop from './Backdrop';

const ModalOverlay = (props) => {
  const [joined, setJoined] = useState(false);
  const [values, setValues] = useState({
    email: '',
    username: '',
    success: false,
    error: false,
  });

  const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const handleSubmit = () => {
    let course = props.course;

    if (username.length > 1 && email.match(mailformat)) {
      joinWaitlist({ username, email, course }).then((data) => {
        if (data.error) {
          setValues({ ...values, error: data.error });
        } else {
          setValues({
            ...values,
            username: '',
            email: '',
          });

          setJoined(true);
        }
      });
    } else {
      setValues({
        ...values,
        username: '',
        email: '',
        error: 'Please enter a valid email address!',
      });
    }
  };

  const { username, email, success, error } = values;

  const handleChange = (name) => (e) => {
    setValues({
      ...values,
      [name]: e.target.value,
    });
  };

  const content = (
    <div className="get-started">
      {joined ? (
        <div className="get-started__content">
          <div className="get-started__content__top">
            <div className="get-started__content__top__title">
              <div>
                Thank you for joining the waitlist, we'll contact you once the
                enrollment opens!
              </div>
              <div onClick={props.onCancel}>
                <i class="fas fa-times"></i>
              </div>
            </div>
          </div>
          <div className="get-started__content__bottom">
            <div className="get-started__content__bottom__login">
              You can also follow us on
            </div>
            <div className="get-started__content__bottom__icons">
              <a
                target="_blank"
                href="https://www.instagram.com/linguapsych_official/"
              >
                <i class="fab fa-instagram"></i>
              </a>
              <a
                target="_blank"
                href="https://www.facebook.com/linguapsychofficial"
              >
                <i class="fab fa-facebook-square"></i>
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className="get-started__content">
          <div className="get-started__content__top">
            <div className="get-started__content__top__title">
              <div>Get Started</div>
              <div onClick={props.onCancel}>
                <i class="fas fa-times"></i>
              </div>
            </div>
            <input
              className="get-started__content__top__input"
              placeholder="name"
              onChange={handleChange('username')}
              value={username}
            />
            {error && (
              <div style={{ color: 'red', fontSize: '16px' }}>{error}</div>
            )}
            <input
              className="get-started__content__top__input"
              placeholder="email"
              onChange={handleChange('email')}
              value={email}
              email
            />
          </div>
          <div className="get-started__content__bottom">
            <div
              onClick={handleSubmit}
              className="get-started__content__bottom__button"
            >
              Join Waitlist
            </div>
            <div className="get-started__content__bottom__login">
              Thanks for showing interest. We'll contact you once we resume!
            </div>
          </div>
        </div>
      )}
    </div>
  );

  return ReactDOM.createPortal(content, document.getElementById('modal'));
};

const Modal = (props) => {
  return (
    <React.Fragment>
      {props.show && <Backdrop onClick={props.onCancel} />}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={500}
        classNames="modal"
      >
        <ModalOverlay event {...props} />
      </CSSTransition>
    </React.Fragment>
  );
};

export default Modal;
