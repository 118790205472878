import React from 'react';
import { Link } from 'react-router-dom';

import '../sass/main.scss';
import MetaTags from 'react-meta-tags';
import Image from '../assets/french-classes-in-delhi.jpeg';
import Language from '../assets/language_course_bottom.png';

const frechDelhi = () => {
  const head = () => (
    <MetaTags>
      <title>
        French Language Course in Delhi | Best Institutes for French Classes in
        Delhi | LinguaPsych
      </title>
      <meta
        name="description"
        content="Want to learn French in Delhi? This is the list of best institutes for French language course in Delhi. Choose from the list of French classes in Delhi."
      />{' '}
      <link
        rel="canonical"
        href="https://linguapsych.com/french-language-course-in-delhi/"
      />
      <meta
        property="og:title"
        content="Want to learn French in Delhi? This is the list of best institutes for French language course in Delhi. Choose from the list of French classes in Delhi."
      />{' '}
      <meta
        property="og:description"
        content="Want to learn French in Delhi? This is the list of best institutes for French language course in Delhi. Choose from the list of French classes in Delhi."
      />{' '}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        href="https://linguapsych.com/french-language-course-in-delhi/"
      />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/french-classes-in-delhi.jpeg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/french-classes-in-delhi.jpeg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}
      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_DOMAIN_PRODUCTION}`}>
          <div className="article__general-banner">
            <div className="article__general-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="french-classes-in-delhi"
            className="article__featured-image"
          />
          <div className="article__header-elements">
            <h1 className="article__title ">
              {' '}
              <span
                style={{ fontSize: '5rem' }}
                className="article__highlight--black "
              >
                {' '}
                French Language Course in Delhi
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <p>
              Planning to learn French? And so looking for the best French
              language course in Delhi? Delhi is the right place for the same.
              The city offers you a variety of French courses to choose from.
              There are multiple options available and you can choose what suits
              you best.
            </p>
            <p>
              Delhi offers language courses in many foreign languages. Learning
              a language is a whole new world in itself. It opens up a number of
              career options for you and also gives you opportunity to interact
              with people around the globe. Learning a language teaches you many
              things.
            </p>
            <p>
              French is a beautiful romantic language. It is the official
              language of about 29 countries across the globe. So the number of
              French speakers are huge and so is the scope of learning the
              language. Let us look at what all French institutes we have in
              Delhi.
            </p>
            <h2 className="u-margin-bottom-medium">University of Delhi</h2>
            <p>
              If you want to pursue University level French course, then the
              best option would be to go for University of Delhi. University of
              Delhi offers language courses in 18 different languages. It offers
              part-time and full-time language courses. So you can choose as per
              your convenience.
            </p>
            <ul>
              <li>
                Full time degree courses are provided at the University level.
                You can pursue your bachelors, masters, M.Phil and PhD in French
                from here. There are different departments for different
                languages. Admission procedure starts in the month of May or
                June. Different courses have different admission requirements.
                Admission to some courses depend upon the cut-off lists released
                by the University and some courses require entrance tests. For
                complete details relating to admission in University of Delhi,
                you can visit their website.
              </li>
              <li>
                Part-time courses are offered for those who want to learn a
                foreign language but cannot enroll for full-time course. There
                are many colleges affiliated to University of Delhi that offer
                Certificate, Diploma and Advanced Diploma in French language.
                Following are the colleges offering French language:
              </li>
              <li>
                Daulat Ram College, Maurice Nagar – Certificate, Diploma and
                Advanced Diploma in French
              </li>
              <li>
                Hans Raj College, MalkaGanj – Certificate, Diploma and Advanced
                Diploma in French
              </li>
              <li>Keshav Mahavidyalay, Pitampura – Certificate in French</li>
              <li>
                Mata Sundari College, Mata Sundari Lane – Certificate in French
              </li>
              <li>Miranda House, Patel Chest Marg – Certificate in French</li>
              <li>Ramjas College, Maurice Nagar – Certificate in French</li>
              <li>
                Satyawati College, Ashok Vihar – Certificate and Diploma in
                French
              </li>
              <li>
                Sri Guru Gobind Singh College of Commerce, Pitampura –
                Certificate in French
              </li>
              <li>
                St. Stephen’s College, University Enclave – Certificate, Diploma
                and Advanced Diploma in French
              </li>
              <li>
                Acharya Narendra Dev College, Govindpuri – Certificate and
                Diploma in French
              </li>
              <li>
                Bharti College, Janakpuri – Certificate, Diploma and Advanced
                Diploma in French
              </li>
              <li>
                College of Vocational Studies, Sheikh Sarai – Certificate and
                Diploma in French
              </li>
              <li>
                Delhi College of Arts and Commerce (DCAC), Netaji Nagar –
                Certificate in French
              </li>
              <li>
                Jesus and Mary College, Chanakyapuri – Certificate in French
              </li>
              <li>
                Kamla Nehru College, August-Kranti Marg – Certificate, Diploma
                and Advanced Diploma in French
              </li>
              <p>
                Website :<a href="http://grs.du.ac.in/">http://grs.du.ac.in/</a>
              </p>
              <p>
                Address: Department of Germanic and Romance Studies, Faculty of
                Arts, University of Delhi, Delhi – 110007
              </p>
              <p>
                Also read:
                <a href="/foreign-language-courses-in-delhi-university/">
                  Foreign Language Courses in Delhi University
                </a>
              </p>
            </ul>
            <h2 className="u-margin-bottom-medium">
              Jawaharlal Nehru University (JNU)
            </h2>
            <p>
              Jawaharlal Nehru University is one the best Universities in Delhi.
              It is known for having best campus with gardens full of greenery
              and all amenities included within the campus itself. For people
              coming from other states or countries, a very well-maintained
              hostel is available for students.
            </p>
            <p>
              JNU offers a degree in literature and translation. You can pursue
              BA, MA, M.Phil and PhD in French from here. You can also pursue
              part-time course; Certificate, Diploma and Advanced Diploma in
              French language.
            </p>
            <p>
              Website:
              <a href="https://www.jnu.ac.in/sllcs">
                https://www.jnu.ac.in/sllcs
              </a>
            </p>
            <p>Address: New Mehrauli Road, New Delhi 110067</p>
            <h2 className="u-margin-bottom-medium">Amity University</h2>
            <p>
              Amity’s School of Foreign Languages offers you to pursue
              bachelor’s and post graduate degree in a foreign language. You can
              pursue bachelors with honors degree in French (BA. Hons. in
              French). For post-graduation, it offers masters in French (MA in
              French). Amity University also offers some short-term courses like
              Certificate and Diploma in French. Refer to their website for
              complete information regarding program structure and fees.
            </p>
            <p>
              Website:
              <a href="https://www.amity.edu/asfl/beta/default.aspx">
                https://www.amity.edu/asfl/beta/default.aspx
              </a>
            </p>
            <p>Address: Amity University Sec-125, Noida</p>

            <h2 className="u-margin-bottom-medium">IGNOU</h2>
            <p>
              IGNOU (Indira Gandhi National Open University,) has a separate
              department, School of Foreign Languages (SOFL) for those aspiring
              to learn foreign language. You can join a part-time Certificate
              course in French language at IGNOU. For Ph.D. enthusiasts, you can
              do Ph.D. in French as well.
            </p>
            <p>
              Website:
              <a href="http://www.ignou.ac.in/ignou/aboutignou/school/sofl/introduction">
                http://www.ignou.ac.in/ignou/aboutignou/school/sofl/introduction
              </a>
            </p>
            <p>
              Address: School of Foreign Languages, Block No. 15-D, 1st Floor,
              Maidan Garhi
            </p>
            <h2 className="u-margin-bottom-medium">
              Alliance Française, Delhi
            </h2>
            <p>
              Alliance Française is an international organization that aims to
              promote the French language and culture around the world. It is
              one of the best institutes for learning French language in Delhi.
              It has centers all around the globe, headquartered in Paris.
            </p>
            <p>
              Alliance Française is affiliated to the French Embassy in India.
              The faculty at Alliance Française is highly skilled and
              experienced. It organizes various cultural events which imparts
              students with cultural understanding of the language. The French
              course is divided into 5 levels:
            </p>
            <ul>
              <li>Beginners (A1)</li>
              <li>Intermediate (A2)</li>
              <li>Advanced (B1)</li>
              <li>Independent (B2)</li>
              <li>Superior (C1)</li>
            </ul>
            <p>
              All these levels are further divided into sub-levels. The
              Intensive and Extensive courses have different duration and you
              can choose the course based on your convenience. There are also
              courses available for kids and certain other crash courses.
            </p>
            <p>
              Alliance Française is the only institute in Delhi authorized to
              conduct the French DELF/DALF/TCF proficiency test. It also
              provides various French scholarships to its merit students.
            </p>
            <p>
              Website:
              <a href="https://delhi.afindia.org/">
                https://delhi.afindia.org/
              </a>
            </p>
            <p>
              Address: 72, KK Birla Marg, Lodi Estate | N.D – 110003 (Nearest
              metro station – Jorbagh and Khan Market)
            </p>
            <h2 className="u-margin-bottom-medium">
              International Language Schools of Canada (ILSC)
            </h2>
            <p>
              International Language Schools of Canada (ILSC) is one of the
              reputed institutes for learning French, operating since 28 years.
              The institute is available across 8 different locations. It offers
              about 130 programs and courses for French learners. The institute
              focuses on improving reading, writing, speaking and listening
              skills. The institute has a faculty of trained and skilled
              teachers imparting world-class knowledge.
            </p>
            <p>
              Website:
              <a href="https://www.ilsc.com/">https://www.ilsc.com/</a>
            </p>
            <p>
              Address: B-17 Shivalik, GF, Gitanjali Road, Malviya Nagar, New
              Delhi 110017, India
            </p>
            <p>Also read:</p>
            <p>
              <a href="/german-language-course-in-delhi/">
                Learn German Language Course in Delhi
              </a>
            </p>
            <p>
              <a href="/learn-japanese-in-delhi/">
                Top 9 Places to learn Japanese Language in Delhi
              </a>
            </p>
          </div>
          <div className="article__blog-container__bottom">
            <div className="article__blog-container__bottom__left">
              <Link to="/">
                <img
                  className="article__blog-container__bottom__image"
                  src={Language}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/foreign-language-courses-delhi/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Foreign Language courses in Delhi
                </li>
              </Link>
              <Link to="/learn-japanese-in-delhi/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Learn Japanese in Delhi
                </li>
              </Link>

              <Link to="/foreign-language-courses-in-delhi-university/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Foreign Language courses in Delhi University
                </li>
              </Link>

              <Link to="/german-language-course-in-delhi/">
                <li className="article__blog-container__bottom__posts__title__item">
                  German Language course in delhi
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default frechDelhi;
