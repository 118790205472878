import React from 'react';
import '../sass/main.scss';
import MetaTags from 'react-meta-tags';
import Image from '../assets/future-tense-spanish.jpg';
import Table1 from '../assets/future-tense-table-1.PNG';
import Table2 from '../assets/future-tense-table-2.PNG';
import Table3 from '../assets/future-tense-table-3.PNG';
import Table4 from '../assets/future-tense-table-4.PNG';
import Image1 from '../assets/Spanish-Future-Tense1.jpg';

const futureTense = () => {
  const head = () => (
    <MetaTags>
      <title>
        Future Tense Spanish: Easy Guide | Everything you need to know |
        LinguaPsych
      </title>
      <meta
        name="description"
        content="You will learn everything you need to know about the Future tense Spanish, it includes verb conjugations, practice test and additional examples for your help."
      />{' '}
      <link
        rel="canonical"
        href="https://linguapsych.com/future-tense-spanish/"
      />
      <meta
        property="og:title"
        content="You will learn everything you need to know about the Future tense Spanish, it includes verb conjugations, practice test and additional examples for your help."
      />{' '}
      <meta
        property="og:description"
        content="You will learn everything you need to know about the Future tense Spanish, it includes verb conjugations, practice test and additional examples for your help."
      />{' '}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        href="https://linguapsych.com/future-tense-spanish/"
      />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/future-tense-spanish.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/future-tense-spanish.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}
      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_SPANISH_COURSE}`}>
          <div className="article__spanish-banner">
            <div className="article__spanish-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="Direct-object-Pronouns-Spanish"
            className="article__featured-image"
          />
          <div className="article__header-elements">
            <h1 className="article__title ">
              {' '}
              <span
                style={{ fontSize: '5rem' }}
                className="article__highlight--black "
              >
                {' '}
                Complete guide to Future Tense Spanish
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            Learning Spanish is fun, isn’t it? Believe me, learning Future Tense
            in Spanish will be exciting and if you have already done the past
            tense in Spanish, this is gonna feel like piece of cake. So let’s
            start with the introduction, Spanish has three ways to express
            future:
            <ul>
              <li>Simple Future or Future Imperfect</li>
              <li>Going to form (Ir + a + Infinitive)</li>
              <li>Future Perfect</li>
            </ul>
            <p>
              Out of these three, you can replace Simple Future with Ir + a +
              Infinitive form in most situations but, Spanish Future Perfect has
              it’s own conditions, let’s begin with the Simple Future
            </p>
            <h2 className="u-margin-bottom-medium">Spanish Simple Future</h2>
            <p>
              The future tense is formed by adding -é, -ás, -á, -emos, -éis and
              -án at the end of a verb’s infinitive form.
            </p>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table1}
                alt="future-tense-table-1"
                className="article__big-images"
              />
            </div>
            <h2 className="u-margin-bottom-medium">
              Irregular Verbs List Future Tense Spanish
            </h2>
            <p>
              Here’s a good news, Spanish Simple Future has very few irregular
              verbs, twelve in total. You’ll find the main root changing in
              these verbs, but the ending remains the same as seen in the table
              below. Let’s take a look
            </p>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table2}
                alt="future-tense-table-2"
                className="article__big-images"
              />
            </div>
            <h2 className="u-margin-bottom-medium">
              When to use Simple Future in Spanish?
            </h2>
            <h3>To make Predictions</h3>
            <p>
              We use the Simple Future to make predictions or to express the
              future actions that are uncontrollable.
            </p>
            <ul>
              <li>
                Mañana lloverá en Barcelona (Tomorrow it will rain in Barcelona)
              </li>
              <li>
                Veo en la líneas de tu mano que vivirás muchos años (I see in
                your hand line, you’ll live many years)
              </li>
              <li>
                en breves instantes, aterrizaremos en el aerepuerto de Madrid
                (Soon we’ll land at the Madrid Airport)
              </li>
              <li>
                el sol saldrá mañana a las 6 h (The Sun will come out at 6)
              </li>
            </ul>
            <h3>To make Hypotheses about the future</h3>
            <p>
              We also use Future tense to make speculations and Hypotheses about
              the future. Normally the speculations are made using some time
              markers like
            </p>
            <ul>
              <li>Seguramente (surely)</li>
              <li>Probablemente (probably)</li>
              <li>Posiblemente (Possibly)</li>
              <li>Seguro que (It’s sure that)</li>
              <li>Creo que (I believe that)</li>
              <li>Supongo que (I suppose that)</li>
            </ul>
            <h3>Now let's take a look at some examples</h3>
            <ul>
              <li>Suponge que iremos de vacaciones a India</li>
              <li>
                Pues seguramente iré al campo mañana (I’m surely going to the
                camp)
              </li>
              <li>
                Creo que me quedaré en casa este fin de semana(I believe I’m
                going to stay at home this weekend)
              </li>
              <li>
                Probablemente volverán muy cansados después de la excursión
                (Probably we’ll come back tired after the trip)
              </li>
              <li>
                {' '}
                Seguro que nos veremos pronto ( I’m sure we’ll meet soon)
              </li>
            </ul>
            <h3>To make Hypotheses on Present</h3>
            <p>
              Likewise, we can make Hypotheses on our Present using Simple
              Future
            </p>
            <ul>
              <li>
                No sé dónde he dejado las llaves del coche ( I don’t remember
                where I kept the keys)
              </li>
              <li>
                Las tendrás en la mesilla, como siempre (They will be on the
                table, like always)
              </li>
            </ul>
            <h2 className="u-margin-bottom-medium">
              Time Markers to talk about future
            </h2>
            <p>
              I know this is very basic, but important too. There are certain
              time markers where you can use the future tense. Below is the list
            </p>
            <ul>
              <li>mañana</li>
              <li>pasado mañana</li>
              <li>el sábado/domingo…</li>
              <li>este mes/sábado/año</li>
              <li>esta noche/tarde/semana</li>
              <li>dentro de unos años/dos años (Within few years)</li>
              <li>el lunes/mes que viene (Coming Monday/month)</li>
              <li>el lunes/mes próximo (Next Monday/month)</li>
            </ul>
            <p>
              He tenido mucho trabajo esta semana y estoy muy cansado. Creo que
              el sábado no saldré. (I had a lot of work this week, I won’t go
              out on Saturday)
            </p>
            <p>
              el lunes que viene iré al campo con mi novia (I’m going to camp
              the coming Monday with my girfriend)
            </p>
            <p>me casaré el año próximo (I will get married the coming year)</p>
            <p>
              Seré promovido dentro de un año (I will get promoted within 1
              year)
            </p>
            <p>
              pasado mañana compraré un coche (I will buy a car the day after
              tomorrow)
            </p>
            <h2 className="u-margin-bottom-medium">To Express a condition</h2>
            <p>
              To express a condition, there is present and future, if you follow
              or don’t follow something, something else will happen. The same
              condition is applied in English too, so there is no need to worry.
            </p>
            <h3>Si + Presente, Futuro (If + Present, Future)</h3>
            <ul>
              <li>
                Si estudias todos los dias, aprobarás el examen (If you study
                everyday, you’ll pass the exams)
              </li>
              <li>
                Si comes todo el día, engordarás (If you eat all day, you will
                get fat)
              </li>
              <li>
                si no te despiertas a tiempo, llegarás tarde ( If you don’t wake
                up on time, you’ll get late)
              </li>
            </ul>
            <h2 className="u-margin-bottom-medium">
              IR A + INFINITIVE (Going to form)
            </h2>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table3}
                alt="future-tense-table-3"
                className="article__big-images"
              />
            </div>
            <p>
              The next method that is used to talk about the future is something
              which is not new for the English Speakers, Spanish speaking people
              use this form more or less in the same frequency as the Simple
              Future form. So it even gets more important to master. Here are
              few examples in English
            </p>
            <ul>
              <li>I’m going to take a bath</li>
              <li>He is going to come home soon</li>
              <li>We are going to get married</li>
              <li>Are you going to Spain Next month?</li>
            </ul>
            <p>
              We can refer to IR a + Infinitve form whenever we want to talk
              about decisions, plans or future actions that are linked at the
              moment when we are talking.
            </p>
            <h3>To talk about Decisions</h3>
            <ul>
              <li>
                Seguramente vamos a ir a la playa (We are surely going to the
                beach)
              </li>
              <li>
                Yo voy a comprar este coche dentro de un mes (I’m going to buy
                this Car within a month)
              </li>
              <li>Ella vas a casrse con Juan (She is going to marry Juan)</li>
              <h3>To talk about Plans</h3>
              <li>
                Probablemente voy a comprar este coche (Probably I’m going to
                buy that Car)
              </li>
              <li>
                Supongo que ella va a perder (I guess she’s going to lose)
              </li>
              <li>
                Posiblemente no ella va a venir esta noche (Possibly she’s not
                coming tonight)
              </li>
              <h3>To talk about Future actions</h3>
              <li>
                La Nasa va a Marte en 2021 ( NASA is going to Mars in 2020)
              </li>
              <li>
                Mi hija va a ser 16 el año que viene (My daughter is going to be
                16 the coming year)
              </li>
            </ul>
            <h2 className="u-margin-bottom-medium">
              When to use Simple Future vs Ir a + Infinitive?
            </h2>
            <p>
              We have described some situations where you can use Simple Future
              and Ir a + Infinite form but that doesn’t mean you can’t use it
              the other way round. Both of these forms are reversible and won’t
              have such a huge impact if you interchange the two of them. But at
              some places, using one of them can be more impactful. Let’s look
              at some situations
            </p>
            <ul>
              <li>
                <h3>When talking about Decisions</h3>
              </li>
              <p>
                Here, when we are talking about the decisions, using Ir a +
                Infinitive form can be more effective as it stresses on more
                chances of getting that thing done if we compare it to the
                Simple Future form.
              </p>
              <li>Voy a comer Pizza (I’m going to eat Pizza)</li>
              <li>iré a comer pizza (I will go to eat Pizza)</li>
              <p>
                As we can see in the above examples, voy a comer pizza sounds
                more natural although, iré a comer pizza is not completely
                incorrect.
              </p>
              <li>
                <h3>When talking about plans</h3>
              </li>
              <p>
                This situation is opposite to the previous one that we
                discussed, we know that a plan is not a decision and hence, the
                possibility of a plan to get executed is not 100%. Using Simple
                Future will sound more natural
              </p>
              <li>
                {' '}
                voy a comprar ferrari si tengo dinero (I’m going to buy Ferrari
                if I have money)
              </li>
              <li>
                compraré ferrari si tengo dinero (I will buy Ferrari if I have
                money)
              </li>
              <p>
                The above example contrasts a plan and possibility of the thing
                happening is not 100% hence, using Simple future will be more
                appropriate.
              </p>
            </ul>
            <h2 className="u-margin-bottom-medium">
              What is Future Perfect in Spanish (Futuro Compuesto)?
            </h2>
            <p>
              There is a third form of talking about the future in Spanish,
              although, it is not as common as the other two we did previously
              but, this can be a difference between an intermediate Spanish
              student and an advanced Spanish student.
            </p>
            <p>
              The future perfect or Futuro Compuesto in Spanish is used to
              express a forthcoming action that will have ended before another
              action in future. Let’s look at some examples in English
            </p>
            <ul>
              <li>I will have finished the report by Saturday</li>
              <li>I will have bought my new house by next year</li>
              <li>I will have passed my graduation by next semester</li>
            </ul>
            <p>
              It is also used to make an assumption that the action would have
              occurred in the past. Let’s look at some examples in English
            </p>
            <ul>
              <li>
                She’s not at home, she must must have left for the office early
              </li>
              <li>
                There’s no food in the refrigerator, Andrea must have ate it
              </li>
              <li>It’s Sunday, my mother must have gone to the Church</li>
            </ul>
            <h2 className="u-margin-bottom-medium">
              How to form Future Perfect in Spanish?
            </h2>
            <p>
              To form Simple Future Tense in Spanish, just take verb haber and
              conjugate it to it’s simple future form followed by a past
              participle form. Let’s see how
            </p>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table4}
                alt="future-tense-table-4"
                className="article__big-images"
              />
            </div>
            <h2 className="u-margin-bottom-medium">
              When to use Future Perfect in Spanish?
            </h2>
            <p>
              There is no difference in using Simple future tense in English or
              Spanish, there are 2 situations where we use Simple Future tense
            </p>
            <h3>While making Hypotheses</h3>
            <p>
              Making a prediction or an assumption about the future when you are
              in the present will take Simple future. Here you assume that
              something will be finished or happen within a particular duration.
              This is exactly like English so need to learn anything extra
            </p>
            <ul>
              <li>
                el trabajo habrá terminado para febrero (The work will have
                finished by February)
              </li>
              <li>
                las reservas habrán terminado este año (The stocks will have
                finished this year)
              </li>
              <li>
                Ellos habrán visto un fantasma (They will have seen a ghost)
              </li>
            </ul>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Image1}
                alt="Spanish-Future-Tense1"
                className="article__big-images"
              />
            </div>
            <h3>While making assumptions on past actions</h3>
            <p>
              Simple future Tense is also used for actions that must have been
              completed in past but we, sitting in present are making
              assumptions about it
            </p>
            <ul>
              <li>
                ¿dónde habré guardado los calcetines? ( Where will I have kept
                the socks?)
              </li>
              <li>
                Maria habrá ido al cine ( Maria will have gone to the Cinema)
              </li>
              <li>
                mañana habrá pasado dos semanas sin comida (Tomorrow will have
                been two weeks without food)
              </li>
            </ul>
            <h2>Let's Practice some skills/ Exercises</h2>
            <p>
              Below are 15 questions, 5 questions for each form of Spanish
              future tense, let’s begin
            </p>
            <ul>
              <li>
                <h3>
                  {' '}
                  Fill in the blanks with suitable verb conjugation (Simple
                  Future)
                </h3>
              </li>
              <li>____________(ir/we) al campo este fin de semana.</li>
              <p>We will go to the camp this weekend.</p>
              <li>_____________(tener/I) que llegar al tiempo.</li>
              <p>I will have to reach on time.</p>
              <li>_______________(salir/ella) de casa pronto.</li>
              <p>She will leave home early.</p>
              <li>el mes próximo _______________ (venir/el) mi hermano.</li>
              <p>My brother will come the next month.</p>
              <li>¿Cuando ______________(llegar/tu_) a mi casa?</li>
              <p>When will you reach my home?</p>
              <li>
                <h3>
                  {' '}
                  Fill in the blanks with suitable verb conjugation ( Ir a +
                  Infinitive)
                </h3>
              </li>
              <li>ella ________ a casarme.</li>
              <p>She is going to marry me.</p>
              <li>Te ____________ a dar un beso.</li>
              <p>I’m going to kiss you.</p>
              <li>ellos __________ a salir de casa.</li>
              <p>They are going to go out of the house.</p>
              <li>¿me ___________(usted) a dar su telefono?</li>
              <p>Are you going to give me your number?</p>
              <li>vosotros me ___________ a llamar.</li>
              <p>They are going to call me.</p>
              <li>
                <h3>
                  Fill in the blanks with suitable verb conjugation (Future
                  Perfect)
                </h3>
              </li>
              <li>ellos ________ visto un fantasma.</li>
              <p>They will have seen a ghost.</p>
              <li>ella _________ ido al campo.</li>
              <p>She will have left for the camp.</p>
              <li>__________ llegado a Madrid por el martes.</li>
              <p>We will have reached Madrid by Tuesday.</p>
              <li>___________ empezado el trabajo el lunes.</li>
              <p>I will have started work by Monday.</p>
              <li>_________ gustado mi presente.</li>
              <p>He will have liked my gift.</p>

              <h2>Answers</h2>
              <h3>Simple Future</h3>
              <li>iremos</li>
              <li>tendré</li>
              <li>saldrá</li>
              <li>vendrá</li>

              <h3>Ir a + Infinite</h3>
              <li>va</li>
              <li>voy</li>
              <li>van</li>
              <li>va</li>
              <li>vais</li>

              <h3>Future Perfect</h3>
              <li>habrán</li>
              <li>habrá</li>
              <li>habremos</li>
              <li>habré</li>
              <li>habrá</li>
            </ul>
            <h2 className="u-margin-bottom-medium">Conclusion</h2>
            <ul>
              <li>
                Today we learnt everything that was available in textbooks about
                the Future tense in Spanish. Starting with the Simple Future
              </li>
              <li>Regular Verbs and Irregular Verbs</li>
              <li>
                Conjugation of Regular verbs and Irregular Verbs in Simple
                Future Tense
              </li>
              <li>When to use Simple Future Tense in Spanish</li>
              <li>Time Markers to talk about Future in Spanish</li>
              <p>
                Second topic of the day was Ir a + Infinitive form in which we
                learnt
              </p>
              <li>What is Ir a + Infinitive form</li>
              <li>How to Conjugate Ir a + Infinite form</li>
              <li>When to use Ir a + Infinite form</li>
              <li>When to use Simple future vs Ir a + Infinitive form?</li>
              <p>
                Final form of the Future tense in Spanish is Future Perfect
                form, in which we learnt
              </p>
              <li>What is Future Perfect form in Spanish?</li>
              <li>How to form Future Perfect form in Spanish?</li>
              <li>When to use Future Perfect in Spanish?</li>
              <p>
                If you want to undestand our other topics as well, you can also
                check out our other lessons on
              </p>
              <li>
                <a href="/spanish-alphabets-accents-pronunciation/">
                  Spanish Alphabets, Accents and Pronunciation
                </a>
              </li>
              <li>
                <a href="/possessive-adjectives-spanish/">
                  Complete Guide on Possessive Adjectives Spanish
                </a>
              </li>
              <li>
                <a href="/spanish-definite-indefinte-articles/">
                  Definitive guide on Definitive and Indefinite Articles in
                  Spanish
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default futureTense;
