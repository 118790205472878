import React from 'react';
import { Link } from 'react-router-dom';
import '../sass/main.scss';
import MetaTags from 'react-meta-tags';
import Image from '../assets/german-colors.jpg';
import Image1 from '../assets/german-colors-all.PNG';
import Image2 from '../assets/list-of-basic-colors-german.jpg';
import Image3 from '../assets/orange-in-german.jpg';
import Image4 from '../assets/yellow-in-german.jpg';
import Image5 from '../assets/green-in-german.jpg';
import Image6 from '../assets/blue-in-german.jpg';
import Image7 from '../assets/pink-in-german.jpg';
import Image8 from '../assets/german-word-for-brown.jpg';
import Image9 from '../assets/german-word-for-black.jpg';
import Image10 from '../assets/german-word-for-white.jpg';
import Image11 from '../assets/germany-colors.jpg';
import German from '../assets/german_course_bottom.png';

const germanColors = () => {
  const head = () => (
    <MetaTags>
      <title>
        5 Unique Ways to Learn German Colors | Playful and Creative Colors in
        German | LinguaPsych
      </title>
      <meta
        name="description"
        content="Want to learn German Colors? This guide will tell you how to learn Colors in German with unusual colors. Gray, blue, green , orange, yellow, black, red in German."
      />{' '}
      <link rel="canonical" href="https://linguapsych.com/german-colors/" />
      <meta
        property="og:title"
        content="Want to learn German Colors? This guide will tell you how to learn Colors in German with unusual colors. Gray, blue, green , orange, yellow, black, red in German."
      />{' '}
      <meta
        property="og:description"
        content="Want to learn German Colors? This guide will tell you how to learn Colors in German with unusual colors. Gray, blue, green , orange, yellow, black, red in German."
      />{' '}
      <meta property="og:type" content="website" />
      <meta property="og:url" href="https://linguapsych.com/german-colors/" />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/german-colors.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/german-colors.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}
      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_GERMAN_COURSE}`}>
          <div className="article__german-banner">
            <div className="article__german-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="german-colors"
            className="article__featured-image"
          />
          <div
            style={{ marginTop: '-24px' }}
            className="article__header-elements"
          >
            <h1 className="article__title">
              {' '}
              <span className="article__highlight--black">
                {' '}
                LET'S LEARN GERMAN COLORS!
              </span>{' '}
            </h1>
          </div>
        </header>

        <div className="article__blog-container">
          <div className="article__body">
            <p>
              The world is full of colors! You will see plenty of colors all
              around the globe. It’s rightly said “Be a pop of color in a Black
              and White world”. So let’s learn German colors so that you can
              count on colors in German as well.
            </p>
            <p>
              Colors are an integral part of the world, be it a country, a
              continent, a culture, a society or the globe. There is a pop of
              color in every tiny particle in the Universe. And we identify many
              things by it’s color. A newly born child can get excited just by
              the color he sees. Colors fill happiness in our lives.
            </p>
            <p>Roses are red, violets are blue; why don’t I have a clue!</p>
            <p>
              Knowing about color in German is one of the basic concepts you
              must know, apart from Alphabets in German and German Numbers. Get
              yourselves familiar with the basic colors list in German. This
              will not only add your set of vocabulary but also help you be part
              of interesting and colorful conversations.
            </p>
            <p>
              If you know all the colors, it will be a lot more easier for you
              to identify and point towards things. Many a times we say “Did you
              see that girl in red?” So we are using colors to identify and
              remember certain things. This will help you boost confidence while
              talking to someone in German.
            </p>
            <blockquote
              className="u-margin-bottom-medium"
              style={{ fontStyle: 'italic', fontWeight: 'bold' }}
            >
              Colors are called die Farben in German.
            </blockquote>
            <h2
              className="u-margin-bottom-medium"
              style={{ backgroundColor: 'blue', color: 'white' }}
            >
              {' '}
              LIST OF BASIC COLORS{' '}
            </h2>
            <p>
              {' '}
              You must have seen a rainbow. It looks so beautiful when a rainbow
              shines bright in between the clouds with a pop of sunlight coming.
              Getting to see a rainbow is like cherry on a cake. A rainbow is
              formed with seven different colors; namely, red, orange, yellow,
              green, blue, indigo and violet.{' '}
            </p>
            <img
              style={{ width: '100%' }}
              src={Image1}
              alt="german-colors-all"
              className="u-margin-bottom-medium"
            />
            <p>
              {' '}
              These were some of the basic colors name you will encounter in
              your day to day life. You should know how to say red, yellow,
              blue, green, orange, black, white, grey, pink, purple, brown in
              German. These are the most frequently used colors in German
              language. Now let us look at some of the unusual colors.{' '}
            </p>
            <p>
              In German, you can combine words to frame one big word. For
              example, if you want to say “white as snow”, you can simply write
              “Schneeweiß“. Likewise following are some more unusual color
              names:
            </p>
            <ul
              className="u-margin-bottom-medium"
              style={{ fontWeight: 'bold', listStyle: 'none' }}
            >
              <li>gelblich grün – yellowish green</li>
              <li>weinrot – wine red</li>
              <li>blutrot – blood red</li>
              <li>rötlich orange – tangerine</li>
              <li>rötlichorange – reddish orange</li>
              <li>goldgelb – golden yellow</li>
              <li>zitronengelb – lemon yellow</li>
              <li>himmelblau – sky blue</li>
              <li>altrosa – dusky pink</li>
              <li>bläulichgrau – bluish grey</li>
              <li>silbergrau – silver grey</li>
              <li>schokoladebraun – chocolate brown</li>
            </ul>
            <p>
              These were some of the ways you can express colors in. There can
              be infinite ways to express complicated colors. You can make use
              of different adjectives to express the German color names. All
              German colors are adjectives. However, in some cases, color names
              can also be nouns. In that case, color names will be capitalized.
            </p>
            <p>
              You can also make use of some adjectives before color words to
              express a particular shade. For example,
            </p>
            <p>hell – light/bright</p>
            <p>dunkel – dark</p>
            <p>bunt – colorful</p>
            <p>
              {' '}
              Now let us make some sentences to understand how to use them with
              colors.{' '}
            </p>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '60%' }}
                  src={Image2}
                  alt="red in german"
                  className="u-margin-bottom-medium"
                />
              </div>
              <div className="col-1-of-2">
                <p> Die Erdbeere ist rot. </p>
                <p> (The strawberry is red.) </p>
                <p> Diese Erdbeeren sind dunkel rot. </p>
                <p> (These strawberries are dark red.) </p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '60%', height: '200px' }}
                  src={Image3}
                  alt="orange-in-german"
                  className="u-margin-bottom-medium"
                />
              </div>
              <div className="col-1-of-2">
                <p> Die Orange ist orange. </p>
                <p> (The orange is orange.) </p>
                <p> Diese Orangen sind hell orange. </p>
                <p> (These oranges are bright orange.) </p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '60%' }}
                  src={Image4}
                  alt="yellow-in-german"
                  className="u-margin-bottom-medium"
                />
              </div>
              <div className="col-1-of-2">
                <p> Die Zitrone ist gelb. </p>
                <p> (The lemon is yellow.) </p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '60%', height: '200px' }}
                  src={Image5}
                  alt="green-in-german"
                  className="u-margin-bottom-medium"
                />
              </div>
              <div className="col-1-of-2">
                <p> Die Blätter sind grün. </p>
                <p> (The leaves are green.) </p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '60%', height: '200px' }}
                  src={Image6}
                  alt="blue-in-german"
                  className="u-margin-bottom-medium"
                />
              </div>
              <div className="col-1-of-2">
                <p>Der Himmel ist blau. </p>
                <p>(The sky is blue.) </p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '60%' }}
                  src={Image7}
                  alt="pink-in-german"
                  className="u-margin-bottom-medium"
                />
              </div>
              <div className="col-1-of-2">
                <p> Die Blumen sind hell rosa. </p>
                <p> (The flowers are light pink.) </p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '60%', height: '200px' }}
                  src={Image8}
                  alt="german-word-for-brown"
                  className="u-margin-bottom-medium"
                />
              </div>
              <div className="col-1-of-2">
                <p> Der Kaffee ist braun. </p>
                <p> (The coffee is brown.) </p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '60%' }}
                  src={Image9}
                  alt="german-word-for-black"
                  className="u-margin-bottom-medium"
                />
              </div>
              <div className="col-1-of-2">
                <p> Ich habe einen schwarzen Hund. </p>
                <p> (I have a black dog.) </p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '60%' }}
                  src={Image10}
                  alt="german-word-for-white"
                  className="u-margin-bottom-medium"
                />
              </div>
              <div className="col-1-of-2">
                <p>Heute trage ich weiße Schuhe. </p>
                <p> (I am wearing white shoes today.) </p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '60%' }}
                  src={Image11}
                  alt="germany-colors"
                  className="u-margin-bottom-medium"
                />
              </div>
              <div className="col-1-of-2">
                <p>Ich spiele mit bunten Bällen.</p>
                <p> (I play with colorful balls.)</p>
              </div>
            </div>
            <h2
              className="u-margin-bottom-medium"
              style={{ backgroundColor: 'pink', color: 'white' }}
            >
              REMEMBER COLORS IN GERMAN
            </h2>
            <p>
              We learn many things while learning a language. But how many times
              do we remember what we had learnt?
            </p>
            <p>
              Make it a point to interact in the vocab or concepts you learn.
              The more you make use of these terms, more likely you are to
              remember them. Following are some of the ways you can keep up with
              the color words you have learnt:
            </p>
            <ul className="u-margin-bottom-medium">
              <li>
                <span style={{ fontWeight: 'bold' }}>
                  {' '}
                  Describe what you see:{' '}
                </span>{' '}
                Describe anything that you see with the help of colors in
                German. You can either describe anything live or you can pick up
                any picture in your textbook or magazine.
              </li>
              <li>
                <span style={{ fontWeight: 'bold' }}> Clothing colors: </span>{' '}
                You come across any person, have a look at their clothes and
                name the color. Everyday in the morning before getting ready,
                you can also name your colors.
              </li>
              <li>
                <span style={{ fontWeight: 'bold' }}>
                  {' '}
                  Fruits and vegetable colors:{' '}
                </span>{' '}
                Look at the fruits and vegetables you are eating and try to
                analyse their colors. In a restaurant you can also look at what
                your friends are eating and you can have a conversation based on
                that.
              </li>
              <li>
                <span style={{ fontWeight: 'bold' }}> Craft work: </span>{' '}
                Describe anything that you see with the help of colors in
                German. You can either describe anything live or you can pick up
                any picture in your textbook or magazine.
              </li>
              <li>
                <span style={{ fontWeight: 'bold' }}>
                  {' '}
                  German Flag colors:{' '}
                </span>{' '}
                You know what color is the German flag. Have a look at it and
                speak out loud about colors of German flag. German flag has
                three colors, namely black, red and yellow (schwarz, rot und
                grün).
              </li>
            </ul>
            <p>
              These are some of the ways you can use to remember and memorize
              color names in German. The world is full of colors. You have
              endless colors to notice and describe in day-to-day chores.
            </p>
          </div>
          <div className="article__blog-container__bottom">
            <div
              className="article__blog-container__bottom__left"
              to="/learn-german"
            >
              <Link to="/learn-german">
                <img
                  className="article__blog-container__bottom__image"
                  src={German}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/german-alphabet-letters/">
                <li className="article__blog-container__bottom__posts__title__item">
                  German Alphabets & Letters
                </li>
              </Link>
              <Link to="/german-numbers/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Numbers in German
                </li>
              </Link>

              <Link to="/german-days-of-the-week/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Days of the Week in German
                </li>
              </Link>
              <Link to="/german-months-seasons/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Months & Seasons in German
                </li>
              </Link>
              <Link to="/german-umlaut/">
                <li className="article__blog-container__bottom__posts__title__item">
                  German Umlaut
                </li>
              </Link>
              <Link to="/time-in-german/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Time in German
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default germanColors;
