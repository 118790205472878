import React from 'react';

import '../sass/main.scss';
import MetaTags from 'react-meta-tags';
import Image from '../assets/time-in-germany.jpg';
import Image1 from '../assets/current-time-in-germany.jpg';
import Image2 from '../assets/german-time.jpg';
import Table1 from '../assets/german-time-table-1.JPG';
import Table2 from '../assets/german-time-table-2.JPG';

const germanTime = () => {
  const head = () => (
    <MetaTags>
      <title>
        Complete Guide on German Time | How to fluently tell time in German |
        LinguaPsych
      </title>
      <meta
        name="description"
        content="Want to learn how to tell/ask time in German? Discover the complete guide on German time including the basics on time in Germany and useful vocabulary related to it."
      />{' '}
      <link rel="canonical" href="https://linguapsych.com/time-in-german/" />
      <meta
        property="og:title"
        content="Want to learn how to tell/ask time in German? Discover the complete guide on German time including the basics on time in Germany and useful vocabulary related to it."
      />{' '}
      <meta
        property="og:description"
        content="Want to learn how to tell/ask time in German? Discover the complete guide on German time including the basics on time in Germany and useful vocabulary related to it."
      />{' '}
      <meta property="og:type" content="website" />
      <meta property="og:url" href="https://linguapsych.com/time-in-german/" />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/time-in-germany.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/time-in-germany.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}

      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_GERMAN_COURSE}`}>
          <div className="article__german-banner">
            <div className="article__german-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="Spanish-definite-indefinite-articles"
            className="article__featured-image"
          />
          <div className="article__header-elements">
            <h1 className="article__title ">
              {' '}
              <span
                style={{ fontSize: '5rem' }}
                className="article__highlight--black "
              >
                {' '}
                German Time : How to Fluently Tell Time in German?
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <p>
              Do you want to learn how to tell time in German? You have landed
              on the correct page. We will learn the complete basics on German
              time including different ways on how to tell and ask time in
              German. We will also take a glimpse of words and vocabulary needed
              to fluently talk about time in Germany.
            </p>
            <p>
              You must know the skill of telling the time in German when on a
              journey towards learning German. You will need this very
              frequently. Time is used in most conversations and small talks.
              Whether you are fixing a meet-up with a friend or planning a date
              with your loved one. You will fix a time for the same.
            </p>
            <p>
              We always have long stories to tell our friends, whether from past
              or present and sometimes planning about future. Just imagine how
              much we are trapped in the vicious circle of time. So let us not
              miss that opportunity of small talk and learn how to talk about
              time in various different ways.
            </p>
            <div
              style={{
                backgroundColor: 'black',
                color: 'white',
                textAlign: 'center',
              }}
            >
              <h2 className="u-margin-bottom-medium">
                How to Learn Time in German?
              </h2>
            </div>
            <p>
              Before moving any further let us look at the approach we will have
              for learning telling time in German. We will move step by step so
              that no confusion persists. We will learn time in the following
              pattern:
            </p>
            <ul>
              <li>
                <span style={{ fontWeight: 'bold' }}>
                  Basic phrase to ask time:
                </span>
                The very first thing you need to know is how do you ask time in
                German. You could encounter many situations where you will have
                to ask time from someone. There are usually some fixed phrases
                which we will learn.
              </li>

              <li>
                <span style={{ fontWeight: 'bold' }}>
                  Basic phrase to tell time:
                </span>
                When you know how to ask time from someone, you are expected to
                know how to tell what time is it as well. There are some fixed
                phrases which are mostly used.
              </li>

              <li>
                <span style={{ fontWeight: 'bold' }}>Numbers in German:</span>
                When you know how to ask and tell time in German, it is very
                significant that you know all numbers in German correctly. It
                should not be the case that you are speaking the phrase
                correctly like an expert but the wrong numbers. Imagine what
                blunders can wrong numbers make!
              </li>

              <li>
                <span style={{ fontWeight: 'bold' }}>
                  Related words for vocabulary:
                </span>
                The next very important step is to learn the vocabulary related
                to such conversations. It is always good to have knowledge about
                words that could be used. This would only add to your pool of
                vocabulary.
              </li>

              <li className="u-margin-bottom-medium">
                <span style={{ fontWeight: 'bold' }}>
                  The 24 hour clock and some tricky numbers:{' '}
                </span>
                You should be aware of the 24 hour clock. It is just a practice
                that can help you with that. Then we will learn all the tricky
                times of the day that could be.
              </li>
            </ul>
            <div
              style={{
                backgroundColor: 'black',
                color: 'white',
                textAlign: 'center',
              }}
            >
              <h2 className="u-margin-bottom-medium">
                How to Ask and Tell Time in German?
              </h2>
            </div>
            <p>
              On your visit to Germany, you are wondering what exactly is the
              current time in Germany. So you should be able to ask what time is
              it in Germany. There are basic phrases you can learn for asking
              time in German. Just change the time as per the situation and you
              are good to go.
            </p>
            <h3>Use the following phrases for asking time:</h3>
            <ul>
              <li>Wie viel Uhr ist es?</li>
              <li className="u-margin-bottom-medium">
                (Literal meaning: How much time is it?)
              </li>
              <li>Wie viel Uhr haben wir?</li>
              <li className="u-margin-bottom-medium">
                (Literal meaning: How much time do we have?)
              </li>
              <li>Wie spät ist es?</li>
              <li className="u-margin-bottom-medium">
                (Literal meaning: How late is it?)
              </li>
            </ul>
            <p>
              These were the informal ways of asking someone about the time. You
              could use them in informal conversations with your friends. Their
              literal meanings are different but actually they all mean the same
              “what time is it?”.
            </p>
            <p>
              For formal conversations, maybe with elder people or wherever you
              are showing some respect and want to talk politely, you can say:
            </p>
            <div style={{ marginLeft: '30px' }}>
              <p>
                Entschuldigung, könnten Sie mir bitte sagen, wie viel Uhr es
                ist?
              </p>
              <p>
                Entschuldigung, könnten Sie mir bitte sagen, wie viel Uhr wir
                haben?
              </p>
            </div>
            <p>
              The trick is simple, just add “Entschuldigung, könnten Sie mir
              bitte sagen,” before the above questions and you will sound
              polite. Just remember one thing, for correct grammatical
              structure, after the comma take the verb on the end.
            </p>
            <p>
              We have learnt how to ask time, now let see how do we tell time in
              German. It is even more easier. There is just one fixed phrase.
              You will just have to change the time in it.
            </p>
            <div style={{ marginLeft: '30px' }}>
              <p>Es it 10 Uhr.</p>
            </div>
            <p className="u-margin-bottom-medium">
              This is it. You just need to say “Es ist” which means “it is/ the
              time is”.
            </p>
            <div
              style={{
                backgroundColor: 'black',
                color: 'white',
                textAlign: 'center',
              }}
            >
              <h2 className="u-margin-bottom-medium">
                German Vocabulary Related to Time
              </h2>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  src={Image1}
                  alt="current-time-in-germany"
                  className="article__featured-image"
                />
              </div>
              <div className="col-1-of-2">
                <p>
                  Let us look at some of the essential words you need to know
                  when talking about time. This will add to your pool of basic
                  vocabulary needed to converse in German. Plus, you will
                  encounter these words very frequently in this lesson.
                </p>
                <ul>
                  <li>Uhr – O’Clock</li>
                  <li>Zeit – Time</li>
                  <li>Um – At/Around</li>
                  <li>Vor – Before</li>
                  <li>Nach – After</li>
                  <li>Kurz – Shortly</li>
                  <li>Viertel – Quarter</li>
                  <li>Halb – Half</li>
                  <li>Heute – Today</li>
                  <li>Morgen – Tomorrow/morning</li>
                  <li>Gestern – Yesterday</li>
                  <li>Tag – Day</li>
                  <li>Woche – Week</li>
                  <li>Übermorgen – Day after tomorrow</li>
                  <li>Vorgestern – Day before yesterday</li>
                  <li>Vormittag – Forenoon</li>
                  <li>Mittag – Noon</li>
                  <li>Nachmittag – Afternoon</li>
                  <li>Abend – Evening</li>
                  <li>Nacht – Night</li>
                </ul>
                <p>
                  I have stated these words with respect to the concept of time.
                  But there could be other meaning of these words as well based
                  on the context. Make sure you thoroughly know all the numbers
                  in German as well since you don not want to mess up with the
                  numerical time.
                </p>
              </div>
            </div>
            <div
              style={{
                backgroundColor: 'black',
                color: 'white',
                textAlign: 'center',
              }}
            >
              <h2 className="u-margin-bottom-medium">
                Different Ways To Tell Time in German
              </h2>
            </div>
            <p>
              Now let us move to how exactly do we tell time in German. We have
              set the base for it and seen all the requirements to learn to tell
              time clocks.
            </p>
            <p>
              There are different ways to tell time in German. Depending on the
              situation you use that way for time quotes. One is the offiziell
              (official / formal) way which is mainly used in formal scenarios;
              when you are talking to some stranger or elderly people. The other
              is the informell (informal) way of telling the time.
            </p>
            <p>
              Another important concept is the 12 hour and 24 hour method of
              telling time. 12 hour clock is mainly used in informal scenarios,
              in usual daily language. The 24 hour clock is more likely to be
              used in formal / official cases, like in flight timing or
              corporate appointment you will find 24 hour clock being used.
            </p>
            <h3 className="u-margin-bottom-medium">To Tell Full Hour...</h3>
            <p>
              When we have full hour, we simply put Uhr after the number. The
              structure of the sentence will be:
            </p>
            <p>Es ist [hour number] Uhr.</p>
            <ul>
              <li>Es ist ein Uhr</li>
              <p>It is one o’clock.</p>
              <li>Es ist zwei Uhr.</li>
              <p>It is two o’clock.</p>
              <li>Es ist drei Uhr.</li>
              <p>It is three o’clck.</p>
              <p>
                This was pretty much similar to English. The same sentence
                structure is used. This was the 12 hour format. For the 24 hour
                clock, you can say like,
              </p>
              <li>Es is dreizehn Uhr.</li>
              <p>It is 15 o’clock.</p>
              <li>Es ist vierzehn Uhr.</li>
              <p>It is 14 o’clock.</p>
              <li>Es ist fünfzehn Uhr.</li>
              <p>It is 15 o’clock.</p>
              <p>
                If you find 24 hour clock difficult to remember, I have a trick
                for you. You just need to subtract 12 from the 24 hour time. For
                example, 13 – 12 = 1. Thus the time is 1 o’clock. Likewise, 14 –
                12 = 2; therefore 2 o’clock.
              </p>
              <h4 className="u-margin-bottom-medium">
                Please note that there is no am and pm in German. For referring
                to such time zones, you can simply add “morgens”, “abends” along
                the time.
              </h4>
            </ul>
            <h3 className="u-margin-bottom-medium">
              To Tell Minutes with Hour...
            </h3>
            <p>
              It is very much straight how to say full hours in German. The
              structure was same as in English. But when we have to say minutes
              along with hours, it becomes a little different. As I mentioned
              above that there are two ways of expressing time, official and
              informal. We will learn time as per both these forms.
            </p>
            <div style={{ textAlign: 'center' }}>
              <img src={Image2} alt="german-time" style={{ width: '70%' }} />
            </div>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <img
                src={Table1}
                alt="german-time-table-1"
                style={{ width: '100%' }}
              />
            </div>
            <div
              className="u-margin-bottom-medium"
              style={{ textAlign: 'center', marginTop: '-20px' }}
            >
              <img
                src={Table2}
                alt="german-time-table-2"
                style={{ width: '100%' }}
              />
            </div>
            <div
              style={{
                backgroundColor: 'black',
                color: 'white',
                textAlign: 'center',
              }}
            >
              <h2 className="u-margin-bottom-medium">Quick Recall</h2>
            </div>
            <p>Let us recall what we have done in a very summarized form:</p>
            <ul>
              <li>To write full hour, we write in the following form:</li>
              <p>Es ist [Time] Uhr.</p>
              <p>Es ist vier Uhr. (It is four o’clck.)</p>
              <li>To write half time like 1:30, 2:30 etc:</li>
              <p>
                In English we say it is “one thirty”. But in German, we round it
                up to next number. So it becomes “halb zwei”. Some more
                examples:
              </p>
              <p>Es ist halb drei. (Its is two-thirty.)</p>
              <p>Es ist halb vier. (It is three-thirty.)</p>
              <li>
                Like in English we use quarter past and quarter to. The same way
                in German as well we say time in this way. Mostly in informal
                surrounding.
              </li>
              <p>Es ist viertel nach drei. (It is quarter past three – 3:15)</p>
              <p>Es ist viertel vor vier. (It is quarter to four – 3:45)</p>
              <li>The watch above will help you when to use nach and vor.</li>
              <ul>
                <li>
                  Usually, for time till 20 minutes on the clock, you use nach
                  (which means after) for the hour.
                </li>
                <p>Example – Es is zwanzig nach sechs. (6:20)</p>
                <li>
                  Another way, you can also use vor (which means before) the
                  half of that hour.
                </li>
                <p>Example – Es ist zwanzig vor halb sieben. (6:20)</p>
                <li>
                  In the above example it is clear that for time from 20 minutes
                  till 30 minutes, you use vor for the half of that hour.
                </li>
                <li>
                  For time from 30 to 40 minutes, you can use nach of half hour.
                </li>
                <p>Example – Es ist zehn nach halb sieben. (6:40)</p>
                <li>
                  For time from 40 minutes till full hour, you can use vor for
                  the full hour.
                </li>
                <p>Example – Es ist zwanzig vor sieben. (6:40)</p>
              </ul>
            </ul>
            <div className="row">
              <div className="col-1-of-2">
                <div
                  style={{
                    backgroundColor: 'green',
                    color: 'white',
                    padding: '0 5px',
                    height: '275px',
                  }}
                >
                  <p>
                    When just two or three minute are left for some time to
                    happen, you can simply use gleich as well instead of telling
                    the exact minutes or you can use kurz vor.
                  </p>
                  <p>Es ist gleich zwei. (13.58)</p>
                  <p>Es ist kurz vor zwei. (13.58)</p>
                </div>
              </div>
              <div className="col-1-of-2">
                <div
                  style={{
                    backgroundColor: 'skyblue',
                    color: 'white',
                    padding: '0 5px',
                  }}
                >
                  <p>
                    When just two or three minutes have past from some time, you
                    can say kurz nach instead of telling exactly how many
                    minutes have past. Likewise when full hour happens, you can
                    also say genau (which means exactly).
                  </p>
                  <p>Es ist kurz nach zwei. (14.03)</p>
                  <p>Es ist genau zwei. (14.00)</p>
                </div>
              </div>
            </div>
            <p>
              This is the whole concept of learning time clock. I can
              understand, you might be finding it a bit tricky in the beginning.
              You must be wondering that for speaking about a time we have two
              different ways. I would suggest you to read it a number of times
              to totally grasp the concept.
            </p>
            <p>
              Let’s be happy that making plans and scheduling things will be a
              piece of cake after grasping this piece of information. Go ahead
              and judge yourselves with these times. Write down the answers and
              start practicing:
            </p>
            <p>17.30, 20.15, 6.00, 8.10, 10.25, 12.35, 13.45, 16.50</p>
            <h3>Also read :</h3>
            <p>
              <a href="/german-alphabet-letters/">German Alphabet</a>
            </p>
            <p>
              <a href="/german-numbers/">Numbers in German</a>
            </p>
            <p>
              <a href="/german-umlaut/">Umlaut in German</a>
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default germanTime;
