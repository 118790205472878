import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

import '../sass/main.scss';
import Image from '../assets/japanese.jpg';
import Desu from '../assets/Desu Japanese.jpg';
import Particles from '../assets/particles-in-japanese.jpg';

const japanese = () => {
  const head = () => (
    <MetaTags>
      <title>Learn japanese with LinguaPsych | LinguaPsych</title>
      <meta
        name="description"
        content="Learn japanese with easy blogs, learn about japanese grammar, japanese vocubalary and patterns with easy examples."
      />{' '}
      <link rel="canonical" href="https://linguapsych.com/japanese/" />
      <meta
        property="og:title"
        content="Learn japanese with easy blogs, learn about japanese grammar, japanese vocubalary and patterns with easy examples."
      />{' '}
      <meta
        property="og:description"
        content="Learn japanese with easy blogs, learn about japanese grammar, japanese vocubalary and patterns with easy examples."
      />{' '}
      <meta property="og:type" content="website" />
      <meta property="og:url" href="https://linguapsych.com/japanese/" />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/japanese.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/japanese.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}

      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_DOMAIN_PRODUCTION}`}>
          <div className="article__general-banner">
            <div className="article__general-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img src={Image} alt="japanese" className="article__featured-image" />
          <div className="article__header--text">
            <h1 className="article__title ">
              {' '}
              {/* <span> Learn japanese</span><div>{' '} */}
            </h1>
          </div>
        </header>
        <div className="row">
          <div className="col-1-of-2">
            <Link to="/desu-japanese/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img src={Desu} style={{ width: '100%' }} height="170px" />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>Definitive Guide on Japanese Desu</h2>
                  </div>
                  <div>
                    <p>
                      Desu in Japanese (When to use, how to pronounce? desu or
                      dess?)
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-1-of-2">
            <Link to="/japanese-particles/">
              <div className="blog-card__container">
                <div className="blog-image">
                  <img
                    src={Particles}
                    style={{ width: '100%' }}
                    height="170px"
                  />
                </div>
                <div className="blog-text">
                  <div className="blog-title">
                    <h2>Japanese Particles: The Essential Guide</h2>
                  </div>
                  <div>
                    <p>
                      Japanese Particles: The Essential Guide to wa, ga, wo, ni,
                      mo & much more.
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default japanese;
