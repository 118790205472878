import React from 'react';
import { Link } from 'react-router-dom';

import '../sass/main.scss';
import MetaTags from 'react-meta-tags';
import Image from '../assets/spanish-plural-noun.jpg';
import Spanish from '../assets/spanish_course_bottom.png';

const spanishPlural = () => {
  const head = () => (
    <MetaTags>
      <title>5 easy ways to make Spanish Plural Nouns | LinguaPsych</title>
      <meta
        name="description"
        content="In this lesson you will learn how to make a Spanish noun plural in 5 easy ways, this lesson is for complete beginners &amp; is constructed in a very basic way."
      />{' '}
      <link
        rel="canonical"
        href="https://linguapsych.com/spanish-plural-nouns/"
      />
      <meta
        property="og:title"
        content="In this lesson you will learn how to make a Spanish noun plural in 5 easy ways, this lesson is for complete beginners &amp; is constructed in a very basic way."
      />{' '}
      <meta
        property="og:description"
        content="In this lesson you will learn how to make a Spanish noun plural in 5 easy ways, this lesson is for complete beginners &amp; is constructed in a very basic way."
      />{' '}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        href="https://linguapsych.com/spanish-plural-nouns/"
      />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/spanish-plural-noun.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/spanish-plural-noun.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}

      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_SPANISH_COURSE}`}>
          <div className="article__spanish-banner">
            <div className="article__spanish-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="spanish-plural-noun"
            className="article__featured-image"
          />
          <div className="article__header-elements">
            <h1 className="article__title ">
              {' '}
              <span
                style={{ fontSize: '5rem' }}
                className="article__highlight--black "
              >
                {' '}
                Easy Ways To Make Spanish Plural Nouns
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <h2 className="u-margin-bottom-medium">Spanish Plural Nouns</h2>
            <p>
              Spanish Nouns are always used with the articles except the names
              of people. So if we want to make a noun plural, not only the noun
              will change it’s ending but also the article. So as we know the
              masculine article is El and feminine article is La.
            </p>
            <h3>El ⇒ Los</h3>
            <h3>LA ⇒ Las</h3>
            <p>So as per the above rule,</p>
            <h4>El reloj becomes los relojes</h4>
            <h4>La mujer becomes las mujeres</h4>
            <p>
              Now let’s have a look at the rules to remember for making a noun
              plural.
            </p>
            <h2 className="u-margin-bottom-medium">
              Nouns that end with a vowel.
            </h2>
            <h4>Make it plural by adding -s</h4>
            <p>
              Let’s start with the easy stuff. All the nouns that end with a
              vowel can be easily converted into plural by adding the alphabet
              -s behind them. Let’s look at the examples
            </p>
            <p>El padre ⇒ Los padres</p>
            <p>El chico ⇒ Los chicos</p>
            <p>La silla ⇒ Las sillas</p>
            <p>La toalla ⇒ Las toallas</p>
            <p>El libro ⇒ Los libros</p>
            <h2 className="u-margin-bottom-medium">
              Nouns that end with a consonant
            </h2>
            <h4>Make it plural by adding -es</h4>
            <p>
              When a Spanish noun ends with a vowel followed by d, j, l, n, s,
              x, y or ch. Add -es at the end.
            </p>
            <h4>Here are some examples</h4>
            <p>La edad ⇒ Las edades</p>
            <p>El reloj ⇒ Los relojes</p>
            <p>El global ⇒ Los globales</p>
            <p>La tensión ⇒ Las tensiones</p>
            <p>El mes ⇒ Los meses</p>
            <p>El fax ⇒ Los faxes</p>
            <p>El rey ⇒ Los reyes</p>
            <p>El sandwich ⇒ Los sandwiches</p>
            <h4>Make it plural be adding -s</h4>
            <p>
              We studied a situation where a noun that ends with a consonant
              following by d, j, l, n, s, x, y or ch were made plural by simply
              adding -es.
            </p>
            <p>
              In all the other situations where the above stated rule is not
              followed just add -s to make it plural. So what can be those
              situations? Let’s have a look:
            </p>
            <p>
              When a noun ends with a vowel followed by a consonant not stated
              above. For example…
            </p>
            <p>El kebab ⇒ Los kebabs</p>
            <p>
              When a noun has 2 consonants together at the end. for example..
            </p>
            <p>El iceberg ⇒ Los icebergs</p>
            <h2 className="u-margin-bottom-medium">When a noun ends with z</h2>
            <h4>Change the ending by replacing -z with -ces.</h4>
            <p>La lapiz ⇒ Las lapices</p>
            <p>La nariz ⇒ Las narices</p>
            <p>
              If you missed out our basic lesson on{' '}
              <a href="/spanish-nouns-articles-genders">
                {' '}
                Spanish Nouns and Articles,
              </a>{' '}
              please click the link.
            </p>
            <h4>Also check out:</h4>
            <ul>
              <li>
                <a href="/direct-indirect-object-pronouns/">
                  Spanish Direct and Indirect Object Pronouns
                </a>
              </li>
              <li>
                <a href="/subject-personal-pronouns-in-spanish/">
                  Complete guide on Subject Pronouns in Spanish
                </a>
              </li>
              <li>
                <a href="/spanish-definite-indefinte-articles/">
                  Guide to Spanish Definite and Indefinite Articles
                </a>
              </li>
            </ul>
          </div>
          <div className="article__blog-container__bottom">
            <div
              className="article__blog-container__bottom__left"
              to="/learn-spanish"
            >
              <Link to="/learn-spanish">
                <img
                  className="article__blog-container__bottom__image"
                  src={Spanish}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/spanish-days-of-the-week/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Days of the Week in Spanish
                </li>
              </Link>
              <Link to="/subject-personal-pronouns-in-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Subject Personal Pronouns
                </li>
              </Link>

              <Link to="/spanish-definite-indefinte-articles/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Definite and Indefinite Articles
                </li>
              </Link>
              <Link to="/preterite-vs-imperfect/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Preterite vs Imperfect
                </li>
              </Link>
              <Link to="/months-in-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Months in Spanish
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default spanishPlural;
