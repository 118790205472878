import React from 'react';

const SectionFeatures = () => {
  return (
    <section className="course-section-features">
      <div className="course-section-features__title">Why LinguaPsych?</div>
      <div className="row">
        <div className="col-1-of-4">
          <div className="course-feature-box">
            <i className="fas fa-video"></i>
            <h3 className="course-feature-box__heading">
              Comprehensive Video Lessons
            </h3>

            <p className="course-feature-box__text">
              Have no time to learn? Watch a video lesson anytime, anywhere at
              your ease; from office, train or couch.
            </p>
          </div>
        </div>
        <div className="col-1-of-4">
          <div className="course-feature-box">
            <i className="fas fa-file-pdf"></i>
            <h3 className="course-feature-box__heading">Downloadable Notes</h3>

            <p className="course-feature-box__text">
              Want something in written? Download the notes of the lesson you
              watch for reference.
            </p>
          </div>
        </div>
        <div className="col-1-of-4">
          <div className="course-feature-box">
            <i className="fas fa-chalkboard-teacher"></i>
            <h3 className="course-feature-box__heading">Quiz / Exercises</h3>

            <p className="course-feature-box__text">
              Practice what you’ve learnt after watching each video lesson, so
              you never forget that ever.
            </p>
          </div>
        </div>
        <div className="col-1-of-4">
          <div className="course-feature-box">
            <i className="fas fa-book-reader"></i>
            <h3 className="course-feature-box__heading">
              Weekly Assignments / Projects
            </h3>

            <p className="course-feature-box__text">
              Complete the weekly assignment/project and submit on time to get
              personalized feedback from us.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-1-of-4">
          <div className="course-feature-box">
            <i className="fas fa-pager"></i>
            <h3 className="course-feature-box__heading">
              Vocabulary Flashcards
            </h3>

            <p className="course-feature-box__text">
              Learn new words every day, in the form of flashcards (with
              examples) for better retention.
            </p>
          </div>
        </div>
        <div className="col-1-of-4">
          <div className="course-feature-box">
            <i className="fas fa-headphones-alt"></i>
            <h3 className="course-feature-box__heading">
              Listening & Reading Comprehension
            </h3>

            <p className="course-feature-box__text">
              Boost confidence in listening as well as reading skills by testing
              yourself with carefully designed comprehensions.
            </p>
          </div>
        </div>
        <div className="col-1-of-4">
          <div className="course-feature-box">
            <i className="fas fa-podcast"></i>
            <h3 className="course-feature-box__heading">Podcasts</h3>

            <p className="course-feature-box__text">
              Get professional help in language learning, even when you are on
              the go and don’t have much time.
            </p>
          </div>
        </div>
        <div className="col-1-of-4">
          <div className="course-feature-box">
            <i className="fas fa-map"></i>
            <h3 className="course-feature-box__heading">
              Interactive Curriculum
            </h3>

            <p className="course-feature-box__text">
              Stay motivated and focused with our well-organized day-to day
              curriculum that guides you on every step.
            </p>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-1-of-4">
          <div className="course-feature-box">
            <i className="fab fa-affiliatetheme"></i>
            <h3 className="feature-box__heading"> Flexible Schedule</h3>

            <p className="feature-box__text">
              Start instantly and learn at your own schedule by taking out at
              minimum 30-60 minutes a day, at anytime, anywhere.
            </p>
          </div>
        </div>
        <div className="col-1-of-4">
          <div className="feature-box">
            <i className="fas fa-spell-check"></i>
            <h3 className="feature-box__heading">Grammatical Perfection</h3>

            <p className="feature-box__text">
              Learn with perfection, to sound like a professional by diving deep
              in the grammatical reasoning behind everything.
            </p>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default SectionFeatures;
