import React from 'react'

const Loader = () => {
    return (
        <div className="loader-container" >
        <h1>Loading</h1>
            <div className="circle-container">
                <div className="circle circle-1"></div>
                <div className="circle circle-2"></div>
                <div className="circle circle-3"></div>
            </div>
        </div>
    );
}

export default Loader;

// import React from 'react';
// import Loader from 'react-loader-spinner';
// export default class App extends React.Component {
//   //other logic
//   render() {
//     return (
//       <Loader
//         type="Rings"
//         color="#00BFFF"
//         height={100}
//         width={100}
//         timeout={20000} //3 secs
//       />
//     );
//   }
// }
