import React from 'react';
import { Link } from 'react-router-dom';

import '../sass/main.scss';
import Image from '../assets/Spanish-Subject-Personal-Pronouns.jpeg';
import MetaTags from 'react-meta-tags';
import Spanish from '../assets/spanish_course_bottom.png';

const spanishPronouns = () => {
  const head = () => (
    <MetaTags>
      <title>
        Easy Guide to Subject(Personal) pronouns in Spanish | LinguaPsych
      </title>
      <meta
        name="description"
        content="This is a beginners guide to learn Subject or Personal Pronouns in Spanish, everything is explained in a very easy way so that you never forget it."
      />{' '}
      <link
        rel="canonical"
        href="https://linguapsych.com/subject-personal-pronouns-in-spanish/"
      />
      <meta
        property="og:title"
        content="This is a beginners guide to learn Subject or Personal Pronouns in Spanish, everything is explained in a very easy way so that you never forget it."
      />{' '}
      <meta
        property="og:description"
        content="This is a beginners guide to learn Subject or Personal Pronouns in Spanish, everything is explained in a very easy way so that you never forget it."
      />{' '}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        href="https://linguapsych.com/subject-personal-pronouns-in-spanish/"
      />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/Spanish-Subject-Personal-Pronouns.jpeg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/Spanish-Subject-Personal-Pronouns.jpeg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}

      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_SPANISH_COURSE}`}>
          <div className="article__spanish-banner">
            <div className="article__spanish-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="Korean-sentence-structure"
            className="article__featured-image"
          />
          <div className="article__header-elements">
            <h1 className="article__title ">
              {' '}
              <span
                style={{ fontSize: '5rem' }}
                className="article__highlight--black "
              >
                {' '}
                Easy Guide On Subject (Personal) Pronouns In Spanish
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <p>
              As the name suggests, Pronoun is a pro-form which substitutes a
              noun or a noun phrase. Repeating a noun again and again while
              narrating a story can create boredom or disinterest. Pronouns were
              designed to avoid the situation where the speaker has to use the
              same noun repeatedly.
            </p>
            <p>
              Like in English, Spanish has Subject Pronouns and Object Pronouns.
              Let’s talk about Subject or Personal Pronouns first.
            </p>
            <h2 className="u-margin-bottom-medium">
              Subject Pronouns in Spanish
            </h2>
            <p>
              Personal Pronouns should be used after the introduction of the
              Subject. Otherwise, it will be complicated to understand the
              context of the sentence.
            </p>
            <p>
              The subject is the Hero of a sentence and the whole story revolves
              around it. Hence it is crucial to use the correct Pronoun as you
              don’t want to confuse the listener.
            </p>
            <p>
              English has six Personal Pronouns whereas Spanish has double the
              number as compared to English. Below is the list.
            </p>
            <table
              style={{
                borderCollapse: 'collapse',
                width: ' 61.9864%',
                height: '284px',
              }}
            >
              <tbody>
                <tr>
                  <td style={{ width: '33.3333%', textAlign: 'center' }}>
                    <span style={{ color: '#000000' }}>English</span>
                  </td>
                  <td style={{ width: '33.3333%', textAlign: 'center' }}>
                    <span style={{ color: '#000000' }}>Spanish</span>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '33.3333%', textAlign: 'center' }}>I</td>
                  <td style={{ width: '33.3333%', textAlign: 'center' }}>Yo</td>
                </tr>
                <tr>
                  <td style={{ width: '33.3333%', textAlign: 'center' }}>
                    You (Informal)
                  </td>
                  <td style={{ width: '33.3333%', textAlign: 'center' }}>tú</td>
                </tr>
                <tr>
                  <td style={{ width: '33.3333%', textAlign: 'center' }}>
                    You (Formal)
                  </td>
                  <td style={{ width: '33.3333%', textAlign: 'center' }}>
                    Usted
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '33.3333%', textAlign: 'center' }}>We</td>
                  <td style={{ width: '33.3333%', textAlign: 'center' }}>
                    Nosotros/Nosotras
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '33.3333%', textAlign: 'center' }}>
                    You (Plural)
                  </td>
                  <td style={{ width: '33.3333%', textAlign: 'center' }}>
                    Vosotros/Vosotras
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '33.3333%', textAlign: 'center' }}>
                    They
                  </td>
                  <td style={{ width: '33.3333%', textAlign: 'center' }}>
                    Ellos/Ellas
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '33.3333%', textAlign: 'center' }}>
                    You Formal (Plural)
                  </td>
                  <td style={{ width: '33.3333%', textAlign: 'center' }}>
                    Ustedes
                  </td>
                </tr>
              </tbody>
            </table>
            <h2 className="u-margin-bottom-medium">
              Yo Tú El Ella Nosotros Vosotros Ellos Ellas
            </h2>
            <h3>Spanish Yo</h3>
            <p>There are just two differences between I in English and yo.</p>
            <ul>
              <li>
                Unlike I in English, yo doesn’t start with a Capital unless it
                is used in the beginning of a sentence.
              </li>
              <li>
                Yo can be omitted if you use the first person conjugation. For
                example
              </li>
            </ul>
            <p> Yo como el pan</p>
            <p> Como el pan</p>
            <p> Both sentences mean the same thing, I eat bread.</p>
            <h3>Tú vs. Usted</h3>
            <p>
              As a foreign language enthusiast, all the languages I have
              studied, there is one thing in common, which is the plural form.
              English, on the other hand, doesn’t provide that angle to the
              native speakers. I am not saying that’s a negative point it’s just
              that it takes some extra effort to get used to the Formal form.
            </p>
            <p>
              Spanish isn’t an exception either, we have a formal form which is
              Usted for singular and Ustedes for plural. It is a broad topic in
              itself but let’s quickly discuss some situation when to use Tú and
              when to use Usted.
            </p>
            <h3>When to use Tú?</h3>
            <ul>
              <li>With friends, family or relatives</li>
              <p>
                You can use Tú with people who are close to you, friends and
                family are no exception. With people you are close to, the age
                or the relation doesn’t matter. Even if you’re talking to your
                Grandfather or Great grandfather, using Tú will sound more
                natural.
              </p>
              <li>People in same age group</li>
              <p>
                It is very natural in Spain and other Spanish speaking countries
                to address the people in the same age group as tú. If you’re not
                strangers and the age group seems close to yours, Use Tú without
                hesitation.
              </p>
              <li>With pets and children</li>
              <p>
                Spanish people love pets and love talking to them. It is quite
                apparent that using Usted with pets will sound odd. Same goes
                for children, use Tú with kids and pets.
              </p>
              <li>With co-workers you know well</li>
              <p>
                Spanish people are very friendly with people they work with,
                addressing your colleagues with Tú is very common in Spain. Feel
                free to use this pronoun with your office colleagues unless you
                work in a Japanese company.
              </p>
              <li>When you’re angry</li>
              <p>
                Using Usted while you’re angry and abusing is not going to look
                good at all, you’re free to abuse or swear in a straightforward
                form Tú. It’s a human thing, and I can understand.
              </p>
            </ul>
            <h2 className="u-margin-bottom-medium">When to use Usted?</h2>
            <ul>
              <li>With your clients in office</li>
              <p>
                You are working in an office, or you own one, you would like to
                grow your business. Whichever country you’re in if you want more
                business, the best thing you can do is be formal and look
                professional. In Spain, there is no exception. Spanish people
                use the formal form with clients or with their bosses.
              </p>
              <li>With older people</li>
              <p>
                If you’re a teenager or just above that age group, using Usted
                with the older people will be more respectful as the same thing
                is followed by all the Spanish speaking citizens. In many cases.
                older people don’t like to be addressed as Usted and they will
                ask you to address them as Tú.
              </p>
              <li>With Government officials</li>
              <p>
                If you’re in a situation where you are stuck with Government
                officials like Police, judges or Bureaucrats, it is needless to
                say, addressing them with Usted will be a wise decision.
              </p>
              <li>With strangers</li>
              <p>
                No need to address this one, using Tú with strangers will
                portray as if the two already have some kind of connection which
                can create an odd situation for both persons. Don’t get into
                that situation; use Usted with strangers.
              </p>
              <p>
                If you’re stuck in any other situation, using Usted will be a
                better option.
              </p>
            </ul>
            <h2 className="u-margin-bottom-medium">Nosotros or Nosotras?</h2>
            <h3>When to use Nosotras?</h3>
            <p>
              Nosotras will be used only when the whole bunch of people are
              women. If there is a single male in the group Nosotras must not be
              used.
            </p>
            <h3>When to use Nosotros?</h3>
            <p>
              For using Nosotros, the whole group doesn’t need to be male only
              group. In a group with all men or a mixed one, nosotros should be
              used. Even if the group of 100 has 99 females and one man, use
              nosotros.
            </p>
            <h3>Vosotros vs Ustedes</h3>
            <p>
              As we studied above the difference between Tú and Usted. Vosotros
              and Ustedes are no exceptions. The only difference between Tú and
              Vosotros is that the prior one is used for a single person and the
              later one is used for more than one.
            </p>
            <div style={{ textAlign: 'center' }}>
              <p>Tú ⇒ You</p>
              <p>Vosotros ⇒ You all</p>
              <p>Usted ⇒ You (Formal)</p>
              <p>Ustedes ⇒ You all (Formal)</p>
            </div>
            <h3>Vosotros vs Vosotras</h3>
            <p>
              As discussed in the Nosotros case, if a group has all females
              Vosotras will be used, in all the other cases Vosotros will be
              used.
            </p>
            <h3>Ellos vs Ellas</h3>
            <p>Ellos and Ellas are the plural form of El and Ella.</p>
            <p>Ellos ⇒ They all ( Can be all men or men and women)</p>
            <p>Ellas ⇒ They all ( All women and no men)</p>
            <h3>Also check our exclusive guide on:</h3>
            <ul>
              <li>
                <a href="/direct-indirect-object-pronouns/">
                  Basics of Spanish Nouns and Articles
                </a>
              </li>
              <li>
                <a href="/spanish-plural-nouns/">
                  How to make Nouns Plural in Spanish
                </a>
              </li>
              <li>
                <a href="/spanish-definite-indefinte-articles/">
                  Complete guide on Definite and Indefinite Articles
                </a>
              </li>
              <li>
                <a href="/spanish-nouns-articles-genders/">
                  Spanish Direct and Indirect Object Pronouns
                </a>
              </li>
            </ul>
          </div>
          <div className="article__blog-container__bottom">
            <div
              className="article__blog-container__bottom__left"
              to="/learn-spanish"
            >
              <Link to="/learn-spanish">
                <img
                  className="article__blog-container__bottom__image"
                  src={Spanish}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/spanish-days-of-the-week/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Days of the Week in Spanish
                </li>
              </Link>
              <Link to="/spanish-numbers/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Numbers in Spanish
                </li>
              </Link>

              <Link to="/spanish-definite-indefinte-articles/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Definite and Indefinite Articles
                </li>
              </Link>
              <Link to="/preterite-vs-imperfect/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Preterite vs Imperfect
                </li>
              </Link>
              <Link to="/months-in-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Months in Spanish
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default spanishPronouns;
