import React from 'react';
import MetaTags from 'react-meta-tags';
import DocumentMeta from 'react-document-meta';
import { Link } from 'react-router-dom';

import '../sass/main.scss';
import Image from '../assets/Spanish-imperative.jpg';
import Table1 from '../assets/spanish-imperative-table1.PNG';
import Table2 from '../assets/spanish-imperative-table2.PNG';
import Table3 from '../assets/spanish-imperative-table2.PNG';
import Table4 from '../assets/spanish-imperative-table4.PNG';
import Spanish from '../assets/spanish_course_bottom.png';

const imperativeSpanish = () => {
  const head = () => (
    <MetaTags>
      <title>
        10 minutes Spanish Imperatives| Learn regular and amp; irregular verbs |
        LinguaPsych
      </title>
      <meta
        name="description"
        content="Learn how to use Spanish Imperatives in just 10 minutes, learn when to use Imperatives, what are the regular and irregular verbs, affirmative and negative."
      />{' '}
      <link
        rel="canonical"
        href="https://linguapsych.com/imperative-spanish/"
      />
      <meta
        property="og:title"
        content="Learn how to use Spanish Imperatives in just 10 minutes, learn when to use Imperatives, what are the regular and irregular verbs, affirmative and negative."
      />{' '}
      <meta
        property="og:description"
        content="Learn how to use Spanish Imperatives in just 10 minutes, learn when to use Imperatives, what are the regular and irregular verbs, affirmative and negative."
      />{' '}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        href="https://linguapsych.com/imperative-spanish/"
      />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/Spanish-imperative.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/Spanish-imperative.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}
      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_SPANISH_COURSE}`}>
          <div className="article__spanish-banner">
            <div className="article__spanish-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="Imperative-in-Spanish"
            className="article__featured-image"
          />
          <div className="article__header-elements">
            <h1 className="article__title ">
              {' '}
              <span
                style={{ fontSize: '5rem' }}
                className="article__highlight--black "
              >
                {' '}
                Learn Spanish Imperative in 10 minutes
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <h3>Imperative is a grammatical mood which is used to:</h3>
            <ul>
              <li>To give instructions</li>
              <li>To give permission</li>
              <li>When offering something</li>
              <li>To give advice</li>
            </ul>
            <h2 className="u-margin-bottom-medium">
              Imperative Affirmative in Spanish
            </h2>
            <p>
              Imperative affirmative in Spanish has 4 forms: tú and vosotras/as
              ( informal), usted and ustedes (formal). So what do these personal
              pronouns mean?
            </p>
            <ul>
              <li>tú – you (informal/singular)</li>
              <li>vosotros/as – you all (informal/plural)</li>
              <li>usted – you (formal/singular)</li>
              <li>ustedes – you all (formal/plural)</li>
            </ul>
            <p>
              Please note that imperatives in Spanish are not used with yo (I),
              él (he), ella (she) and nosotros (we).
            </p>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table1}
                alt="spanish-imperative-table1"
                className="article__big-images"
              />
            </div>
            <h2 className="u-margin-bottom-medium">
              How to form Imperative Affirmative in Spanish?
            </h2>
            <p>
              To learn a language efficiently, it’s always a good idea to build
              a solid foundation first by learning the rules. Here, we are going
              to learn how to make a verb Imperative with different personal
              pronouns.
            </p>
            <ul>
              <li>
                <h3>How to form Imperative with tú?</h3>
              </li>
              <p>
                With most of the verbs, making tú form of a verb imperative is
                going to be very easy, just take the present conjugated form of
                the verb with tú and remove -s. Let’s see how:
              </p>
              <p>piensas will become piensa.</p>
              <p>comes will become come.</p>
              <p>duermes will become duerme.</p>

              <h3>¡Atención!</h3>
              <p>
                There are certain irregular verbs that don’t follow the above
                rule
              </p>
              <li>salir = sal</li>
              <li>poner = pon</li>
              <li>hacer = haz</li>
              <li>venir = ven</li>
              <li>tener = ten</li>
              <li>decie = di</li>
              <p>
                We just saw, how to make tú form of a verb Imperative. Let’s
                look at few examples to make it even clearer
              </p>
            </ul>
            <div className="row">
              <div className="col-1-of-2">
                <ul>
                  <h3>To give instructions</h3>
                  <li>abre la ventana por favor</li>
                  <p>Open the window please</p>
                  <li>apaga la luz</li>
                  <p>Turn off the light</p>
                  <h3>To give permission</h3>
                  <li>¿Puedo entrar por favor?</li>
                  <p>May I enter please?</p>
                  <li>sí, pasa pasa</li>
                  <p>Yes, please enter</p>
                  <p></p>
                  <li>¿puedo tomar el libro?</li>
                  <p>Can I take the book?</p>
                  <li>sí, tomalo</li>
                  <p>Yes, take it</p>
                </ul>
              </div>
              <div className="col-1-of-2">
                <ul>
                  <h3> When offering something</h3>
                  <li>prueba la pizza, esta muy deliciosa</li>
                  <p>Try the pizza, it’s very tasty</p>
                  <li>toma la pelota y juega</li>
                  <p>Take the ball and play</p>
                  <h3>To give advice</h3>
                  <li>¿Qué me pongo hoy?</li>
                  <p>What should we wear today?</p>
                  <li>Ponganles la camisa negra, te queda muy bien</li>
                  <p>Wear the black shirt, it suits you very well</p>
                  <p></p>
                  <li>No sé qué comer</li>
                  <p>We don’t know what to eat</p>
                  <li>Coma la masa primero</li>
                  <p>Eat the pastry first</p>
                </ul>
              </div>
            </div>
            <ul>
              <li>
                <h3>How to form Imperative with usted?</h3>
              </li>
              <p>
                To form Imperative in usted form, you must know the present
                third person conjugation of usted. Take the conjugtion and
                reverse the ending vowel. Verbs ending with -ar will end with
                -e, verbs ending with -er, -ir will end with -a. Let’s see how:
              </p>
              <p>piensa will become piense.</p>
              <p>come will become coma.</p>
              <p>duerme will become duerma.</p>
              <h3>Let’s look at a few examples to make it even clear</h3>
              <div className="row">
                <div className="col-1-of-2">
                  <ul>
                    <h3>To give instructions</h3>
                    <li>abran la ventana por favor</li>
                    <p>Open the window please</p>
                    <li>apague la luz</li>
                    <p>Turn off the light</p>
                    <h3>To give permission</h3>
                    <li>¿Podemos entrar por favor?</li>
                    <p>May we enter please?</p>
                    <li>sí, pasen pasen</li>
                    <p>Yes, please enter</p>
                    <p></p>
                    <li>¿podemos tomar el libro?</li>
                    <p>Can we take the book?</p>
                    <li>sí, tomenlo</li>
                    <p>Yes, take it</p>
                  </ul>
                </div>
                <div className="col-1-of-2">
                  <ul>
                    <h3> When offering something</h3>
                    <li>prueben la pizza, esta muy deliciosa</li>
                    <p>Try the pizza, it’s very tasty</p>
                    <li>tomen la pelota y juega</li>
                    <p>Take the ball and play</p>
                    <h3>To give advice</h3>
                    <li>¿Qué me ponemos hoy?</li>
                    <p>What should we wear today?</p>
                    <li>Ponganles la camisa negra, te queda muy bien</li>
                    <p>Wear the black shirt, it suits you very well</p>
                    <p></p>
                    <li>No sabemos qué comer</li>
                    <p>We don’t know what to eat</p>
                    <li>Comen la masa primero</li>
                    <p>Eat the pastry first</p>
                  </ul>
                </div>
              </div>
              <h3>¡Atención!</h3>
              <p>
                There are certain irregular verbs that don’t follow the
                Imperative conjugation rule for usted and ustedes. These are the
                verbs which have an irregular first person conjugation in the
                present tense. What you need to do is, take the ending -o and
                replace it with -a for usted form and -an for ustedes form.
                Let’s look at the verbs
              </p>
              <li>pongo – ponga/pongan</li>
              <li>hago – haga/hagan</li>
              <li>salgo – salga/salgan</li>
              <li>tengo – tenga/tengan</li>
              <li>vengo – venga/vengan</li>
              <li>digo – diga/digan</li>
              <li>traigo – traiga/traigan</li>
              <li>conozco – conozca/conozcan</li>
            </ul>
            <h2 className="u-margin-bottom-medium">
              Imperative Affirmative for Ser and Ir
            </h2>
            <p>
              Imperative forms of one of the most used Spanish verbs ser and ir
              are unique and don’t follow the rules that we discussed above.
              Let’s see how are they conjugated in Imperative
            </p>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table2}
                alt="spanish-imperative-table-2"
                className="article__big-images"
              />
            </div>
            <h2 className="u-margin-bottom-medium">
              Using Imperative with Pronouns
            </h2>
            <p>
              When learning Imperatives it’s very important to learn how to
              position the pronouns because you’re gonna use it very often.
              Subject pronouns are often omitted and you’ll not hear someone
              using it while using the imperative. Apart from that, all the
              pronouns will come after the verb and will be attached to the verb
              making it a single word. Talking about the position, verb will be
              followed by the indirect object pronoun/reflexive pronoun
              following by the direct object pronouns. If the command is two
              syllables or more, an accent mark will be placed at the second to
              last syllable without counting the pronouns.
            </p>
            <ul>
              <li>
                <h3>With direct object pronouns</h3>
              </li>
              <li>cuéntame de tus padres</li>
              <p>Tell me about your parents</p>
              <li>Dime lo que pasó</li>
              <p>Tell me what happened</p>
              <li>
                <h3>With reflexive pronouns</h3>
              </li>
              <li>fíjate bien</li>
              <p>Pay attention</p>
              <li>
                <h3>With direct object pronouns</h3>
              </li>
              <li>Súbeme la radio</li>
              <p>Turn on the radio for me</p>
              <li>
                <h3>With direct and indirect object pronouns</h3>
              </li>
              <li>cómpramelo por favor</li>
              <p>Buy me that please</p>
            </ul>
            <h3>¡Atención!</h3>
            <p>
              In the reflexive verbs, when we combine the vosotros form of
              Imperative with the pronoun os, the -d at the end of the
              conjugated stem is replaced by os.
            </p>
            <div style={{ textAlign: 'center' }}>
              <img
                src="../assets/imperative-in-spanish.jpg"
                alt="imperative-in-spanish"
                className="article__big-images"
              />
            </div>
            <h2 className="u-margin-bottom-medium">
              Negative Imperative in Spanish
            </h2>
            <p>
              Unlike English, Spanish has different conjugations for
              Imperatives. In English, you just need to add don’t before the
              verb but here even the conjugation changes in some forms. Let look
              at the basic conjugations first
            </p>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table3}
                alt="spanish-imperative-table-2"
                className="article__big-images"
              />
            </div>
            <p>
              Making a verb Imperative in negative form is much more easier than
              the affirmative form. You don’t have to learn different rule for
              each pronoun, let’s see how to make verbs Imperative in it’s
              negative form
            </p>
            <ul>
              <li>
                <h3>With usted and ustedes</h3>{' '}
              </li>
              <p>
                As you might have noticed, Imperative form for usted and ustedes
                is same in both affirmative and negative form. You just need to
                add no before the Imperative form to make it negative.
              </p>
              <li>
                <h3>With -ar verbs</h3>{' '}
              </li>
              <p>
                Like I mentioned above, making a verb Imperative in it’s
                negative form is much simpler than to make it affirmative. With
                the -ar ending verbs, just take the present tense conjugation of
                the verb and replace the a with e.
              </p>
              <p>hablas – no hables</p>
              <p>habla – no hable</p>
              <p>habláis – no habléis</p>
              <p>hablan – no hablen</p>
              <li>
                <h3>With -er/ir verbs</h3>{' '}
              </li>
              <p>
                {' '}
                With the -er/ir verbs, it’s the opposite to what we do with -ar
                verbs. Here, replace the e of a present form conjugation with a.
                There is one exception, in -ir verbs the vosotros form -ís will
                become -áis rather becoming ás as per the rule.
              </p>
              <p>comes – no comas</p>
              <p>come – no come</p>
              <p>coméis – no comáis</p>
              <p>comen – no coman</p>
            </ul>
            <h3>¡Atención!</h3>
            <p>
              There are certain verbs that have the irregular first person
              conjugation in the present tense, those verbs don’t follow the
              negative Imperative conjugation rule for any personal pronoun.
              What you need to do is, take the ending -o of it’s first person
              form and make rest of the changes as per above mentioned rule.
              Let’s look at the conjugation of 2 irregular verbs.
            </p>
            <div className="row">
              <div className="col-1-of-2">
                <p>For verb poner which is pongo in first person conjugation</p>
                <p>tú – no pongas</p>
                <p>usted – n​o ponga</p>
                <p>vosotros – no pongáis</p>
                <p>ustedes – no pongan</p>
              </div>
              <div className="col-1-of-2">
                <p>
                  For verb conocer which is conozco in first person conjugation
                </p>
                <p>tú – no conozcas</p>
                <p>usted – n​o conozca</p>
                <p>vosotros – no conozcáis</p>
                <p>ustedes – no conozcan</p>
              </div>
            </div>
            <h2 className="u-margin-bottom-medium">
              Imperative Negative for ser, ir and estar
            </h2>
            <p>
              Just like Imperative in affirmative, verbs ser and ir don’t follow
              the regular Imperative rules, this time with the verb estar. Let’s
              see how to conjugate them
            </p>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table4}
                alt="spanish-imperative-table-4"
                className="article__big-images"
              />
            </div>
            <h2 className="u-margin-bottom-medium">
              Using Negative Imperative with Pronouns
            </h2>
            <p>
              Unlike the use of pronouns in the affirmative form of Imperative,
              the object pronouns will be used before the verb and not with it
              as in the affirmative for. Position of the pronouns will be the
              same as in the affirmative form, indirect object
              pronouns/reflexive pronouns will come before the direct object
              pronouns. They will be written separately and not as a same word.
            </p>
            <ul>
              <li>No me digas lo que tengo que hacer</li>
              <p>Don’t tell me what I have to do</p>
              <li>No me hagas preguntas tontas</li>
              <p>Don’t ask me silly questions</p>
              <li>No nos pidas que renunciemos</li>
              <p>Don’t ask us to quit</p>
              <li>No lo comas por favor</li>
              <p>Don’t eat it please</p>
              <li>No me lo escribas</li>
              <p>Don’t write me this</p>
            </ul>
            <h2 className="u-margin-bottom-medium">Conclusion</h2>
            <p>
              Today we learned where the Imperatives are used, how to make
              regular verbs Imperative with all it’s forms, how to use pronouns
              with Imperatives, irregular verbs which don’t follow the general
              rule. We learnt how negative Imperative form is different to the
              affirmative form. Also, we looked at the irregular verbs which
              have a seperate conjugation for becoming in their Imperative form.
            </p>
            <p>
              Interested in learning more? It can be a good idea to learn these
              grammar points as well
            </p>
            <ul>
              <li>
                <h3>
                  <a href="/preterite-vs-imperfect/">
                    Preterite ve Imperfect tense
                  </a>
                </h3>
              </li>
              <li>
                <h3>
                  <a href="/future-tense-spanish/">Future tense in Spanish</a>
                </h3>
              </li>
              <li>
                <h3>
                  <a href="/possessive-adjectives-spanish/">
                    Possessive Adjectives in Spanish
                  </a>
                </h3>
              </li>
            </ul>
          </div>
          <div className="article__blog-container__bottom">
            <div
              className="article__blog-container__bottom__left"
              to="/learn-spanish"
            >
              <Link to="/learn-spanish">
                <img
                  className="article__blog-container__bottom__image"
                  src={Spanish}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/spanish-definite-indefinte-articles/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Definite & Indefinite Articles
                </li>
              </Link>
              <Link to="/subject-personal-pronouns-in-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Subject Personal Pronouns
                </li>
              </Link>

              <Link to="/spanish-nouns-articles-genders/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Nouns, articles and Genders
                </li>
              </Link>
              <Link to="/spanish-alphabets-accents-pronunciation/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Alphabets in Spanish
                </li>
              </Link>
              <Link to="/possessive-adjectives-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Possessive Adjectives
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default imperativeSpanish;
