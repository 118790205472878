import React from 'react';

const Thankyou = () => {
  return (
    <div className="thankyou">
      <div className="thankyou__box">
        <h1 className="thankyou__box__description">
          Thank you for enrolling for the Workshop!
        </h1>
        <span className="thankyou__box__sub">
       You will receive a confirmation email shortly. Please get in touch if you have any questions.
        </span>
       
      </div>
    </div>
  );
};

export default Thankyou;
