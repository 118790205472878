import React from 'react';
import { Link } from 'react-router-dom';

import Spanish from '../assets/Spanish.png';
import German from '../assets/German.png';

const ChooseLanguage = () => {
  return (
    <div className="choose">
      <div className="choose__box">
        <div className="choose__title">Choose your favorite language</div>
        <div className="choose__container">
          <div className="choose__container__box">
            <img className="choose__container__box__spanish" src={Spanish} />
            <div className="choose__container__box__rect">
              <div className="choose__container__box__rect__left">
                Spanish Beginner Course
              </div>
              <Link to="/learn-spanish">
                <div className="choose__container__box__rect__right">
                  Go to course{' '}
                  <span style={{ marginLeft: '5px' }}>
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </div>
              </Link>
            </div>
          </div>
          <div className="choose__container__box">
            <img className="choose__container__box__spanish" src={German} />
            <div className="choose__container__box__rect">
              <div className="choose__container__box__rect__left">
                German Beginner Course
              </div>
              <Link to="/learn-german">
                <div className="choose__container__box__rect__right">
                  Go to course{' '}
                  <span style={{ marginLeft: '5px' }}>
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseLanguage;
