import React, { useState } from 'react';

const WeekPlan = () => {
  const [week1, setWeek1] = useState(false);
  const [week2, setWeek2] = useState(false);
  const [week3, setWeek3] = useState(false);
  const [week4, setWeek4] = useState(false);
  const [week5, setWeek5] = useState(false);
  const [week6, setWeek6] = useState(false);
  return (
    <div className="week-plan">
      <div className="week-plan__top">6 WEEKS. PERFECTLY STRUCTURED.</div>
      <div className="week-plan__second">
        Learn through a step-by-step curriculum
      </div>
      <div className="week-plan__third">
        Drastically improve your skills through in-depth video lessons,
        projects, and feedback. It’s a world-class curriculum and unforgettable
        learning experience designed to level you up.
      </div>
      <div className="week-plan__card">
        <div className="week-plan__card__top">6 WEEKS PLAN</div>
        <div className="week-plan__card__main">
          <div className="week-plan__card__main__left">1</div>
          <div className="week-plan__card__main__right">
            <div className="week-plan__card__main__right__title">
              Week #1 - Mastering the Fundamentals
            </div>
            <div className="week-plan__card__main__right__desc">
              In the first week, we will set up all the tools required for
              mastering Spanish, you will learn about the Spanish alphabets,
              accents, numbers and so much more.
            </div>
            <div
              onClick={() => setWeek1(!week1)}
              className="week-plan__card__main__right__show"
            >
              {' '}
              {!week1 ? ' + SHOW DAILY SCHEDULE' : ' - HIDE DAILY SCHEDULE'}
            </div>
            {week1 && (
              <div className="week-plan__card__main__right__cards">
                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Greetings and Goodbyes
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      We will start from the very basics learning your very
                      first Spanish words, formally and informally.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 1
                  </div>
                </div>
                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Learning the ABC of Spanish
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      Every language learning starts from ABCD, we will follow
                      the norms and learn the Spanish alphabets.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 2
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Learning the accents
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      Unlike English, Spanish uses a lot of accent marks which
                      can be daunting in the beginning, to take that fear away,
                      we will talk about the accent marks in Spanish.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 2
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Numbers in Spanish
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      To be able to Introduce yourself, you need to learn about
                      numbers, for example talking about your date of birth,
                      your age your time table etc. We will learn about Ordinal
                      numbers and Cardinal numbers.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 3
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Introduction
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      Now that you know about Spanish alphabets, accents and
                      numbers, it's time to introduce yourself, you'll learn how
                      to introduce yourself extensively. After this lesson
                      you'll feel confident about giving or writing your
                      introduction.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 4
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      ASKING QUESTIONS AND RESPONDING
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      By now, you have learnt how to introduce yourself, in this
                      lesson, you will learn how to ask question in Spanish and
                      how to respond when someone asks you a question.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 5
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      THINGS AROUND US
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, we will focus more on the vocabulary and
                      will talk about things we see in our daily lives. This
                      vocabulary will help in framing sentences in the future.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 6
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      DAYS OF THE WEEK
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      Learn how to say days of the week in Spanish. This lesson
                      will be very handy for you, to help you refer to any day
                      easily. You'll also learn to frame sentences in Spanish
                      while refering to a particular day. This lesson is a
                      consolidated guide for mastering not just the days
                      vocabulary but also the stringent grammar associated with
                      days in Spanish.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 7
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="week-plan__card__main">
          <div className="week-plan__card__main__left">2</div>
          <div className="week-plan__card__main__right">
            <div className="week-plan__card__main__right__title">
              Week #2 - Let's start talking
            </div>
            <div className="week-plan__card__main__right__desc">
              This week starts with talking about where you come from and gets
              into some grammar you'll need to frame grammatically correct
              sentences. You'll learn some practical concepts like telling your
              Email ID and talking about your likes and dislikes and start
              planning your days in Spanish.You'll start framing basic Spanish
              sentences.
            </div>
            <div
              onClick={() => setWeek2(!week2)}
              className="week-plan__card__main__right__show"
            >
              {' '}
              {!week2 ? ' + SHOW DAILY SCHEDULE' : ' - HIDE DAILY SCHEDULE'}
            </div>
            {week2 && (
              <div className="week-plan__card__main__right__cards">
                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      TELLING PHONE NUMBER & EMAIL
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn how do you tell or ask
                      someone about Email ID and phone number. You'll learn
                      pronunciaton of different symbols used in Email addresses,
                      which is different than in English. After this lesson, you
                      won't have any confusion understanding Email IDs.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 8
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      NOUNS AND ARTICLES
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      Everything you see around yourself is a noun, so to start
                      making sentences, it's very important to learn the nouns
                      in Spanish as they come with little exceptions. Since you
                      need to use articles with nouns in Spanish, we will see
                      how articles compliment the nouns.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 9
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      REGULAR VERBS AND CONJUGATIONS
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      Verbs are an integral part of any language, whatever you
                      do is a verb and in this lesson, we will see the regular
                      verbs in Spanish and how to conjugate them.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 10
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      HOW TO FORM SENTENCES
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      Now that we have some basic vocabulary in our bucket,
                      let's learn how to construct basic sentences in Spanish.
                      We will learn where to use the nouns and verbs while
                      forming a sentence.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 11
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      TALKING ABOUT LIKES AND DISLIKES
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      By now you have learnt how to introduce yourself, you know
                      about nouns and articles, in this lesson, you will learn
                      how to talk about your likes and dislikes which will help
                      you express yourself better.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 12
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      POSSESSIVE PRONOUNS & ADJECTIVES
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you learn how to talk about possession in
                      Spanish (my, your, etc). This is an introductory lesson to
                      possessive pronouns in Spanish and explaining the
                      difference between possessive pronouns and adjectives.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 13
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      USING VERB HABER AND TENER
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      This topic is a very confusing one for a lot of
                      beginnners, the verbs haber and tener are used very
                      extinsively in Spanish & sometimes they get very confusing
                      because of the same meaning. We will learn the differences
                      with the help of a lot of examples.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 14
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="week-plan__card__main">
          <div className="week-plan__card__main__left">3</div>
          <div className="week-plan__card__main__right">
            <div className="week-plan__card__main__right__title">
              Week #3 - Descriptions & Advice
            </div>
            <div className="week-plan__card__main__right__desc">
              You'll level up in this week and start framing some more complex
              sentences. You find yourself expressing yourself better by
              learning to talk about your profession or about places you visit
              and talk about you meals. You'll be able to talk about months and
              seasons coming up. We'll also dive in some cultural aspects of
              Gemany.
            </div>
            <div
              onClick={() => setWeek3(!week3)}
              className="week-plan__card__main__right__show"
            >
              {' '}
              {!week3 ? ' + SHOW DAILY SCHEDULE' : ' - HIDE DAILY SCHEDULE'}
            </div>
            {week3 && (
              <div className="week-plan__card__main__right__cards">
                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      VERBS AND PREPOSITIONS
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      Unlike English, in Spanish we have a lot of verbs which
                      only take specific prepositions, These verbs with
                      prepositions are used a lot and hence, are very important
                      to understand.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 15
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      QUESTION TYPES
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      We have learnt how to ask basic questions, in this lesson,
                      we will see all the question types available in Spanish
                      which will help a lot in making a conversation.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 16
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      TALKING ABOUT WEATHER AND MUCH MORE
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson we will learn how to talk about weather in
                      Spanish which will help you a lot in conversation, not
                      just that, we will also learn how to use the verb ser with
                      nouns and adjectives.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 17
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      USING ARTICLES WITH ADJECTIVES
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      This is where we will start replacing the sentence
                      structure and shorten it up. We use this form when we know
                      which nouns we are referring to and we don’t have to
                      mention them particularly.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 18
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Learning Colors and some grammar
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson we will learn how the colors are called in
                      Spanish, not just that, we will learn how to use a very
                      important grammar form que with noun.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 19
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Giving advice
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn how to advice and give
                      recommendations. After finishing this lesson, you will
                      start carrying out more natural conversation.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 20
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      PRESENT IRREGULAR VERBS
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      We have already learnt how the regular verbs work and are
                      conjugated, in this lesson, we will learn the present
                      irregular verbs which are used very much extensively in
                      Spanish.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 21
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="week-plan__card__main">
          <div className="week-plan__card__main__left">4</div>
          <div className="week-plan__card__main__right">
            <div className="week-plan__card__main__right__title">
              Week #4 - Let's express Ourselves
            </div>
            <div className="week-plan__card__main__right__desc">
              This week will be more of grammar with some practical
              conversations like purchasing from a market. You'll learn to
              express possession of anything and express you needs and
              compulsory things. Frame complex sentences as you move forward.
            </div>
            <div
              onClick={() => setWeek4(!week4)}
              className="week-plan__card__main__right__show"
            >
              {' '}
              {!week4 ? ' + SHOW DAILY SCHEDULE' : ' - HIDE DAILY SCHEDULE'}
            </div>
            {week4 && (
              <div className="week-plan__card__main__right__cards">
                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      TALKING ABOUT ASPECTS
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, we are going to learn how we talk about
                      aspects in Spanish, to learn that, we will see a lot of
                      adjectives that represent a person. This is going to be a
                      very interesting lesson.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 22
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      COMPARING VERBS WITH SAME MEANING
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll be looking at some Spanish verbs
                      which have same meaning but are used in different context.
                      This lesson will help you understand Spanish verbs better.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 23
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      ADVERBS
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      This is the perfect time you learn about adverbs, adverbs
                      will help you make much more complex sentences, also, it
                      will help you to express yourself much much better.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 24
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Possessive Adjectives
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      Learn about Spanish possessive adjective and learn how to
                      form possessive adjectives in Spanish in this lesson.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 25
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      TALKING ABOUT FAMILY
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      Now that you have learnt about possessive adjectives, it's
                      time to talk about our family since this vocabulary uses a
                      lot of possessive adjectives.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 26
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      TIME AND SEQUENCE
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      A great speaker is able to narrate a story well, for that,
                      you need to know how to talk about time and sequence, so
                      in this lesson we will learn a lot about time and sequence
                      in Spanish.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 27
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      ASKING AND GIVING DIRECTIONS
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      You were asking basic questions till now, but now it's
                      time to be a little advance and learn how to ask
                      directions and even give directions.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 28
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="week-plan__card__main">
          <div className="week-plan__card__main__left">5</div>
          <div className="week-plan__card__main__right">
            <div className="week-plan__card__main__right__title">
              Week #5 - Learning more advanced Topics
            </div>
            <div className="week-plan__card__main__right__desc">
              Get deeper in the language and you'll keep learning. By now,
              you'll be able to frame almost any kind of a sentence.You'll also
              dive into talking about your home and be proficient enough to
              order food in a Spanish restaurant. You'll also acquant yourself
              with the rich Spanish food culture and the basic manners and
              etiquettes to follow.
            </div>
            <div
              onClick={() => setWeek5(!week5)}
              className="week-plan__card__main__right__show"
            >
              {' '}
              {!week5 ? ' + SHOW DAILY SCHEDULE' : ' - HIDE DAILY SCHEDULE'}
            </div>
            {week5 && (
              <div className="week-plan__card__main__right__cards">
                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      FORMING IMPERSONAL SENTENCES
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      So far, we were only talking about ourselves, in short, we
                      were only making personal sentences, in this lesson we
                      will learn how to form impersonal sentences. This will
                      help you in advancing your conversational skills.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 29
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      SUPERLATIVES
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      Since you're in Week 5, it's time to learn a little
                      advanced topics, one of them is superlatives which helps
                      us to compare nouns, so that you can make a proper
                      conversation about different things.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 30
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      REFLEXIVE VERBS
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      We have learnt about regular verbs and irregular verbs,
                      but in Spanish, we also have reflexive verbs which are
                      used when the subject and the object are same. You will
                      learn about it more in this lesson.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 31
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      POR VS PARA
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      This is a topic you will find in almost all Spanish
                      forums, this one is pretty confusing for all the Spanish
                      beginners. You will learn about it more once you get to
                      this topic.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 32
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      DEMONSTRATIVES
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      Taking your Spanish to the next level, you will learn
                      about Demonstratives. Demonstratives are words, such as
                      this and that, used to indicate which entities are being
                      referred to and to distinguish those entities from others.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 33
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      QUANTFIERS
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll be learning about quantifiers. A
                      quantifier is a word that usually goes before a noun to
                      express the quantity of the object; for example, a little
                      milk. ... (It's clear that I mean 'a little milk'.) There
                      are quantifiers to describe large quantities (a lot, much,
                      many), small quantities (a little, a bit, a few) and
                      undefined quantities (some, any).
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 34
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      CONTRADICTIONS IN SPANISH
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, we will use everything we have learnt till
                      now as you learn how to contradict in Spanish. For
                      contradicting someone, you need to be very fluent, sp this
                      lesson will check your skills.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 35
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="week-plan__card__main">
          <div className="week-plan__card__main__left">6</div>
          <div className="week-plan__card__main__right">
            <div className="week-plan__card__main__right__title">
              Week #6 - Talking about past
            </div>
            <div className="week-plan__card__main__right__desc">
              This week, you'll be learning to include prepositions in your
              sentence to be able to connect them in correct grammatical
              structures. You'll be able to talk in past tense and learn the
              most practical way in which Spanishs talk. Get geared up to read
              the job advertisements and giving opinions. By nearing the end of
              the course, you'll be conversaional enough to be able to express
              how your day went and even book appointments. Infact, pick any any
              topic and speak over it.
            </div>
            <div
              onClick={() => setWeek6(!week6)}
              className="week-plan__card__main__right__show"
            >
              {' '}
              {!week6 ? ' + SHOW DAILY SCHEDULE' : ' - HIDE DAILY SCHEDULE'}
            </div>
            {week6 && (
              <div className="week-plan__card__main__right__cards">
                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      HIGHLIGHTING LIKES
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      You have already learnt how to talk about likes and
                      dislikes, we will expand your horizon and learn how to
                      highlight the likes. For example, what I like the most is
                      an Apple.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 36
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      PRETERITO PERFECTO
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      So far, everything we have learnt was in present tense,
                      but things are going to take an interesting twist, we will
                      start learning about the past tenses in this lesson. This
                      will introduce you how many kind of Past tenses are there
                      in Spanish.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 37
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      IR + A + INFINITIVE
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll be learning a form which is used a
                      lot in Spanish, you can use this form in present or in
                      future as well. You will see yourself how useful this
                      topic is.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 38
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      PREPOSITIONS
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      This lesson is an introduction to prepositions in Spanish.
                      You'll get an overview of different kinds of prepositions
                      in Spanish.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 39
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      PRETERITE INDEFENITE
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll be introduced to another past form
                      in Spanish. You will learn when to use different past
                      forms in different situations.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 40
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      COMPARING VERBS
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll see the most used verbs in Spanish,
                      and how are they different to each other. These verbs are
                      used in daily lives and it's very important to learn the
                      differences well.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 41
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      CONNECTORS
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      This lesson will be about the different connectors in
                      Spanish, this will help you a lot in joining two or more
                      senteces together. This is one of the most useful to have
                      while learning in Spanish.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 42
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeekPlan;
