import React, { useState } from 'react';

import { emailContactForm } from '../actions/contact';

const ContactForm = () => {
  const [values, setValues] = useState({
    message: '',
    name: '',
    email: '',
    sent: false,
    buttonText: 'Send Message',
    success: false,
    error: false,
  });

  const { message, name, email, sent, buttonText, success, error } = values;

  const clickSubmit = (e) => {
    e.preventDefault();
    setValues({ ...values, buttonText: 'Sending...' });
    emailContactForm({ name, email, message }).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error });
      } else {
        console.log('message sent');
        setValues({
          ...values,
          sent: true,
          name: '',
          email: '',
          error: false,
          message: '',
          buttonText: 'Sent',
          success: 'Thank you for contacting us',
        });
      }
    });
  };

  const handleChange = (name) => (e) => {
    setValues({
      ...values,
      [name]: e.target.value,
      error: false,
      success: false,
      buttonText: 'Send Message',
    });
  };

  const showError = () =>
    error ? (
      <div
        style={{
          backgroundColor: 'red',
          fontSize: '1.6rem',
          padding: '1rem',
          color: 'white',
        }}
      >
        {error}
      </div>
    ) : (
      ''
    );
  const showSuccess = () =>
    success ? (
      <div
        style={{
          backgroundColor: 'green',
          fontSize: '1.6rem',
          padding: '1rem',
          color: 'white',
        }}
      >
        {success}
      </div>
    ) : (
      ''
    );

  const contactForm = () => {
    return (
      <div className="section-book">
        <div className="row">
          <div className="book">
            <div className="book__form">
              <form onSubmit={clickSubmit} className="form">
                <div className="u-margin-bottom-medium">
                  <h2 className="heading-secondary">Send us a message</h2>
                </div>
                <div className="form__group">
                  <textarea
                    onChange={handleChange('message')}
                    type="text"
                    className="form__input"
                    value={message}
                    placeholder="Tell us what's up?"
                    required
                    rows="10"
                  ></textarea>
                  <label className="form__label">Message</label>
                </div>
                <div className="form__group">
                  <input
                    value={name}
                    type="name"
                    className="form__input"
                    placeholder="Type your name"
                    onChange={handleChange('name')}
                    required
                  ></input>
                  <label className="form__label">Name</label>
                </div>
                <div className="form__group">
                  <input
                    value={email}
                    type="email"
                    className="form__input"
                    onChange={handleChange('email')}
                    placeholder="email"
                    required
                  ></input>
                  <label className="form__label">Email address</label>
                </div>
                <div className="form__group">
                  <button className="btn btn--green"> {buttonText} </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {showSuccess()}
      {showError()}
      {contactForm()}
    </React.Fragment>
  );
};

export default ContactForm;
