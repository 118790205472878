import React from 'react';
import { Link } from 'react-router-dom';

import Video from '../assets/video2.mp4';
import GermanFlag from '../assets/German_Flag.png';
import SpanishFlag from '../assets/Spanish_Flag.png';

const Section1 = () => {
  return (
    <header className="header">
      <div className="bg-video">
        <video autoPlay muted loop className="bg-video__content">
          <source src={Video} type="video/mp4" />
          Your browser is not supported!
        </video>
      </div>

      <div className="header__text-box">
        <h1 className="header__text-box__main">
          Want to learn a language Online?{' '}
          {/* <span className="heading-primary--sub">
            {' '}
            Languages at your own cost{' '}
          </span> */}
        </h1>

        <div className="header__box">
          <div className="header__box__title">
            Learn a language in just 6 Weeks
          </div>
          {/* <div className="header__box__sub">I want to Learn</div> */}
          <div className="header__box__buttons">
            <Link className="header__box__buttons__column" to="learn-spanish">
              <img
                src={SpanishFlag}
                className="header__box__buttons__first"
              ></img>
              <div className="header__box__buttons__text">Spanish</div>
            </Link>
            <Link className="header__box__buttons__column" to="learn-german">
              <img
                src={GermanFlag}
                className="header__box__buttons__second"
              ></img>
              <div className="header__box__buttons__text">German</div>
            </Link>
          </div>
        </div>
        {/* <Link href="#section-languages">
          <a className="btn btn--white btn--animated"> Languages we offer ? </a>
        </Link> */}
      </div>
    </header>
  );
};
export default Section1;
