import React from 'react';
import { Link } from 'react-router-dom';

import '../sass/main.scss';
import MetaTags from 'react-meta-tags';
import Image from '../assets/days-of-the-week-in-spanish.jpg';
import Sunday from '../assets/sunday-in-spanish.png';
import Monday from '../assets/monday-in-Spanish.png';
import Tuesday from '../assets/tuesday-in-spanish.png';
import Wednesday from '../assets/wednesday-in-spanish.png';
import Thursday from '../assets/thursday-in-spanish.png';
import Friday from '../assets/friday-in-spanish.png';
import Saturday from '../assets/saturday-in-spanish.png';
import Spanish from '../assets/spanish_course_bottom.png';

const spanishWeek = () => {
  const head = () => (
    <MetaTags>
      <title>
        Easy Guide to Spanish Days of the Week with Rules &amp; Pronunciation |
        LinguaPsych
      </title>
      <meta
        name="description"
        content="Complete guide on how to learn Spanish Days of the Week and tips on how to use them daily with rules and Pronunciation, extra tips on related grammar."
      />{' '}
      <link
        rel="canonical"
        href="https://linguapsych.com/spanish-days-of-the-week/"
      />
      <meta
        property="og:title"
        content="Complete guide on how to learn Spanish Days of the Week and tips on how to use them daily with rules and Pronunciation, extra tips on related grammar."
      />{' '}
      <meta
        property="og:description"
        content="Complete guide on how to learn Spanish Days of the Week and tips on how to use them daily with rules and Pronunciation, extra tips on related grammar."
      />{' '}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        href="https://linguapsych.com/spanish-days-of-the-week/"
      />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/days-of-the-week-in-spanish.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/days-of-the-week-in-spanish.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}

      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_SPANISH_COURSE}`}>
          <div className="article__spanish-banner">
            <div className="article__spanish-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="Days of the week in Spanish"
            className="article__featured-image"
          />
          <div className="article__header-elements">
            <h1 className="article__title ">
              {' '}
              <span
                style={{ fontSize: '5rem' }}
                className="article__highlight--black "
              >
                {' '}
                Days Of The Week In Spanish
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <p>
              Started learning Spanish? Well, this is a great place to begin. In
              this article, we will learn days of the week in Spanish along with
              their pronunciation. We will make sure once you finish reading
              this article, you won’t look back. With the help of examples for
              better understanding, I will give you tips on how to learn and
              remember days of the week forever. We will learn how to say
              Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, and Sunday
              in Spanish. Vamos…
            </p>
            <h2 className="u-margin-bottom-medium">
              Why you should learn Days of Week in Spanish?
            </h2>
            <p>
              Before we get into learning days of the week in Spanish, let’s put
              some attention in learning the useful words, learning advanced
              vocabulary has no meaning unless you got the basics right. Yes,
              this is basic but, one of the most used Spanish vocabulary.
            </p>
            <p>
              I have written a whole page on this because I remember when I
              stared learning Spanish, I had to come over to study it again and
              again as I would get confused very often. I don’t want you guys to
              go through the same problem and hence, let’s take a pledge to
              finish this topic here and today itself.
            </p>
            <p>
              Days of the week are used in day to day conversations. So it is
              very important to pay attention to this. There are 7 days in a
              week. And to make it clear, there is no word relation with English
              so keep your mind fresh.
            </p>
            <h2 className="u-margin-bottom-medium">
              What are the Days of the Week in Spanish?
            </h2>
            <blockquote
              className="u-margin-bottom-medium"
              style={{ color: 'brown' }}
            >
              All the days in Spanish start with the article “el” which is
              Masculine. All the names of the Spanish week is taken from the
              Solar system. Days of the week in Spanish are not capitalized.
            </blockquote>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '50%', marginTop: '-30px' }}
                  src={Monday}
                  alt=" monday-in-spanish"
                />
              </div>
              <div className="col-1-of-2 ">
                <h3>Monday</h3>
                <p>
                  Monday in Spanish is El lunes. It is pronounced as “loo-neys”.
                  The name comes from the word La luna which means moon. El
                  Lunes is Monday & los lunes is the plural form Mondays. In
                  Spain, Monday is the first day of the week.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '50%', marginTop: '-30px' }}
                  src={Tuesday}
                  alt=" tuesday-in-spanish"
                />
              </div>
              <div className="col-1-of-2 ">
                <h3>Tuesday</h3>
                <p>
                  Tuesday in Spanish is El martes. It is pronounced as
                  “maar-tays”. The name comes from the planet Mars which makes
                  it martes. El martes is Tuesday and los martes is Tuesdays.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '50%', marginTop: '-30px' }}
                  src={Wednesday}
                  alt="wednesday-in-spanish"
                />
              </div>
              <div className="col-1-of-2 ">
                <h3>Wednesday</h3>
                <p>
                  Wednesday in Spanish is El miércoles. It is pronounced as
                  “me-air-co-lays”. The name origins from the planet Mercury. El
                  miércoles is Wednesday and los miércoles is Wednesdays.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '50%', marginTop: '-30px' }}
                  src={Thursday}
                  alt="thursday-in-spanish"
                />
              </div>
              <div className="col-1-of-2 ">
                <h3>Thursday</h3>
                <p>
                  Thursday in Spanish is El jueves. It is pronounced as
                  “Khway-ways”. The name originates from the planet Jupiter. El
                  jueves is Thursday and los jueves is Thursdays.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '50%', marginTop: '-30px' }}
                  src={Friday}
                  alt="friday-in-spanish"
                />
              </div>
              <div className="col-1-of-2 ">
                <h3>Friday</h3>
                <p>
                  Friday in Spanish is El viernes . It is pronounced as
                  “bee-air-nays”. The name originates from the planet Venus. El
                  viernes is Friday and los viernes is Fridays.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '50%', marginTop: '-30px' }}
                  src={Saturday}
                  alt="saturday-in-spanish"
                />
              </div>
              <div className="col-1-of-2 ">
                <h3>Saturday</h3>
                <p>
                  Saturday in Spanish is El sábado. It is pronounced as
                  “sah-bah-doh”. The name originated from a Hebrew word
                  “sabbath” which means a day of rest. El sábado is Saturday and
                  los sábados is Saturdays.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-of-2">
                <img
                  style={{ width: '50%', marginTop: '-30px' }}
                  src={Sunday}
                  alt="sunday-in-spanish"
                />
              </div>
              <div className="col-1-of-2 ">
                <h3>Sunday</h3>
                <p>
                  Sunday in Spanish is El domingo. It is pronounced as
                  “doh-ming-oh”. The name originated from a Latin word which
                  means Lord’s day. El domingo is Sunday and los domingos is
                  Sundays.
                </p>
              </div>
            </div>
            <h2 className="u-margin-bottom-medium">
              Abbreviations in Spanish Days of the Week
            </h2>
            <p>
              Abbreviation are equally important to learn as the days in a week
              are. Most of the time while writing we prefer to use abbreviations
              for Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, and
              Sunday. Abbreviations make writing easier and faster.
            </p>
            <p>
              In English, abbreviations for days are Mon, Tue, Wed, Thu, Fri,
              Sat, Son. In English, we write just the first three letters of the
              week days as abbreviations.
            </p>
            <p>
              But it is a little different in Spanish. In Spanish, to write
              abbreviation for week days, we write just the initial letter.
              Thus, abbreviation for el lunes is L. Likewise, for el martes, el
              miércoles, el jueves, el viernes, el sábado, and el domingo,
              abbreviation will be M, X, J, V, S and D respectively.
            </p>
            <p>
              Please note that abbreviation for Wednesday (el miércoles) is not
              it’s initial letter M but X.
            </p>
            <h2 className="u-margin-bottom-medium">
              Related Grammar You Must Know
            </h2>
            <p>
              We have learnt all the days in Spanish. Now we should also know
              some grammar related to this topic.
            </p>
            <ul>
              <li>Capitalized Nouns</li>
              <p>
                Spanish uses fewer capitalized nouns than English, when Spanish
                capitalizes a word, English does the same thing to that word
                but, opposite to that is not true, Spanish doesn’t capitalize
                all the words that English does. So some nouns that are
                capitalized in Spanish are proper nouns for people, places,
                newspapers, personal titles, name of the Sun and the Moon, first
                word in the titles for example movies, books, albums etc.
              </p>
              <li>Gender of Days</li>
              <p>
                All the days of the week are Masculine and not capitalized. So
                it takes the article el (definite article) and written as el
                lunes, el martes, el miércoles, el jueves, el viernes, el sábado
                & el domingo.
              </p>
              <li>To ask “what day of the week is it”</li>
              <p>
                To ask someone what day is it today, you can ask “¿Qué día de la
                semana es hoy? or “¿Qué día es hoy?”
              </p>
              <li>To say “on Monday/Tuesday”</li>
              <p>
                How would you say “I will go to gym on Monday”? The answer is
                simple. For saying on Monday, we use the definite article “el”
                before day. There is no specific preposition that we use with
                days so using the definite article will always work.
              </p>
              <p>So it becomes, “voy al gimnasio el lunes“</p>
              <p>
                More examples, El martes es San Valentín. (On Tuesday, it is
                Valentine’s Day)
              </p>
              <li>To say from…to…</li>
              <p>
                Do you know how to say “From Monday to Friday, I go to school”?
              </p>
              <p>
                It is very simple. de is used for “from” and a is used for
                “to/till”.
              </p>
              <p>
                Voy a la escuela de lunes a viernes. (From Monday to Friday, I
                go to school)
              </p>
            </ul>
            <h2 className="u-margin-bottom-medium">
              Related Words You Must Know
            </h2>
            <p>
              At this stage, we know how to say the days of the week in Spanish,
              but knowing that is not enough if you want to make sentences
              related to this topic. Below are some very useful words which are
              going to be used very often with the Spanish days of the week, I
              have added some examples too, so you won’t find it difficult to
              understand the context. Vamos..
            </p>
            <ul>
              <li> hoy (Today)</li>
              <p>
                {' '}
                It is undoubtedly one of the most important terms that you’ll
                use with days of the week. Hoy stands for today and takes the
                verb Ser just like English takes the verb to be with Today
                (Today is), take a note that whenever you’re using es
                (Conjugation of the verb ser) which is same as “is” in English,
                it will not take the definite article el or la. Let’s look at
                the examples
              </p>
              <li>Hoy es domingo (Today is Sunday)</li>
              <li>Hoy no es lunes (Today’s not Monday)</li>
              <li>mañana (Tomorrow/Morning)</li>
              <p>
                It is an important word or I should say words, mañana means both
                tomorrow and morning that’s why, it is very important to
                understand the context of the sentence. Mañana is used in day to
                day conversations and generally if you’re using both terms
                together, in the morning takes the preposition “por”. Let’s take
                a look at the examples
              </p>
              <li>
                Tengo el examen mañana por la mañana (I have an exam tomorrow in
                the morning)
              </li>
              <li>Mañana es jueves (Tomorrow is Thursday)</li>
              <li>el día ( The day)</li>
              <p>
                This is the word which you would probably know as it’s used
                everywhere, any paragraph you read is incomplete without using
                this word. Although, this word ends with a but is masculine so
                it takes the article “el”, for more reference on this topic, you
                can check out our lesson on Spanish nouns and gender. Let’s look
                at few examples
              </p>
              <li>¿Qué día es hoy? (What day is it today?)</li>
              <li>¿Qué tal tu día? (How was your day?)</li>
              <li>la semana (The week)</li>
              <p>
                For learning days of the week, you must the how to say day and
                week in Spanish. The week in Spanish is la semana. Let’s look at
                some examples now
              </p>
              <li>tiene siete días en una semana ( A week has seven days)</li>
              <li>tengo el examen esta semana (I have an exam this week)</li>
              <li>ayer (Yesterday)</li>
              <p>
                Talking about past is as important as talking about present or
                future. If you want to say yesterday was Monday, how would you
                say it? Let’s take a look
              </p>
              <li>ayer fue lunes (Yesterday was Monday)</li>
              <li>hoy es mejor que ayer (Today is better than yesterday)</li>
              <li>Anteayer (Day before yesterday)</li>
              <p>
                One more important word if you want to talk about the past, both
                anteayer and ayer will take the past form of the verb ser “fue“.
                Learning ayer will help you learn this word too. Let’s see some
                examples
              </p>
              <li>
                anteayer fue mi cumpleaños (Day before yesterday was my
                Birthday)
              </li>
              <li>anteayer fue martes (Day before yesterday was Tuesday)</li>
              <li>pasado (Past / last)</li>
              <li>
                When used with the definite article “el” pasado means past, when
                used after a noun it means last, let’s look at some examples
              </li>
              <li>el pasado es incierto (The past is uncertain)</li>
              <li>la semana pasada fue mi examen (My exam was last week)</li>
              <li>próximo/a (The next)</li>
              <p>
                When we want to talk about coming week or coming days, we use
                this word , let’s see how we use it in sentences
              </p>
              <li>
                {' '}
                Vamos a la playa el próximo verano (We are going to the beach
                next summer)
              </li>
              <li>
                el próximo lunes tengo el examen (I have an exam next Monday)
              </li>
              <li>siguiente (following)</li>
              <p>
                Like the word próximo, siguiente is used to talk about the
                coming weeks or days. The word is originated from the verb
                seguir which means to follow. Let’s see some examples
              </p>
              <li>
                la semana siguiente es importante (The following week is
                imortant)
              </li>
              <li>
                {' '}
                El siguiente curso es mas interesante que los anteriores (The
                following course is more interesting than the others)
              </li>
              <li>Cada (each/every)</li>
              <p>
                Cada is a word which is very common but it makes you sound like
                a native because of it’s tone. This means both each and every.
                Let’s see some examples
              </p>
              <li>
                cada persona aqui habla español (Every person here speaks
                Spanish)
              </li>
              <li className="u-margin-bottom-medium">
                Tengo el examen cada semana (I have the exam every week)
              </li>
            </ul>
            <h2 className="u-margin-bottom-medium">Still facing difficulty?</h2>
            <p>
              Here is a song made especially for the Spanish beginners to learn
              the Days of the week in Spanish, enjoy the song.
            </p>
            <iframe
              width="840"
              height="615"
              src="https://www.youtube.com/embed/C4fREj60Crk?playlist=C4fREj60Crk&loop=1"
            ></iframe>
            <h2 className="u-margin-bottom-medium">
              Finally mastered Days of the Week in Spanish...
            </h2>
            <p>
              Now you know Spanish days of the week. You also went through all
              the grammar topics related to days and how to be part of any
              conversation involving days in Spanish. Make sure you go through
              the lesson once again if you come across any problem, since these
              small topics form the base for further learning process. Try
              making small sentences related to this topic.
            </p>
            <p>
              Let me give you a task for the day. Make any five sentences
              involving days in Spanish and you can write to me at
              info@linguapsych.com
            </p>
          </div>
          <div className="article__blog-container__bottom">
            <div
              className="article__blog-container__bottom__left"
              to="/learn-spanish"
            >
              <Link to="/learn-spanish">
                <img
                  className="article__blog-container__bottom__image"
                  src={Spanish}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/spanish-definite-indefinte-articles/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Definite & Indefinite Articles
                </li>
              </Link>
              <Link to="/subject-personal-pronouns-in-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Subject Personal Pronouns
                </li>
              </Link>

              <Link to="/spanish-nouns-articles-genders/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Nouns, articles and Genders
                </li>
              </Link>
              <Link to="/preterite-vs-imperfect/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Preterite vs Imperfect
                </li>
              </Link>
              <Link to="/months-in-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Months in Spanish
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default spanishWeek;
