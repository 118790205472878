import React from 'react';

import Week1 from '../assets/Week1.jpg';
import Week2 from '../assets/Week2.jpg';
import Week3 from '../assets/Week3.jpg';
import Week4 from '../assets/Week4.jpg';
import Week5 from '../assets/Week5.jpg';
import Week6 from '../assets/Week6.jpg';
import WeekVideoPlayer from '../components/WeekVideoPlayer';

const SpanishWeekVideo = () => {
  return (
    <>
      <div className="week-video">
        <div className="week-video__top">AN IMMERSIVE LEARNING EXPERIENCE</div>
        <div className="week-video__second">
          Become a Multilingual in 6 Weeks
        </div>
        <div className="week-video__third">
          Custom tailored course for any Spanish lover, it is not just another
          video based course, you will learn every aspect of learning a language
          including reading, comprehension and listening skills.
        </div>
        <div className="week-video__videos">
          <div className="week-video__videos__video-box">
            <div className="week-video__videos__video">
              <WeekVideoPlayer
                poster={Week1}
                src="https://linguapsych-videos.s3.ap-south-1.amazonaws.com/Spanish+A1+Course/SPANISH_WEEK1.mp4"
              />
            </div>
            <div className="week-video__videos__video__title">
              Week #1 - Mastering the Fundamentals
            </div>

            <div className="week-video__videos__video__desc">
              In the first week, we will set up all the tools required for
              mastering Spanish, you will learn about the Spanish alphabets,
              accents, numbers and so much more.
            </div>
          </div>
          <div className="week-video__videos__video-box">
            <div className="week-video__videos__video">
              <WeekVideoPlayer
                poster={Week2}
                src="https://linguapsych-videos.s3.ap-south-1.amazonaws.com/Spanish+A1+Course/SPANISH_WEEK2.mp4"
              />
            </div>
            <div className="week-video__videos__video__title">
              Week #2 - Let's start talking
            </div>

            <div className="week-video__videos__video__desc">
              In Week 2, you'll learn to express yourself by talking about your
              likes and dislikes, we will focus on framing sentences much more
              extensively so that you can initiate conversation.
            </div>
          </div>
          <div className="week-video__videos__video-box">
            <div className="week-video__videos__video">
              <WeekVideoPlayer
                poster={Week3}
                src="https://linguapsych-videos.s3.ap-south-1.amazonaws.com/Spanish+A1+Course/SPANISH_WEEK3.mp4"
              />
            </div>
            <div className="week-video__videos__video__title">
              Week #3 - Descriptions & Advice
            </div>

            <div className="week-video__videos__video__desc">
              By now, you know how the sentences are formed in Spanish. You will
              start describing things around you, not just that, you will be
              able to give advice since you're going to learn a lot of new
              words.
            </div>
          </div>
          <div className="week-video__videos__video-box">
            <div className="week-video__videos__video">
              <WeekVideoPlayer
                poster={Week4}
                src="https://linguapsych-videos.s3.ap-south-1.amazonaws.com/Spanish+A1+Course/SPANISH_WEEK4.mp4"
              />
            </div>
            <div className="week-video__videos__video__title">
              Week #4 - Let's express Ourselves
            </div>

            <div className="week-video__videos__video__desc">
              This week, you will start choosing perfect words to express
              yourself better. You will learn a lot of new vocabulary, including
              family words and words for directions. Not just that, this week,
              we will talk about the possessives.
            </div>
          </div>
          <div className="week-video__videos__video-box">
            <div className="week-video__videos__video">
              <WeekVideoPlayer
                poster={Week5}
                src="https://linguapsych-videos.s3.ap-south-1.amazonaws.com/Spanish+A1+Course/SPANISH_WEEK5.mp4"
              />
            </div>
            <div className="week-video__videos__video__title">
              Week #5 - Learning more advanced Topics
            </div>

            <div className="week-video__videos__video__desc">
              In this week, we will study some advanced topics which cause
              confusion to a lot of new Spanish speakers, topics include
              quantifiers, reflexive verbs and superlatives.
            </div>
          </div>
          <div className="week-video__videos__video-box">
            <div className="week-video__videos__video">
              <WeekVideoPlayer
                poster={Week6}
                src="https://linguapsych-videos.s3.ap-south-1.amazonaws.com/Spanish+A1+Course/SPANISH_WEEK6.mp4"
              />
            </div>
            <div className="week-video__videos__video__title">
              Week #6 - Talking about past
            </div>

            <div className="week-video__videos__video__desc">
              Week 6 is going to be interesting as we will learn the past
              tenses, prepositions and connectors with a lot more. This week is
              going to be a challenging week. Fasten your seat belt.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpanishWeekVideo;
