import React from 'react';

import Week1 from '../assets/Week1.jpg';
import Week2 from '../assets/Week2.jpg';
import Week3 from '../assets/Week3.jpg';
import Week4 from '../assets/Week4.jpg';
import Week5 from '../assets/Week5.jpg';
import Week6 from '../assets/Week6.jpg';
import WeekVideoPlayer from '../components/WeekVideoPlayer';

const GermanWeekVideo = () => {
  return (
    <>
      <div className="week-video">
        <div className="week-video__top">AN IMMERSIVE LEARNING EXPERIENCE</div>
        <div className="week-video__second">
          Become a Multilingual in 6 Weeks
        </div>
        <div className="week-video__third">
          Exclusive 'Beginner' video course, carefully structured for you to
          start from the base. You'll learn German efficiently at home, all by
          yourself (We have such strong guided curriculum!). Immerse yourself in
          the process and find yourself speaking and understanding German
          without any bumps.
        </div>
        <div className="week-video__videos">
          <div className="week-video__videos__video-box">
            <div className="week-video__videos__video">
              <WeekVideoPlayer
                poster={Week1}
                src="https://linguapsych-videos.s3.ap-south-1.amazonaws.com/German+A1+Course/GERMAN_WEEK1.mp4"
              />
            </div>
            <div className="week-video__videos__video__title">
              Week #1 - Onboarding and Introduction
            </div>

            <div className="week-video__videos__video__desc">
              This week will be your introductory week and you'll learn how to
              greet someone and introduce yourself. Gradually you'll learn to
              build sentences and be able to ask questions. You'll also learn
              the basics like numbers and alphabets in German.
            </div>
          </div>
          <div className="week-video__videos__video-box">
            <div className="week-video__videos__video">
              <WeekVideoPlayer
                poster={Week2}
                src="https://linguapsych-videos.s3.ap-south-1.amazonaws.com/German+A1+Course/GERMAN_WEEK2.mp4"
              />
            </div>
            <div className="week-video__videos__video__title">
              Week #2 - Components of a Sentence
            </div>

            <div className="week-video__videos__video__desc">
              In Week 2, you'll learn some practical concepts like telling your
              Email ID and talking about your likes and dislikes and start
              planning your days in German.You'll start framing basic German
              sentences.
            </div>
          </div>
          <div className="week-video__videos__video-box">
            <div className="week-video__videos__video">
              <WeekVideoPlayer
                poster={Week3}
                src="https://linguapsych-videos.s3.ap-south-1.amazonaws.com/German+A1+Course/GERMAN_WEEK3.mp4"
              />
            </div>
            <div className="week-video__videos__video__title">
              Week #3 - Talking about your Surroundings
            </div>

            <div className="week-video__videos__video__desc">
              You'll level up in this week and start framing some more complex
              sentences. You will start expressing yourself better by learning
              to talk about your profession or about places you visit and talk
              about your meals. We'll also dive in some cultural aspects of
              Gemany.
            </div>
          </div>
          <div className="week-video__videos__video-box">
            <div className="week-video__videos__video">
              <WeekVideoPlayer
                poster={Week4}
                src="https://linguapsych-videos.s3.ap-south-1.amazonaws.com/German+A1+Course/GERMAN_WEEK4.mp4"
              />
            </div>
            <div className="week-video__videos__video__title">
              Week #4 - Framing Complex Sentences
            </div>

            <div className="week-video__videos__video__desc">
              This week will be more of grammar with some practical
              conversations like purchasing from a market. You'll learn to
              express possession of anything and express you needs and
              compulsory things. Frame complex sentences as you move forward.
            </div>
          </div>
          <div className="week-video__videos__video-box">
            <div className="week-video__videos__video">
              <WeekVideoPlayer
                poster={Week5}
                src="https://linguapsych-videos.s3.ap-south-1.amazonaws.com/German+A1+Course/GERMAN_WEEK5.mp4"
              />
            </div>
            <div className="week-video__videos__video__title">
              Week #5 - Ordering Food and Learning Culture
            </div>

            <div className="week-video__videos__video__desc">
              By Week 5, you'll be able to frame almost any kind of a
              sentence.You'll also dive into talking about your home and be
              proficient enough to order food in a German. You'll also acquant
              yourself with the rich German food culture and the basic manners
              and etiquettes to follow.
            </div>
          </div>
          <div className="week-video__videos__video-box">
            <div className="week-video__videos__video">
              <WeekVideoPlayer
                poster={Week6}
                src="https://linguapsych-videos.s3.ap-south-1.amazonaws.com/German+A1+Course/GERMAN_WEEK6.mp4"
              />
            </div>
            <div className="week-video__videos__video__title">
              Week #6 - Talking about Past and giving Opinions
            </div>

            <div className="week-video__videos__video__desc">
              By the final week, You'll start using prepositions & tenses. Get
              geared up to read the job advertisements and giving opinions. By
              nearing the end of the course, you'll be conversaional enough to
              be able to express how your day went and even book appointments.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GermanWeekVideo;
