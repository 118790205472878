import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Logo from '../assets/logo.png';

const Footer = () => {

  const landing = [
    "/spanish-workshop", "/webinar-thankyou", "/learn-german", "/learn-spanish"
  ]

  useEffect(() => {
    showFooter()
  }, [])

const showFooter = () => {
  if(landing.includes(window.location.pathname)) {
    return null
} else {

  return (
    <React.Fragment>
      <footer className="footer">
        <div className="footer__logo-box">
          <img src={Logo} className="footer__logo" alt="Full logo" />
          <div className="Brand-name">
            <Link className="footer__link" to="/">
              Linguapsych
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-1-of-2">
            <div className="footer__navigation">
              <ul className="footer__list">
                <li className="footer__item">
                  <Link className="footer__link" to="/disclaimer/">
                    Disclaimer
                  </Link>
                </li>
                {/* <li className="footer__item">
                  <Link className="footer__link" to="/contact/">
                    Contact us 
                  </Link>
                </li> */}
                <li className="footer__item">
                  <Link className="footer__link" to="/privacy-policy/">
                    Privacy policy
                  </Link>
                </li>
                <li className="footer__item">
                  <Link className="footer__link" to="/learn-spanish/">
                    Spanish Course
                  </Link>
                </li>
                <li className="footer__item">
                  <Link className="footer__link" to="/learn-german/">
                    German Course
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-1-of-2">
            <div className="footer__copyright">
              <Link to="/spanish/">
                <div className="footer__copyright__list">Spanish Blogs</div>
              </Link>
              <Link to="/german/">
                <div className="footer__copyright__list">German Blogs</div>
              </Link>
              <Link to="/japanese/">
                <div className="footer__copyright__list">Japanese Blogs</div>
              </Link>
              <Link to="/korean/">
                <div className="footer__copyright__list">Korean Blogs</div>
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );

}
}

return (
  <>
{showFooter()}
  </>
)
};

export default  withRouter(Footer);
