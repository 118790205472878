import React from 'react';
import { Link } from 'react-router-dom';

import '../sass/main.scss';
import MetaTags from 'react-meta-tags';
import Desu from '../assets/Desu Japanese.jpg';
import Language from '../assets/language_course_bottom.png';

const desuJapanese = () => {
  const head = () => (
    <MetaTags>
      <title>
        Desu in Japanese (When to use, how to pronounce? desu or dess?) |
        LinguaPsych
      </title>
      <meta
        name="description"
        content="This is a definitive guide on Desu in Japanese. This lesson includes the Definition, guide on how to pronounce it and when to use it with its substitutes."
      />{' '}
      <link rel="canonical" href="https://linguapsych.com/desu-japanese/" />
      <meta
        property="og:title"
        content="This is a definitive guide on Desu in Japanese. This lesson includes the Definition, guide on how to pronounce it and when to use it with its substitutes."
      />{' '}
      <meta
        property="og:description"
        content="This is a definitive guide on Desu in Japanese. This lesson includes the Definition, guide on how to pronounce it and when to use it with its substitutes."
      />{' '}
      <meta property="og:type" content="website" />
      <meta property="og:url" href="https://linguapsych.com/desu-japanese/" />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/Desu Japanese.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/Desu Japanese.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}
      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_SPANISH_COURSE}`}>
          <div className="article__spanish-banner">
            <div className="article__spanish-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Desu}
            alt="What does Desu mean in Japanese?"
            className="article__featured-image"
          />
          <div className="article__header-elements">
            <h1 className="article__title ">
              {' '}
              <span
                style={{ fontSize: '5rem' }}
                className="article__highlight--black "
              >
                {' '}
                Definitive Guide on Japanese Desu: Meaning, pronunciation and
                uses
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <p>
              I’ve been asked by a lot of people who are either interested in
              learning Japanese or are trying to learn it already, that what
              exactly is “Desu”? A lot of people come up to me and say that Desu
              is not a word, let me tell you that Desu is a word and it is very
              important, it’s just that it has its own specific meaning
              depending on the context.
            </p>
            <p>
              Anime lovers always add Desu in every word they say, but is it
              appropriate to use it everywhere? Let’s have a look
            </p>
            <h2 className="u-margin-bottom-medium">
              What does Desu mean in Japanese?
            </h2>
            <ul>
              <li>
                Desu です is a Japanese copula which means it links the subject
                of a sentence to a subject compliment, for example “The Shirt is
                white”. Desu usually translates to is/am/are in English or in
                simple term it works as a verb “to be” in Japanese. For example
              </li>
              <li>Kore wa pen desu</li>
              <li>これ は ぺん です</li>
              <li>This is a Pen</li> OR
              <li>Kono Pen wa Aoi Desu</li>
              <li>この ぺん は 青い です</li>
              <li>This Pen is Blue</li>
              <p>Here, if we break the second sentence down,</p>
              <p>Kono Pen = This Pen</p>
              <p>Wa = Subject marking particle</p>
              <p>Aoi = Blue</p>
              <p>Desu = Copula</p>
            </ul>
            <p>
              If we do the literal translation of this sentence, it will become
              “As for this Pen, Blue is” but natural translation of this
              sentence is “This Pen is Blue”.
            </p>

            <p>
              {' '}
              Here, Desu is same as “is” in English but is used in the end.
            </p>
            <p>
              In Japanese, a verb comes at the end of the sentence therefore,
              Desu which is used as a linking verb is always placed at the end
              of the sentence. So a very basic sentence would be:
            </p>
            <ul>
              <li>A wa B desu</li>
              <li>A is B</li>
              It’s as simple as that so don’t overthink it.
              <p>Let’s look at another sentence</p>
              <li>Kore wa Present desu</li>
              <li>これはプレゼントです</li>
              Here, your friend means this is a Gift for you and you might ask
              if “desu” means for you here?
              <p>
                Answer is no, desu means “is” here as well but implies for you.
                Japanese is all about context.
              </p>
            </ul>
            <h2 className="u-margin-bottom-medium">How to Pronounce Desu?</h2>
            <p>
              In my Japanese teaching career, if there is one question that is
              asked the most, that will be this ” Why is Desu pronounce as Des?”
              I have an opportunity to share my thoughts so I’ll answer that
              here
            </p>
            <h3>Why Does Desu and Masu sound like Dess and Mass?</h3>
            <p>
              Both Desu and Masu are spelled as two syllables but are pronounced
              as one. But what’s going on with the “U” sound?
            </p>
            <p>
              So, the last “U” sound is taken off in spoken Japanese. This often
              happens when the sound “u” is followed by a soundless consonant
              such as す(su), く(ku), つ(tsu) and ふ(fu). When these soundless
              consonants are used at the end of a phrase the sound “U” will
              always be hidden.
            </p>
            <p>
              When you say words like Masu, desu, suki, you pronounce the vowel
              “u” but the vocals don’t vibrate enough to create an evident “u”
              sound resulting, we don’t hear the u sound. This rule is also
              followed by the polite form of verb masu such as tabemasu,
              nomimasu, mimasu etc. Another example is Sukiyaki (すき焼き)which
              is a Japanese dish.
            </p>
            <p>
              Remember ohayou gozaimasu? start pronouncing it as gozaimass and
              not gozaimasu
            </p>
            <div>
              <h2
                style={{ backgroundColor: 'green', color: 'black' }}
                className="u-margin-bottom-medium"
              >
                When to say Desu?
              </h2>
              <p>
                Japanese Language is known for its politeness and there is no
                hiding the fact that Japanese people are indeed, the most polite
                people on the planet. Japanese has multiple levels of
                politeness, the level changes from a friend to a colleague till
                your customers.
              </p>
              <p>
                Using which form of Japanese to whom is a great lesson in
                itself. Some factors include closeness to the listener, their
                age compared to you, your designation and multiple others. So
                let’s begin
              </p>
              <ul>
                <li>DA だ – Informal</li>
                <li>DESU です – Formal</li>
                <li>DE GOZAIMASU – でございます – Super Formal (Keigo)</li>
              </ul>
              <h3>DA だ in Japanese</h3>
              <p>
                This is the most informal way to say Desu in Japanese, it is
                very easy to say and very frequently used in all animes. You can
                use Da だ with your friends, family members and close relatives.
                You can replace it anytime with Desu and the context of the
                sentence will remain the same. Only the level of politness
                changes when you switch from Desu to Da だ. Just pay attenion to
                it while talking to strangers because Japanese people are very
                polite with Strangers.
              </p>
              <h3>DESU です in Japanese</h3>
              <p>
                This is the most widely used copula in Japanese as it is very
                handy, learning Desu will save you all the time as you can use
                it in most of the situations unless you’re talking to the Prime
                Minister of Japan and even that can work in your favor as we all
                are 外人 (foreigners) and very few foreigners know the Keigo
                (most formal Japanese). Desu can be used with almost everyone,
                new friends, new colleagues, someone on the street, owner of the
                restaurant you’re eating in, your teachers or even the police
                officers. So whom else are you going to talk to? If you are next
                form is for you
              </p>
              <h3>DE GOZAIMASU でございます in Japanese</h3>
              <p>
                Well you told me that you know people with whom you’re going to
                use the most formal form of “desu”, so if you’re going to use it
                I’m sure you’ll be either giving an interview in a Japanese
                company, or you work or going to work in a Japanese firm where
                all your clients are Japanese or you’re going to work in a
                Japanese restaurant where you’re going to interact with
                customers daily. If these are not the cases then you must be
                working as a person who makes formal announcements in Japan
                Airport or the Railway station. Where else in the world are you
                going to use it? I hope I’ve given you enough examples
              </p>
              <h2
                style={{ backgroundColor: 'red', color: 'black' }}
                className="u-margin-bottom-medium"
              >
                Any thoughts?
              </h2>
              <p>
                I think in this lesson, I was able to explain every information
                available related to Desu in Japanese. Today, we learned what is
                Desu? What does it mean, how to pronounce it and how to use it.
                I think you’re good enough with using Da and Desu itself as
                you’re gonna use it 99% of the times. If you’re using the Keigo
                form “De Gozaimasu” that means you’re actually meeting the Prime
                Minister. In case you are, say my Hello.
              </p>
            </div>
          </div>
          <div className="article__blog-container__bottom">
            <div
              className="article__blog-container__bottom__left"
             
            >
              <Link to="/">
                <img
                  className="article__blog-container__bottom__image"
                  src={Language}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/japanese-particles/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Particles in Japanese
                </li>
              </Link>
              <Link to="/learn-japanese-in-delhi/">
                <li className="article__blog-container__bottom__posts__title__item">
                 Learn Japanese in Delhi
                </li>
              </Link>

            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default desuJapanese;
