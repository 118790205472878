import React, { useState } from 'react';

const GermanWeekPlan = () => {
  const [week1, setWeek1] = useState(false);
  const [week2, setWeek2] = useState(false);
  const [week3, setWeek3] = useState(false);
  const [week4, setWeek4] = useState(false);
  const [week5, setWeek5] = useState(false);
  const [week6, setWeek6] = useState(false);
  return (
    <div className="week-plan">
      <div className="week-plan__top">6 WEEKS. PERFECTLY STRUCTURED.</div>
      <div className="week-plan__second">
        Learn through a step-by-step curriculum
      </div>
      <div className="week-plan__third">
        Drastically improve your skills through in-depth video lessons,
        projects, and feedback. It’s a world-class curriculum and unforgettable
        learning experience designed to level you up.
      </div>
      <div className="week-plan__card">
        <div className="week-plan__card__top">6 WEEKS PLAN</div>
        <div className="week-plan__card__main">
          <div className="week-plan__card__main__left">1</div>
          <div className="week-plan__card__main__right">
            <div className="week-plan__card__main__right__title">
              Week 1: Onboarding and Introduction
            </div>
            <div className="week-plan__card__main__right__desc">
              This week will be your introductory week and you'll learn how to
              greet someone and introduce yourself. Gradually you'll learn to
              build sentences and be able to ask questions. You'll also learn
              the basics like numbers and alphabets in German.
            </div>
            <div
              onClick={() => setWeek1(!week1)}
              className="week-plan__card__main__right__show"
            >
              {' '}
              {!week1 ? ' + SHOW DAILY SCHEDULE' : ' - HIDE DAILY SCHEDULE'}
            </div>
            {week1 && (
              <div className="week-plan__card__main__right__cards">
                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Greetings and Goodbyes
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      You'll learn how to greet people around you in different
                      situations. For example, when you meet someone (formally
                      as well as infomally), saying goodbye and greeting on
                      various festivals.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 1
                  </div>
                </div>
                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Introducing Yourself and Others
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      Learn how to introduce yourself and others. So this lesson
                      will help to give your introduction in the most precise
                      and perfect manner.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 2
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Hallo! Tschus!
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      You will be listening to Germans in this lesson. This will
                      also acquaint you with German accent. This is a short
                      conversation between two people who meet and greet each
                      other.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 2
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      First and Last Name
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      This lesson is all about explaining what is the first and
                      last name called in German. Unlike English it is a little
                      different in German.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 3
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Guten Morgen! Auf Wiedersehen!
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      Another German listening lesson, wherein you'll be
                      listening to the Germans. This is a short conversation
                      between two people who greet each other formally.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 3
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      W-Questions
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn about the type of questions
                      in German language. This lesson emphasizes on the W-word
                      questions like what, when, where, etc.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 4
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Yes / No Questions
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      This lesson cover the other type of questions, that is the
                      yes and no questions. After this lesson, you'll everything
                      about asking questions in German and you'll be able to
                      frame simple everyday questions in German.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 4
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Cardinal Numbers
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      Numbers are a vital part of our life. This lesson teaches
                      about all the cardinal numbers in a very beautiful manner.
                      This lesson starts with smaller numbers, then goes on the
                      bigger number. And by the end of this lesson you'll know
                      the tricks that'll help you know any number. You don't
                      need to memorise everything, just know the trick and you
                      can speak any number.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 5
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Date and Telephone Number
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      You will learn about how to tell dates and ask about
                      dates. Also you'll learn telling or asking telephone
                      numbers. This lesson practically tells us how to use
                      numbers learnt in previous lesson.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 5
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Years, Decimals, Percentages, Fraction, Prices
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      This lesson is all about numbers in different forms.You
                      find numbers in years, decimal form, percentages,
                      fractions. You will learn how to say years, decimals,
                      percentages, fractions and prices in German and we will
                      frame sentences using these numerical forms in German.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 6
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Ordinal Numbers
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, we will learn the ordinal numbers in
                      German (first, second, third, etc.), which are used to
                      show the numerical order of things rather than just
                      counting. We'll also look at some examples.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 6
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Alphabets
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      It is important to master the German alphabet completely
                      from the start. In this lesson, we'll learn German
                      Alphabet from scratch. Also you'll get the tips and advice
                      for learning how to master the German alphabet easily.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 7
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Sounds
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn how to pronounce some extra
                      letters in German, other than the usual alphabets. These
                      letters have a different sound. So we'll introduce you to
                      German sounds and some extra letters.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 7
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="week-plan__card__main">
          <div className="week-plan__card__main__left">2</div>
          <div className="week-plan__card__main__right">
            <div className="week-plan__card__main__right__title">
              Week 2: Components of a Sentence
            </div>
            <div className="week-plan__card__main__right__desc">
              This week starts with talking about where you come from and gets
              into some grammar you'll need to frame grammatically correct
              sentences. You'll learn some practical concepts like telling your
              Email ID and talking about your likes and dislikes and start
              planning your days in German.You'll start framing basic German
              sentences.
            </div>
            <div
              onClick={() => setWeek2(!week2)}
              className="week-plan__card__main__right__show"
            >
              {' '}
              {!week2 ? ' + SHOW DAILY SCHEDULE' : ' - HIDE DAILY SCHEDULE'}
            </div>
            {week2 && (
              <div className="week-plan__card__main__right__cards">
                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Countries and Languages
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn the names of the countries
                      and languages spoken in those countries. Most countries
                      are spelled differently in German than English and they
                      may be masculine, feminine, or neuter.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 8
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Länder und Sprachen
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll listen to few German native
                      speakers who talk about themselves and you learn framing
                      sentences and how to talk about your country and the
                      languages you speak. This gives you real life exposure
                      about talking about yourself.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 8
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Nominativ Articles
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn about the nominative case in
                      German. The nominative represents the subject of the
                      sentence. We'll start with articles in nominative case and
                      get in-depth with the help of examples.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 9
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Hints for Articles
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn the tricks for learning
                      articles. Since articles will help you in identifying
                      gender of the noun, it's important to know the articles of
                      the nouns. Thus, you'll learn some tricks that will always
                      help you to identify the arricles.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 9
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Compound Nouns
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, we will talk about German compound nouns -
                      words that are made of two or more nouns combined together
                      to form one word. We will learn some of their grammatical
                      characteristics and how to use them. Also, you'll get to
                      know the trick to determine the article of such combined
                      nouns.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 10
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      How to tell / ask Email ID?
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn how do you tell or ask
                      someone about Email ID. You'll learn pronunciaton of
                      different symbols used in Email addresses, which is
                      different than in English. After this lesson, you won't
                      have any confusion understanding Email IDs.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 10
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Nominativ Personal Pronouns
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson we will learn all about personal pronouns:
                      what they are, how they are used. We'll make use of the
                      first case in German, i.e nominative case and learn it's
                      personal pronouns by framing sentences and quoting
                      examples.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 11
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Nominativ Verbs
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      There are certain verbs which only take a certain case. In
                      this lesson, we'll be learning few verbs which always take
                      up nominative after them and you'll also understand how to
                      find out what case will come based on the meaning of a
                      verb. This trick will help you identify Nominative case
                      forever.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 11
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Verb Conjugation - Regular Verbs
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      Verbs help us express better. In this lesson, you'll learn
                      to conjugate verbs in respect to the personal pronouns.
                      This will set the base for you to frame any kind of
                      sentences. In this video lesson, we'll focus on regular
                      verbs which conjugate the same way for all verbs. So
                      learning to conjugate one will help you conjugate any
                      verb.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 12
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Verb Conjugation - Irregular Verbs
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn the conjugation of irregular
                      verbs. Irregular verbs are a little different than the
                      regular verbs. A few pronouns conjugate differently.
                      You'll learn with the help of tricks to help you remember
                      those verbs differently.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 12
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Talking about Hobbies
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn how to talk about your
                      favorite hobby in German and anything that you like to do.
                      Also be able to ask other what they like to do. Learn to
                      express your likes and desires better.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 13
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Days of the Week
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      Learn how to say days of the week in German. This lesson
                      will be very handy for you, to help you refer to any day
                      easily. You'll also learn to frame sentences in German
                      while refering to a particular day. This lesson is a
                      consolidated guide for mastering not just the days
                      vocabulary but also the stringent grammar associated with
                      days in German.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 14
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="week-plan__card__main">
          <div className="week-plan__card__main__left">3</div>
          <div className="week-plan__card__main__right">
            <div className="week-plan__card__main__right__title">
              Week 3: Talking about your Surroundings
            </div>
            <div className="week-plan__card__main__right__desc">
              You'll level up in this week and start framing some more complex
              sentences. You find yourself expressing yourself better by
              learning to talk about your profession or about places you visit
              and talk about you meals. You'll be able to talk about months and
              seasons coming up. We'll also dive in some cultural aspects of
              Gemany.
            </div>
            <div
              onClick={() => setWeek3(!week3)}
              className="week-plan__card__main__right__show"
            >
              {' '}
              {!week3 ? ' + SHOW DAILY SCHEDULE' : ' - HIDE DAILY SCHEDULE'}
            </div>
            {week3 && (
              <div className="week-plan__card__main__right__cards">
                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Months & Seasons
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, we'll learn how to say months and seasons
                      in German. You'll also learn to frame sentences in German
                      while refering to a months and seasons. This lesson not
                      just covers vocabulary but also the stringent grammar
                      associated with months and seasons in German.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 15
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Filling forms
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll how to fill some basic forms in
                      German. This is a very useful lesson since it covers all
                      the basic vocabulary and thing you can find in a German
                      form.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 16
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Professions
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn about different professions
                      in German, including how it stands for male and female.
                      You'll learn how to frame questions to ask someone about
                      professions and also how to answer back to such questions
                      and tell your profession.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 16
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Talking about your Profession
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      This is a listening and reading lesson. You'll listen to
                      few people talk about their prfession in German and side
                      by side read the same in order to hone listening skills.
                      Also you get to know how people describe what are they by
                      profession and give live examples for you to grasp.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 16
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Plural
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      This is the ultimate guide for learning plurals in German.
                      You'll not just learn plurals of some nouns but also learn
                      the tricks to remember these plurals and frame plurals of
                      enormous nouns yourself.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 17
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Talking about Places
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      This is a listening lesson, wherein you can read and
                      listen about some places. So in this lesson you learn to
                      describe certain places. You are not only honing your
                      listening skills but also you get exposure to German
                      accent, at the same time you can pick up phrases to
                      describe places.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 18
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      The Taxi-Ride
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you listen to a short conversation between
                      a taxi-driver and a girl, talking about all the places
                      that come during the taxi ride. So with this lesson you
                      get to know how you ask about things and tell about
                      different places.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 18
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Events in Hamburg
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      This is another listening pratice lesson, wherein you get
                      to know about certain events happening in Hamburg. This
                      lesson will help you grasp the German accent and know how
                      you can talk about different things in German.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 18
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      What is Akkusativ?
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      Akkusativ, being the second grammatical case in German, is
                      very important part of German grammar. This lesson
                      basically gives you base of Akkusativ case and describes
                      how you can differentiate it from the Nominativ case.
                      You'll start with examples and tricks to grasp Akkusativ
                      in any sentence.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 19
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Akkusativ Articles
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you learn articles in the Akkusativ case.
                      You will also learn certain tricks to remember these
                      articles and differentiate from the Nominativ case.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 19
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Akkusativ Personal Pronouns
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn personal pronouns in the
                      Akkusativ case. You'll learn with the help of examples
                      that will help you remember and be able to differentiate
                      from Nominative case.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 20
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Akkusativ Verbs
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      There are certain verbs which only take a certain case. In
                      this lesson, we'll be learning few verbs which always take
                      up Akkusativ after them and you'll also understand how to
                      find out what case will come based on the meaning of a
                      verb. This trick will help you identify Akkusativ case
                      forever.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 20
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      German Meals and Food items
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      This is a very useful lesson for you. In this lesson,
                      you'll learn about different meals and food items in
                      German. This will enable you to speak about basic food
                      items and meal in German. If you know these food items,
                      you can easily treat your tummy well in a German
                      environment.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 21
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Talking about Breakfast, Lunch and Dinner
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      This a listening practice lesson. You'll listen to few
                      people talk about their meals of the day. You will not
                      only grab food items and meals but also learn how you can
                      talk about your meals in German and describe what you eat
                      in a day.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 21
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="week-plan__card__main">
          <div className="week-plan__card__main__left">4</div>
          <div className="week-plan__card__main__right">
            <div className="week-plan__card__main__right__title">
              Week 4: Framing Complex Sentences
            </div>
            <div className="week-plan__card__main__right__desc">
              This week will be more of grammar with some practical
              conversations like purchasing from a market. You'll learn to
              express possession of anything and express your needs and
              important things. Frame complex sentences as you move forward.
            </div>
            <div
              onClick={() => setWeek4(!week4)}
              className="week-plan__card__main__right__show"
            >
              {' '}
              {!week4 ? ' + SHOW DAILY SCHEDULE' : ' - HIDE DAILY SCHEDULE'}
            </div>
            {week4 && (
              <div className="week-plan__card__main__right__cards">
                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Conjunctions
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, we'll learn how to say months and seasons
                      in German. You'll also learn to frame sentences in German
                      while refering to a months and seasons. This lesson not
                      just covers vocabulary but also the stringent grammar
                      associated with months and seasons in German.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 22
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Planing a Purchase
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll be listening to Germans have a
                      conversation about planning a purchase. So this will give
                      you great exposure in how you can talk to others about
                      planning an event or any meal and deciding anything you
                      have to purchase.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 22
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Purchasing in Supermarket
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      This lesson includes short conversations between the
                      shopkeeper and the customer, related to purchasing in
                      Supermarket. This lesson will help you hone your listening
                      skills as well as grasp phrases used in real life for
                      making purchases, asking the seller, etc.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 22
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Fruits and Vegetables
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn basic vocabulary related to
                      fruits and vegetables in German. These are the basic
                      themed vocabulary which comes handy while you speak in
                      German.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 23
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Possessiv Pronouns
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you learn how to talk about possession in
                      German (my, your, etc). This is an introductory lesson to
                      possessive pronouns in German and explaining the
                      difference between possessive pronouns and adjectives.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 23
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Declination of Possessive Articles
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, we focus on possessive articles. You will
                      learn to make use of possessive articles in sentences and
                      learn how to memorize them for correct use.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 24
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Declination of Possessive Pronouns
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you learn about possessive pronouns. You
                      will understand the correct differentiation and
                      declination of pronouns to sound grammatically
                      professional.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 24
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      What are Modal Verbs?
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn what are modal verbs. You'll
                      come across the modal verbs which help us express our
                      emotions better. Learn how to say "I have to/ I must/ I
                      shouldů etc."
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 25
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Basic Modal Verbs
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, your learn all the basic modal verbs in
                      German with help of verb conjugation (which will help in
                      better conjugation of verbs in respect of personal
                      pronouns) and example sentences, which will enable to
                      grasp the concept efficiently.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 25
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Other Modal Verbs
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      This lesson includes some additional modal verbs in German
                      to say things like "I would like toů". You will learn
                      every such modal verb which will make your German
                      conversations easy.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 26
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Trennbare v/s Untrennbare Verben
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you will come across the concept of
                      separable and inseparable verbs. This is a very important
                      grammar topic, which revolves around verbs. Learn the
                      concept of such verbs in this lesson and learn how they
                      are different from other verbs that you have learnt.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 26
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Trennbare Verb
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn everything you need to know
                      about separable verbs. You will find these verbs in very
                      basic conversations in German. Here, you'll learn all the
                      tricks and hints you can apply to identify separable
                      verbs. Memrize the hints and look the examples quoted.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 27
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Untrennbare Verb
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn the grammar behind
                      inseparable verbs. You'll be making use of examples to
                      frame any kind of sentence, when you know the kinds of
                      verbs. Memorize the verbs with the hints and understanding
                      behind these verbs.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 27
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Time
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      This is a very important lesson, which will enable you to
                      not just ask time, but also tell what time it is. You'll
                      learn every tiny bit about telling time in this lesson.
                      From quarters to hours to minutes, also formal and
                      informal ways of telling any time in German.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 28
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="week-plan__card__main">
          <div className="week-plan__card__main__left">5</div>
          <div className="week-plan__card__main__right">
            <div className="week-plan__card__main__right__title">
              Week 5: Ordering Food and Learning Culture
            </div>
            <div className="week-plan__card__main__right__desc">
              Get deeper in the language and you'll keep learning. By now,
              you'll be able to frame almost any kind of a sentence.You'll also
              dive into talking about your home and be proficient enough to
              order food in a German restaurant. You'll also acquant yourself
              with the rich German food culture and the basic manners and
              etiquettes to follow.
            </div>
            <div
              onClick={() => setWeek5(!week5)}
              className="week-plan__card__main__right__show"
            >
              {' '}
              {!week5 ? ' + SHOW DAILY SCHEDULE' : ' - HIDE DAILY SCHEDULE'}
            </div>
            {week5 && (
              <div className="week-plan__card__main__right__cards">
                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      In Restaurant
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn the German phrases you need
                      to dine like natives at restaurants. You'll learn how to
                      order food in German and lead a conversation with the
                      waiter. Post this lesson, you'll be speaking perfect
                      German at a restaurant.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 29
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      What is Dativ?
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      Dativ is the third grammatical case in German. This lesson
                      basically gives you base of Akkusativ case and describes
                      how you can differentiate it from Nominativ and Dativ
                      cases. You'll start with examples and learn the tricks to
                      identify Dativ in any sentence.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 30
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Dativ Articles
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn articles used in the Dativ
                      case. They are the third kind of articles that you'll be
                      doing, after Nominativ and Akkusativ. You will also learn
                      when and how to identify Dativ scenario and make use of
                      these articles.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 30
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Dativ Personal Pronouns
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn personal pronouns in the
                      Dativ case. We'll frame simple German sentences which
                      involves all the three cases that you have learnt and
                      you'll learn how you can substitute Dativ objects with
                      Dativ personal pronouns.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 31
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Dativ Sentence Structure
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, the focus is on building any kind of
                      sentence in German. With the three German cases,
                      Nominativ, Akkusativ and Dativ, you can build even complex
                      German sentences. So, in this lesson, you'll learn the
                      structure and the positioning of different aspects in a
                      sentence and post that you'll find framing sentences
                      easier.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 31
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Dativ Verbs
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll come across some verbs that only
                      take up Dativ. When you can identify by the verb itself
                      what case will come, things will become so easier. We'll
                      be framing more sentences with these verbs to remember
                      them.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 32
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      My Home
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn how to say different rooms
                      and things in a house and what are different kinds of
                      houses/apartments. We'll also be framing some sentences in
                      this lesson, based on the things in your house. Thus, this
                      lesson will enable you to describe parts of your house.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 32
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Imperative
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      Imperaive is used for expressing an order in a language.
                      In this lesson, you'll learn how to give and take orders
                      in German. The complete grammar associated with imperative
                      is explained with the help of examples to grasp the
                      details and make you remember. Imperative is very common
                      in German and it can make you conversations sound polite
                      or harsh, depending on the way you speak.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 33
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Furniture
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll be learning different furniture
                      pieces at your home. This will enable to express what you
                      have or need at your home. This will also be handy, when
                      you have to buy some furniture piece.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 34
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Talking about your Appartment
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      This is a listening lesson, wherein you'll be listening
                      and reading a few people express their houses, what they
                      like and dislike about their houses. Such lessons, not
                      just hone your listening skills, but also you can notice
                      the real life German way of expressing things and use
                      similar phrases to express yourself better and sound like
                      a native.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 34
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      German Cases - Nominative, Akkusative, Dative
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      This is a consolidated lesson covering all the three
                      German cases, Nominativ, Akkusativ and Dativ. In this
                      lesson, you will get an overview of the three cases and
                      how to differentiate between them and tricks to understand
                      which case will apply.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 34
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Prepositions
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      This lesson is an introduction to prepositions in German.
                      You'll get an overview of different kinds of prepositions
                      in German.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 35
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Akkusative Prepositions
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn all the Akkusativ
                      prepositions. These prepositions only take up Akkusativ
                      after them. So these prepositions will also help you
                      identify the case.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 35
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="week-plan__card__main">
          <div className="week-plan__card__main__left">6</div>
          <div className="week-plan__card__main__right">
            <div className="week-plan__card__main__right__title">
              Week 6: Talking about Past and giving Opinions
            </div>
            <div className="week-plan__card__main__right__desc">
              This week, you'll be learning to include prepositions in your
              sentence to be able to connect them in correct grammatical
              structures. You'll be able to talk in past tense and learn the
              most practical way in which Germans talk. Get geared up to read
              the job advertisements and giving opinions. By nearing the end of
              the course, you'll be conversaional enough to be able to express
              how your day went and even book appointments. Infact, pick any any
              topic and speak over it.
            </div>
            <div
              onClick={() => setWeek6(!week6)}
              className="week-plan__card__main__right__show"
            >
              {' '}
              {!week6 ? ' + SHOW DAILY SCHEDULE' : ' - HIDE DAILY SCHEDULE'}
            </div>
            {week6 && (
              <div className="week-plan__card__main__right__cards">
                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Dative Prepositions
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn all the Dativ prepositions.
                      These prepositions only take up Dativ after them. So these
                      prepositions will also help you identify the case.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 36
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Wechsel Prepositions
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson you'll be learning preposition that might
                      take up Akkusativ or Dativ case. You'll comceptually
                      understand how you can decide on the case that these
                      prepositions will take.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 37
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Temporal Prepositions
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll be learning prepositions that have
                      referrence to the time. They reflect/denote time in
                      general. Thus, they are very useful to have everyday
                      conversations.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 38
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Appointment with Doctor
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll be listening to Germans booking an
                      appointment with the doctor. You will learn how the person
                      books the appointment with the doctor and you can pick up
                      the usable phrases for this kind of situation.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 39
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Job Advertisement
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, we'll be looking at some job
                      advertisements in German and understand the meaning of
                      different aspects of an advertisement. You'll learn how to
                      understand any job advertisement in German.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 39
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Introduction to Präteritum
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll be introduced to the past form in
                      German. You will learn the basics of using past form in
                      German.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 40
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Präteritum of Basic Verbs
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll how to conjugate some basic verbs
                      in order to denote the past tense. This will enable you to
                      frame basic sentences in the past form and you can
                      conjugate the other verbs in the same way.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 40
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Giving Opinion on Jobs
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll listen to Germans, talk about their
                      experiences of a job. You'll get to listen to the
                      viewpoints of two different people, one talking about pros
                      and the other cons. This lesson will help you learn how
                      you can talk about pros and cons of a job and also about
                      other things you encounter.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 40
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Partizip Perfekt
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn the perfect form in German.
                      This is a grammatical topic that will enable you to speak
                      your heart in German, since this tense form is most used
                      in the verbal German.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 41
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      How to build Past Participle?
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn how to build past participle
                      of different verbs in order to speak in the present
                      perfect form. You'll every possible way that a verb is
                      made participle and get tricks to remember them.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 41
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Sentence Structure with Partizip Perfekt
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll learn to build up sentences in
                      present perfect form. You'll learn the structure of a
                      sentence in perfect form in various different scenarios.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 42
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Expressing your Day
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll listen to Germans, talk about how
                      their day went. You'll learn to describe your day and you
                      could also start writing a diary for yourself and
                      describing all that you did in the day.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 42
                  </div>
                </div>

                <div className="week-plan__card__main__right__card">
                  <div className="week-plan__card__main__right__card__info">
                    <div className="week-plan__card__main__right__card__info__title">
                      Social Networks
                    </div>
                    <div className="week-plan__card__main__right__card__info__desc">
                      In this lesson, you'll listen to the pros and cons of few
                      social media networks in German. This is a listening
                      lesson which will enable you to grasp German accent and
                      understand anything that's said in German. You can also
                      pick up phrases to use in day-to-day living to talk about
                      anything.
                    </div>
                  </div>
                  <div className="week-plan__card__main__right__card__right">
                    Day 42
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GermanWeekPlan;
