import fetch from 'isomorphic-fetch';

export const emailContactForm = data => {
  return fetch(
    `https://m3ucxha2z8.execute-api.ap-south-1.amazonaws.com/dev/contact`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }
  )
    .then(response => {
      return response.json();
    })
    .catch(err => console.log(err));
};
