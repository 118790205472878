import React from 'react';
import Spanish from '../assets/learn-spanish.jpg';
import Japanese from '../assets/Learn-Japanese-in-Delhi.jpg';
import German from '../assets/learn-german.jpg';

const SectionAbout = () => {
  return (
    <section className="section-about">
      <div className="u-center-text u-margin-bottom-big">
        <h2 className="heading-secondary">Learn a language on the go</h2>
      </div>
      <div className="row">
        <div className="col-1-of-2">
          <h3 className="heading-tertiary u-margin-bottom-small">
            Learning a language has never been easier and more accessible.
          </h3>
          <p className="paragraph">
            Learn a language on the go, from your couch, office or anywhere you
            wish. We optimize your language learning journey. So set your goals
            for learning a foreign language and start out from base with
            LinguaPsych.
          </p>
          <h3 className="heading-tertiary u-margin-bottom-small">
            Learn a language and travel the world
          </h3>
          <p className="paragraph">
            Combining all language learning resources you need to travel the
            world. Linguapsych is your one stop solution when you are on the go.
            Grasp all the basics about the language of the country you're
            stepping into.
          </p>
        </div>
        <div className="col-1-of-2">
          <div className="composition">
            <img
              //   srcset="img/nat-1.jpg 300w, img/nat-1-large.jpg 1000w"
              //   sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
              alt="spanish 1"
              className="composition__photo composition__photo--p1"
              src={Spanish}
            />

            <img
              //   srcset="img/nat-2.jpg 300w, img/nat-2-large.jpg 1000w"
              //   sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
              alt="japanese 2"
              className="composition__photo composition__photo--p2"
              src={Japanese}
            />

            <img
              //   srcset="img/nat-3.jpg 300w, img/nat-3-large.jpg 1000w"
              //   sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
              alt="german 3"
              className="composition__photo composition__photo--p3"
              src={German}
            />

            {/*                             
                            <img src="img/nat-1-large.jpg" alt="Photo 1" class="composition__photo composition__photo--p1">
                            <img src="img/nat-2-large.jpg" alt="Photo 2" class="composition__photo composition__photo--p2">
                            <img src="img/nat-3-large.jpg" alt="Photo 3" class="composition__photo composition__photo--p3"> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionAbout;
