import React, { useState, useEffect } from 'react';
import history from './utils/history';
import { Router, Route, Switch } from 'react-router-dom';
import Homepage from './pages/Homepage';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Conditional from './pages/conditional-tense-spanish';
import Desu from './pages/desu-japanese';
import Pronouns from './pages/direct-indirect-object-pronouns';
import Disclaimer from './pages/disclaimer';
import LanguageCourse from './pages/foreign-language-courses-delhi';
import LanguageCourseDelhi from './pages/foreign-language-courses-in-delhi-university';
import French from './pages/french-language-course-in-delhi';
import Future from './pages/future-tense-spanish';
import GermanAlphabet from './pages/german-alphabet-letters';
import GermanDays from './pages/german-days-of-the-week';
import GermanColors from './pages/german-colors';
import GermanMonths from './pages/german-months-seasons';
import GermanCourse from './pages/german-language-course-in-delhi';
import GermanNumbers from './pages/german-numbers';
import GermanUmlaut from './pages/german-umlaut';
import Imperative from './pages/imperative-spanish';
import German from './pages/german';
import Japanese from './pages/japanese';
import JapaneseParticles from './pages/japanese-particles';
import KoreanAlphabet from './pages/korean-alphabet-letters';
import KoreanNumbers from './pages/korean-numbers';
import KoreanSentence from './pages/korean-sentence-structure';
import Spanish from './pages/spanish';
import SpanishDefinite from './pages/spanish-definite-indefinte-articles';
import Korean from './pages/korean';
import SpanishMonths from './pages/months-in-spanish';
import SpanishDays from './pages/spanish-days-of-the-week';
import PossessiveAdjectives from './pages/possessive-adjectives-spanish';
import SpanishNouns from './pages/spanish-nouns-articles-genders';
import Privacy from './pages/privacy-policy';
import SpanishNumbers from './pages/spanish-numbers';
import SpanishPlural from './pages/spanish-plural-nouns';
import Preterite from './pages/preterite-vs-imperfect';
import SpanishPersonal from './pages/subject-personal-pronouns-in-spanish';
import LearnJapanese from './pages/learn-japanese-in-delhi';
import GermanTime from './pages/time-in-german';
import ScrollToTop from './components/ScrollToTop';
import SpanishAlphabets from './pages/spanish-alphabets-accents-pronunciation';
import SpanishCourse from './pages/spanish-course';
import GermanCoursePage from './pages/german-course';
import SpanishWhatsapp from './pages/spanish-whatsapp';
import Thankyou from './pages/Thankyou';
//import Webinar from './pages/webinar'
import './App.css';

const App = () => {
 // const [landing, setLanding] = useState(false)

  return (
    <Router history={history}>
     
      <Navigation />
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route exact path="/learn-spanish" component={SpanishCourse} />
        <Route exact path="/spanish-workshop" component={SpanishWhatsapp} />
        <Route exact path="/learn-german" component={GermanCoursePage} />
        {/* <Route exact path="/webinar" component={Webinar} /> */}
        <Route
          exact
          path="/conditional-tense-spanish"
          component={Conditional}
        />
        <Route exact path="/desu-japanese" component={Desu} />
        <Route
          exact
          path="/direct-indirect-object-pronouns"
          component={Pronouns}
        />
        <Route
          exact
          path="/foreign-language-courses-delhi"
          component={LanguageCourse}
        />
        <Route exact path="/disclaimer" component={Disclaimer} />
        <Route
          exact
          path="/foreign-language-courses-in-delhi-university"
          component={LanguageCourseDelhi}
        />
        <Route
          exact
          path="/french-language-course-in-delhi"
          component={French}
        />
        <Route exact path="/future-tense-spanish" component={Future} />
        <Route
          exact
          path="/german-alphabet-letters"
          component={GermanAlphabet}
        />

        <Route exact path="/german" component={German} />
        <Route exact path="/german-colors" component={GermanColors} />
        <Route exact path="/german-days-of-the-week" component={GermanDays} />
        <Route
          exact
          path="/german-language-course-in-delhi"
          component={GermanCourse}
        />
        <Route exact path="/german-months-seasons" component={GermanMonths} />
        <Route exact path="/german-numbers" component={GermanNumbers} />
        <Route exact path="/german-umlaut" component={GermanUmlaut} />
        <Route exact path="/imperative-spanish" component={Imperative} />
        <Route exact path="/japanese" component={Japanese} />
        <Route exact path="/japanese-particles" component={JapaneseParticles} />
        <Route exact path="/korean" component={Korean} />
        <Route exact path="/webinar-thankyou" component={Thankyou} />
        <Route
          exact
          path="/korean-alphabet-letters"
          component={KoreanAlphabet}
        />
        <Route exact path="/korean-numbers" component={KoreanNumbers} />
        <Route
          exact
          path="/korean-sentence-structure"
          component={KoreanSentence}
        />
        <Route
          exact
          path="/learn-japanese-in-delhi"
          component={LearnJapanese}
        />
        <Route exact path="/months-in-spanish" component={SpanishMonths} />
        <Route
          exact
          path="/possessive-adjectives-spanish"
          component={PossessiveAdjectives}
        />
        <Route exact path="/preterite-vs-imperfect" component={Preterite} />
        <Route exact path="/privacy-policy" component={Privacy} />
        <Route exact path="/spanish" component={Spanish} />
        <Route
          exact
          path="/spanish-alphabets-accents-pronunciation"
          component={SpanishAlphabets}
        />
        <Route exact path="/spanish-days-of-the-week" component={SpanishDays} />
        <Route
          exact
          path="/spanish-definite-indefinte-articles"
          component={SpanishDefinite}
        />
        <Route
          exact
          path="/spanish-nouns-articles-genders"
          component={SpanishNouns}
        />
        <Route exact path="/spanish-numbers" component={SpanishNumbers} />
        <Route exact path="/spanish-plural-nouns" component={SpanishPlural} />
        <Route
          exact
          path="/subject-personal-pronouns-in-spanish"
          component={SpanishPersonal}
        />
        <Route exact path="/time-in-german" component={GermanTime} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
