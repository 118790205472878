import React, { useState } from 'react';

import GetStarted from '../components/GetStarted';

const Enroll = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return (
    <div className="enroll">
      <GetStarted
        show={showModal}
        onCancel={() => closeModal()}
        contentClass="place-item__modal-content"
        footerClass="place-item__modal-actions"
      />
      <div className="enroll__top">Ready to enroll?</div>
      <div className="enroll__mid">-</div>
      <div className="enroll__text">
        Join the waitlist now to be the first one to know when enrollment opens
        for the course and get access to our exciting early bird discounts and
        offers. Join thousands of language learners, learning language all by
        yourself!
      </div>
      <div
        onClick={() => {
          openModal();
          window.scrollTo(0, 0);
        }}
        className="enroll__button"
      >
        Join Waitlist
      </div>
    </div>
  );
};
export default Enroll;
