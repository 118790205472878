import React from 'react';
import Video from '../assets/video.mp4';
import User1 from '../assets/user1.jpg';
import User2 from '../assets/user2.jpg';

const SectionStories = () => {
  return (
    <section className="section-stories">
      <div className="bg-video">
        <video autoPlay muted loop className="bg-video__content">
          <source src={Video} type="video/mp4" />
          Your browser is not supported!
        </video>
      </div>
      <div className="u-center-text u-margin-bottom-big">
        <h2 className="heading-secondary">Some Students also say</h2>
      </div>
      <div className="row">
        <div className="story">
          <figure className="story__shape">
            <img src={User1} alt="A reader" className="story__img" />
            <figcaption className="story__caption"> Ashely Roberts </figcaption>
          </figure>
          <div className="story__text">
            <h3 className="heading-tertiary u-margin-bottom-small">
              Lessons are very easy to understand
            </h3>
            <p>
              I learnt basic German lessons from Linguapsych when I was
              travelling to Germany. I utilized my time in flight for grasping
              some basic language hacks and when I reached Germany, I was able
              to understand a bit of the language and my friends were awestruck
              looking at my language grasping skills.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="story">
          <figure className="story__shape">
            <img src={User2} alt="A reader" className="story__img" />
            <figcaption className="story__caption">
              {' '}
              Martin Gillespie{' '}
            </figcaption>
          </figure>
          <div className="story__text">
            <h3 className="heading-tertiary u-margin-bottom-small">
              Come here if you want to have complete information
            </h3>
            <p>
              You have really great content on your website. Really helpful for
              those wanting to start learning a language. I started my language
              journey from here. It gave me an idea of the languages I wanted to
              learn.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionStories;
