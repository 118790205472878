import React, { useState, useEffect } from 'react';
import moment from 'moment';

const Sale = (props) => {
  const [days, setDays] = useState(undefined);
  const [hours, setHours] = useState(undefined);
  const [minutes, setMinutes] = useState(undefined);
  const [seconds, setSeconds] = useState(undefined);

  useEffect(() => {
    if (props) {
      setInterval(() => {
        const { timeTillDate, timeFormat } = props;
        const then = moment(timeTillDate, timeFormat);
        const now = moment();
        const countdown = moment(then - now);
        setDays(countdown.format('DD'));
        setHours(countdown.format('HH'));
        setMinutes(countdown.format('mm'));
        setSeconds(countdown.format('ss'));
      });
    }
  }, []);

  return (
    <>
      <div className="timer">
      <div className="timer__days">
      <div className="timer__days__top">
        {days}
        </div>
        <div className="timer__days__bottom">
        Days
        </div>
        </div>
        <div className="timer__hours">
      <div className="timer__hours__top">
        {hours}
        </div>
        <div className="timer__hours__bottom">
        Hours
        </div>
        </div>
        <div className="timer__minutes">
      <div className="timer__minutes__top">
        {minutes}
        </div>
        <div className="timer__minutes__bottom">
        Minutes
        </div>
        </div>
       
        <div className="timer__seconds">
      <div className="timer__seconds__top">
        {seconds}
        </div>
        <div className="timer__seconds__bottom">
        Seconds
        </div>
        </div>
      </div>
    </>
  );
};

export default Sale;
