import React from 'react';
import { Link } from 'react-router-dom';

import '../sass/main.scss';
import MetaTags from 'react-meta-tags';
import Image from '../assets/german-numbers.jpg';
import Image1 from '../assets/german-numbers-1.PNG';
import Robot from '../assets/german-numbers-robot.jpg';
import Numbers1 from '../assets/german-number-1-10.PNG';
import Numbers2 from '../assets/german-number-1-20.PNG';
import Numbers3 from '../assets/german-number-20-90.PNG';
import Numbers4 from '../assets/german-number-21-97.PNG';
import Numbers5 from '../assets/german-number-1-17.PNG';
import Numbers6 from '../assets/german-number-100-billion.PNG';
import Numbers7 from '../assets/german-tricky-numbers.PNG';
import Numbers8 from '../assets/ordinal-numbers-german.PNG';
import Numbers9 from '../assets/german-numbers-1-10.png';
import German from '../assets/german_course_bottom.png';

const GermanNumbers = () => {
  const head = () => (
    <MetaTags>
      <title>
        Easiest Way To Count German Numbers With Pronunciation, 1-100 |
        LinguaPsych
      </title>
      <meta
        name="description"
        content="Want to learn how to say German Numbers? Learn how to count numbers in German easily with pronunciation. Learn what are cardinal and ordinal numbers and also how to say your age."
      />
      <link rel="canonical" href="https://linguapsych.com/german-numbers/" />
      <meta
        property="og:title"
        content="Want to learn how to say German Numbers? Learn how to count numbers in German easily with pronunciation. Learn what are cardinal and ordinal numbers and also how to say your age."
      />
      <meta
        property="og:description"
        content="Want to learn how to say German Numbers? Learn how to count numbers in German easily with pronunciation. Learn what are cardinal and ordinal numbers and also how to say your age."
      />
      <meta property="og:type" content="webiste" />
      <meta
        property="og:url"
        content="https://linguapsych.com/german-numbers/"
      />
      <meta property="og:site_name" content="https://linguapsych.com/" />

      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/german-numbers.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/german-numbers.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );
  return (
    <React.Fragment>
      {head()}

      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_GERMAN_COURSE}`}>
          <div className="article__german-banner">
            <div className="article__german-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="german numbers"
            className="article__featured-image"
          />
          <div
            style={{ marginTop: '-75px' }}
            className="article__header-elements"
          >
            <h1 className="article__title">
              {' '}
              <span
                style={{ backgroundColor: 'black' }}
                className="article__highlight"
              >
                {' '}
                How to count German Numbers?
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <p>
              Do you want to learn how to count German Numbers? In this article,
              you will learn how to count numbers in German language along with
              their pronunciation in a manner that you will never forget them in
              future.
            </p>
            <p>
              Numbers is something we encounter in our day to day living. We
              need to know how to count to be able to be a part of the day to
              day conversations. You’ll find numbers everywhere; while telling
              time, when paying bills, while telling date. In short, you need
              numbers everywhere. Numbers are all over the place, whether you
              want to count how many pages are there in your book or how many
              marks did you score in your German exam.
            </p>
            <p>
              So when on a journey towards learning German, you must know how to
              count in German language. Only then will you be able to be part of
              conversations with anyone; be it your small talk with a friend or
              to buy coffee at the coffee shop. Being able to read and write
              numbers in German language is a goal for beginners since numbers
              are the building blocks of learning a language.
            </p>
            <img
              src={Image1}
              alt="german-numbers"
              style={{ width: '80%' }}
              className="u-margin-bottom-medium"
            />
            <h2 className="u-margin-bottom-medium">
              {' '}
              German Numbers From 1 to 100​ (Cardinal Numbers){' '}
            </h2>
            <p className="u-margin-bottom-medium">
              Before moving any further, let us first look at all the numbers at
              one glance. It is recommended that you go through these numbers on
              a daily basis initially, specially when you are a beginner in
              German language. You will need these numbers frequently, either
              while having conversations or while doing you homework.
            </p>
            <div style={{ color: 'black' }} className="row">
              <div
                style={{ backgroundColor: 'lightblue' }}
                className="col-1-of-3"
              >
                <p>
                  <span> 1 </span>
                  <span> Ein </span>
                </p>
                <p>
                  <span> 2 </span>
                  <span> Zwei </span>
                </p>
                <p>
                  <span> 3 </span>
                  <span> Drei </span>
                </p>
                <p>
                  <span> 4 </span>
                  <span> Vier </span>
                </p>
                <p>
                  <span> 5 </span>
                  <span> Fünf </span>
                </p>
                <p>
                  <span> 6 </span>
                  <span> Sechs </span>
                </p>
                <p>
                  <span> 7 </span>
                  <span> Sieben </span>
                </p>
                <p>
                  <span> 8 </span>
                  <span> Acht </span>
                </p>
                <p>
                  <span> 9 </span>
                  <span> Neun </span>
                </p>
                <p>
                  <span> 10 </span>
                  <span> Zehn </span>
                </p>
                <p>
                  <span> 11 </span>
                  <span> Elf </span>
                </p>
                <p>
                  <span> 12 </span>
                  <span> Zwölf </span>
                </p>
                <p>
                  <span> 13 </span>
                  <span> Dreizehn </span>
                </p>
                <p>
                  <span> 14 </span>
                  <span> Vierzehn </span>
                </p>
                <p>
                  <span> 15 </span>
                  <span> Fünfzehn </span>
                </p>
                <p>
                  <span> 16 </span>
                  <span> Sechzehn </span>
                </p>
                <p>
                  <span> 17 </span>
                  <span> Siebzehn </span>
                </p>
                <p>
                  <span> 18 </span>
                  <span> Achtzehn </span>
                </p>
                <p>
                  <span> 19 </span>
                  <span> Neunzehn </span>
                </p>
                <p>
                  <span> 20 </span>
                  <span> Zwanzig </span>
                </p>
                <p>
                  <span> 21 </span>
                  <span> Einundzwanzig </span>
                </p>
                <p>
                  <span> 22 </span>
                  <span> Zweiundzwanzig </span>
                </p>
                <p>
                  <span> 23 </span>
                  <span> Dreiundzwanzig </span>
                </p>
                <p>
                  <span> 24 </span>
                  <span> Vierundzwanzig </span>
                </p>
                <p>
                  <span> 25 </span>
                  <span> Fünfundzwanzig </span>
                </p>
                <p>
                  <span> 26 </span>
                  <span> Sechsundzwanzig </span>
                </p>
                <p>
                  <span> 27 </span>
                  <span> Siebenundzwanzig </span>
                </p>
                <p>
                  <span> 28 </span>
                  <span> Achtundzwanzig </span>
                </p>
                <p>
                  <span> 29 </span>
                  <span> Neunundzwanzig </span>
                </p>
                <p>
                  <span> 30 </span>
                  <span> Dreißig </span>
                </p>
                <p>
                  <span> 31 </span>
                  <span> Einunddreißig </span>
                </p>
                <p>
                  <span> 32 </span>
                  <span> Zweiunddreißig </span>
                </p>
                <p>
                  <span> 33 </span>
                  <span> Dreiunddreißig </span>
                </p>
                <p>
                  <span> 34 </span>
                  <span> Vierunddreißig </span>
                </p>
              </div>
              <div
                style={{ backgroundColor: 'lightblue' }}
                className="col-1-of-3"
              >
                <p>
                  <span> 35 </span>
                  <span> Fünfunddreißig </span>
                </p>
                <p>
                  <span> 36 </span>
                  <span> Sechsunddreißig </span>
                </p>
                <p>
                  <span> 37 </span>
                  <span> Siebenunddreißig </span>
                </p>
                <p>
                  <span> 38 </span>
                  <span> Achtunddreißig </span>
                </p>
                <p>
                  <span> 39 </span>
                  <span> Neununddreißig </span>
                </p>
                <p>
                  <span> 40 </span>
                  <span> Vierzig </span>
                </p>
                <p>
                  <span> 41 </span>
                  <span> Einundvierzig </span>
                </p>
                <p>
                  <span> 42 </span>
                  <span> Zweiundvierzig </span>
                </p>
                <p>
                  <span> 43 </span>
                  <span> Dreiundvierzig </span>
                </p>
                <p>
                  <span> 44 </span>
                  <span> Vierundvierzig </span>
                </p>
                <p>
                  <span> 45 </span>
                  <span> Fünfundvierzig </span>
                </p>
                <p>
                  <span> 46 </span>
                  <span> Sechsundvierzig </span>
                </p>
                <p>
                  <span> 47 </span>
                  <span> Siebenundvierzig </span>
                </p>
                <p>
                  <span> 48 </span>
                  <span> Achtundvierzig </span>
                </p>
                <p>
                  <span> 49 </span>
                  <span> Neunundvierzig </span>
                </p>
                <p>
                  <span> 50 </span>
                  <span> Fünfzig </span>
                </p>
                <p>
                  <span> 51 </span>
                  <span> Einundfünfzig </span>
                </p>
                <p>
                  <span> 52 </span>
                  <span> Zweiundfünfzig </span>
                </p>
                <p>
                  <span> 53 </span>
                  <span> Dreiundfünfzig </span>
                </p>
                <p>
                  <span> 54 </span>
                  <span> Vierundfünfzig </span>
                </p>
                <p>
                  <span> 55 </span>
                  <span> Fünfundfünfzig </span>
                </p>
                <p>
                  <span> 56 </span>
                  <span> Sechsundfünfzig </span>
                </p>
                <p>
                  <span> 57 </span>
                  <span> Siebenundfünfzig </span>
                </p>
                <p>
                  <span> 58 </span>
                  <span> Achtundfünfzig </span>
                </p>
                <p>
                  <span> 59 </span>
                  <span> Neunundfünfzig </span>
                </p>
                <p>
                  <span> 60 </span>
                  <span> Sechzig </span>
                </p>
                <p>
                  <span> 61 </span>
                  <span> Einundsechzig </span>
                </p>
                <p>
                  <span> 62 </span>
                  <span> Zweiundsechzig </span>
                </p>
                <p>
                  <span> 63 </span>
                  <span> Dreiundsechzig </span>
                </p>
                <p>
                  <span> 64 </span>
                  <span> Vierundsechzig </span>
                </p>
                <p>
                  <span> 65 </span>
                  <span> Fünfundsechzig </span>
                </p>
                <p>
                  <span> 66 </span>
                  <span> Sechsundsechzig </span>
                </p>
                <p>
                  <span> 67 </span>
                  <span> Siebenundsechzig </span>
                </p>
              </div>
              <div
                style={{ backgroundColor: 'lightblue' }}
                className="col-1-of-3"
              >
                <p>
                  <span> 68 </span>
                  <span> Achtundsechzig </span>
                </p>
                <p>
                  <span> 69 </span>
                  <span> Neunundsechzig </span>
                </p>
                <p>
                  <span> 70 </span>
                  <span> Siebzig </span>
                </p>
                <p>
                  <span> 71 </span>
                  <span> Einundsiebzig </span>
                </p>
                <p>
                  <span> 72 </span>
                  <span> Zweiundsiebzig </span>
                </p>
                <p>
                  <span> 73 </span>
                  <span> Dreiundsiebzig </span>
                </p>
                <p>
                  <span> 74 </span>
                  <span> Vierundsiebzig </span>
                </p>
                <p>
                  <span> 75 </span>
                  <span> Fünfundsiebzig </span>
                </p>
                <p>
                  <span> 76 </span>
                  <span> Sechsundsiebzig </span>
                </p>
                <p>
                  <span> 77 </span>
                  <span> Siebenundsiebzig </span>
                </p>
                <p>
                  <span> 78 </span>
                  <span> Achtundsiebzig </span>
                </p>
                <p>
                  <span> 79 </span>
                  <span> Neunundsiebzig </span>
                </p>
                <p>
                  <span> 80 </span>
                  <span> Achtzig </span>
                </p>
                <p>
                  <span> 81 </span>
                  <span> Einundachtzig </span>
                </p>
                <p>
                  <span> 82 </span>
                  <span> Zweiundachtzig </span>
                </p>
                <p>
                  <span> 83 </span>
                  <span> Dreiundachtzig </span>
                </p>
                <p>
                  <span> 84 </span>
                  <span> Vierundachtzig </span>
                </p>
                <p>
                  <span> 85 </span>
                  <span> Fünfundachtzig </span>
                </p>
                <p>
                  <span> 86 </span>
                  <span> Sechsundachtzig </span>
                </p>
                <p>
                  <span> 87 </span>
                  <span> Siebenundachtzig </span>
                </p>
                <p>
                  <span> 88 </span>
                  <span> Achtundachtzig </span>
                </p>
                <p>
                  <span> 89 </span>
                  <span> Neunundachtzig </span>
                </p>
                <p>
                  <span> 90 </span>
                  <span> Neunzig </span>
                </p>
                <p>
                  <span> 91 </span>
                  <span> Einundneunzig </span>
                </p>
                <p>
                  <span> 92 </span>
                  <span> Zweiundneunzig </span>
                </p>
                <p>
                  <span> 93 </span>
                  <span> Dreiundneunzig </span>
                </p>
                <p>
                  <span> 94 </span>
                  <span> Vierundneunzig </span>
                </p>
                <p>
                  <span> 95 </span>
                  <span> Fünfundneunzig </span>
                </p>
                <p>
                  <span> 96 </span>
                  <span> Sechsundneunzig </span>
                </p>
                <p>
                  <span> 97 </span>
                  <span> Siebenundneunzig </span>
                </p>
                <p>
                  <span> 98 </span>
                  <span> Achtundneunzig </span>
                </p>
                <p>
                  <span> 99 </span>
                  <span> Neunundneunzig </span>
                </p>
                <p>
                  <span> 100 </span>
                  <span> Hundert </span>
                </p>
              </div>
            </div>
            <h2 className="u-margin-bottom-medium">
              {' '}
              How To Count Numbers In German?{' '}
            </h2>
            <div className="row">
              <div className="col-1-of-2">
                <p>
                  {' '}
                  Numbers in German follow a specific pattern. Once you know the
                  trick, it won’t be hard for you to learn German numbers.
                </p>
                <p>
                  In German, we count numbers from right to left, unlike in
                  English. German numbers follow the pattern same as in Hindi
                  (Hindi is a language spoken in India). For example: In
                  English, we say 21 as “twenty-one”; i.e. 20 then 1. In German,
                  we say 21 as “einundzwanzig”; i.e. 1 and 20.
                </p>
              </div>
              <div className="col-1-of-2">
                <img src={Robot} style={{ width: '100%' }} />
              </div>
            </div>
            <p className="u-margin-bottom-medium">
              In German, we read the number at the right first and then the
              number on the left and add “und” (means and) in between. So
              basically you need to know numbers from 1 to 10 and then you can
              frame the other two or more digit numbers with just a little
              effort. Numbers till 9 lay down the basis for counting other
              numbers. Just eleven (11) and twelve (12) do not follow any rule.
            </p>
            <h2 className="u-margin-bottom-medium">
              What are Numbers in German From 1 To 10?
            </h2>
            <p>
              The numbers from 1 to 10 are significant to learn. If you learn
              these numbers by heart, it won’t be hard for you to say any number
              in German. The pattern in German numbers depends on numbers from 1
              to 10. These numbers are called cardinal numbers.
            </p>
            <img
              src={Numbers1}
              style={{ width: '80%' }}
              alt="german-number-1-10"
              className="u-margin-bottom-medium"
            />
            <p>
              These numbers above are called cardinal numbers. Cardinal numbers
              are the numbers that denote quantity; detailing much of something.
            </p>
            <ul>
              <span style={{ fontWeight: 'bold' }}>
                {' '}
                Let us look at some examples:{' '}
              </span>
              <li className="u-margin-top-small" style={{ listStyle: 'none' }}>
                Ich habe ein Hund. (I have a dog)
              </li>
              <li style={{ listStyle: 'none' }}>
                Wir haben zwei Hefte. (We have 2 notebooks)
              </li>
            </ul>
            <p style={{ fontWeight: 'bold' }} className="u-margin-top-small">
              Imagine you are in a meeting and the following conversation goes
              on:
            </p>
            <p>A: Wie viele Stühle sind hier? (How many chairs are here?)</p>
            <p>
              B: Wir zählen die Stühle: ein, zwei, drei, usw. (We count the
              chairs: one, two, three, etc.)
            </p>
            <p>C: Wir haben zehn Stühle. (We have ten chairs)</p>

            <h2 className="u-margin-top-small u-margin-bottom-medium ">
              {' '}
              German Numbers From 11 to 19
            </h2>
            <p>
              Other than 11 and 12, numbers from 13 till 19 follow a pattern.
              The trick is to add “zehn”, i.e 10 after every number. For
              example, to say 13, we add zehn after drei. So it becomes
              “dreizehn”. Likewise for all numbers till 19.
            </p>
            <ul className="u-margin-bottom-medium">
              <li>The trick is:- Base number + zig</li>
              <li>Fünf + zig = Fünfzig</li>
            </ul>
            <img
              style={{ width: '80%' }}
              src={Numbers2}
              className="u-margin-bottom-medium"
            />
            <h2 className="u-margin-bottom-medium">
              German Numbers in Multiples of 10
            </h2>
            <p>
              To say multiples of 10, from 20 to 90, in German numbers, there is
              also a pattern followed. 20 and 30 are the only exceptions. Simply
              add -zig after the numbers; except for 30, which ends with -ßig.
              For example, for 30 we say “dreißig” and for 40 we say “vierzig”.
            </p>
            <p>The trick is:- Base number + zig</p>
            <p>Fünf + zig = Fünfzig</p>
            <img
              src={Numbers3}
              style={{ width: '80%' }}
              alt="german-number-20-90"
              className="u-margin-bottom-medium"
            />
            <h2 className="u-margin-bottom-medium">
              German Numbers from 21 to 99
            </h2>
            <p>
              This is where the tricky part lies. To say such numbers, go from
              right to left, making it one giant word. For example, to say 21,
              we write einundzwanzig; i.e 1 and 20. This is the pattern that
              will be followed all through.
            </p>
            <p>
              The trick here is:- Base number on the right + und(and) + tens
              number
            </p>
            <p>Zwei + und + dreißig = zweiunddreißig</p>
            <img
              src={Numbers4}
              style={{ width: '80%' }}
              alt="german-number-21-97"
              className="u-margin-bottom-medium"
            />
            <h2 className="u-margin-bottom-medium">Points To Note... </h2>
            <img
              src={Numbers5}
              alt="german-number-1-17"
              style={{ width: '80%' }}
              className="u-margin-bottom-medium"
            />
            <h2 className="u-margin-bottom-medium">
              Counting German Numbers in 100s and 1000s
            </h2>
            <p className="u-margin-bottom-medium">
              To say German numbers in hundreds and thousand is also very easy.
              You just need to add “hundert” (means hundred) and “tausend”
              (means thousand) after the 1-digit numbers. For example, 100 is
              einhundert.
            </p>
            <img
              src={Numbers6}
              alt="german-number-100-billion"
              style={{ width: '80%' }}
              className="u-margin-bottom-medium"
            />
            <h2 className="u-margin-bottom-medium">
              Now Let's Practice Some Tricky Numbers...
            </h2>
            <p className="u-margin-bottom-medium">
              Now we know how to say any number in German. So let us practice
              some of the tricky ones for better understanding of German
              Numbers.
            </p>
            <img
              src={Numbers7}
              alt="german-tricky-numbers"
              style={{ width: '80%' }}
              className="u-margin-bottom-medium"
            />
            <h2 className="u-margin-bottom-medium">
              What are Ordinal Numbers in German?
            </h2>
            <p>
              An Ordinal number tells the position or rank of something. For
              instance, when you say “I want to score first position in class”.
              We all use ordinal numbers very frequently. Like while saying “Who
              was the forty-fifth President?” or while saying “This is my fifth
              ice-cream of the day”. Also while saying dates, we use ordinal
              numbers; like you say “My birthday is on third January” So let us
              learn how to say ordinal numbers in German. Ordinal numbers also
              follow a pattern.
            </p>
            <p>
              {' '}
              For numbers from 1-19, add prefix -te to them. For example:
              vierte, sechste.
            </p>
            <p>
              There are two exception to it – first is written as ‘erste‘ and
              third is written as ‘dritte‘.
            </p>
            <p>
              {' '}
              For numbers from 20-100, add prefix -ste. For example: zwanzigste,
              einndzwanzigste
            </p>
            <img
              src={Numbers8}
              alt="ordinal-numbers-german"
              style={{ width: '80%' }}
              className="u-margin-bottom-medium"
            />
            <h2 className="u-margin-bottom-medium">
              How To Say Dates In German?
            </h2>
            <p>
              When learning numbers, how can we forget to learn how to speak
              dates in German! If someone asks you what is the date today, you
              should be able to answer it.
            </p>
            <p>
              Whenever talking about dates, we mostly use ordinal numbers in
              German. Let us how it is said.{' '}
            </p>
            <p>1.5 will be said as “Der erste Mai”</p>
            <p> 2.7 will become “der zweite Juli” </p>
            <p>
              {' '}
              To say “today is 14.2”, we say “Heute ist vierzehnte Zweite” or
              you can say “Heute ist vierzehnte Februar”{' '}
            </p>
            <h2 className="u-margin-bottom-medium">
              {' '}
              How Do You Say Your Age In German?{' '}
            </h2>
            <div className="row">
              <div className="col-1-of-2">
                <p>
                  We all have encountered this question at least once in our
                  lives. So we should be able to answer this question when we
                  have learnt numbers in German. Let us learn how to answer the
                  question “how to say your age in German?”.{' '}
                </p>
                <p style={{ fontWeight: 'bold' }}> Wie alt bist du? </p>
                <p>How old are you? </p>
                <p style={{ fontWeight: 'bold' }}>
                  {' '}
                  Ich bin vierundzwanzig Jahre alt.{' '}
                </p>
                <p>I am 24 years old. </p>
              </div>
              <div className="col-1-of-2">
                <img
                  src={Numbers9}
                  alt="german-numbers-1-10"
                  style={{ width: '80%' }}
                  className="u-margin-bottom-medium"
                />
              </div>
            </div>
            <h2 className="u-margin-bottom-medium">
              {' '}
              Let's Start Counting Stars...{' '}
            </h2>
            <p>
              {' '}
              We have learnt how to count numbers in German language. How did
              you find learning them? It won’t be hard for you learn German
              numbers since there are a lot of similarities between English and
              German numbers. Just by reading these numbers a number of times,
              you’ll be able to memorize them. For being more clear about
              pronunciation, you can also refer to Alphabets in German and
              German Umlaut.{' '}
            </p>
            <p>
              {' '}
              Now I want to know how much you learnt today. So write down the
              following numbers in words in the comments section below:{' '}
            </p>
            <ul style={{ listStyle: 'none' }}>
              <li>61 </li>
              <li> 85 </li>
              <li> 397</li>
              <li> 421</li>
              <li> 1118</li>
              <li> 1315</li>
            </ul>
          </div>
          <div className="article__blog-container__bottom">
            <div
              className="article__blog-container__bottom__left"
              to="/learn-german"
            >
              <Link to="/learn-german">
                <img
                  className="article__blog-container__bottom__image"
                  src={German}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/german-alphabet-letters/">
                <li className="article__blog-container__bottom__posts__title__item">
                  German Alphabets & Letters
                </li>
              </Link>

              <Link to="/german-days-of-the-week/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Days of the Week in German
                </li>
              </Link>
              <Link to="/german-months-seasons/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Months & Seasons in German
                </li>
              </Link>
              <Link to="/german-umlaut/">
                <li className="article__blog-container__bottom__posts__title__item">
                  German Umlaut
                </li>
              </Link>
              <Link to="/time-in-german/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Time in German
                </li>
              </Link>
              <Link to="/german-colors/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Colors in German
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GermanNumbers;
