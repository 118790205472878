import React from 'react';
import { Link } from 'react-router-dom';

import '../sass/main.scss';
import GermanMonths from '../assets/german-months-seasons.jpg';
import SpanishNumbers from '../assets/Spanish-Ordinal-number.jpg';
import JapaneseParticles from '../assets/particles-in-japanese.jpg';
import SpanishMonths from '../assets/months-in-spanish.jpg';
import Preterite from '../assets/Preterite-ve-Imperfect.jpg';
import Time from '../assets/time-in-germany.jpg';
import Days from '../assets/days-of-the-week-german.jpg';

const HomeBlog = () => {
  return (
    <div className="homeblog">
      <div className="internal">
        <h1 className="homeblog__header">Some of our curated Content</h1>
        <div className="homeblog__body">
          <Link to="/german-months-seasons/">
            <div className="homeblog__body__container">
              <img
                className="homeblog__body__container__image"
                src={GermanMonths}
                alt="german-months"
              />
              <div className="homeblog__body__container__text">
                <span className="homeblog__body__container__text__cat">
                  # GERMAN
                </span>
                <div className="homeblog__body__container__text__title">
                  How do you say German Months and Seasons?
                </div>
                <div className="homeblog__body__container__text__sub">
                  The ultimate guide to German Months, seasons with
                  Pronunciation & Abbreviation
                </div>
              </div>
            </div>
          </Link>
          <Link to="/spanish-numbers">
            <div className="homeblog__body__container">
              <img
                className="homeblog__body__container__image"
                src={SpanishNumbers}
                alt="german-months"
              />
              <div className="homeblog__body__container__text">
                <span className="homeblog__body__container__text__cat">
                  # SPANISH
                </span>
                <div className="homeblog__body__container__text__title">
                  SPANISH NUMBERS | How to count in Spanish
                </div>
                <div className="homeblog__body__container__text__sub">
                  Learn how to count Spanish Numbers 1-100, 1-1000, 1-10000
                </div>
              </div>
            </div>
          </Link>
          <Link to="/japanese-particles/">
            <div className="homeblog__body__container">
              <img
                className="homeblog__body__container__image"
                src={JapaneseParticles}
                alt="german-months"
              />
              <div className="homeblog__body__container__text">
                <span className="homeblog__body__container__text__cat">
                  # JAPANESE
                </span>
                <div className="homeblog__body__container__text__title">
                  Japanese Particles: The Essential Guide
                </div>
                <div className="homeblog__body__container__text__sub">
                  Japanese Particles: The Essential Guide TO WO, GA, NI, WO, DE,
                  MO
                </div>
              </div>
            </div>
          </Link>
          <div className="homeblog__bottom">
            <div className="homeblog__bottom__blogs">
              <Link to="/german-days-of-the-week/">
                <img className="homeblog__bottom__blogs__image" alt="Bottom Blog" src={Days} />
              </Link>
              <div className="homeblog__bottom__blogs__title">
                Days of the week in German
              </div>
            </div>

            <div className="homeblog__bottom__blogs">
              <Link to="/months-in-spanish/">
                <img
                  className="homeblog__bottom__blogs__image"
                  src={SpanishMonths}
                  alt="Bottom Blog"
                />
              </Link>
              <div className="homeblog__bottom__blogs__title">
                Spanish Months & Seasons
              </div>
            </div>

            <div className="homeblog__bottom__blogs">
              <Link to="/preterite-vs-imperfect/">
                <img
                  className="homeblog__bottom__blogs__image"
                  src={Preterite}
                  alt="Bottom Blog"
                />
              </Link>
              <div className="homeblog__bottom__blogs__title">
                Preterite vs Imperfect
              </div>
            </div>

            <div className="homeblog__bottom__blogs">
              <Link to="/time-in-german/">
                <img
                  className="homeblog__bottom__blogs__image"
                  alt="time"
                  src={Time}
                />
              </Link>
              <div className="homeblog__bottom__blogs__title">
                How to tell time in German
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBlog;
