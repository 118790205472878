import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

import '../sass/main.scss';
import Image from '../assets/possessive-adjectives-spanish.jpg';
import Table1 from '../assets/spanish-adjectives-table-1.JPG';
import Table2 from '../assets/spanish-adjectives-table-2.JPG';
import Table3 from '../assets/spanish-adjectives-table-3.JPG';
import Image1 from '../assets/Possessive-Adjectives-in-Spanish.jpg';
import Spanish from '../assets/spanish_course_bottom.png';

const spanishAdjectives = () => {
  const head = () => (
    <MetaTags>
      <title>
        Easy Guide to Possessive Adjectives In Spanish With Exercises |
        LinguaPsych
      </title>
      <meta
        name="description"
        content="Learn how to say this is mine, yours using Possessive Adjectives in Spanish. The lesson is designed for beginners and advanced learner with exercises and bonus."
      />{' '}
      <link rel="canonical" href="https://linguapsych.com/time-in-german/" />
      <meta
        property="og:title"
        content="Learn how to say this is mine, yours using Possessive Adjectives in Spanish. The lesson is designed for beginners and advanced learner with exercises and bonus."
      />{' '}
      <meta
        property="og:description"
        content="Learn how to say this is mine, yours using Possessive Adjectives in Spanish. The lesson is designed for beginners and advanced learner with exercises and bonus."
      />{' '}
      <meta property="og:type" content="website" />
      <meta property="og:url" href="https://linguapsych.com/time-in-german/" />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/possessive-adjectives-spanish.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/possessive-adjectives-spanish.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}
      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_SPANISH_COURSE}`}>
          <div className="article__spanish-banner">
            <div className="article__spanish-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="Possessive adjectives Spanish"
            className="article__featured-image"
          />
          <div className="article__header-elements">
            <h1 className="article__title ">
              {' '}
              <span
                style={{ fontSize: '5rem' }}
                className="article__highlight--black "
              >
                {' '}
                Possessive Adjectives Spanish
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <h2 className="u-margin-bottom-medium">
              What are Possessive Adjectives?
            </h2>
            <p>
              Possessive Adjectives are used as pronoun to indicate possession
              or belonging. Possessive Adjectives can be used before or after
              the noun, indicating the same meaning but in another form.
            </p>
            <p>
              Possessive Adjectives that are used before the noun are used to
              indicate something or someone referring to their owner. It changes
              according to who the owner is (yo = mi casa, tú = tu casa).
            </p>
            <p>
              Singular Pronouns like mi, tu, su don’t change their ending
              according to the gender of the noun, on the other hand, plural
              pronouns like Nuestro, vuestro will be changed to nuestra, vuestra
              if the nouns they are referring to is feminine (nuestra casa,
              vuestras casas, etc.)
            </p>
            <p>
              Let’s look at the Possessive Adjectives that are used before the
              noun (Short form Possessive Adjectives)
            </p>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table1}
                alt="spanish-adjectives-table-1"
                className="article__big-images"
                style={{ width: '90%' }}
              />
            </div>
            <p>
              The Possessive Adjectives su/sus can refer to multiple persons
              (él, ella, usted, ellos, ellas, ustedes) and hence, it is
              advisable to use them only when the person you’re pointing to is
              clearly indicated. That’s why it is advisable to use it only when
              there is no scope of confusion.
            </p>
            <ul>
              <li> Esos son Javier y su novia, Julieta.</li>
              <li>They are Javier and his girlfriend, Julieta.</li>
              <li> Señor Pablo, ¿es él su hijo?</li>
              <li className="u-margin-bottom-medium">
                Sir Pablo, is he your son?
              </li>
            </ul>
            <h2 className="u-margin-bottom-medium">
              Preposition de for indicating Possession
            </h2>
            <p>
              Since Possessive Adjectives su/sus can be misleading as it
              indicates to multiple persons at once, when it comes to pointing
              out possession or ownership, it is very common to use the
              preposition de, the preposition is used when the ownership is not
              very evident. Use de + Name
            </p>
            <li> Esto es el coche de Javier y eso, lo de Julia.</li>
            <li className="u-margin-bottom-medium">
              This is Javier’s Car and this, this is Julia’s.
            </li>
            <li>Javier es amigo de mi hijo.</li>
            <li className="u-margin-bottom-medium">
              Javier is my son’s friend.
            </li>
            <li>Tengo libros de la Biblioteca.</li>
            <li className="u-margin-bottom-medium">
              I have books from the library.
            </li>
            <li>Madrid es la capital de España.</li>
            <li className="u-margin-bottom-medium">
              Madrid is the capital of Spain.
            </li>
            <h2 className="u-margin-bottom-medium">
              Let's look at long form of Spanish Possessive Adjectives
            </h2>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table2}
                alt="spanish-adjectives-table-2"
                className="article__big-images"
                style={{ width: '90%' }}
              />
            </div>
            <li>These Possessive Adjectives can be used in three contexts:</li>
            <ol>
              <h3>
                To give or ask information about something that belongs to
                someone, it is used just after the conjugation of verb Ser.
              </h3>
              <ul>
                <li>¿es tuya esta maleta?</li>
                <li className="u-margin-bottom-medium">sí, es mía</li>
                <li>Tu eres mío</li>
                <li className="u-margin-bottom-medium">Your are mine</li>
                <li>El coche es suyo</li>
                <li className="u-margin-bottom-medium">The car is hers/his.</li>
                <li>El mundo es nuestro</li>
                <li className="u-margin-bottom-medium">The world is ours</li>
              </ul>
              <p>
                This form of Possessive Adjectives resembles Mine, yours, his,
                hers and ours of English. Here, we are not using the adjectives
                just after the noun but with the verb Ser which also indicates
                permanent form of possession.
              </p>
              <h3>
                After the noun accompanied by Indefinite article and other
                determinants used before the noun.
              </h3>
              <ul>
                <li>He visitado a un amigo mío</li>
                <li className="u-margin-bottom-medium">
                  I visited a friend of mine
                </li>
                <li>Un coche suyo está perdido</li>
                <li className="u-margin-bottom-medium">
                  One Car of his is lost
                </li>
                <li>¿Es este bolígrafo suyo?</li>
                <li className="u-margin-bottom-medium">Is this pen yours?</li>
              </ul>
              <p>
                {' '}
                The form un/una + Noun + long form of Possessive Adjectives can
                be translated to of mine, of yours, of his, of hers, of ours in
                English. Try making some sentences with the above mentioned form
                and you’ll understand how it works.
              </p>
              <h3>
                This form is used with Definite Articles el/la substituting a
                noun which is already mentioned or known by the speaker or the
                listener.
              </h3>
              <ul>
                <li>¿Esto es tu coche? (is that your car?)</li>
                <li className="u-margin-bottom-medium">
                  No, el mío es blanco (No, mine is white)
                </li>
                <li>Mi novia es española ( My girlfriend is Spanish)</li>
                <li className="u-margin-bottom-medium">
                  La mía es francesa (Mine is French)
                </li>
              </ul>
            </ol>
            <p>
              The form el/la + Long form Possessive Adjectives can be linked to
              Mine, yours, his, hers which are used in the beginning of an
              English sentence.
            </p>
            <h3 className="u-margin-bottom-medium">
              Learn Family words using Possessive Adjectives
            </h3>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table3}
                alt="spanish-adjectives-table-3"
                className="article__big-images"
                style={{ width: '90%' }}
              />
            </div>
            <div className="row">
              <h3>Some more family words</h3>
              <div className="col-1-of-2">
                <p>mi amigo Juan</p>
                <p>mi amiga Luisa</p>
                <p>mi abuelo</p>
                <p>mi abuela</p>
              </div>
              <div className="col-1-of-2">
                <p>un amigo (mío)</p>
                <p>una amiga (mía)</p>
                <p>un abuelo (mío)</p>
                <p>una abuela (mía)</p>
              </div>
            </div>
            <h3>Exercise Time - My Family</h3>
            <div className="row">
              <div className="col-1-of-2">
                <p>
                  Look at the family vocabulary above and using the vocab, make
                  5 sentences about your family. Figure out the relationship.
                  For example
                </p>
                <p>Se llama Juan. Es el padre de mi padre. Es tu abuelo</p>
              </div>
              <div className="col-1-of-2">
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={Image1}
                    alt="Possessive-Adjectives-in-Spanish"
                    className="article__big-images"
                    style={{ width: '40%' }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="article__blog-container__bottom">
            <div
              className="article__blog-container__bottom__left"
              to="/learn-spanish"
            >
              <Link to="/learn-spanish">
                <img
                  className="article__blog-container__bottom__image"
                  src={Spanish}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/spanish-definite-indefinte-articles/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Definite & Indefinite Articles
                </li>
              </Link>
              <Link to="/subject-personal-pronouns-in-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Subject Personal Pronouns
                </li>
              </Link>

              <Link to="/spanish-nouns-articles-genders/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Nouns, articles and Genders
                </li>
              </Link>
              <Link to="/spanish-alphabets-accents-pronunciation/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Alphabets in Spanish
                </li>
              </Link>
              <Link to="/months-in-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Months in Spanish
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default spanishAdjectives;
