import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

import '../sass/main.scss';
import Image from '../assets/Korean-sentence-structure.jpg';
import Image1 from '../assets/Korean-sentence-structure-pattern.PNG';
import Image2 from '../assets/Korean-sentence-structure-pattern-1.PNG';
import Language from '../assets/language_course_bottom.png';

const koreanSentence = () => {
  const head = () => (
    <MetaTags>
      <title>
        Complete Guide to Korean Sentence Structure, Korean Particles |
        LinguaPsych
      </title>
      <meta
        name="description"
        content="This is a complete guide to learn Korean Sentence Structure and Korean Particles. Learn how to write Korean sentences and Korean grammar rules and structure."
      />{' '}
      <link
        rel="canonical"
        href="https://linguapsych.com/korean-sentence-structure/"
      />
      <meta
        property="og:title"
        content="This is a complete guide to learn Korean Sentence Structure and Korean Particles. Learn how to write Korean sentences and Korean grammar rules and structure."
      />{' '}
      <meta
        property="og:description"
        content="This is a complete guide to learn Korean Sentence Structure and Korean Particles. Learn how to write Korean sentences and Korean grammar rules and structure."
      />{' '}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        href="https://linguapsych.com/korean-sentence-structure/"
      />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/Korean-sentence-structure.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}../assets/Korean-sentence-structure.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}

      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_DOMAIN_PRODUCTION}`}>
          <div className="article__general-banner">
            <div className="article__general-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="Korean-sentence-structure"
            className="article__featured-image"
          />
          <div className="article__header-elements">
            <h1 className="article__title ">
              {' '}
              <span
                style={{ fontSize: '5rem' }}
                className="article__highlight--black "
              >
                {' '}
                Korean Sentence Structure
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <p>
              One of the most challenging parts about learning the Korean
              language, is its sentence structure. It is also the part where
              some of the Korean language learners get confused easily. For
              those who are not yet aware, the Korean language sentence
              structure is completely different from that of the English
              language. Sentence structure is how words or phrases are put
              together in a language.
            </p>
            <p>
              Hence, in this article we will discuss about the basic Korean
              sentence structures.
            </p>
            <h2 className="u-margin-bottom-medium">
              1. Subject + Object + Verb Sentence Pattern:
            </h2>
            <p>
              We have to clearly differentiate the English sentence structure
              from that of the Korean sentence structure first.
            </p>
            <p>
              This is the most common sentence structures in Korean language.
              Therefore, it is the most basic sentence structure to learn about
              the Korean language. When you remember that the objects comes
              first before verbs, then learning other parts of the Korean
              language will become a bit easier.
            </p>
            <p>Example:</p>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Image1}
                alt="Korean-sentence-structure-pattern"
                className="article__big-images"
              />
            </div>
            <p>Korean: 저는 한국어를 좋아해요</p>
            <p>
              While it is described as “I like Korean” In English, it is written
              as “I Korean like” in Korean.
            </p>
            <p>Example:</p>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Image2}
                alt="Korean-sentence-structure-pattern-1"
                className="article__big-images"
              />
            </div>
            <p>Korean: 저는 한국어를 공부해요</p>
            <p>
              “I Study Korean” In English, it is written as “I Korean study” in
              Korean.
            </p>
            <h2 className="u-margin-bottom-medium">
              2. Subject + Verb Sentence Pattern:
            </h2>
            <p>
              It is the easiest Korean sentence structure. This sentence pattern
              is similar with English. So, it is not difficult to create a
              sentence using this particular sentence pattern.
            </p>
            <p>
              Sometimes people just want to say what action they are doing and
              the context is not necessary at all.
            </p>
            <h3>Example:</h3>
            <p>English: I sing</p>
            <p>Korean: 저는 노래해요</p>
            <h3>Example:</h3>
            <p>English: I eat</p>
            <p>Korean: 저는 먹어요</p>
            <h2 className="u-margin-bottom-medium">
              3. Subject + Adjective Sentence Pattern:
            </h2>
            <p>
              In Korean language, it is a typical sentence pattern. The S+A
              sentence pattern is also similar with English. That is why, when
              you translate the sentences from English to Korean with S+A
              pattern, the structure of the sentences are still the same, unlike
              the S+O+V pattern. When learning the Korean language, you have to
              clearly understand that every Korean sentence must end with either
              a verb or an adjective. It is a very important rule in Korean
              language sentence structure.
            </p>
            <h3>Example:</h3>
            <p>English: She is pretty</p>
            <p>Korean: 그녀는 예뻐요</p>
            <p>English: He is boring</p>
            <p>Korean: 그는 지루해요</p>
            <p>
              So, those are just the top three basic Korean sentence structures.
              I hope it will help your Korean language learning, especially if
              you are still a beginner. Those Korean sentence structures are
              just basic, so are the example sentences.
            </p>
            <h2 className="u-margin-bottom-medium">
              Korean Particles (~는/은, 이/가 and ~를/을):
            </h2>
            <p>
              You must have noticed that in the above examples the particles are
              highlighted as red so that you can know how we use these particles
              in a normal sentence.
            </p>
            <p>
              Most words in a Korean sentence have a particle attached to them.
              These particles indicate the role of each word in a sentence –
              that is, specifically which word is the subject or object. Note
              that there is absolutely no way of translating these particles to
              English, as we do not use anything like them.
            </p>
            <h2 className="u-margin-bottom-medium">
              1. 은/는 – Topic Particle:
            </h2>
            <p>
              This is placed after a word to indicate that it is the subject of
              a sentence.
            </p>
            <p>
              Use 는 when the last letter of the last syllable of the subject is
              a vowel.
            </p>
            <h3>Example:</h3>
            <p>나 = 나는</p>
            <p>저 = 저는</p>
            <p>
              Use 은 when the last letter of the last syllable of the subject is
              a consonant.
            </p>
            <h3>Example:</h3>
            <p>집 = 집은</p>
            <p>책 = 책은</p>
            <h2 className="u-margin-bottom-medium">
              2. 이/가 – Subject Particle:
            </h2>
            <p>This participle is used to introduce a NEW subject</p>
            <p>
              Use 가 when the last letter of the last syllable of the subject is
              a vowel
            </p>
            <h3>Example:</h3>
            <p>내가 마셨다 (I drank)</p>
            <p>
              Use 은 when the last letter of the last syllable of the subject is
              a consonant
            </p>
            <h3>Example:</h3>
            <p>저것이 한강이야 (That is Han River)</p>
            <h2 className="u-margin-bottom-medium">
              3. 를/을 - Object Particle:
            </h2>
            <p>
              This is placed after a word to indicate that is the object of a
              sentence. It is used with all Objects.
            </p>
            <p>Use 를 when the last letter of the last syllable is a vowel.</p>
            <h3>Example:</h3>
            <p>영화를 (Movie)</p>
            <p>
              Use을 when the last letter of the last syllable is a consonant.
            </p>
            <h3>Example:</h3>
            <p>책을 (Book)</p>
            <h3>Lets practice with some sentences:</h3>
            <ul>
              <li>저는 영화를 봤어요 (I watched a movie)</li>
              <li>나는 라면을 먹었어요 (I ate Ramen noodles)</li>
              <li>너의 제킷이 너무 예뻐요 (Your Jacket is pretty)</li>
              <li>내가 피자를 먹었다 (I ate Pizza)</li>
            </ul>
          </div>
          <div className="article__blog-container__bottom">
            <div className="article__blog-container__bottom__left">
              <Link to="/">
                <img
                  className="article__blog-container__bottom__image"
                  src={Language}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/korean-alphabet-letters/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Korean Alphabets and Letters
                </li>
              </Link>
              <Link to="/korean-numbers/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Numbers in Korean
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default koreanSentence;
