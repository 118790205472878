import React from 'react';

import Section1 from '../components/Section1';
import Sale from '../components/Sale';
import SectionAbout from '../components/SectionAbout';
import FeaturesSection from '../components/FeaturesSection';
import SectionStories from '../components/SectionStories';
import FAQ from '../components/FAQ';
import ContactForm from './ContactForm';
import HomeBlog from '../components/Home_blog';

// import '../public/static/styles.css';
import '../sass/main.scss';
import ChooseLanguage from '../components/ChooseLanguage';

const Homepage = () => {
  return (
    <div style={{ maxWidth: '1640px', margin: '0 auto' }}>
      <Section1 />
      {/* <Sale
        timeTillDate="01 30 2021, 6:00 am"
        timeFormat="MM DD YYYY, h:mm a"
      /> */}
      <ChooseLanguage />
      <HomeBlog />
      <SectionAbout />
      <FeaturesSection />
      <SectionStories />
      <FAQ />
      <ContactForm />
    </div>
  );
};

export default Homepage;
