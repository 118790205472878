import React from 'react';

import SpanishMainHeader from '../components/SpanishMainHeader';
import SpanishWeekVideo from '../components/SpanishWeekVideo';
import SpanishWeekPlan from '../components/SpanishWeekPlan';
import SpanishHomePrice from '../components/SpanishHomePrice';
import FeaturesSection from '../components/FeaturesSection';
import FAQ from '../components/FAQ';
import ContactForm from './ContactForm';
import Enroll from '../components/Enroll';

const Homepage = () => (
  <div>
    <SpanishMainHeader />
    <SpanishWeekVideo />
    <SpanishWeekPlan />
    <FeaturesSection />
    <SpanishHomePrice />
    <FAQ />
    <ContactForm />
    <Enroll />
  </div>
);

export default Homepage;
