import React from 'react';

import GermanMainHeader from '../components/GermanMainHeader';
import GermanWeekVideo from '../components/GermanWeekVideo';
import GermanWeekPlan from '../components/GermanWeekPlan';
import HomePrice from '../components/HomePrice';
import FeaturesSection from '../components/FeaturesSection';
import FAQ from '../components/FAQ';
import ContactForm from '../pages/ContactForm';
import Enroll from '../components/Enroll';

const GermanCourse = () => (
  <div>
    <GermanMainHeader />
    <GermanWeekVideo />
    <GermanWeekPlan />
    <FeaturesSection />
    <HomePrice />
    <FAQ />
    <ContactForm />
    <Enroll />
  </div>
);

export default GermanCourse;
