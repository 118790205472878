import React, { useState } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import {useHistory} from "react-router-dom";
import Backdrop from "./Backdrop";
import Loader from "./Loader";

const ModalOverlay = (props) => {

  let history = useHistory();

  const [loading, setLoading] = useState(false)


 const pushPage = () => {
  
  history.push('/webinar-thankyou')

 }



  const [values, setValues] = useState({
    course: props.event,
    error: "",
    courseValue: 199,
    userEmail: "",
    userName: "",
   

  });

  const { email, username, course, error, courseValue, userEmail, userName } = values;

 const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const paymentHandler = () => {
   
    const options = {
      key: process.env.REACT_APP_RAZOR_PAY_KEY,
      amount: courseValue * 100,
      name: 'Linguapsych',
      description: 'Spanish Kickstarter Workshop',
      handler(response) {
        
        const paymentId = response.razorpay_payment_id;
        const amount = courseValue;
        const url = 'https://api.linguafy.in/course/whatsapp-payment';
        //'http://localhost:3000/dev/add-payment';

        // ('https://api.linguafy.in/course/add-payment');
        // process.env.REACT_APP_API + '/payments/' + paymentId + '/' + amount;
        const info = {
          paymentId,
          amount,
          userEmail,
          userName,
          course
        };

        fetch(url, {
          method: 'post',
          headers: {
            'Content-type':
              'application/x-www-form-urlencoded; charset=UTF-8',
          },
          body: JSON.stringify(info),
        }, setLoading(true))
          .then((resp) => resp.json())
          .then(function (data) {
           // setLoading(true)
           console.log("INTO FUNCTION DATA")
           console.log(data)
            if (data.error) {
              setLoading(false)
              setValues({ ...values, message: data.error });
            } else {
              console.log("INTO FUNCTION PUSH")
              setLoading(true)
              pushPage();
              props.onCancel()
              
            }
          })
          .catch(function (error) {
            console.log('Request failed', error);
          });
      },

      prefill: {
        name: 'Linguapsych',
        email: '',
      },
      notes: {
        address: 'New Delhi,India',
      },
      theme: {
        color: '#9D50BB',
      },
    };
    const rzp1 = new window.Razorpay(options);

    rzp1.open();
    

   
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (userEmail.match(mailformat) && userName  ) { 

      paymentHandler()
     

    } else {
      setValues({...values, error: "Please provide a valid email"});
    }


  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, error: false, [name]: e.target.value });
  };

 
     const content =    <div className="get-started">
        <div className="get-started__content">
        {loading ? <div className="loader" > <Loader /> </div> : <div> <div className="get-started__content__top">
        <div className="get-started__content__top__title">
          <div>Spanish Kickstarter Workshop</div>
          <div onClick={props.onCancel}>
            <i className="fas fa-times"></i>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
        <input
          className="get-started__content__top__input"
          placeholder="name"
          onChange={handleChange('userName')}
          value={username}
        />
      
        <input
          className="get-started__content__top__input"
          placeholder="email"
          onChange={handleChange('userEmail')}
          value={email}
          email="true"
        />
        {error && (
          <div style={{ color: 'red', fontSize: '16px' }}>{error}</div>
        )}
        <button style={{visibility: "none"}} />
        </form>
       
      </div>
      <div className="get-started__content__bottom">
        <div
          onClick={handleSubmit}
          className="get-started__content__bottom__button"
        >
          Count me in!
        </div>
        <div className="get-started__content__bottom__login">
          You'll get a confirmation email after successful enrollment!
        </div>
      </div>
    </div> }
    </div>
    </div>
    
    
   
   

  return ReactDOM.createPortal(content, document.getElementById("modal"));
};

const Modal = ( props) => {
  return (
  
    <React.Fragment>
      {props.show && <Backdrop onClick={props.onCancel} />}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={500}
        classNames="modal"
      >
        <ModalOverlay {...props} />
      </CSSTransition>
    </React.Fragment>
  );
 
};

export default Modal;
