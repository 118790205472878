import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

import '../sass/main.scss';
import Image from '../assets/Preterite-ve-Imperfect.jpg';
import Table1 from '../assets/spanish-past-tense-table-1.JPG';
import Table2 from '../assets/spanish-past-tense-table-2.JPG';
import Table3 from '../assets/spanish-past-tense-table-3.JPG';
import Table4 from '../assets/spanish-past-tense-table-4.JPG';
import Table5 from '../assets/spanish-past-tense-table-5.JPG';
import Table6 from '../assets/spanish-past-tense-table-6.JPG';
import Table7 from '../assets/spanish-past-tense-table-7.JPG';
import Spanish from '../assets/spanish_course_bottom.png';

const spanishPast = () => {
  const head = () => (
    <MetaTags>
      <title>
        Spanish Preterite vs Imperfect The complete Past Tense Guide |
        LinguaPsych
      </title>
      <meta
        name="description"
        content="Ever got confused on when to use Preterite vs Imperfect? Here&#039;s a complete Spanish Past Tense guide with multiple examples and scenarios."
      />{' '}
      <link
        rel="canonical"
        href="https://linguapsych.com/preterite-vs-imperfect/"
      />
      <meta
        property="og:title"
        content="Ever got confused on when to use Preterite vs Imperfect? Here&#039;s a complete Spanish Past Tense guide with multiple examples and scenarios."
      />{' '}
      <meta
        property="og:description"
        content="Ever got confused on when to use Preterite vs Imperfect? Here&#039;s a complete Spanish Past Tense guide with multiple examples and scenarios."
      />{' '}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        href="https://linguapsych.com/preterite-vs-imperfect/"
      />
      <meta property="og:site_name" content="https://linguapsych.com/" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}/static/images/Preterite-ve-Imperfect.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.REACT_APP_DOMAIN_PRODUCTION}/static/images/Preterite-ve-Imperfect.jpg`}
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta
        property="fb:app_id"
        content={`${process.env.REACT_APP_FB_APP_ID}`}
      />
    </MetaTags>
  );

  return (
    <React.Fragment>
      {head()}

      <div className="article">
        <a target="_blank" href={`${process.env.REACT_APP_SPANISH_COURSE}`}>
          <div className="article__spanish-banner">
            <div className="article__spanish-banner__image"> </div>
          </div>
        </a>
        <header className="article__header">
          <img
            src={Image}
            alt="When to use Preterite ve Imperfect"
            className="article__featured-image"
          />
          <div className="article__header-elements">
            <h1 className="article__title ">
              {' '}
              <span
                style={{ fontSize: '5rem' }}
                className="article__highlight--black "
              >
                {' '}
                When to Use Preterite vs Imperfect?
              </span>{' '}
            </h1>
          </div>
        </header>
        <div className="article__blog-container">
          <div className="article__body">
            <p>
              Learning Spanish is easy we all have heard, but all the languages
              have their own challenges, don’t they? Well, we are at one of the
              biggest challenges with learning Spanish right now, which is the
              Past tense in Spanish. Spanish has 3 main ways to express past, el
              preterite, imperfect & Past Perfect.
            </p>
            <p>
              Using which one at which situation is the biggest confusion but,
              what are we here for then? Let’s start off with Preterite Tense
            </p>
            <div style={{ backgroundColor: 'grey', color: 'black' }}>
              <h2 className="u-margin-bottom-medium">
                What is Preterite Tense (pretérito indefinido)?
              </h2>
            </div>
            <p>
              The Preterite Tense is used to talk about the actions that have
              been started and finished in past. We use Preterite tense to talk
              about the moments or situation which are not related to the
              present. As we all know Spanish has verbs which end with -ar, -er,
              -ir. Verbs are either Regular or Irregular, let’s look at how to
              conjugate the regular verbs first:
            </p>
            <h3>Regular Verbs</h3>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table1}
                alt="spanish-past-tense-table-1"
                className="article__big-images"
                style={{ width: '60%' }}
              />
            </div>
            <p>
              Conjugation of Regular Verbs stay the same like the ones in the
              table above, just omit the endings -ar, -er, ir and add the
              endings highlighted with each verb, pay attention on where to put
              the accent mark as it can change the sense of the sentence,
              especially with first person conjugation in present and third
              person conjugation in the past, for example
            </p>
            <ul>
              <li>hablo con mi madre ( I talk to my mother)</li>
              <li className="u-margin-bottom-medium">
                habló con mi madre ( he/she/you (formal) talked to my mother)
              </li>
              <p>
                <h4>Note:</h4>
                These forms are same as in the present tense
              </p>
              <div style={{ textAlign: 'center' }}>
                <img
                  src={Table2}
                  alt="spanish-past-tense-table-2"
                  className="article__big-images"
                  style={{ width: '60%' }}
                />
              </div>
              <li>Ayer estuve en mi casa</li>
              <p>
                All the verbs which have irregular root in Preterite tense have
                same endings.
              </p>
            </ul>
            <h3 className="u-margin-bottom-medium">
              Irregularities in Preterite (Pretérito Indefinido)
            </h3>
            <p>Vocal closure: e to i, o to u</p>
            <p>
              There are many irregular verbs in Spanish where e changes to i in
              the person conjugation for both singular and plural form, it is
              used with verbs where the last vocal of the root is e, like verb
              pedir. In verb pedir, e changes to i in the third person singular
              and plural. Same thing happens when the last vocal of the root is
              o, like verb dormir. In this case o changes to u in both singular
              and plural form conjugations.
            </p>
            <ul>
              <li>él/ella/usted pidió ayuda (He/She asked for help)</li>
              <li>ellos/ellas/ustedes pidieron ayuda (They asked for help)</li>
              <li>él/ella/usted durmió pronto (He/She slept early)</li>
              <li>ellos/ellas/ustedes durmieron pronto (They slept early)</li>
            </ul>
            <h4>Breaking the Triphtong</h4>
            <p>
              Triphthong is a union of 3 vowels pronounced in one syllable (as
              in fire)
            </p>
            <p>
              When the root of the verb of the second or third conjugation ends
              in a vowel, the third person i converts into y
            </p>
            <ul>
              <li>caer – cayó/cayeron</li>
              <li>huir – huyó/huyeron</li>
            </ul>
            <h4>Change in Spellings</h4>
            <p>
              Spanish verbs that end with -car, -gar, -zar, change their
              spellings when conjugated to first person singular.
            </p>
            <ul className="u-margin-bottom-medium">
              <li>acercar – acerqué</li>
              <li>llegar – llegué</li>
              <li>almorzar – almorcé</li>
            </ul>
            <h3 className="u-margin-bottom-medium">
              Verbs with Irregular Endings
            </h3>
            <p>
              All the following verbs have unique irregularities in roots and
              have their own unique endings.
            </p>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table3}
                alt="spanish-past-tense-table-3"
                className="article__big-images"
                style={{ width: '40%' }}
              />
            </div>
            <p>
              In the First person and the third person of the Regular verbs in
              the single form yo, el, ella, usted, the last syllable is tonic.
              On the other hand, in the irregular verbs, the second last
              syllable takes the tonic sound.
            </p>
            <p>
              Note- The verbs where * is mentioned, there are some exceptions,
              let’s look here
            </p>
            <ul>
              <li>*él/ella/usted hizo</li>
              <li>**ellos/ellas/ustedes dijeron dijieron</li>
            </ul>
            <p>
              {' '}
              In the third person plural, i disappears (condujeron, trajeron,
              dijeron). All the verbs ending with -ucir will be conjugated like
              that.
            </p>
            <h3 className="u-margin-bottom-medium">
              Verbs Ir and Ser have same conjugations in the Preterite Tense
            </h3>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table4}
                alt="spanish-past-tense-table-4"
                className="article__big-images"
                style={{ width: '40%' }}
              />
            </div>
            <ul className="u-margin-bottom-medium">
              <li>Fui al parque ayer</li>
              <li>(I went to the park yesterday)</li>
              <li>El partido fue muy interesante</li>
              <li>(The match was very interesting)</li>
            </ul>
            <div style={{ backgroundColor: 'grey', color: 'black' }}>
              <h2 className="u-margin-bottom-medium">When to use Preterite?</h2>
            </div>
            <h3>Using Temporary markers to talk about the past</h3>
            <p>
              Preterite tense is used to tell about the actions that have
              occurred in past and has no relation with the present, also can be
              presented as conclusion. Generally these temporary markers are
              used to talk about the past
            </p>
            <ul>
              <li>la semana pasada, el mes pasado ( Last week, last month)</li>
              <li>el martes/año/mes/siglo pasado ( The year/week, month…)</li>
              <li>fechas (en 1990, en 2013, el 13 de octubre, en mayo etc.)</li>
              <li>
                ayer, anoche, anteayer (yesterday, last night, day before
                yesterday)
              </li>
              <li>el otro día (The other day)</li>
              <li>en mayo/verano/1998 (In may, summers, 1998 etc..)</li>
            </ul>
            <p>
              {' '}
              El lunes hablé con mi padre (I talked to my father on Monday)
            </p>
            <p> anteayer comí Pasta ( I ate Pasta day before yesterday)</p>
            <h3 className="u-margin-bottom-medium">Empezar a + Infinitive</h3>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table5}
                alt="spanish-past-tense-table-5"
                className="article__big-images"
                style={{ width: '60%' }}
              />
            </div>
            <p>
              Whenever you want to say you started to something, always the
              preterite form of the verb empezar will be used .
            </p>
            <ul>
              <li>Conjugation of empezar + a + infinitive form of verb</li>
              <li>
                empecé a trabajar con mi padre el último año (Last year I
                started to work with my father)
              </li>
              <li className="u-margin-bottom-medium">
                This can be translated to started to do something in English.
              </li>
              <h3 className="u-margin-bottom-medium">
                To relate to Past Events
              </h3>
              <li>Se casaron en 2013 y dos años despues se divorciaron.</li>
              <li>
                (They got married in 2013 and after three years they got a
                divorce).
              </li>
              <li>
                terminé el curso de español en junio y al mes siguiente encontró
                trabajo
              </li>
              <li className="u-margin-bottom-medium">
                (I completed my Spanish course in June and the following month I
                got the job).
              </li>
              <h3 className="u-margin-bottom-medium">
                Talking about the duration
              </h3>
              <li>El vivió en Madrid desde enero</li>
              <li>(He was living in Madrid since January).</li>
              <li>Estuve en el Pub hasta las cinco de la mañana.</li>
              <li>(I was in the pub till 5 in the morning)</li>
              <li>
                trabajé como persona libre durante trabaje como persona libre
                durante tres años.
              </li>
              <li className="u-margin-bottom-medium">
                (I worked as a freelancer for 3 years)
              </li>
            </ul>
            <h3 className="u-margin-bottom-medium">
              What is Imperfect Tense (Pretérito Imperfecto)?
            </h3>
            <p>
              The imperfect tense is used to describe the habits, customs and
              describing circumstances or situation in the past. It is same as
              telling the habits in the present but referred in past.
            </p>
            <h3>Regular Verbs</h3>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table6}
                alt="spanish-past-tense-table-6"
                className="article__big-images"
                style={{ width: '90%' }}
              />
            </div>
            <p>
              Learning Imperfect tense is not as difficult as learning the
              Preterite because it has just three irregular verbs whereas
              Preterite has a lot of them. Just take the root of the verb and
              keep on adding the endings which are marked bold in the above
              table. As I mentioned, there are just three irregular verbs in the
              Imperfect tense ser, ir & ver. Let’s see how to conjugate them
            </p>
            <h3>Irregular Verbs Imperfect</h3>
            <div style={{ textAlign: 'center' }}>
              <img
                src={Table7}
                alt="spanish-past-tense-table-7"
                className="article__big-images"
                style={{ width: '60%' }}
              />
            </div>
            <div style={{ backgroundColor: 'grey', color: 'black' }}>
              <h2 className="u-margin-bottom-medium">
                When to use the Imperfect?
              </h2>
            </div>
            <h3>Using Temporary Markers</h3>
            <ul className="u-margin-bottom-medium">
              <li>de niño/a (As a kid)</li>
              <li>de joven (As a young man)</li>
              <li>a los 12 años (At 12 years old)</li>
              <li>cuando era niño ( When I was a kid)</li>
              <li className="u-margin-bottom-medium">
                Cuando tenía 20 años ( When I was 20 years old)
              </li>
              <p>
                We’ll use these time markers with Imperfect tense because they
                tell us about a stage of life where we were performing something
                as a habit or hobby and continued to do that for a long time.
              </p>
              <li>Cuano era niño, era travieso</li>
              <li>When I was a kid, I was very naughty</li>
              <li>Cuando tenía 17 años estaba en la escuela</li>
              <li>When I was 17, I was in school</li>
              <li>a los 12 años, era un niño normal</li>
              <li className="u-margin-bottom-medium">
                At 12 years I was a normal kid
              </li>
              <p>
                When we refer to a timeframe which we have already discussed or
                informed before, we’ll use the following expressions
              </p>
              <li>en aquellos tiempos (in those times)</li>
              <li>en esa/aquella época (at that time)</li>
              <li className="u-margin-bottom-medium">entonces (then)</li>
              <p>
                yo, de niño, leía mucho. Como en aquella época no teníamos
                televisión en casa.
              </p>
              <p>
                I read a lot as a child, as in that time, there were no
                televisions at home.
              </p>
              <h3>To describe the circumstances that surround a past event</h3>
              <li>como estábamos cansados, nos quedamos en casa</li>
              <li>As we were tired, we stayed at home</li>
              <li>Ayer no tenía ganas de estar en casa y me fui al cine</li>
              <li className="u-margin-bottom-medium">
                Yesterday, I didn’t feel like being at home & I went to the
                movies
              </li>
              <h3>To give description of something in the past</h3>
              <li>Ayer vi a Marta. Estaba guapísima</li>
              <li>I saw Marta yesterday, she was beautiful.</li>
              <li>Mi escuela era grande y tenía muchas habitaciones</li>
              <li className="u-margin-bottom-medium">
                My school was enormous and had a lot of rooms
              </li>
              <h3>To talk about Customs & Habits</h3>
              <li>De niño, siempre iba a visitar mis abuelos al verano</li>
              <li>
                As a child, I would always visit my grandparents in the summer
              </li>
              <li>En mi época de estudiante, dormía muy poco</li>
              <li className="u-margin-bottom-medium">
                In my time as a student, I slept very little
              </li>
              <h3>When to use Preterite and Imperfect?</h3>
              <li>
                A story is a succession of facts tell us whether to use
                Preterite or Imperfect. We keep on making the progess with each
                and every new fact that comes later on.
              </li>
              <li>
                Aquel día Juan no oyó el despertador y se despertó media hora
                tarde. Salió de casa sin desayunar y tomó un taxi. Por suerte,
                consiguió llegar a tiempo al aeropuerto.
              </li>
              <li>
                (That day Juan didn’t hear the alarm and woke up half an hour
                late. He left home without breakfast and took a taxi. Luckily,
                he got to the airport on time).
              </li>
              <p>
                In each even that occurred, we can stop the action and look at
                the circumstances surrounding it. To do this, we can use
                imperfect
              </p>
              <li>
                Aquel día Juan estaba muy cansado y no oyó el despertador, así
                que se despertó media hora tarde. Como no tenía tiempo, salió de
                casa sin desayunar y tomó un taxi. Por suerte, no había mucho
                tráfico y consiguió llegar a tiempo al aeropuerto.
              </li>
              <li>
                (That day Juan was very tired and didn’t hear the alarm, so he
                woke up half an hour late. Since he didn’t have time, he left
                home without breakfast and took a taxi. Luckily, there wasn’t
                much traffic and he got to the airport on time).
              </li>
              <p>
                The decision of using Preterite and Imperfwcr doesn’t depend on
                the duration of the actions occurred but the way in which we
                want to present a story and the role of it in a story.
              </p>
              <li>Ayer, como estaba lloviendo, no salí</li>
              <li>
                Here, the focus is on the fact that he did not go out and lesser
                on the rain.
              </li>
              <li>Ayer, estaba lloviendo todo el día y no salí</li>
              <li className="u-margin-bottom-medium">
                Here, the fact that it was raining was used as an excuse to not
                go out.
              </li>
              <h3>Conclusion</h3>
              <p>
                When we talk about an event that occurred in the past, we can
                use both Preterite and Imperfect. We will use Preterite when we
                talk about the event that progresses the story. On the other
                hand, we use Imperfect when the story stops and we look around
                the events.
              </p>
              <li>
                Visitó Madrid por primera vez en 2003. Era verano y hacía mucho
                calor
              </li>
              <li>
                Aprendí a cocinar en casa, Mi madre era una cocinera excelente
              </li>
            </ul>
          </div>
          <div className="article__blog-container__bottom">
            <div
              className="article__blog-container__bottom__left"
              to="/learn-spanish"
            >
              <Link to="/learn-spanish">
                <img
                  className="article__blog-container__bottom__image"
                  src={Spanish}
                />
              </Link>
            </div>
            <div className="article__blog-container__bottom__posts">
              <ul className="article__blog-container__bottom__posts__title">
                Popular Posts
              </ul>
              <Link to="/spanish-definite-indefinte-articles/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Definite & Indefinite Articles
                </li>
              </Link>
              <Link to="/subject-personal-pronouns-in-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Subject Personal Pronouns
                </li>
              </Link>

              <Link to="/spanish-nouns-articles-genders/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Nouns, articles and Genders
                </li>
              </Link>
              <Link to="/spanish-alphabets-accents-pronunciation/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Alphabets in Spanish
                </li>
              </Link>
              <Link to="/months-in-spanish/">
                <li className="article__blog-container__bottom__posts__title__item">
                  Months in Spanish
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default spanishPast;
