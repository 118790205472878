import React, {useState} from 'react'

import Sale from '../components/Sale'
import WhatsappModal from '../components/WhatsappModal'
import Spoken from '../assets/spoken.png'
import Travel from '../assets/travel.png'
import Paying from '../assets/paying.png'
import Easy from '../assets/easy.png'

const SpanishWhatsapp = () => {

    const [card1, setCard1] = useState(false);
    const [card2, setCard2] = useState(false);
    const [card3, setCard3] = useState(false);
    const [card4, setCard4] = useState(false);
    const [card5, setCard5] = useState(false);
    const [card6, setCard6] = useState(false);
    const [showModal, setShowModal] = useState(false);
    

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    return (
        <>
       {/* {loading ? <div> <Loader /> </div> 
       : */}
       <div>
       <WhatsappModal
        show={showModal}
        onCancel={() => closeModal()}
        contentClassName="place-item__modal-content"
        footerClassName="place-item__modal-actions"
        event="Spanish Webinar 1"
       
      />
       <div className="class-container">
       <div className="class-container__top">
           <h1>
           The 5 Day Spanish Kickstarter Workshop
           </h1>
           <h2>
           Learn the #1 Effective Way to Learn Spanish without Wasting any more Time in just Aimlessly Learning Grammar & Vocabulary.

           </h2>

           <h3>
           Date: 05 April, 2021 - 09 April, 2021
           </h3>
            <div 
            onClick={() => {
              openModal()
              window.scrollTo(0, 0) }} 
             className="class-container__top__box">
                <div className="class-container__top__box__top">
                REGISTER NOW AT JUST INR 199/-
                </div>
                <div className="class-container__top__box__bottom">
                Original Value INR 1999 - Limited Time Offer!
                </div>
            </div>
            <div className="class-container__top__batch">
            Batch starts in
        </div>
            <Sale
            timeTillDate="04 05 2021, 12:00 am"
            timeFormat="MM DD YYYY, h:mm a"
          />
           </div>
           <div className="class-container__section">
           <div className="class-container__section__heading">
           Here's how you are going to master Spanish Proficiency System in the 5-Day WhatsApp Class
               </div>
               <div className="class-container__section__days">
               <div className="class-container__section__days__1">
                <span style={{color: "#F6BD00", marginRight: "5px"}} >  
                    Day 1 - 
                </span>
                Learn the what, when & how of learning Spanish & get all your doubts about learning Spanish answered.
                </div>
                <div className="class-container__section__days__2">
                <span style={{color: "#F6BD00", marginRight: "5px"}} >  
                    Day 2 - 
                </span>
                Learn how do Spanish Alphabets work & how they differ from English. [Practice Spanish Alphabets using vocabulary]
                </div>
                <div className="class-container__section__days__3">
                <span style={{color: "#F6BD00", marginRight: "5px"}} >  
                    Day 3 - 
                </span>
                You’ll learn to frame sentences in Spanish & understand the correct grammatical structures.
                </div>
                <div className="class-container__section__days__4">
                <span style={{color: "#F6BD00", marginRight: "5px"}} >  
                    Day 4 - 
                </span>
                Elaborating the sentences to make them interesting with some fillers like ‘very’, ‘little’, etc.

                </div>
                <div className="class-container__section__days__5">
                <span style={{color: "#F6BD00", marginRight: "5px"}} >  
                    Day 5 - 
                </span>
                Learn to introduce yourself in Spanish & start using Spanish in day-to-day chores. Voila! You will be talking in Spanish FINALLY!

                </div>
               </div>
               <div 
               onClick={() => {
              openModal()
              window.scrollTo(0, 0) }} 
                className="class-container__top__box">
                <div className="class-container__top__box__top">
                REGISTER NOW AT JUST INR 199/-
                </div>
                <div className="class-container__top__box__bottom">
                Original Value INR 1999 - Limited Time Offer!
                </div>
            </div>
           </div>
           <div className="workshop" >
           <div className="workshop__title" >
           How will this workshop help?
          </div>
          <div className="workshop__desc" >
          Everyday you’ll find yourself getting closer to the language. This 5-day workshop covers all the basics you’ll need to learn Spanish. Our goal is to get you closer to having real-life conversations in Spanish.
          </div>
          <ul className="workshop__list" >
        <li>
       <span><i className="fas fa-check"></i></span>	This 5-day workshop is structured to get you started with Spanish with the least investment you can ever do. It is a platform to test if Spanish is for you.
        </li>
        <li>
        <span><i className="fas fa-check"></i></span>	Clear even tiniest doubts about learning Spanish from the expert and get all your doubts about the language cleared.
        </li>
        <li>
        <span><i className="fas fa-check"></i></span> 	5-days of dedicated time & effort towards achieving a goal with discipline. Learn | Practice | Get feedback.
        </li>
        <li>
        <span><i className="fas fa-check"></i></span>  	We’ll ensure top-quality Spanish learning journey that’s capable, engaging & yes, even fun.
        </li>
          </ul>
           </div>
           <div className="know">
           <div className="know__title">
             Do you know?
             </div>
             <div className="know__cards">
             <div className="know__cards__card">
             <div className="know__cards__card__top">
             <div className="know__cards__card__top__title">  Spanish speakers </div>
             <img className="know__cards__card__top__image" alt="Most Spoken Language" src={Spoken} />
             </div>
             <div className="know__cards__card__bottom">
            	Second most-spoken language of the world. There are about 17% of the people in the entire world who speak Spanish. 17% is huge!
             </div>
             </div>
             <div className="know__cards__card">
             <div className="know__cards__card__top">
             <div className="know__cards__card__top__title">  For travellers </div>
             <img className="know__cards__card__top__image" alt="Travel" src={Travel} />
             </div>
             <div className="know__cards__card__bottom">
             	Spanish is one of the best languages to learn for travel. Knowing Spanish eases up your travelling, with about 21 countries who have Spanish as their official/national language.
             </div>
             </div>
             <div className="know__cards__card">
             <div className="know__cards__card__top">
             <div className="know__cards__card__top__title">  High paying skill </div>
             <img className="know__cards__card__top__image" alt="Money" src={Paying} />
             </div>
             <div className="know__cards__card__bottom">
             	It is a high paying skill. Learning Spanish makes you more employable with employment opportunities from all over the world. Those who know Spanish earn approximately 30-40% more than the normal English speakers in the world.
             </div>
             </div>
             <div className="know__cards__card">
             <div className="know__cards__card__top">
             <div className="know__cards__card__top__title">  Easiest language </div>
             <img className="know__cards__card__top__image" alt="Easy language" src={Easy} />
             </div>
             <div className="know__cards__card__bottom">
             	Spanish is the easiest language out of the most useful languages to learn if you already speak English. You can quickly learn Spanish by taking advantage of online solutions.
             </div>
             </div>
             </div>
           </div>
           <div className="faq">
      <div className="faq__top">STILL HAVE QUESTIONS? HERE ARE ANSWERS</div>
      <div className="faq__second">Frequently Asked Questions</div>
      <div className="faq__third">
        {' '}
        If your questions aren’t covered below, you can chat with us.
      </div>
      <div className="faq__cards">
        <div onClick={() => setCard1(!card1)} className="faq__cards__card">
          <div className="faq__cards__card__top">
            <div className="faq__cards__card__title">
            1.	Is this workshop for me?
            </div>
            <div className="faq__cards__card__plus">&#43; </div>
          </div>
          {card1 && (
            <div className="faq__cards__card__desc">
            Yes, this workshop is for you if you want to kickstart learning Spanish in just 5 days. Whether you already know some Spanish or have been planning to learn, this 5-day class will tell you everything you need to know about learning Spanish. From career prospects to market demand, we’ve got it all covered for you. These 5 days will be deep learning sessions where we’ll make you speak Spanish & you’ll find yourself framing sentences.
            </div>
          )}
        </div>
        <div onClick={() => setCard2(!card2)} className="faq__cards__card">
          <div className="faq__cards__card__top">
            <div className="faq__cards__card__title">
            2.	What are the pre-requisites?
            </div>
            <div className="faq__cards__card__plus">&#43; </div>
          </div>
          {card2 && (
            <div className="faq__cards__card__desc">
            Self-discipline is the only pre-requisite. You do not have to have any prior knowledge of Spanish. We’ll start everything from scratch and bring you to a level where you’ll be able to frame basic Spanish sentences. So no experience in Spanish is required.
            </div>
          )}
        </div>
        <div onClick={() => setCard3(!card3)} className="faq__cards__card">
          <div className="faq__cards__card__top">
            <div className="faq__cards__card__title">
            3.	Will this be live or pre-recorded?
            </div>
            <div className="faq__cards__card__plus">&#43; </div>
          </div>
          {card3 && (
            <div className="faq__cards__card__desc">
            This is a completely live session. The live session link will be sent to your registered Email ID. Also we’ll share a group invite of WhatsApp to stay connected for 5 days. We’ll use this platform to perform certain activities & daily tasks to ensure you that you utilize what you’ve learnt to full capacity & get constant feedback.
            </div>
          )}
        </div>
        <div onClick={() => setCard4(!card4)} className="faq__cards__card">
          <div className="faq__cards__card__top">
            <div className="faq__cards__card__title">
            5.	I made the payment but didn’t receive any email.
            </div>
            <div className="faq__cards__card__plus">&#43; </div>
          </div>
          {card4 && (
            <div className="faq__cards__card__desc">
            Please write to: info@linguapsych.com, our awesome support team will get back to you in 24 hours.
            </div>
          )}
        </div>
        <div onClick={() => setCard5(!card5)} className="faq__cards__card">
          <div className="faq__cards__card__top">
            <div className="faq__cards__card__title">
            6.	How do I access the workshop?
            </div>
            <div className="faq__cards__card__plus">&#43; </div>
          </div>
          {card5 && (
            <div className="faq__cards__card__desc">
            You can register yourself for the workshop with your name & email ID. And after the payment, you’ll receive a confirmation email.
            </div>
          )}
        </div>
        <div onClick={() => setCard6(!card6)} className="faq__cards__card">
          <div className="faq__cards__card__top">
            <div className="faq__cards__card__title">
            7.	How much does the workshop cost?
            </div>
            <div className="faq__cards__card__plus">&#43; </div>
          </div>
          {card6 && (
            <div className="faq__cards__card__desc">
            The usual price of the workshop is ₹1999. But currently you can enroll for an offer price of ₹199 only. The price covers live language training by our Spanish expert, along with study material for you to practice & get personalized feedback on daily tasks.
            </div>
          )}
        </div>
        <div style={{marginTop: "100px"}} onClick={() => {
              openModal()
              window.scrollTo(0, 0) }} 
              className="class-container__top__box">
                <div className="class-container__top__box__top">
                REGISTER NOW AT JUST INR 199/-
                </div>
                <div className="class-container__top__box__bottom">
                Original Value INR 1999 - Limited Time Offer!
                </div>
            </div>
      </div>
    </div>
       </div>
       </div>
       
        </>
    )
}

export default SpanishWhatsapp