import fetch from 'isomorphic-fetch';
//import { useHistory } from 'react-router-dom';

// export const handleResponse = (response) => {
//   if (response.status === 401) {
//     signout(() => {
//       let history = useHistory();
//       history.push({
//         pathname: '/',
//         query: {
//           message: 'Your session is expired. Please signin',
//         },
//       });
//     });
//   }
// };

export const joinWaitlist = (data) => {
  return fetch(`https://api.linguafy.in/users/join-waitlist`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const subscribe = (data) => {
  return fetch(`https://api.linguafy.in/users/course-offer`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};